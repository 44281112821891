import { Component,OnInit, Input ,Output,EventEmitter} from '@angular/core';
@Component({
  selector: 'InputSeletion',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.css'],
  inputs: [
    'inputData',
    'selectionValue',
    'inputId',
  ],
   outputs: [
    'isNoValidation',
  ]
})
export class SelectionComponent implements OnInit {
public inputData:     any;
public selectionValue:  any;
public isNoValidation=new EventEmitter<boolean>();
public boxValidation:     boolean;
public inputId: string;
constructor(){
 }
ngOnInit() {
  if (!this.inputId) {
    this.inputId = this.inputData + '_' + new Date().getTime();
  }
this.inputData.value="";
this.isNoValidation.emit(false);
this.boxValidation=false;
    }
 
CheckValidation(){
//compare input value and every optionitem
     this.boxValidation=true;
     this.selectionValue.forEach(element => {
      if(element.Value.indexOf(this.inputData.value)!==-1){
          this.boxValidation=false;
      }
     });
     this.isNoValidation.emit(this.boxValidation);
}
}