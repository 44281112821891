import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from 'app/common/services/message/messageService'

@Component({
  selector: 'app-error-message-popup',
  templateUrl: './error-message-popup.component.html',
  styleUrls: ['./error-message-popup.component.css']
})
export class ErrorMessagePopupComponent implements OnInit {
  public isShow: boolean;

  constructor(private message: MessageService) { }

  ngOnInit() {

    this.message.getMultiFilesErrorMsg().subscribe(result => {
      if(result.hasOwnProperty('fileErrMsgPopup')){
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    })
  }

  ngAfterViewInit(): void {
    this.popupResize();
  }

  closeErrPop(){
    this.isShow = false;
  }


  /**
   * for custom popup resize position(top)
   * @return {void}@memberof ErrorMessagePopupComponent
   */
  popupResize(): void {
    $('.search_headbox').css({
      'top': ($(window).scrollTop()) / $('.all_box').height() * 100 + '%'
    });
  }

}
