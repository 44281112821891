<h2 mat-dialog-title class="title">
    <div class="title-content">Action Required</div>
    <i (click)="onStayClick()" class="close-icon mdui-icon material-icons">clear</i>
</h2>
<mat-dialog-content class="content">
    <div class="msg">
        <i class="mdui-icon material-icons warning m_r">error</i>
        <div>Your work has not been saved or submitted. Are you sure you want to leave this page?</div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="footer">
    <button class="submit_btn cancel fr" (click)="onLeaveClick()" style="width: 82px">
        <i class="mdui-icon material-icons sub_btn">close</i>
        <span class="btn_txt">Leave</span>
    </button>
    <button class="submit_btn confirm fr" (click)="onStayClick()" style="width: 82px">
        <i class="mdui-icon material-icons sub_btn">check</i>
        <span class="btn_txt">Stay</span>
    </button>
</mat-dialog-actions>