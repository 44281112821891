import { NgModule } from '@angular/core';
import { ClickBlankDirective } from './clickBlank.directive';
// import { TooltipDirective } from 'app/common/tooltip/tooltip.directive';
import { DragDropPreventDefaultDirective } from './dragDropPreventDefault.directive';

@NgModule({
  imports: [],
  declarations: [
    ClickBlankDirective,
    // TooltipDirective,
    DragDropPreventDefaultDirective
  ],
  exports: [
    ClickBlankDirective,
    // TooltipDirective,
    DragDropPreventDefaultDirective
  ]
})
export class DirectiveModule {

}