import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
import { PositioningService } from 'ngx-bootstrap/positioning';
import { TabelFilterDirective } from './tabel-filter.directive';
import { ColFilterDirective } from './col-filter.directive';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import {SimpleSearchComponent} from './component/simple-search.component';
import {SimpleDropdownComponent} from './component/simple-dropdown.component';
import {MuiltSearchComponent} from './component/muilt-search.component';
import {DateScopeComponent} from './component/date-scope.component';
import { TabelFilterService } from './service/tabel-filter.service';
import { FormsModule } from '@angular/forms';
import { FilterArrayPipe } from './pipe/filter-array.pipe';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
    ],
    declarations: [
        TabelFilterDirective,
        ColFilterDirective,
        SimpleDropdownComponent,
        SimpleSearchComponent,
        MuiltSearchComponent,
        DateScopeComponent,
        FilterArrayPipe
    ],
    exports: [
        TabelFilterDirective,
        ColFilterDirective,
        FilterArrayPipe
    ],
    providers: [
    // TabelFilterService
    ]
})
export class TabelFilterModule {
  static forRoot(): ModuleWithProviders<TabelFilterModule> {
    return {
      ngModule: TabelFilterModule,
      providers: [ComponentLoaderFactory, PositioningService]
    };
  }
}
