import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateUtil } from 'app/common/util/date-util';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.css'],
  inputs: [
    'inputData',
    'enableChange',
    'inputId',
  ],
  outputs: [
    'change'
  ]
})
export class DateComponent implements OnInit {

  public enableChange: any = false;
  public inputData: any;
  public bsConfig: any;
  public change = new EventEmitter<any>();
  public inputId: string;
  constructor(private datepipe: DatePipe, ) { }

  ngOnInit() {
    if (!this.inputId) {
      this.inputId = this.inputData.filedId + '_' + new Date().getTime();
    }
    if (this.inputData.value != "" && this.inputData.value != null) {
      if (this.inputData.isRead) {
        this.inputData.value = this.datepipe.transform(this.inputData.value, 'dd-MMM-yyyy')
      } else {
        var cvalue = new Date(this.datepipe.transform(this.inputData.value, 'dd MMM yyyy'));
        this.inputData.value = cvalue;
      }
    }
    this.bsConfig = { dateInputFormat: 'DD-MMM-YYYY', showWeekNumbers: false }
  }

  onChanged(e: any) {
    if (this.inputData.value !== "" && this.inputData.value !== null) {
      this.inputData.isValidation = true;
    }

    if (true === this.enableChange) {
      console.log("model to change");
      this.change.emit();

    }

  }

  dateBlur (event) {
    var target = event.target ? event.target : event.srcElement;
    if (event && !DateUtil.isValid(target.value)) {
        target.value = '';
    }
  }
}
