<div class="mask"></div>
<div class="search_headbox">
    <div class="titlBox">
        <p class="team_title fl">Search Supplier</p>
        <i (click)="closeSupplierNameSearch()" class="mdui-icon material-icons close_icon fr">clear</i>
    </div>
    <div class="tab_content" style="padding-bottom: 0px;">
        <div class="tab_line" style="width: auto;margin: 0;">
            <div class="wid_25 v_mid">
                <input id="spplier_not_in_sys" (change)="isSearch()" class="v_mid dsNone" name="supplierNotSystem" type="checkbox" value="" [checked]="isNoSyData"/>
                <label for="spplier_not_in_sys" class="yet" > Supplier not yet in System </label>
            </div>
            <div class="wid_25 v_mid ml_4">
                <input id="spplier_no_sap_id" (change)="isNoSAP()" class="v_mid dsNone" name="noSAPIdRequired" type="checkbox" value="" [checked]="isNoSAPData"/>
                <label for="spplier_no_sap_id" class="yet" > No SAP ID Required </label>
            </div>
        </div>
        <div class="tab_line" *ngIf="!isNoSyData && !isNoSAPData" style="width: auto;display: flex;margin: 0;margin-right: 15px;">
               <div class="wid_25" style="flex: 1;">
                <app-single-select [inputData]="searchFormData.PrimaryCountry" [selectionValue]="recordCountry"
                    (change)="searchBtnValidate()"></app-single-select>
            </div>
            <div class="wid_37 mod_4" style="flex: 1.5;">
                <app-input [inputData]="searchFormData.SupplierName" (change)="searchBtnValidate()"
                    (keyup)="searchBtnValidate()"></app-input>
            </div>
            <div class="wid_25 mod_4" style="flex: 1;">
                <app-input [inputData]="searchFormData.CompanyCode" (change)="searchBtnValidate()"
                    (keyup)="onClear($event.target.value)"></app-input>
            </div>
        </div>
        <div class="tab_line" *ngIf="isNoSyData || isNoSAPData" style="width: auto;display: flex;margin: 0;margin-right: 15px;">
               <div class="wid_25" style="flex: 1;">
                <app-single-select [(inputData)]="searchFormData.PrimaryCountry"
                    (change)="primaryCountryChange()" [selectionValue]="recordCountry"></app-single-select>
            </div>
            <div class="wid_37 mod_4" style="flex: 1.5;">
                <app-input [inputData]="searchFormData.SupplierName"></app-input>
            </div>
            <div class="wid_25 mod_4" style="flex: 1;">
                <type-ahead noResultMsg="Company code does not exist." [limit]="10" [minMatchSize]="1"
                    [(text)]="searchFormData.CompanyCode.value" [sourceList]="companyCodeList">
                    <app-input [inputData]="searchFormData.CompanyCode" (keyup)="onClear($event.target.value)"
                        ></app-input>
                </type-ahead>
            </div>
        </div>
        <div *ngIf="!isNoSyData && !isNoSAPData" class="tab_line rele" style="margin: 0;padding: 0;padding-right: 15px;padding-bottom: 15px;">
            <button type="submit" class="submit_btn fr select_btn"
                (click)="getSupplierName()" [disabled]="isLoading||isSearchBtnDisabled">
                <i class="mdui-icon material-icons sub_btn">search</i>
                <span class="btn_txt">Search</span>
            </button>
        </div>
        <div class="tab_line rele" *ngIf="isNoSyData || isNoSAPData" style="margin: 0;padding: 0;padding-right: 15px;">
            <button (click)="selectOk()" type="submit" class="submit_btn fr ok_btn" [disabled]="okvalidation">
                <i class="mdui-icon material-icons sub_btn">check</i>
                <span class="btn_txt">OK</span>
            </button>
            <button type="submit" class="submit_btn fr cancle_btn" style="margin-bottom: 15px;" (click)="closeSupplierNameSearch()">
                <i class="mdui-icon material-icons sub_btn">close</i>
                <span class="btn_txt">Cancel</span>
            </button>
        </div>        
    </div>
    <div [hidden]="!isResultShow">
    <div class="table-loading-shade"
    *ngIf="isLoadingResults">
 <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>
        <div class="search_context" style="padding: 0;margin-top: 0px;">
            <div class="resultTable" style="padding: 0px 33px 0 36px;margin-bottom: 20px;">
                <button class="btn btn-link tabel-filter clear-filter" (click)="tabelfilter.cleanFilter()"
                    [disabled]="!tabelfilter.hasAllData()">
                    <i class="mdui-icon material-icons btn_txt clearFilterIcon">clear</i>
                    <span>Clear All Filters</span>
                </button>
                <mat-table tabelfilter #tabelfilter="tabel-filter" class="tab" #table [dataSource]="dataSource"  matSort [matSortActive]="sort.active" matSortDisableClear [matSortDirection]="sort.direction" style="margin-top:0px;">
                        <ng-container matColumnDef="CompanyDescription">
                            <mat-header-cell class="tab_txt TableHeader" *matHeaderCellDef  mat-sort-header tooltip="Company Description"
                                [colfilter]=""
                                [isLike]="true"
                                [property]="'Company'" 
                                [checkeds]="pageData.getFilterValue('Company')"
                                [type]="'simple-search'"> Company Description 
                            </mat-header-cell>
                            <mat-cell [ngClass]="{'selectedclass': highClass===i,'mat-cell': highClass===i,'notselectedclass': highClass!==i}" *matCellDef="let RecordResult" [tooltip]="RecordResult.CompanyDescription"> {{RecordResult.CompanyDescription}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="CompanyCode">
                            <mat-header-cell class="tab_txt TableHeader" *matHeaderCellDef  mat-sort-header tooltip="Company Code"
                                [colfilter]="compCo"
                                [property]="'CompanyCode'" 
                                [checkeds]="pageData.getFilterValue('CompanyCode')"
                                [type]="'muilt-search'"
                                (filterCondition)="GetCompanyCode()"> Company Code </mat-header-cell>
                            <mat-cell [ngClass]="{'selectedclass': highClass===i,'mat-cell': highClass===i,'notselectedclass': highClass!==i}" *matCellDef="let RecordResult" [tooltip]="RecordResult.CompanyCode"> {{RecordResult.CompanyCode}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="SupplierID">
                            <mat-header-cell class="tab_txt TableHeader" *matHeaderCellDef  mat-sort-header tooltip="Supplier SAP ID"
                                [colfilter]=""
                                [isLike]="true"
                                [property]="'SupplierID'" 
                                [checkeds]="pageData.getFilterValue('SupplierID')"
                                [type]="'simple-search'"> Supplier SAP ID </mat-header-cell>
                            <mat-cell [ngClass]="{'selectedclass': highClass===i,'mat-cell': highClass===i,'notselectedclass': highClass!==i}" *matCellDef="let RecordResult" [tooltip]="RecordResult.SupplierID"> {{RecordResult.SupplierID}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="SupplierName">
                            <mat-header-cell class="tab_txt TableHeader" *matHeaderCellDef mat-sort-header tooltip="Supplier Name"
                                [colfilter]=""
                                [isLike]="true"
                                [property]="'Title'" 
                                [checkeds]="pageData.getFilterValue('Title')"
                                [type]="'simple-search'"> Supplier Name </mat-header-cell>
                            <mat-cell [ngClass]="{'selectedclass': highClass===i,'mat-cell': highClass===i,'notselectedclass display-inline-block': highClass!==i}" *matCellDef="let RecordResult" [tooltip]="RecordResult.SupplierName"> {{RecordResult.SupplierName}} </mat-cell>
                        </ng-container>
                  
                 
            
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; let i = index; columns: displayedColumns; " (click)="selectedOne(row,i)" [id]="'tr'+i" [ngClass]="{'selectedLineclass': highClass===i,'mat-cell': highClass===i,'notselectedLineclass': highClass!==i}" class="trHover"></mat-row>
            
                  </mat-table>
                  <div *ngIf="!isLoadingResults && resultsLength === 0" class="load_box">
                        No Record Found!
                  </div>
                  <new-paginator #paginator
                  [length]="resultsLength"
                  [pageSize]="10"
                  (onPageRefresh)="onPageRefresh()">
                  </new-paginator>
        
              </div>
            </div>

            <div *ngIf="!isNoSyData && !isNoSAPData" class="tab_line rele btnBox" style="margin-left: 0;padding-right: 33px;padding-bottom: 0px;">
                <button type="submit" style="margin-right:0;" class="submit_btn fr select_btn " [disabled]="isDisable"
                    (click)="selectSupplierName()">
                    <i class="mdui-icon material-icons sub_btn">check</i>
                    <span class="btn_txt">Select</span>
                </button>
                <button type="submit" class="submit_btn fr cancle_btn" style="margin-bottom: 15px;" (click)="closeSupplierNameSearch()">
                    <i class="mdui-icon material-icons sub_btn">close</i>
                    <span class="btn_txt">Cancel</span>
                </button>
            </div>
            </div>
    </div>
