import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { MessageService } from 'app/common/services/message/messageService';
import { RecordType } from 'app/common/enum/type.enum';
import { A11yModule } from '@angular/cdk/a11y';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.css'],
  inputs: [
    'inputData'
  ],
  outputs:[
    'outputData'
  ]
})
export class RadioComponent implements OnInit {

  public inputData: any;
  public radioValue :any;
  public FileListlength: number;
  public outputData =new EventEmitter();
  public MultiDocsInValid: boolean;
  public ToolTip: any;
  public newValue: boolean;
  public ParentorSupportingRecord: any;
  constructor(private message: MessageService,) { }

  ngOnInit() {
    if(this.inputData.toolTip == true && this.inputData.selectTip == true){
      this.newValue = true;
      this.ToolTip = this.inputData.oldValue;
    }else{
      this.newValue = false;
      this.ToolTip = "";
    }
    if (this.inputData.value == RecordType.Parent) {
      this.ParentorSupportingRecord = "Parent Record";
    } else {
      this.ParentorSupportingRecord = "Supporting Record";
    }
    // this.radioValue = "";
    this.message.getDocToRadioErr().subscribe(result => {
      if(result.hasOwnProperty('FileListlength')){
        this.FileListlength = result.FileListlength;
      }
    })

  }


  onChanged(event: any) {
    // this.radioValue = event;
    // if (this.radioValue !== "") {
    //   this.inputData.isValidation = true;
    //   this.outputData.emit(this.radioValue);
    // }
  }

  handleClick(evt: any, inputData: any) {
    var target = evt.target;
    if (this.FileListlength > 1 && target.value == RecordType.Parent) {
      this.message.sendMultiFilesErrorMsg({ fileErrMsgPopup: inputData });
    } else {
      inputData.value = target.value;
      this.inputData.isValidation = true;
      this.outputData.emit(inputData.value);
    }
  }

  isSelected(name: string): boolean {
    return (this.inputData.value === name); // if current radio button is selected, return true, else return false  
  }

}
