import { Component, Input, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'multi-select-button',
    templateUrl: './multi-select-button.component.html',
    styleUrls: ['./multi-select-button.component.css'],
})
export class MultiSelectButtonComponent implements OnInit {
    
    @ViewChild("expand_more")
    public expandMore: ElementRef;

    @Input()
    public inputData: any;
    @Input()
    public selectionValue: string[];
    @Input()
    public set excludes(excludes: string[]) {
        this.excludeOptions = excludes;
        this.removeExcludeOptions();
    }
    @Input()
    public inputId: string;
    @Input()
    public labelName: string;
    @Input()
    public resetTooltip: string;
    @Input()
    public btnTooltip: string;
    @Input()
    public placeholder: string;
    @Input()
    public set selectOptions(options: { value: string; label?: string; }[]) {
        // if no label, dafault show value as label
        this.sourceOptions = options.map(option => {
            return {
                value: option.value,
                label: option.label ? option.label : option.value,
            };
        });
        this.removeExcludeOptions();
    }

    @Input()
    public set btnDisabled(isBtnDisabled: boolean) {
        if (isBtnDisabled) {
            this.isBtnDisable = true;
            this.isShowSelect = false;
            this.reset();
        } else {
            this.isBtnDisable = false;
        }
    }

    @ViewChild("options_tab")
    public optionsTab: ElementRef;

    public excludeOptions: string[];
    public sourceOptions: { value: string; label: string; }[] = [];
    public options: { value: string; label: string; }[] = [];

    public isBtnDisable: boolean;
    public isShowSelect: boolean;
    public searchText: string;
    public isShowOptions: boolean;
    public isSelectedAll: boolean;

    public offsetWidth: number = 0;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        if (!this.inputId) {
            this.inputId = this.inputData.filedId + '_' + new Date().getTime();
        }
        if (!this.selectionValue) {
            if (!this.inputData.value) {
                this.selectionValue = [];
            } else {
                this.selectionValue = this.inputData.value.split(',');
            }
        }
    }

    ngDoCheck() {
        if (!this.searchText && this.options.length > 0) {
            if (this.selectionValue.length === this.options.length) {
                this.isSelectedAll = true;
            } else {
                this.isSelectedAll = false;
            }
        }
        this.inputData.value = this.selectionValue.join(',');
        if (this.selectionValue.length > 1 || this.selectionValue.length === 1 && this.selectionValue[0]) {
            this.isShowSelect = true;
        }
    }

    ngAfterViewChecked() {
        if (!this.inputData.isRead && !this.inputData.IsDisabled && this.expandMore) {
            this.offsetWidth = (<HTMLElement>this.expandMore.nativeElement).offsetWidth;
            this.changeDetectorRef.detectChanges();
        }
    }
    
    public getSelectedDisplayText(): string {
        if (!this.selectionValue) {
            return '';
        }
        return this.selectionValue.map(item => {
            let label = '';
            this.options.forEach(option => {
                if (option.value === item) {
                    label = option.label;
                }
            });
            return label;
        }).join(', ');
    }

    public reset() {
        this.searchText = '';
        this.selectionValue = [];
    }

    public isChecked(value): boolean {
        return this.selectionValue.indexOf(value) !== -1;
    }

    public toggleSelectAll(isSelectedAll: boolean): void {
        if (isSelectedAll) {
            this.selectionValue = this.options.map(option => option.value);
        } else {
            this.selectionValue = [];
        }
    }

    public toggleChecked(value): void {
        // let oldSelectionValue = this.selectionValue;
        // this.selectionValue = this.options.filter(option => {
        //     if (option.value === value) {
        //         return oldSelectionValue.indexOf(value) === -1;
        //     } else {
        //         return oldSelectionValue.indexOf(option.value) !== -1
        //     }
        // }).map(option => {
        //     return option.value;
        // });
        let valueIndex = this.selectionValue.indexOf(value);
        if (valueIndex < 0) {
            this.selectionValue.push(value);
        } else {
            this.selectionValue.splice(valueIndex, 1);
        }
    }

    public toggleOptions(): void {
        if (!this.isShowOptions) {
            this.searchText = '';
        }
        this.isShowOptions = !this.isShowOptions;
    }

    public closeOptions(): void {
        this.isShowOptions = false;
    }

    public isShowOption(option: {value: string; label: string}): boolean {
        if (!this.searchText) {
            return true;
        }
        return option.label.toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) !== -1;
    }

    public searchTextChange(): void {
        $(<HTMLElement>this.optionsTab.nativeElement).scrollTop(0);
    }

    private removeExcludeOptions(): void {
        this.options = this.sourceOptions.filter(option => {
            if (this.excludeOptions && this.excludeOptions.indexOf(option.value) !== -1) {
                return false;
            } else {
                return true;
            }
        });
    }
}


