<section>
    <div class="rele">
        <div class="up_title another_up" (click)="isRiskCompliance()">
            <i *ngIf="isMore" class="mdui-icon material-icons expend_icon">expand_more</i>
            <i *ngIf="!isMore" class="mdui-icon material-icons expend_icon">chevron_right</i>
            Risk and Compliance
        </div>
        <div class="tab_content isRiskCompliance">
            <div class="tab_line">
                <div class="row">
                    <div class="col-6 col-lg-3">
                        <app-radioYN [inputData]="recordFormData.MarketIntelligence"></app-radioYN>
                    </div>
                    <div class="col-6 col-lg-3">
                        <app-radioYN [inputData]="recordFormData.InfoSec"></app-radioYN>
                    </div> 
                    <!-- <div class="mod_2 wid_17">
                        <app-radioYN [inputData]="recordFormData.MinimumSpendCommitment"></app-radioYN>
                    </div> -->
                    <div class="col-6 col-lg-3">
                        <app-radioYN [inputData]="recordFormData.GDPR"></app-radioYN>
                    </div>
                    <div class="col-6 col-lg-3">
                        <app-radioYN [inputData]="recordFormData.USDExceeded"></app-radioYN>
                        <span *ngIf="!recordFormData.USDExceeded.isRead">
                            <span *ngIf="recordFormData.USDExceeded.value === 'Yes';" [ngClass]="{'red_message': true}">
                                Please note this will go to Controllership for review.</span>
                        </span>
                    </div>
                </div> 
                <div class="row mard_top_10">
                    <div class="col-6 col-lg-3">
                        <app-radioYN [inputData]="recordFormData.SupplierCodeOfConduct"></app-radioYN>
                    </div>
                    <div class="col-6 col-lg-3">
                        <app-radioYN [inputData]="recordFormData.KeySupplier" (change)="KeySupplierClick()"></app-radioYN>
                    </div>
                    <div class="col-3 col-lg-6">
                         <!-- <app-radioAST [inputData]="recordFormData.AgreementSpendType" (change)="ASTClick()"></app-radioAST> -->
                         <app-radioAST [inputData]="recordFormData.AgreementSpendType"></app-radioAST>
                        </div>
                    <div class="col-6 col-lg-3" *ngIf="recordFormData.LegacyTool.value === 'Yes'">
                        <app-radioY [inputData]="recordFormData.LegacyTool"></app-radioY>
                    </div>
                    <!-- <div class="mod_2 wid_14">
                        
                    </div>     -->
                </div>  
                <div class="row mard_top_10" [(ngModel)]="recordFormData.CompanyCode" *ngIf="recordFormData.CompanyCode.value === '3300' || recordFormData.CompanyCode.value === '3306'">
                    <div class="col-6 col-lg-3">
                        <!-- <app-radioCloud [inputData]="recordFormData.CloudServicesInformation"></app-radioCloud> -->
                        <app-radioYN (change)="changeYN()" [inputData]="recordFormData.CloudServicesInformation" ></app-radioYN>
                    </div>
                    <div class="col-6 col-lg-3">
                        <app-input *ngIf="recordFormData.CloudServicesInformation.value === 'Yes';" [inputData]="recordFormData.WhichCountryLocation" ></app-input>
                    </div>
                </div>           
            </div>
        </div>
    </div>
</section>