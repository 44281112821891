import { Directive, Input, HostListener, ElementRef, Renderer2, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[clickBlank]'
})

export class ClickBlankDirective {

    @Input('clickBlank') clickCallback: any;

    constructor(private elementRef: ElementRef, private renderer: Renderer2, private viewContainer: ViewContainerRef){
        this.elementRef = elementRef;
    }

    @HostListener('document:click', ['$event'])
    onClick(e){
        if (!(this.elementRef.nativeElement == e.target || 
            this.elementRef.nativeElement.contains(e.target))){
            this.clickCallback(this.viewContainer);
        }
        // this.elementRef.nativeElement.style.backgroundColor = 'yellow';
        // this.highlight('yellow');
    }

    // highlight(color: string) {
    //     this.renderer.setElementStyle(this.elementRef.nativeElement, 'backgroundColor', color);
    // }

}

