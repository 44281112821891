import { CompanyCodeService } from './company-code.service';
import { Component, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { Factory } from '../../../../../app.factory';
import { HPEStaticService } from "../../../../../common/services/staticService/HPEStaticService";
import { configurationIDs } from 'app/common/enum/configurationIDs';
import { MessageService } from 'app/common/services/message/messageService';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NewPaginatorComponent } from '../../../component/new-paginator/new-paginator.component';
import { SupplierNameService, PageResult } from "../../../../../common/services/NewRecord/supplierNameSearchService";
import { merge, of as observableOf, of } from 'rxjs';
import { catchError, map, startWith, switchMap} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TabelFilterDirective } from 'app/modules/main/component/tabel-filter';
import { ColFilterDirective } from 'app/modules/main/component/tabel-filter';
import { PageNavigationService, PageData } from 'app/common/services/staticService/page-navigation.service';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'supplierNameSearch',
  templateUrl: './supplierNameSearch.component.html',
  styleUrls: ['./supplierNameSearch.component.css'],
  providers: [Factory],
  inputs: ['recordCountry',
  ],
  outputs: [
    'searchDataFn',
    'isShowOrHideFn'
  ]
})
export class supplierNameSearchComponent {
  //input
  public recordCountry: any;
  //output
  public searchDataFn = new EventEmitter();
  public isShowOrHideFn = new EventEmitter();

  service: any;
  public supplierList: any = [];
  public highClass: any;
  public selectSupplier: any;
  public isResultShow: boolean;
  //public isYetShow: boolean = true;
  public isLoading: boolean;
  public isSearchBtnDisabled: boolean;
  public isNoValidation: boolean;
  public isDisable: boolean = true;
  public searchFormData: any;
  public noSupplierList: any = [];
  // public isNoSAPShow: boolean = false;
  public isNoSyData: boolean = false;
  public isNoSAPData: boolean = false;
  public okvalidation: boolean = false;
  public flag = false;
  public isLoadingResults = false;
  public resultsLength = 0;
  public isSearchResults = false;
  public compCo: any = [];
  public searchstr:any;


  public displayedColumns = [
    'SupplierName',
    'SupplierID',
    'CompanyCode',
    'CompanyDescription', 
  ];
  dataSource = new MatTableDataSource();
  @ViewChild(NewPaginatorComponent) paginator: NewPaginatorComponent;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  constructor(
    private factory: Factory,
    private HPEService: HPEStaticService,
    private message: MessageService,
    public companyCodeService: CompanyCodeService,
    private supplierService: SupplierNameService,
    private pageNavigation: PageNavigationService,
    public route: ActivatedRoute,
    private router: Router,
  ) { }
  @ViewChild(TabelFilterDirective)
  public tabelFilter: TabelFilterDirective;

  public companyCodeList: string[];
  private pageData: PageData;

  ngOnInit() {
    this.service = this.factory.getService();
    this.resetData();
    this.primaryCountryChange();
    console.log(this.sort);
    Object.assign(this.sort, { active: 'SupplierName', direction: 'asc' });
    this.pageData = this.pageNavigation.getPageDataByRoute(this.route);
    if (this.pageData.hasPageDataValue(this.route)) {
     this.pageData.assignPageData(this.route);
     this.sort.active = this.pageData.sort;
     this.paginator.pageIndex = this.pageData.pageNum;
     this.sort.direction = this.pageData.sortDirection;
    } else {
     this.pageData.rest();
    }
  }

  GetCompanyCode() {
    (this.factory.getService() as any).GetCompanyCode()
      .then((compCo) => {
        this.compCo = compCo.map((m) => m.Key);
      });
  }

  ngAfterViewInit(): void {
    this.popupResize();
    //this.Database = new SupplierNameService(this.factory, this.tabelFilter);
    this.sort.sortChange.subscribe(() => this.paginator.pageIndexNoEmit = 0);
    this.tabelFilter.filterItems.subscribe(() => this.paginator.pageIndexNoEmit = 0);

    merge(this.sort.sortChange, this.paginator.page,this.tabelFilter.filterItems)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isDisable = true;
          this.highClass = -1;
          this.isLoadingResults = true;
          this.pageData
          .setSort(this.sort.active)
          .setPageNum(this.paginator.pageIndex)
          .setSortDirection(this.sort.direction)
          .setFilters(this.tabelFilter.getFilterQueryMap());
          this.pageNavigation.refreshRoute(this.router, this.route, this.pageData);
          var pageindex = 0;
          if (!this.isSearchResults) {
            pageindex = this.paginator.pageIndex;
          }
          if (this.flag) {
            const filterString = this.tabelFilter.getFilterString();
              if(filterString!=""){
                  const searchstring = this.searchstr ? (this.searchstr + filterString): filterString ;
                  var encodeString=encodeURIComponent(searchstring);
                  return this.supplierService!.getPage(
                    this.sort.active, this.sort.direction, pageindex, this.paginator.pageSize,encodeString);
              } else{
                return this.supplierService!.getPage(
                  this.sort.active, this.sort.direction, pageindex, this.paginator.pageSize,encodeURIComponent(this.searchstr));
              }
          } else {
            var result = new PageResult();
            result.Total = 0;
            return of(result);
          }
        }),
        map(data => {

          this.isLoadingResults = false;
          this.isSearchResults = false;

          this.resultsLength = data.Total;

          return data.Data;
        }),
        catchError(() => {

          this.isSearchResults = false;
          this.isLoadingResults = false;
          return observableOf([]);
        })
      ).subscribe(data => {
        this.dataSource.data = data;
        this.supplierList = data;
        this.flag = true;
      });

  }

  resetData() {
    this.isSearchBtnDisabled = true;
    this.isResultShow = false;
    this.isNoValidation = false;
    this.isDisable = true;
    this.highClass = -1;
    var ids = configurationIDs.supplierSearchForm.SearchFormIDs;
    var formDataJsonList = this.HPEService.getFieldbyFiledId(ids);
    this.searchFormData = this.HPEService.setFormDataToJson(formDataJsonList);
    this.searchFormData.PrimaryCountry.IsRequired = false;
    this.searchFormData.SupplierName.IsRequired = false;
    this.searchFormData.CompanyCode.IsRequired = false;
    this.searchFormData.CompanyCode.IsDisabled = false;
    this.searchFormData.SupplierName.value = "";
    this.searchFormData.PrimaryCountry.value = "";
    // this.dataSource.data = null;
    this.supplierList = null;
    this.resultsLength = 0;
    //search button disable function
    var requirelist = this.HPEService.getFormdataFieldNames(ids);
    requirelist.forEach(element => {
      if (this.searchFormData[element].value != "" && this.searchFormData[element].value != null) {
        this.isSearchBtnDisabled = false;
      }
    });
    //search button disable function end
  }
  //search button disable function
  searchBtnValidate() {
    this.isSearchBtnDisabled = true;
    var ids = configurationIDs.supplierSearchForm.SearchFormIDs;
    var requirelist = this.HPEService.getFormdataFieldNames(ids);
    requirelist.forEach(element => {
      if (element == "PrimaryCountry" || element == "SupplierName" || element == "CompanyCode") {
        if (this.searchFormData[element].value != "" && this.searchFormData[element].value != null) {

          this.isSearchBtnDisabled = false;
          return false;
        }
      }
    });
  }
  //search button disable function end
  getSupplierName() {
    this.highClass = -1;
    this.isResultShow = true;
    this.isLoadingResults = true;
    this.supplierList = [];
    var seachDataList = [];
    if (this.searchFormData.PrimaryCountry.value == 'Avanade USA') {
      this.searchFormData.CountryKey.value = 'United States';
      this.searchFormData.CompanyCodeOwnedByCd.value = 'AVA';
    } else if (this.searchFormData.PrimaryCountry.value == 'Avanade UK') {
      this.searchFormData.CountryKey.value = 'United Kingdom';
      this.searchFormData.CompanyCodeOwnedByCd.value = 'AVA';
    } else if (this.searchFormData.PrimaryCountry.value.substring(0, 7) == 'Avanade') {
      this.searchFormData.CountryKey.value = this.searchFormData.PrimaryCountry.value.substring(8, this.searchFormData.PrimaryCountry.value.length);
      this.searchFormData.CompanyCodeOwnedByCd.value = 'AVA';
    } else if (this.searchFormData.PrimaryCountry.value == '') {
      this.searchFormData.CountryKey.value = '';
      this.searchFormData.CompanyCodeOwnedByCd.value = '';
    } else {
      this.searchFormData.CountryKey.value = this.searchFormData.PrimaryCountry.value;
      this.searchFormData.CompanyCodeOwnedByCd.value = 'ACN';
    }
    seachDataList.push(this.searchFormData.SupplierName);
    seachDataList.push(this.searchFormData.CompanyCodeOwnedByCd);
    seachDataList.push(this.searchFormData.CountryKey);
    seachDataList.push(this.searchFormData.CompanyCode);
    this.searchstr = this.configeSearchString(seachDataList);
    this.supplierService.teamSearchRecordData = encodeURIComponent(this.searchstr);
    Object.assign(this.sort, { active: 'SupplierName', direction: 'asc' });
    this.tabelFilter.cleanFilter(null);
    this.paginator.page.emit()

  }

  onPageRefresh() {
    this.paginator.pageIndexNoEmit = 0
  }
  configeSearchString(formData) {
    var result = "type:\"Supplier\"";
    formData.forEach(res => {
      if (res.value != "") {
        if (res.searchClauseName == 'CountryKey') {
          res.searchClauseName = 'PrimaryCountry';
        }
        if (res.searchClauseName == 'PrimaryCountry' || res.searchClauseName == 'CompanyCodeOwnedByCd') {
          result = result + " and " + res.searchClauseName + ":\"" + res.value + "\"";
        } else {
          if (res.searchClauseName == 'typedTitle') {
              var value=JSON.parse(JSON.stringify(res.value));
              value = value.replace(/"/g, '\\"');
              result = result + " and " + res.searchClauseName + ":\"*" + value + "*\"";
          } else
            result = result + " and " + res.searchClauseName + ":\"*" + res.value + "*\"";
        }
      }
    });
    // result += ` and not SupplierSource: "Awaiting SAP ID","Not required" and not SupplierID: "Awaiting SAP ID","Not required",""`;
    result += ` and not SupplierSource: "Awaiting SAP ID","Not required" and not SupplierID: "Awaiting SAP ID","Not required","" and not SupplierID:02* and not SupplierID:03* and not SupplierID:A* `;
    return result;
  }
  selectSupplierName() {
    // secondarySupplierDataSource
    let keySupplierList = [];
    this.supplierList.forEach(supplier => {
      if (supplier.SupplierUri != this.selectSupplier.SupplierUri) {
        keySupplierList.push(supplier);
      }
    });
    // keySupplier
    var ids = configurationIDs.supplierSearchForm.SearchFormIDs;
    var supReqlist = this.HPEService.getFieldbyFiledId(ids);
    var KeySupplierData = this.HPEService.setFormDataToJson(supReqlist);
    KeySupplierData.SupplierName.value = this.selectSupplier.SupplierName;
    KeySupplierData.PrimaryCountry.value = this.selectSupplier.PrimaryCountry;
    KeySupplierData.SupplierID.value = this.selectSupplier.SupplierID;
    KeySupplierData.CompanyCode.value = this.selectSupplier.CompanyCode;
    KeySupplierData.SupplierUri.value = this.selectSupplier.SupplierUri;
    KeySupplierData.CompanyDescription.value = this.selectSupplier.CompanyDescription;
    
    var keySupplierOption = this.HPEService.setFormDataToJson(supReqlist);
    keySupplierOption.SupplierName.value = this.searchFormData.SupplierName.value;
    keySupplierOption.PrimaryCountry.value = this.searchFormData.PrimaryCountry.value;
    keySupplierOption.CompanyCode.value = this.searchFormData.CompanyCode.value;
    keySupplierOption.CompanyDescription.value = this.searchFormData.CompanyDescription.value;

    let KeySupplier = { selectSupplier: KeySupplierData, supplierList: keySupplierList, selectoption: keySupplierOption }
    this.searchDataFn.emit(KeySupplier);
    let ojgShowOrHide = {
      isAddScndSupplier: true,
      isKeySupplierShow: false
    }
    this.isShowOrHideFn.emit(ojgShowOrHide);
    this.message.sendSupplierFlag({ supplier: KeySupplierData });
  }

  // click Not yet in System
  isSearch() {
    this.resetData();
    this.isNoSyData = !this.isNoSyData;
    if (this.isNoSyData) {
      this.isNoSAPData = false;
      this.ResetField();
    } else {
      this.ResetDefaultField();
    }
  }
  // click No SAP ID Required
  isNoSAP() {
    this.resetData();
    this.isNoSAPData = !this.isNoSAPData;
    if (this.isNoSAPData) {
      this.isNoSyData = false;
      this.ResetField();
    } else {
      this.ResetDefaultField();
    }
  }
  // to origin
  ResetDefaultField() {
    this.searchFormData.SupplierName.IsRequired = false;
    this.searchFormData.CompanyCode.IsRequired = false;
    this.searchFormData.SupplierName.labelName = "Supplier Name";
    this.searchFormData.SupplierName.value = "";
    this.searchFormData.PrimaryCountry.value = "";
    this.searchFormData.CompanyCode.value = "";
    this.searchFormData.CompanyCodeOwnedByCd.value = "";
    this.searchFormData.CountryKey.value = "";
    this.searchFormData.SupplierName.isValidation = true;
    this.searchFormData.CompanyCode.isValidation = true;
  }
  // to other option
  ResetField() {
    this.searchFormData.SupplierName.IsRequired = true;
    this.searchFormData.CompanyCode.IsRequired = true;
    this.searchFormData.SupplierName.labelName = "Suggested Supplier Name";
    this.searchFormData.SupplierName.value = "";
    this.searchFormData.PrimaryCountry.value = "";
    this.searchFormData.CompanyCode.value = "";
    this.searchFormData.CompanyCodeOwnedByCd.value = "";
    this.searchFormData.CountryKey.value = "";
    this.searchFormData.SupplierName.isValidation = true;
    this.searchFormData.CompanyCode.isValidation = true;
    this.resultsLength = 0;
  }

  selectOk() {
    this.okvalidation = true;
    if (this.searchFormData.SupplierName.value == "") {
      this.searchFormData.SupplierName.isValidation = false;
      this.okvalidation = false;
    }
    if (this.searchFormData.CompanyCode.value == "") {
      this.searchFormData.CompanyCode.isValidation = false;
      this.searchFormData.CompanyCode.placeholder = "Field must be completed";
      this.okvalidation = false;
      return;
    } else {
      var seachDataList = [];
      seachDataList.push(this.searchFormData.CompanyCode);
      if (this.searchFormData.PrimaryCountry !== "") {
        seachDataList.push(this.searchFormData.PrimaryCountry);
      }
      var searchstr = this.configeSearchString(seachDataList);
      var searchData = {
        searchString: searchstr,
        customProperty: [
          this.searchFormData.CompanyCode.customPropertyName
        ]
      }

      if (this.companyCodeList.indexOf(this.searchFormData.CompanyCode.value) === -1) {
        this.searchFormData.CompanyCode.value = "";
        this.searchFormData.CompanyCode.isValidation = false;
        this.searchFormData.CompanyCode.placeholder = "Invalid Value";
        this.okvalidation = false;
        return;
      }
      if (this.searchFormData.SupplierName.isValidation == true) {
        if (this.isNoSyData) {
          this.searchFormData.SupplierID.value = "Awaiting SAP ID";
        } else if (this.isNoSAPData) {
          this.searchFormData.SupplierID.value = "Not required";
        }
        this.noSupplierList.SupplierID = this.searchFormData.SupplierID;
        this.noSupplierList.PrimaryCountry = this.searchFormData.PrimaryCountry;
        this.noSupplierList.SupplierName = this.searchFormData.SupplierName;
        this.noSupplierList.CompanyCode = this.searchFormData.CompanyCode;
        this.noSupplierList.SupplierUri = this.searchFormData.SupplierUri;
        this.noSupplierList.SupplierCountry = this.searchFormData.SupplierCountry;
        this.noSupplierList.SupplierCountry.value = this.searchFormData.PrimaryCountry.value;
        this.noSupplierList.CompanyDescription = this.searchFormData.CompanyDescription;
        this.selectSupplier = this.noSupplierList;
        let KeySupplier = { selectSupplier: this.selectSupplier, supplierList: [] }
        this.searchDataFn.emit(KeySupplier);
        this.isShowOrHideFn.emit({
          isAddScndSupplier: true,
          isKeySupplierShow: false
        });
        this.message.sendSupplierFlag({ supplier: this.selectSupplier });
        this.okvalidation = false;
      }
      this.okvalidation = false;
    }

  }
  selectedOne(selectedSupplier, index) {
    this.selectSupplier = selectedSupplier;
    this.highClass = index;
    this.isDisable = false;
  }
  NoValidation(event: boolean) {
    this.isNoValidation = event;
  }

  closeSupplierNameSearch() {
    let ojgShowOrHide = {
      isAddScndSupplier: false,
      isKeySupplierShow: false
    }
    this.isShowOrHideFn.emit(ojgShowOrHide);
  }
  onClear(event: any) {
    //var valedData = this.searchFormData.CompanyCode.value.replace(/[^\d]/g, '').trim();
    var valedData = event.replace(/[^\d]/g, '').trim();
    console.log("Exg", valedData);
    if (valedData.length > 6) {
      valedData = valedData.substring(0, 6);
    }
    this.searchFormData.CompanyCode.value = valedData;
    this.isSearchBtnDisabled = true;
    var ids = configurationIDs.supplierSearchForm.SearchFormIDs;
    var requirelist = this.HPEService.getFormdataFieldNames(ids);
    requirelist.forEach(element => {
      if (this.searchFormData[element].value != "" && this.searchFormData[element].value != null) {
        this.isSearchBtnDisabled = false;
        return false;
      }
    });
  }

  primaryCountryChange() {
    const name = this.searchFormData.PrimaryCountry.value;
    this.searchFormData.CompanyCode.value = '';
    this.companyCodeService.getSourceList(name, (list) => {
      this.companyCodeList = list.sort((a, b) => parseInt(a) - parseInt(b));
    });
  }


  /**
   * for custom popup resize position(top)
   * @return {void}@memberof supplierNameSearchComponent
   */
  popupResize(): void {
    $('.search_headbox').css({
      'top': ($(window).scrollTop()) / $('.all_box').height() * 100 + '%'
    });
  }

}
