export enum RecordType {
     Parent = "Parent Record",
     Supporting = "Supporting Record",
     Document = "Document",
     UserAccessRequest = "User Access Request",
}
export enum AgreementStaus {
     InProgress = "In Progress",
     Pending = "Pending",
     Active = "Active",
     Terminated = "Terminated",
     Expired = 'Expired',
     Deleted = 'Deleted',
}
export enum RenewalType {
     AllowToExpire = "Allow to expire",
}
export enum RecordStatus {
     InProgress = "In Progress",
     Pending = "Pending",
     PendingReview = "Pending Review",
     AwaitingSAPID = "Awaiting SAP ID",
     AwaitingUserAction = "Awaiting User Action",
     Active = "Active",
     Terminated = "Terminated",
     Inactive = "Inactive",
     Expired = "Expired",
     PendingDelete = "Pending Deletion",
     PendingTermination = "Pending Termination",
     PendingDocumentOwnerReview = "Pending Document Owner Review",
     PendingAdminReview = "Pending Admin Review",
     PendingGUReview = "Pending GU review",
     Approved= "Approved"
}

export enum RejectReasonFilter {
     IncorrectMetadata = "Incorrect metadata fields",
     IncorrectAgreement = "Incorrect agreement attached",
     NotSigned = "Agreement attached not signed/finalized",
     IncompleteAgreement = "Incomplete agreement",
     Other = "Other"
}

export enum ReturnMessage {
     Success = "Success",
}

export enum CommenString {
     AdminRole = "MMRP.Admin",
     StandardRole = "MMRP.Standard",
     ReadOnlyRole = "MMRP.ReadOnly",
     DocumentOwnerRole = "MMRP.DocumentOwner",
     GuLeadRole = "MMRP.GULead",
     DocumentOwnAndGuLead = "MMRP.DocumentOwner,MMRP.GULead"
}

export enum SupplierSearch{
     NoSapIdRequiered = "No SAP ID Required"
}

export enum ReviewOperation{
     Approve = "Approve",
     Reject = "Reject",
}

export enum ShowContentType {
     ShowOldValue = 1,
     ShowNewValue = 2,
}
export enum SearchCriteriaType{
     Text = "Text",
     MultipleSelect = "MultipleSelect",
     PeoplePicker = "PeoplePicker",
     ClientPicker = "ClientPicker",
     SingleSelect = "SingleSelect",
     Date = "Date",
}

export enum ExportTypeEnum
{
    Dashboard = 1,
    MassData = 2,
    ExpiringTable=3,
    ExpiringTableForGULeadOrDO=4
}

export enum PageType {
     SubmissionPending = 'SubmissionPending',
     RequiredActions = 'RequiredActions',
     ExpiringContracts = 'ExpiringContracts',
     saveInProgress = 'saveInProgress',
     MyRecords = 'MyRecords',
     TeamQueue = 'TeamQueue',
     DeletionRequests = "DeletionRequests",
     RecentlyAccessed = "RecentlyAccessed",
     SearchRecord = "SearchRecord",
     MainSearch = 'MainSearch',
     UserAccessRequest = 'UserAccessRequest',
}

export enum UserStatus {
     Active = 'Active',
     Inactive = 'Inactive'
}
export const TerminationAgreementTypes: string[] = [
     "Affiliate Addendum",
     "Amendment",
     "Exhibit/Schedule",
     "Framework Agreement",
     "Master Goods Agreement",
     "Master License and Service Agreement",
     "Master Services Agreement",
     "Software Agreement", // TODO will be remove at R3
     "Software / License Agreement",
     "Termination Letter"
 ];
  export enum ASTtype {
     Delegation = "Delegation",
     BThreshold = "Below Threshold",
     PNotInvolved = "P+ not involved(excl. Delegation/Threshold)",
}
export enum CStype{
     Y = "Yes",
     N = "No",
}