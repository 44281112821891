import { Injectable } from "@angular/core";
import { Observable, } from "rxjs";
import { Factory } from 'app/app.factory';
import { RecordStatus } from 'app/common/enum/type.enum';
@Injectable()
export class ParentService {
    constructor(private factory: Factory) { }
    public service: any;
    public teamSearchRecordData: string;
    public searchDataProperties: string;
    public pageArgs: string;
    getPage(sort: string, order: string, page: number, pageSize: number, ): Observable<PageResult> {

      this.service = this.factory.getService();
      this.searchDataProperties = "properties=TotalResults%2Cnumber%2CUSDExceeded%2CAgreementPaper%2CSupplierName%2CKeyCategory%2CCategoryVal%2CAgreementType%2CPrimaryCountry%2CDocumentOwner%2CAgreementStatus%2CAgreementStartDate%2CSubCategory%2CAgreementName%2CStakeholderOwner%2CRecordAllHolds";
      //this.searchDataProperties = "properties=TotalResults%2Cnumber%2CategoryVal%2CAgreementType%2CPrimaryCountry";

      this.pageArgs = this.bindPageArgs(sort, order, page, pageSize);
      return this.service.HPServiceRecord("format=json&q=" + this.teamSearchRecordData, this.searchDataProperties + this.pageArgs).then(res => {
        var result = new PageResult();
        var array = [];
        result.Total = res.TotalResults;
        if (result.Total > 0) {
          res.Results.forEach(element => {
            var temp = new RecordResult();
            if (this.checkUndefined(element.RecordNumber)) {
              temp.RecordNumber = element.RecordNumber.Value;
            }

            if (this.checkUndefined(element.Uri)) {
              temp.Uri = element.Uri;
            }

            if (this.checkUndefined(element.RecordAllHolds)) {
              temp.legalHold = element.RecordAllHolds.Value != '' ? true : false;
            }

            if (this.checkUndefined(element.Fields)) {
              temp.SupplierName = this.bindValue(element.Fields.SupplierName);
              temp.AgreementType = this.bindValue(element.Fields.AgreementType);
              temp.PrimaryCountry = this.bindValue(element.Fields.PrimaryCountry);
              temp.KeyCategory = this.bindValue(element.Fields.KeyCategory);
              temp.CategoryVal = this.bindValue(element.Fields.CategoryVal);
              temp.DocumentOwner = this.bindValue(element.Fields.DocumentOwner);
              temp.AgreementStatus = this.bindValue(element.Fields.AgreementStatus);
              temp.USDExceeded = this.bindValue(element.Fields.USDExceeded);
              temp.AgreementStartDate = element.Fields.AgreementStartDate.DateTime;
              temp.SubCategoryVal=this.bindValue(element.Fields.SubCategory);
              temp.AgreementPaper=this.bindValue(element.Fields.AgreementPaper);
              temp.StakeholderOwner=this.bindValue(element.Fields.StakeholderOwner);
            }

            if (this.checkUndefined(element.Fields.AgreementName)) {
              temp.RecordTitle = element.Fields.AgreementName.Value;
            }

            array.push(temp);
          });
          result.Data = array;
        }
        return result;
      });
    }
    bindPageArgs(sort: string, order: string, page: number, pageSize: number) {
      var result = "&pageSize=" + pageSize + "&start=" + (page * pageSize + 1);
      if (typeof (sort) != "undefined") {
        // if (sort == "SupplierName" || sort == "RecordTitle") {
        //   sort = "typedTitle";
        // }
        if (sort == "RecordNumber") {
          sort = "number";
        }


        result += "&sortBy=" + sort;
      }
      if (order == 'desc') {
        result += "-";
      }
      return result;
    }
    bindValue(field: any) {
      if (typeof (field) != 'undefined') {
        return field.Value;
      }
      return "";
    }
    checkUndefined(field: any) {
      if (typeof (field) != 'undefined') {
        return true;
      }
      return false;
    }
  }

  export class LocationMember {
    public Uri: string;
    public EID: number;
  }

  export class RecordResult {
    Uri: string;
    legalHold: boolean;
    RecordNumber: string;
    SupplierName: string;//SupplierName
    KeyCategory: string;//KeyCategory
    CategoryVal: string;//CategoryVal
    RecordTitle: string;
    AgreementType: string;//AgreementType
    PrimaryCountry: string;//PrimaryCountry
    DocumentOwner: string;//DocumentOwner
    AgreementStatus: string;//AgreementStatus
    AgreementStartDate: Date//AgreementStartDate
    USDExceeded: string;//USDExceeded
    SubCategoryVal: string;
    AgreementPaper: string;
    StakeholderOwner: string;
  }

  export class PageResult {
    public Total: number;
    public Data: RecordResult[];
  }

