<div class="mask"></div>
<div class="search_headbox">
  <div class="titlBox">
    <p class="team_title">Search Parent Record</p>
    <i (click)="closePage()" class="mdui-icon material-icons close_icon">clear</i>
  </div>
  <!-- search header start-->
  <div class="tab_content">
    <div class="tab_line">
      <div class="wid_25">
        <app-input [inputData]="searchFormData.SupplierName" [enableChange]="enableChange"
        (change)="repalceForbidChar(searchFormData.SupplierName)"></app-input>
      </div>
      <div class="wid_25">
        <app-single-select [inputData]="searchFormData.PrimaryCountry" [selectionValue]="dorpdownValues.getCountrys" (change)="searchBtnValidate()"></app-single-select>
      </div>
      <div class="wid_25">
        <app-single-select [inputData]="searchFormData.KeyCategory" [selectionValue]="dorpdownValues.getKeyCategorys" (change)="searchBtnValidate();setCategory()"></app-single-select>
      </div>
      <div class="wid_25">
        <app-single-select [inputData]="searchFormData.CategoryVal" [selectionValue]="getCategorys" (change)="searchBtnValidate()"></app-single-select>
      </div>
    </div>
    <div class="tab_line">
      <div class="wid_25">
        <app-input [inputData]="searchFormData.AgreementName" [enableChange]="enableChange"
        (change)="repalceForbidChar(searchFormData.AgreementName)"></app-input>
      </div>
      <div class="wid_25">
        <app-single-select [inputData]="searchFormData.AgreementType" [selectionValue]="dorpdownValues.getTypes" (change)="searchBtnValidate()"></app-single-select>
      </div>
      <div class="wid_25">
        <app-input [inputData]="searchFormData.AgreementID" [enableChange]="enableChange"
        (change)="repalceForbidChar(searchFormData.AgreementID)"></app-input>
      </div>
      <div class="wid_25">
        <app-single-select [inputData]="searchFormData.AgreementStatus" [selectionValue]="agreementStatueValue" (change)="searchBtnValidate()"></app-single-select>

      </div>
    </div>
    <div class="tab_line">
      <div class="wid_25">
        <app-input [inputData]="searchFormData.DocumentOwner" [enableChange]="enableChange"
        (change)="repalceForbidChar(searchFormData.DocumentOwner)"></app-input>
      </div>
      <div class="wid_25">
        <app-date class="app-date" [inputData]="searchFormData.AgreementStartDate" [enableChange]="enableChange" (change)="searchBtnValidate()"></app-date>
      </div>
      <div class="wid_25">
        <app-radioYN [inputName]="'p_search_' + searchFormData.USDExceeded" [inputData]="searchFormData.USDExceeded" (change)="searchBtnValidate()"></app-radioYN>
      </div>
      <div class="btn_set wid_25" style="float:right">
        <i class="mdui-icon material-icons nosapInfo fr"
        [tooltip]="information"
        [rePositionX]="20"
        [rePositionY]="-38">info</i>
        <button type="submit" class="submit_btn fr sea_btn btn-primary pd_7" (click)="onSearch()" [disabled]="isSearchBtnDisabled">
          <i class="mdui-icon material-icons sub_btn">search</i>
          <span class="btn_txt">Search</span>
        </button>
        <button type="submit" class=" fr reset_btn btn-secondary" (click)="ReSetParent()">
          <i class="mdui-icon material-icons sub_btn">autorenew</i>
          <span class="btn_txt">Reset</span>
        </button>
        <div class="clearfloat"></div>
      </div>
    </div>
  </div>
  <div [hidden]="!isResultShow">
  <div class="table-loading-shade"
       *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>
  <div class="search_context">
    <div class="resultTable">

      <mat-table class="tab" #table [dataSource]="dataSource" matSort matSortActive="CreatedOn" matSortDisableClear matSortDirection="asc">

        <ng-container matColumnDef="RecordNumber">
          <mat-header-cell class="tab_txt" *matHeaderCellDef mat-sort-header class="TableHeader" tooltip="Agreement ID"> Agreement ID </mat-header-cell>
          <mat-cell class="not-auto-ellipsis flex-al-center parent-mell" [ngClass]="{'selectedclass': highClass===i,'mat-cell': highClass===i,'notselectedclass': highClass!==i}" *matCellDef="let RecordResult">
            <i class="mdui-icon material-icons vm legal-hold-icon" [tooltip]="LegalHoldInfo" *ngIf="RecordResult.legalHold" >account_balance</i>
            <div class="lipTip" [tooltip]="RecordResult.RecordNumber">
              <span class="vm">{{RecordResult.RecordNumber}}</span>
            </div></mat-cell>
        </ng-container>
        <ng-container matColumnDef="SupplierName">
          <mat-header-cell class="tab_txt" *matHeaderCellDef mat-sort-header class="TableHeader" tooltip="Supplier Name"> Supplier Name </mat-header-cell>
          <mat-cell [ngClass]="{'selectedclass': highClass===i,'mat-cell': highClass===i,'notselectedclass': highClass!==i}" *matCellDef="let RecordResult" [tooltip]="RecordResult.SupplierName"> {{RecordResult.SupplierName}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="AgreementName">
          <mat-header-cell class="tab_txt" *matHeaderCellDef mat-sort-header class="TableHeader" tooltip="Agreement Name"> Agreement Name </mat-header-cell>
          <mat-cell [ngClass]="{'selectedclass': highClass===i,'mat-cell': highClass===i,'notselectedclass': highClass!==i}" *matCellDef="let RecordResult" [tooltip]="RecordResult.RecordTitle"> {{RecordResult.RecordTitle}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="AgreementType">
          <mat-header-cell class="tab_txt" *matHeaderCellDef mat-sort-header class="TableHeader" tooltip="Agreement Type"> Agreement Type</mat-header-cell>
          <mat-cell [ngClass]="{'selectedclass': highClass===i,'mat-cell': highClass===i,'notselectedclass': highClass!==i}" *matCellDef="let RecordResult" [tooltip]="RecordResult.AgreementType"> {{RecordResult.AgreementType}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="PrimaryCountry">
          <mat-header-cell class="tab_txt" *matHeaderCellDef mat-sort-header class="TableHeader" tooltip="Country/ Location"> Country/ Location</mat-header-cell>
          <mat-cell [ngClass]="{'selectedclass': highClass===i,'mat-cell': highClass===i,'notselectedclass': highClass!==i}" *matCellDef="let RecordResult" [tooltip]="RecordResult.PrimaryCountry"> {{RecordResult.PrimaryCountry}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="KeyCategory">
          <mat-header-cell class="tab_txt" *matHeaderCellDef mat-sort-header class="TableHeader" tooltip="Key Category"> Key Category </mat-header-cell>
          <mat-cell [ngClass]="{'selectedclass': highClass===i,'mat-cell': highClass===i,'notselectedclass': highClass!==i}" *matCellDef="let RecordResult" [tooltip]="RecordResult.KeyCategory"> {{RecordResult.KeyCategory}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="CategoryVal">
          <mat-header-cell class="tab_txt" *matHeaderCellDef mat-sort-header class="TableHeader" tooltip="Category"> Category </mat-header-cell>
          <mat-cell [ngClass]="{'selectedclass': highClass===i,'mat-cell': highClass===i,'notselectedclass': highClass!==i}" *matCellDef="let RecordResult" [tooltip]="RecordResult.CategoryVal"> {{RecordResult.CategoryVal}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="DocumentOwner">
          <mat-header-cell class="tab_txt" *matHeaderCellDef mat-sort-header class="TableHeader" tooltip="Document Owner"> Document Owner</mat-header-cell>
          <mat-cell [ngClass]="{'selectedclass': highClass===i,'mat-cell': highClass===i,'notselectedclass': highClass!==i}" *matCellDef="let RecordResult" [tooltip]="RecordResult.DocumentOwner"> {{RecordResult.DocumentOwner}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="AgreementStatus">
          <mat-header-cell class="tab_txt" *matHeaderCellDef mat-sort-header class="TableHeader" tooltip="Agreement Status"> Agreement Status</mat-header-cell>
          <mat-cell [ngClass]="{'selectedclass': highClass===i,'mat-cell': highClass===i,'notselectedclass': highClass!==i}" *matCellDef="let RecordResult" [tooltip]="RecordResult.AgreementStatus"> {{RecordResult.AgreementStatus}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="AgreementStartDate">
          <mat-header-cell class="tab_txt" *matHeaderCellDef mat-sort-header class="TableHeader" tooltip="Start Date"> Start Date</mat-header-cell>
          <mat-cell [ngClass]="{'selectedclass': highClass===i,'mat-cell': highClass===i,'notselectedclass': highClass!==i}" *matCellDef="let RecordResult" [tooltip]="RecordResult.AgreementStartDate|date:'dd-MMM-yyyy'"> {{RecordResult.AgreementStartDate|date:'dd-MMM-yyyy'}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; let i = index; columns: displayedColumns; " (click)="clickItem(i,row)" [id]="'tr'+i" [ngClass]="{'selectedLineclass': highClass===i,'mat-cell': highClass===i,'notselectedLineclass': highClass!==i, 'noPointer': row.legalHold}" class="trHover"></mat-row>

      </mat-table>
      <div *ngIf="!isLoadingResults && resultsLength === 0" class="load_box">
        No Results Found !
      </div>
      <new-paginator #paginator
      [length]="resultsLength"
      [pageSize]="10"
      (onPageRefresh)="onPageRefresh()">
      </new-paginator>

      </div>
    </div>
    <div class="rele">
      <button type="submit" class="submit_btn fr select_btn btn-primary" [disabled]="isDisable" (click)="selectItem()">
        <i class="mdui-icon material-icons sub_btn">check</i>
        <span class="btn_txt">Select</span>
      </button>
      <button type="submit" class="submit_btn fr cancle_btn" (click)="closePage()" hidden=true>
        <i class="mdui-icon material-icons sub_btn">close</i>
        <span class="btn_txt">Cancel</span>
      </button>
    </div>
  </div>
</div>


