<section>
    <div>
        <label [for]="inputId"><span *ngIf="inputData.IsRequired" class="red">*</span> {{displayLabel ? displayLabel : inputData.labelName}}
            <i *ngIf="InfoShow && !inputData.isRead"
              class="mdui-icon material-icons nosapInfo"
              [tooltip]="information"
              >info</i>
        </label>
    </div>
    <div [ngStyle]="inputData.isRead ? {display: 'none'} : {}">
        <input [id]="inputId" #input_ref type="text" [maxlength]="inputData.maxlength" [(ngModel)]="inputData.value" (ngModelChange)="inputData.value = onChanged($event)"
        class="form-control" [disabled]="inputData.IsDisabled" [ngClass]="{'error':!inputData.isValidation, 'nowrap':inputData.IsDisabled}" [placeholder]="inputData.placeholder" />
    </div>
    <div *ngIf="inputData.isRead">
        <span class="span_is_read nowrap"><span class="nowrapspan" [ngClass]="{'tip_span':newValue}" [tooltip] = "displayValue ? displayValue.value : inputData.value" >{{displayValue ? displayValue.value : inputData.value}}</span><i *ngIf="newValue && inputData.isRead" class="mdui-icon material-icons tooltip_icon" [tooltip]= "ToolTip ? ToolTip : ''">info</i></span>
    </div>
</section>
