
export const configurationIDs = {
    submitForm: {
        //those ids for submit form, view build
        SubmitFormIDs: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 23, 24, 25, 26, 27, 28, 29, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 48, 54, 63, 64, 65, 66, 69, 84, 86, 87, 88, 98, 99, 100, 101, 102, 103, 104,105,106,107,108,109,110],
        //those ids for submit form, need submited fields to webapi part
        NeedSubmitedFieldIDs: [1, 3, 4, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 23, 24, 25, 26, 27, 28, 29, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 48, 54, 63, 64, 65, 66, 69, 84, 86, 87, 88, 98, 99, 100, 101, 102, 103, 104,105,106,107,108,108,110],
        NeedSubmitedFieldIDsNoRenewed:[1, 3, 4, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 23, 24, 25, 26, 27, 28, 29, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 48, 54, 63, 64, 65, 66, 69, 84, 86, 87, 88, 98, 99, 100, 101, 102, 103, 104,106,107,108,109,110],
        //those ids for submit form, need submited fields to webapi part
        TemplateFieldIDs: [3, 4, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 23, 24, 25, 26, 27, 28, 29, 32, 33, 34, 35, 36, 37, 39, 40, 41, 43, 44, 45, 54, 63, 64, 65, 66, 69, 84, 85, 86, 87, 88, 98, 99, 100, 101, 102, 103, 104,105,106,107,108,109,110],
        //those ids for submit form, need validation's fields
        SubmitVilidationIDs: [1, 3, 7, 8, 9, 10, 11, 12, 14, 23, 24, 25, 26, 29, 33, 34, 36, 37,41, 43, 44, 45, 63, 66, 84, 88],
        //those ids for submit form, parent or supporting diffrent required fields ids
        // remove 43(Business Contract Owner) - PBI 132291 #10 Business Contract Owner should not be a mandatory field
        // add 43(Business Contract Owner) - PBI 139893 #1 Business Contract Owner field must be mandatory in parent record
        // BUG 158352
        ParSupRequiredIDs: [3, 6, 15, 23, 24,26, 29, 34, 40,41, 45, 63],
        //those ids for submit form, when choose add supporting document need set value to "" fields
        SupResetIDs: [1, 2, 3, 4, 6, 11, 12, 13, 14, 15, 23, 24, 25, 26, 27, 28, 29, 34, 35, 36, 37, 38, 39, 40, 41, 42, 45, 48, 54, 63, 64, 66, 84, 86, 87, 88, 98, 99, 100, 101,102,103,106,107,108,109,110],
        //remove 43(Business Contract Owner) - PBI 640003 Add pre-filling function for supporting records' "Business Contract Owner" field
        //those ids for submit form, all the radio fields in the form
        SubmitRadioFieldIDs: [3, 23, 24, 29, 45, 63, 106,107],
        //those ids for submit form, when choose add supporting document need set value to "" fields,
        //remove 43(Business Contract Owner) - PBI 640003 Add pre-filling function for supporting records' "Business Contract Owner" field
        ManualAddSupportingSupResetIDs: [1, 3, 4, 6, 11, 12, 13, 14, 15, 23, 24, 25, 26, 27, 28, 29, 34, 35, 36, 37, 38, 39, 40, 41, 42, 45, 48, 54, 63, 64, 66, 84, 86, 87, 88, 98, 99, 100, 101,102,103,105,106,107,108,109,110],

        DocumentMoveCheckNewParentvalid:[14, 33, 38],
        SubmitVilidationRenewedIDs :[1, 3, 7, 8, 9, 10, 11, 12, 14, 23, 24, 25, 26, 29, 33, 34, 36, 37,41, 43, 44, 45, 63, 66, 84, 88,105]
    },
    parentSearchForm: {
        SearchFormIDs: [1,2,3,7,8,9,10,11,12,13,14,15,44,66],
        ResCusFieldIDs:    [1,3,7,8,9,10,11,12,13,14,15,44,66]
    },
    supplierSearchForm: {
        SearchFormIDs: [12,16,18,19,65,69,70,71,104],
    },
    viewRecordForm:{
        //those ids for view form customproperty fields     
        customPropertyIDs:[3,4,6,7,8,9,10,11,12,13,14,15,16,18,23,24,25,26,27,28,29,32,33,34,35,36,37,38,39,40,41,42,43,44,45,54,63,64,65,66,69,79,84,86,87,80, 88, 98, 99, 100, 101,102,103,104,105,106,107,108,109,110],
        //those ids for view form all need fields     
        neededFieldsIDs:[1,2,3,4,6,7,8,9,10,11,12,13,14,15,16,18,23,24,25,26,27,28,29,32,33,34,35,36,37,38,39,40,41,42,43,44,45,48,54,63,64,65,66,69,84,86,87,80, 88, 98, 99, 100, 101,102,103,104,105,106,107,108,109,110],
        neededFieldsIDsNoRenewed:[1,2,3,4,6,7,8,9,10,11,12,13,14,15,16,18,23,24,25,26,27,28,29,32,33,34,35,36,37,38,39,40,41,42,43,44,45,48,54,63,64,65,66,69,84,86,87,80, 88, 98, 99, 100, 101,102,103,104,106,107,108,109,110]
    },
    adminViewRecordForm: {
        //those ids for view form customproperty fields     
        customPropertyIDs:[3,4,6,7,8,9,10,11,12,13,14,15,16,18,23,24,25,26,27,28,29,32,33,34,35,36,37,38,39,40,41,42,43,44,45,50,51,52,53,54,63,64,65,66,69,84,86, 87, 88, 98, 99, 100, 101,102,103,104,105,106,107,108,109,110],
        //those ids for view form all need fields     
        neededFieldsIDs: [1,2,3,4,6,7,8,9,10,11,12,13,14,15,16,18,23,24,25,26,27,28,29,32,33,34,35,36,37,38,39,40,41,42,43,44,45,48,50,51,52,53,54,63,64,65,66,69,79,84,86, 87, 88, 98, 99, 100, 101,102,103,104,105,106,107,108,109,110],
        //those ids for editable fields in edit mode
        editableFieldsIDs : [1,3,4,6,7,8,9,10,11,12,13,14,15,16,18,23,24,25,26,27,28,29,32,34,35,36,37,38,39,40,41,42,43,44,45,48,50,53,54,63,64,65,66,69,84,86, 87, 88, 98, 99, 100, 101,102,103,104,105,108,109,110],
        //those ids for reject reason in edit mode
        rejectReasonIDs: [55,56,57,58,59,60,61],
        //those ids for approve reason in edit mode
        approveReasonIDs: [89,90,91,92,93,94,95,96],
    },
    activeRecordEditForm:{
        //those ids for view form customproperty fields     
        customPropertyIDs:[3,4,6,7,8,9,10,11,12,13,14,15,16,18,23,24,25,26,27,28,29,32,33,34,35,36,37,38,39,40,41,42,43,44,45,54,63,64,65,66,68,69,79,81,82,84,86, 87, 88, 97, 98, 99, 100, 101,102,103,104,105,106,107,108,109,110],
        //those ids for view form all need fields     
        neededFieldsIDs: [1,2,3,4,6,7,8,9,10,11,12,13,14,15,16,18,23,24,25,26,27,28,29,32,33,34,35,36,37,38,39,40,41,42,43,44,45,48,54,63,64,65,66,68,69,79,82,84,86, 87, 88, 98, 99, 100, 101,102,103,104,105,106,107,108,109,110],
        //those ids for submit form, need submited fields to webapi part
        needSubmitedFieldIDs: [1,2,3,4,6,7,8,9,10,11,12,13,14,15,16,18,23,24,25,26,27,28,29,32,33,34,35,36,37,38,39,40,41,42,43,44,45,48,54,63,64,65,66,69,84,86, 87, 88, 98, 99, 100, 101,102,103,104,105,106,107,108,109,110],
        NeedSubmitedFieldIDsNoRenewed: [1,2,3,4,6,7,8,9,10,11,12,13,14,15,16,18,23,24,25,26,27,28,29,32,33,34,35,36,37,38,39,40,41,42,43,44,45,48,54,63,64,65,66,69,84,86, 87, 88, 98, 99, 100, 101,102,103,104,106,107,108,109,110]
    },
    MainSearchForm:{
        //those ids for main search form search criterials     
        searchCriterialIDs:[2,3,8,9,10,11,12,13,14,15,36,39,66,103]//absence DocumentName and Secondary Supplier
    },
    UserAccessRequestsApproveOrRejectForm:{
        //those ids for main search form search criterials     
        customPropertyIDs:[83,83]//absence DocumentName and Secondary Supplier
    }
}
