import { Injectable, Inject, ViewChild, TemplateRef, Directive }    from '@angular/core';
import { Observable,Subject, lastValueFrom } from 'rxjs';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
//import { SessionService }      from '../session/session.service';
import { SessionService }  from 'app/common/services/session/session.service';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { ExpectionInfo } from 'app/common/enum/warning.enum'
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { last } from 'lodash';
// import { AgreementStaus } from 'app/common/enum/type.enum';
@Directive()
@Injectable()
export class AjaxService{

    private headers = this.getHeader();
    private Url = environment.ApiBase;
    private subject = new Subject<any>();
    public ExpectionErrMsgPopup: any=[];
    @ViewChild("popup_content")
    private popupContentRef: TemplateRef<any>;

    public footerInfo;
    public popupContent;
    public dialogRef: MatDialogRef<TemplateRef<any>>;

    // param key, for cache issue
    private CACHE_PARAM_KEY = 'param_r_t';
    constructor(
        private http: HttpClient,
        private router: Router,
        private session: SessionService,
        public dialog: MatDialog,
       
    ){}
	
    getJson(data) {
        this.headers =this.getHeader();
        let obj = {};
        data.forEach((e) => {
            obj[e.name] = this.getMethodFn(e); 
        });
        return obj;
    }

    getHeader(){
        return new HttpHeaders().set("Cache-Control", "no-cache")
    }

    getMethodFn(e){
        let ojb = {
            'get'       : e => this.doGet(e),
            'post'      : e => this.doPost(e),
            'HPServiceApiget'      : e => this.doHPGet(e),
            'doWebGet':e => this.doWebGet(e),
        }
        this.session.setTimeFn(new Date());
        return ojb[e.method](e);
    }
    
    doPost(data){
        return async (param) => {
            let cacheData;
            if (data.cache && (cacheData = sessionStorage.getItem(param?(data.name+"-"+JSON.stringify(param)):data.name))) { 
                return Promise.resolve(JSON.parse(cacheData));
            } else {
                var request = this.http.post(this.Url + data.url,param,{headers: this.headers, withCredentials: true})    
                return await lastValueFrom(request).then( (response:any) => {
                    this.checkAccessFn(response.status); 
                    if(data.cache) {
                        sessionStorage.setItem(param?(data.name+"-"+JSON.stringify(param)):data.name, JSON.stringify(response));
                    }
                    return response;
                },err=>{
                    console.log("HttpError", err);
                })
                .catch(this.handleError);
            }
        }
    }

    doHPGet(data){ 
        return async (paramP,paramProperties) => {
            let requestTime = new Date().getTime();
            var request = this.http.get(environment.HPApiBase + data.url + "?" + paramP + "&" + paramProperties + "&" + this.CACHE_PARAM_KEY + "=" + requestTime,{headers: this.headers })
            return await lastValueFrom(request).then( (response:any) => {
                this.checkAccessFn(response.status); 
                return response;
            })
            .catch( (err) => {
                this.handleError(err);
            })
        }
        
    }

    doWebGet(data) {
        return async (paramP,paramProperties) => {
            let requestTime = new Date().getTime();
            
            let parseParams = (params): any => {
                let paramObj = {};
                if (params) {
                    params = params.split('&');
                    params.forEach(param => {
                        let paramItem = param.split('=');
                        paramItem.forEach((item, index) => {

                            item = decodeURIComponent(item);

                            if (index === 0) {
                                paramObj[paramItem[0]] = '';
                            } else if (index === 1) {
                                paramObj[paramItem[0]] += item;
                            } else {
                                paramObj[paramItem[0]] += ('=' + item);
                            }
                        });
                    });
                }
                return paramObj;
            };
            let paramObj = parseParams(paramP);
            let propertiesObj = parseParams(paramProperties);
            if (paramObj.hasOwnProperty("q")){
                paramObj["q"] = paramObj["q"].replace("%2F", "/")
                paramObj["q"] = decodeURIComponent(paramObj["q"])
            }

            var request = this.http.post(environment.ApiBase + data.url, {...paramObj, ...propertiesObj, [this.CACHE_PARAM_KEY]: requestTime},{headers: this.headers })
            return lastValueFrom(request).then( (response:any) => {
                if (response.Status === 'Failed') {
                    throw new Error('Failed');
                }
                this.checkAccessFn(response.status); 
                response = response.Data;
                return response;
            })
            .catch( (err) => {
                this.handleError(err);
            })
        }
    }

    doGet(data){ 
        return async (param) => {
            let cacheData;
            if(data.cache && (cacheData = sessionStorage.getItem(param?(data.name+"-"+JSON.stringify(param)):data.name))) { 
                return Promise.resolve(JSON.parse(cacheData));
            } else {
                var search = new HttpParams();
                if(param){
                    for (let k in param){
                        search = search.set(k,param[k]);
                    }
                }
                // request time, for cache issue
                search = search.set(this.CACHE_PARAM_KEY, new Date().getTime().toString());
                var request = this.http.get(this.Url + data.url,{params : search ,headers: this.headers, withCredentials: true })
                return lastValueFrom(request).then( (response:any) => {
                    if(response!=null && response!=undefined && response.hasOwnProperty('Status')) {
                        this.checkAccessFn(response.status);
                    }
                    if(data.cache) {
                        sessionStorage.setItem(param?(data.name+"-"+JSON.stringify(param)):data.name, JSON.stringify(response));
                    }
                    return response;
                })
                .catch( (err) => {
                    this.handleError(err);
                })
            }
        }
        
    }
    
    checkAccessFn(status){
        switch (status) {
          case 401: 
            this.router.navigate(['/main/noaccess']);
            break;
          case 403: 
            this.router.navigate(['/home']);
            break;
            case 504:
            this.router.navigate(['/home']);
        }
    }

	private handleError(error: any){
        this.ExpectionErrMsgPopup[0]=ExpectionInfo.ExpectionTitle;
        this.ExpectionErrMsgPopup[1]=ExpectionInfo.ExpectionMsg_MessageContent_Line1;
        this.sendMsg(this.ExpectionErrMsgPopup);
        return Promise.reject(error);
    }
    sendMsg(message: any) {
        this.subject.next(message);
    }

    get(): Observable<any> {
        return this.subject.asObservable();
    }
}
