import { TypeAheadServiceI } from './../../../component/type-ahead/type-ahead.serviceI';
import { Injectable } from '@angular/core';
import { Factory } from 'app/app.factory';

@Injectable()
export class CompanyCodeService {

    private service;

    constructor (private factory: Factory) {
        this.service = this.factory.getService();
    }

    public getSourceList (name: string, resFunc: (list: string[]) => void) {
        let searchParams = 'format=json&q=members:[type:Organization';
        if (name !== '') {
            searchParams += ' and name:"' + name + '"';
        }
        searchParams += '] and type:Organization';
        const props = 'properties=IDNumber&pageSize=100000';
        this.service.HPServiceLocation(searchParams, props).then(res => {
            let textList = [];
            if (res && res.Results) {
                res.Results.forEach(item => {
                    if (item.LocationIdNumber) {
                        let companyCode = item.LocationIdNumber.Value;
                        if (companyCode && textList.indexOf(companyCode) === -1) {
                            textList.push(companyCode);
                        }
                    }
                });
            }
            resFunc(textList);
        });
    }
}