import {
  Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output,
  HostListener, ComponentFactoryResolver, Injector, ApplicationRef,
  Inject, forwardRef, ComponentRef, AfterViewInit, OnChanges, SimpleChanges, ChangeDetectorRef, Renderer2
} from '@angular/core';

import { TabelFilterDirective } from './tabel-filter.directive';
import { TabelFilterService } from './service/tabel-filter.service';

/**
 * A lightweight, extensible directive for fancy popover creation.
 */
@Directive({selector: '[colfilter]', exportAs: 'col-filter'})
export class ColFilterDirective implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  /**
   * 
   */
  @Input()
  public property: string = 'null';

  @Input()
  public isLike: boolean = false;

  private setTimeoutId: any = null;

  /**
   * 
   * parent|mouseenter|mouseleave
   */
  @Input()
  public showAction: 'hover' | '' = 'hover';

  /**
   * Placement of a popover. Accepts: "top", "bottom", "left", "right"
   */
  @Input()
  public placement: 'top' | 'bottom' | 'left' | 'right'
                    | 'top left' | 'top right' | 'bottom left' | 'bottom right' = 'bottom left';

  /**
   * 
   */
  @Input('colfilter')
  public items: Array<string | number> = [];

  @Input()
  public checkeds: Array<any> = [];

  /**
   * 
   */
  @Input()
  public type: 'simple-search' | 'simple-dropdown' | 'date-scope' | 'muilt-search' | 'none' = 'simple-dropdown';

  /**
   * 
   */
  @Input()
  public itemOrder: 'none' | 'ASC' | 'DESC' = 'ASC';

    /**
   * 
   */
  @Input()
  public relations: Array<any> = [];

  public _relations = new Map();

  // 'date-scope'
  @Input()
  public showNext: boolean = false;

  /**
   * 
   */
  @Output()
  public filterItem: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public filterCondition: EventEmitter<any> = new EventEmitter<any>();

  private _componentRef: ComponentRef<any>;

  private _instance: any;

  private _location: any;

  private _showCallBack: any;
  private _hideCallBack: any;

  constructor(
    private _elementRef: ElementRef,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _injector: Injector,
    private _applicationRef: ApplicationRef,
    public _tabelFilterService: TabelFilterService,
    public _cdrf: ChangeDetectorRef,
    public _renderer: Renderer2,
    @Inject(forwardRef(() => TabelFilterDirective))
    private _parent: TabelFilterDirective
  ) { }

  @HostListener('click', ['$event'])
  onMouseClick(event: MouseEvent) {
    // event.stopPropagation();
    // event.preventDefault();
    // this._tabelFilterService.singleShowFilter(this._instance.bsDropdown);
  }

  ngOnInit(): void {

    if (this.type === 'none') {
      return;
    }
    // 
    const containerType = this._tabelFilterService.getContainerType(this.type);
    const _componentFactory = this._componentFactoryResolver
    .resolveComponentFactory<any>(containerType);
    const injector = Injector.create({
      providers: [],
      parent: this._injector
    });
    this._componentRef = _componentFactory.create(injector);
    this._applicationRef.attachView(this._componentRef.hostView);

    // 
    this._instance = this._componentRef.instance;
    this._instance._tabelFilterService = this._tabelFilterService;
    this._instance.items = this.items;
    this._instance.property = this.property;
    this._instance.placement = this.placement;
    this._instance.itemOrder = this.itemOrder;
    this._instance.isLike = this.isLike;
    this._instance.relations = this.relations;
    this._instance.showNext = this.showNext;
    this._instance.checkeds = this.checkeds;
    this._instance._parent = this;
    this._parent.pushColFilter(this);
    this._location = this._componentRef.location;

    this._location.nativeElement.classList.add("filter-container");

    // 
    const nativeElement = <HTMLElement>this._elementRef.nativeElement;
    if(nativeElement.firstElementChild != null){
      if(nativeElement.firstElementChild.classList.contains('mat-sort-header-container')){
          nativeElement.firstElementChild.appendChild(
            this._location.nativeElement
          );
      }
    }else{
      nativeElement.appendChild(
        this._location.nativeElement
      );
    }

    this._componentRef.changeDetectorRef.markForCheck();
    this._componentRef.changeDetectorRef.detectChanges();

    this._instance.filterCondition.subscribe(() => {
        this.filterCondition.emit();
    });

    // 
    this._instance.filterItem.subscribe((value) => {

      if(this.setTimeoutId != null){
        clearTimeout(this.setTimeoutId);
      }
      this.setTimeoutId = setTimeout(() => {
          this.filterItem.emit(value);
          this._parent.itemEvent.emit();
          clearTimeout(this.setTimeoutId);
          this.setTimeoutId = null;
      }, 50);
      if(this._instance.hasCondition()){
        this.removeShowAction();
      }else {     
          if(this.getRelation('KeyCategory') && this.getRelation('KeyCategory').hasCondition() === false){
            this.categoryShowAction(false);
          }else{
            this.regeditShowAction();
          }
          if(this.getRelation('PrimaryCountry') && this.getRelation('PrimaryCountry').hasCondition() === false){
            this.categoryShowAction(false);
          }else{
            this.regeditShowAction();
          }
      }
    });
    this.regeditShowAction();
    
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      let chng = changes[propName];
      if(propName == 'items'){
        this.items = chng.currentValue;
        if(this._instance != null){
          this._instance.items = chng.currentValue;
          this._instance.ngOnInit();
          this.filterItem.emit({addeds: this.checkeds, removeds: []});
        }
      }
      if(propName == 'checkeds' && this._instance != null){
        if(this.hasCondition()){
          this.removeShowAction();
        }
      }
    }
  }
  
  public categoryShowAction(status:boolean): void {
    if(status === false) {
      if(this.showAction === 'hover'){
        this.hide();
        this._elementRef.nativeElement.removeEventListener('mouseenter', this._showCallBack, false);
        this._elementRef.nativeElement.removeEventListener('mouseleave', this._hideCallBack, false);
      }
    }else {
      if(this.showAction === 'hover'){
        this._elementRef.nativeElement.addEventListener('mouseenter', this._showCallBack, false);
        this._elementRef.nativeElement.addEventListener('mouseleave', this._hideCallBack, false);
      }
    }
  }

  /**
   * 
   */
  private regeditShowAction(): void {
    if(this.showAction === 'hover'){
      this.hide();
      if(this._showCallBack == null){
        this._showCallBack = this.show.bind(this);
      }
      if(this._hideCallBack == null){
        this._hideCallBack = this.hide.bind(this);
      }
      this._elementRef.nativeElement.addEventListener('mouseenter', this._showCallBack, false)
      this._elementRef.nativeElement.addEventListener('mouseleave', this._hideCallBack, false)
    }
  }

 private removeShowAction(): void {
    if(this.showAction === 'hover'){
      this.show();
      this._elementRef.nativeElement.removeEventListener('mouseenter', this._showCallBack, false)
      this._elementRef.nativeElement.removeEventListener('mouseleave', this._hideCallBack, false)
    }
  }

  ngAfterViewInit(): void {
    
  }

  ngOnDestroy(): void {

  }

  hide(){
    const classList = this._location.nativeElement.classList;
    classList.remove('v-show');
    classList.add('v-hide');
  }

  show(){
    const classList = this._location.nativeElement.classList;
    classList.remove('v-hide');
    classList.add('v-show');
  }

  public hasCondition(): boolean{
    return this._instance.hasCondition();
  }

  public getRelation(key: string): ColFilterDirective {
    return this._relations.get(key);;
  }
}
