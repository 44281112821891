import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    templateUrl: './deletion-confirm.component.html',
    styleUrls: ['./deletion-confirm.component.css'],
})
export class DeletionConfirmComponent {

    constructor(
        public dialogRef: MatDialogRef<DeletionConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    public selectedReasons: { [key: string]: boolean } = {};
    public selectedReasonsInput: { [key: string]: string } = {};

    public isLoading: boolean = false;

    public onConfirm(): void {
        this.isLoading = true;
        this.data.confirm(() => {
            this.dialogRef.close();
            this.isLoading = false;
        }, () => {
            this.dialogRef.close();
            this.isLoading = false;
        });
    }
    public onClose(): void {
        this.dialogRef.close();
    }
}