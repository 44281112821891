import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { Factory } from '../../../../../app.factory';
import { Router } from '@angular/router';
import { HPEStaticService }   from "../../../../../common/services/staticService/HPEStaticService";
import { MessageService } from 'app/common/services/message/messageService';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BasicInfoComponent } from '../BasicInfo/BasicInfo.component';
import { CategoryLocationComponent } from '../CategoryLocation/CategoryLocation.component';

@Component({
    selector: 'RecordView',
    templateUrl: './RecordView.component.html',
    styleUrls: ['./RecordView.component.css'],
    providers: [Factory],
    inputs: ["inputsData", "fileInputer","recordTypeInputer","secondarySuppliersInputer","templatePage","templateDocPage","isTemplate","isEditProcess"],
    // outputs: ["docOutputer"]
})
export class RecordViewComponent {
    public inputsData: any;
    public fileInputer: any;
    public templatePage: any;
    public isEditProcess:any;
    public isTemplate: boolean;
    public templateDocPage: any;
    public secondarySuppliersInputer: any;
    public recordTypeInputer:any;
    @Output() docOutputer: EventEmitter<any> = new EventEmitter();
    @Output() docSuccessFilesOutputer: EventEmitter<any> = new EventEmitter();
    @Output() sndSuppliersOutputer: EventEmitter<any> = new EventEmitter();
    @Output() showMaskOutputer: EventEmitter<boolean> = new EventEmitter();
    @ViewChild(BasicInfoComponent)
    public basicInfoComponent: BasicInfoComponent;
    @ViewChild(CategoryLocationComponent)
    public categoryLocationComponent: CategoryLocationComponent;

    public docMiddleware: any;
    public sndSupplierMiddleWare: any;
    public RecordType:any;
    
    public isShowMask = false;

    constructor(private factory:Factory,
        private router: Router,
        private HPEService: HPEStaticService,
        private message:MessageService,
    ) { }
    ngOnInit() {
        console.log();
    }   
    outputHandler(outputer: any){
        console.log("docs: ", outputer);
        this.docMiddleware = outputer.data;
        this.docOutputer.emit(outputer);
        // this.docOutputer.emit(this.docMiddleware);
    }
    outSuccessFilesHandler(outputer: any){
        this.docSuccessFilesOutputer.emit(outputer);
    }
    outputRecordTypeDataHandler(event:any){
        //this.RecordType=event;

        this.recordTypeInputer=event;
        this.fileInputer.isValidation = true;
    }
    outputSecondarySupplierDataHandler(event: any){
        this.sndSupplierMiddleWare = event.data;
        this.sndSuppliersOutputer.emit(event);
    }

    docMaskStatusOutputHandler(event: boolean){
        this.showMaskOutputer.emit(event);
    }
    
}