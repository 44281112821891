import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Factory } from '../../../../../app.factory';
import { Router } from '@angular/router';
import { HPEStaticService } from "../../../../../common/services/staticService/HPEStaticService";
import { ViewRecordStaticService } from "../../../../../common/services/staticService/ViewRecordStaticService";
import { RecordType, RecordStatus, AgreementStaus, ReturnMessage } from 'app/common/enum/type.enum';
import { configurationIDs } from 'app/common/enum/configurationIDs';
import { MessageService } from 'app/common/services/message/messageService';
import { DefaultConstantValue } from 'app/common/enum/DefaultConstantValue';
import { AuthService } from 'app/common/services/UserAuth/AuthService';
import { CheckFormData, CheckSaveI } from 'app/modules/main/check-save-can-deavtivate';
import { InputComponent } from 'app/modules/main/component/input/input.component';
import { RecordViewComponent } from 'app/modules/main/component/recordForm/RecordView/RecordView.component';
import { ExpectionInfo } from 'app/common/enum/warning.enum';
import { AjaxService } from "app/common/services/Ajax/ajax.services";

@Component({
    selector: 'Template-New-Record',
    templateUrl: './TemplateMain.component.html',
    styleUrls: ['./TemplateMain.component.css'],
    inputs: ["recordDorpdownData"],
    providers: [Factory]
})
export class TemplateNewRecordComponent implements OnInit, CheckSaveI {
    public isTemplateDisable: boolean;
    public isShowErrorTip: boolean;
    public templateErrorTip: any;
    public templatePage: boolean = true;
    public templateDocPage: boolean = true;
    public templateParam: any;
    public formTitle: string;
    public recordFormData: any;
    public recordDorpdownData: any;
    public RecordViewInputData: any;
    public FilesInfo: any;
    public fileInputer: any;
    public service: any;
    public neededSubmitFieldsID: any = configurationIDs.submitForm.NeedSubmitedFieldIDs;
    public temSubmitFormIDs: any = configurationIDs.submitForm.SubmitFormIDs;
    public templateFieldsID: any = configurationIDs.submitForm.TemplateFieldIDs;
    public DocumentMoveCheckNewParentvalid = configurationIDs.submitForm.DocumentMoveCheckNewParentvalid;
    public ParentDocumentUri: any = { uri: "" };
    public SupplierUri: any = { uri: "" };
    public recordUri: string = "";
    public searchButtonShow: any;
    public isShowMask: boolean = false;
    public isShowSpinner: boolean = false;
    public isShowLoad: boolean;
    public isShowModal: boolean;
    public isShowModaShade: boolean = false;
    public isShowWaiting: boolean;
    public isNoValidation: any = { valid: false };
    public isSaveNoValidation: any = { valid: false };
    public isBtnDisable: boolean = false;
    public recordTypeInputer: any;
    public SecondarySuppliersInfo: any = [];
    public secondarySuppliersInputer: any;
    public agreeValidation: boolean;
    public noticedays: any = DefaultConstantValue.NoticeDays;
    public isParentDocumentValid: boolean = true;
    public NewParentrecordFormData: any;
    public ExpectionErrMsgPopup: any = [];
    public isShowLegalHoldWarning: boolean = false;
    public temdatas: string[] = [];
    public isUlshow: boolean = false;
    public isdivshow: boolean = true;
    public temInpValue: string;
    public temInpIndex: any = 0;
    public recordFieldJsonList: any;
    public neededSubmitrecordFieldJsonList: any;
    public getUserEid: any;
    public temdatasuri: string[] = [];
    public docOwnerChange :boolean = false;

    @ViewChild(RecordViewComponent)
    public recordViewComponent: RecordViewComponent;
    @ViewChild('tem_select_ul')
    public temSelectUl: ElementRef;
    constructor(private factory: Factory,
        private router: Router,
        private HPEService: HPEStaticService,
        private vrService: ViewRecordStaticService,
        private message: MessageService,
        private auth: AuthService,
        private ajaxservice: AjaxService,
    ) { }

    private hasSaved: boolean = false;
    private checkFormData: CheckFormData = new CheckFormData();
    public checkSaved(): boolean {
        return this.hasSaved;
    }
    public checkNoChange(): boolean {
        if (!this.checkFormData) {
            return false;
        }
        return !this.checkFormData.checkChanged();
    }
    ngDoCheck() {
        if (this.checkFormData) {
            this.checkFormData.doCheck({
                data: {
                    formData: this.recordFormData,
                    fileData: this.FilesInfo,
                    SecondarySuppliersInfo: this.SecondarySuppliersInfo,
                },
                isEdit: true,
            });
        }
    }

    ngOnInit() {
        this.templateReset(0);
    }

    templateReset(numparam) {
        this.isShowErrorTip = false;
        this.agreeValidation = true;
        this.isShowLoad = true;
        this.fileInputer = { isRead: false, isValidation: true }
        this.formTitle = "New Record Setup";
        this.searchButtonShow = { searchButtonFlag: true };
        // this.recordDorpdownData = {
        //     selectValue: [],
        //     getStatus: [],
        //     getTypes: [],
        //     getRenewals: [],
        //     getComplexitys: [],
        //     getPapers: [],
        //     getTerms: [],
        //     getLanguages: [],
        //     getCountrys: [],
        //     getKeyCategorys: [],
        //     getLicenses: [],
        //     getLicTypes: [],
        // };

        this.service = this.factory.getService();
        this.recordFieldJsonList = this.HPEService.getFieldbyFiledId(this.templateFieldsID);
        this.neededSubmitrecordFieldJsonList = this.HPEService.getFieldbyFiledId(this.temSubmitFormIDs);

        var user = this.auth.getUserInforFn();
        this.getUserEid = {
            EID: user.EID,
        }
        this.temdatas = [];
        this.temdatasuri = [];
        this.service.GetMyTempsList(this.getUserEid).then(res => {
            res.Data.forEach(res => {    
                this.temdatas.push(res[2].Value);
                this.temdatasuri.push(res[1].Value);
            });
            if(numparam == 0){
                this.temInpValue = this.temdatas[0];
            }
            if(this.temdatasuri[numparam] == ""){
                var neededFieldsID = configurationIDs.submitForm.SubmitFormIDs;
                var formDataJsonList = this.HPEService.getFieldbyFiledId(neededFieldsID);
                this.recordFormData = this.HPEService.setFormDataToJson(formDataJsonList);
                this.templateGetData(this.recordFormData);
            }else{
                let customPropertyName = this.vrService.getFormdataCustomPropertyNames(this.templateFieldsID);
                this.templateParam = {
                    EID: user.EID,
                    RecordUri: this.temdatasuri[numparam],
                    CustomProperty: customPropertyName
                }
             
                console.log("templateParam",this.templateParam);
                this.service.ToGetTemplateRecord(this.templateParam).then(res => {
                    console.log("templateParamres",res);
                    this.recordFormData = this.vrService.setTemplateFormDataToJson(this.neededSubmitrecordFieldJsonList, res.Data);
                    console.log("recordFormDataChanged",this.recordFormData);
                    this.recordFormData.AgreementID.value = "";
                    this.templateGetData(this.recordFormData);
                    if(this.recordFormData.RenewedReviewed.value == "No"){
                        this.recordFormData.RenewedReviewed.value = false;
                    }
                    // $('#RenewedReviewedS').css('visibility','hidden');  
                    // document.getElementById("RenewedReviewedS").style.display='none';
                }, error => {
                    console.log('templateRes:', error);
                });
            }  
            this.secondarySuppliersInputer = {
                isRead: false,
                uri: this.temdatasuri[numparam],
                reApprove: "old",
              };
        }, error => {
            console.log('getUserEid:', error);
        });
        this.message.getPrimaryChange().subscribe(result => {
            if (result.key === "DisableUpload") {
                this.isBtnDisable = result.isBtnDisable;
            }
        })
    }

    templateGetData(getDataParam) {
        // var _promise = Promise.all([
        //     this.service.getSelectValue(), //this.recordDorpdownData.selectValue
        //     this.service.getStatus(), //this.recordDorpdownData.getStatus
        //     this.service.getAgreType(), //this.recordDorpdownData.getTypes
        //     this.service.getRenType(), //this.recordDorpdownData.getRenewals
        //     this.service.getAgreComplex(), //this.recordDorpdownData.getComplexitys
        //     this.service.getAgrePaper(), //this.recordDorpdownData.getPapers
        //     this.service.getPayTerm(), //this.recordDorpdownData.getTerms
        //     this.service.getLanguage(), // this.recordDorpdownData.getLanguages
        //     this.service.getCountry(), // this.recordDorpdownData.getCountrys
        //     this.service.getKeyCategory(), //this.recordDorpdownData.getKeyCategorys
        //     this.service.getLicense(), //this.recordDorpdownData.getLicenses
        //     this.service.getLicType(), //this.recordDorpdownData.getLicTypes
        // ]);
        // _promise.then(res => {
        //     var [selectValue, getStatus, getTypes, getRenewals, getComplexitys, getPapers, getTerms, getLanguages, getCountrys, getKeyCategorys, getLicenses, getLicTypes] = [...res];
        //     this.recordDorpdownData.selectValue = selectValue;
        //     this.recordDorpdownData.getStatus = getStatus;
        //     this.recordDorpdownData.getTypes = getTypes;
        //     this.recordDorpdownData.getRenewals = getRenewals;
        //     this.recordDorpdownData.getComplexitys = getComplexitys;
        //     this.recordDorpdownData.getPapers = getPapers;
        //     this.recordDorpdownData.getTerms = getTerms;
        //     this.recordDorpdownData.getLanguages = getLanguages;
        //     this.recordDorpdownData.getCountrys = getCountrys;
        //     this.recordDorpdownData.getKeyCategorys = getKeyCategorys;
        //     this.recordDorpdownData.getLicenses = getLicenses;
        //     this.recordDorpdownData.getLicTypes = getLicTypes;
            this.RecordViewInputData = {
                formFields: getDataParam,
                dropDownValues: this.recordDorpdownData,
                SupplierUri: this.SupplierUri,
                ParentDocumentUri: this.ParentDocumentUri,
                submitFormErrMsg: {
                    isNoValidation: this.isNoValidation,
                    isSaveNoValidation: this.isSaveNoValidation,
                },
                isNewRecordSetup: true
            };
        //     console.log("mlpokn", this.RecordViewInputData);
        //     this.isShowLoad = false;
        // }, err => {
        //     console.log("LoadInforError", err);
        //     this.isShowLoad = false;
        // });
        this.isShowLoad = false;
    }
    showteminp() {
        $(".optionBox").hide();
        $(".templateInp").show();
        $(".templateInp").val("").focus().val(this.temInpValue);
        this.isShowErrorTip = false;
    }
    selectedTem(i,item) {
        this.temInpIndex = i;
        this.temInpValue = item;
        this.isUlshow = false;
        if (this.temInpValue == this.temdatas[0]) {
            this.templateReset(0);
        } else if (this.temInpValue == this.temdatas[1]) {
            this.templateReset(1);
        }else if (this.temInpValue == this.temdatas[2]) {
            this.templateReset(2);
        }
    }
    clickTemBtnOutSide() {
        if (!this.temSelectUl || !this.temSelectUl.nativeElement) {
            return;
        }
        $(this.temSelectUl.nativeElement).fadeOut();
    }
    clickTempateOutSide() {
        $(".templateInp").hide();
        $(".optionBox").show();
    }
    istemplateshow() {
        $(this.temSelectUl.nativeElement).toggle();
    }
    clicktemplates() {
        this.isUlshow = false;
    }

    resetData() {
        var neededFieldsID = configurationIDs.submitForm.SubmitFormIDs;
        var formDataJsonList = this.HPEService.getFieldbyFiledId(neededFieldsID);
        this.recordFormData = this.HPEService.setFormDataToJson(formDataJsonList);
    }
    checkTemplateName(str){
        return /^[\da-z]+$/i.test(str);
    }

    /* template */
    templateRecordData(FormRecordDataList, recordUri, templateName, templateIndex, secondarySuppliersInfo) {
        var user = this.auth.getUserInforFn();
        var recordFormFields = [];
        this.HPEService.SetAllDateTimeToDate(FormRecordDataList);
        FormRecordDataList.forEach(res => {
            var formField = {
                "Key": res.customPropertyName,
                "Value": res.value,
                "IsCustomer": res.IsCustomerFiled
            };
            recordFormFields.push(formField);
        });
        let sndSuppliers = [];
        if (secondarySuppliersInfo && secondarySuppliersInfo.length > 0) {
            console.log("secondarySuppliersInfoAC",secondarySuppliersInfo);
            secondarySuppliersInfo.forEach(element => {
                if (element) {
                    let ss;
                    ss = {
                        "CompanyCode": element.CompanyCode,
                        "SupplierID": element.SupplierID,
                        "SupplierName": element.SupplierName,
                        "PrimaryCountry": element.PrimaryCountry,
                        "SupplierUri": element.SupplierUri,
                        "SupplierSource": element.SupplierSource
                    }
                    sndSuppliers.push(ss);
                }
            });
        }
        var result;
        result = {
            "RecordFields": recordFormFields,
            "RecordUri": recordUri,
            "User": user,
            "TemplateName": templateName,
            "TemplateIndex": templateIndex,
            "SecondarySuppliers": sndSuppliers
        };
        return result;
    }

    saveTemplateClick(){
        if(!this.temInpValue || !this.temInpValue.trim()) {
            this.isShowErrorTip = true;
            this.templateErrorTip = "Template name cannot be empty.";
        // }else if(this.checkTemplateName(this.temInpValue) == false){
        //     this.isShowErrorTip = true;
        //     this.templateErrorTip = "Template name can only consist of Numbers or letters";
        }else{
            this.isTemplateDisable = true;
            var recordFormFields = [];
                var fieldNames = this.HPEService.getFormdataFieldNames(this.neededSubmitFieldsID);
                fieldNames.forEach(res => {
                    recordFormFields.push(this.recordFormData[res]);
                });
            var newRecordInput = this.templateRecordData(recordFormFields, this.temdatasuri[this.temInpIndex],this.temInpValue,this.temInpIndex, this.SecondarySuppliersInfo);
            console.log("newRecordInputAAAAAAAAAA", newRecordInput);
            this.service.SaveTemplateRecord(newRecordInput).then(res => {
                console.log("SaveTemplateRecord",res);
                this.hasSaved = true;
                if (res.Status == ReturnMessage.Success) {
                    this.temdatas = [];
                    res.Data.MyTemps.forEach(res => {
                        this.temdatas.push(res[2].Value);
                    });
                    $(".success_box").fadeIn("slow");
                    setTimeout("$('.success_box').fadeOut('slow');", 5000);
                    this.isTemplateDisable = false;
                    this.isShowMask = false;
                } else {
                    $(".errorstatus_box").fadeIn("slow");
                    setTimeout("$('.error_box').fadeOut('slow');", 5000);
                    this.isBtnDisable = false;
                    console.log("save template failed!" + res.Status);
                    this.ExpectionErrMsgPopup[0] = ExpectionInfo.ExpectionTitle;
                    this.ExpectionErrMsgPopup[1] = ExpectionInfo.ExpectionMsg_MessageContent_Line1;
                    this.ajaxservice.sendMsg(this.ExpectionErrMsgPopup);
                }
            }, err => {
                this.isBtnDisable = false;
                console.log("save template failed!");
            });   
      }
    }


    /**
     * document upload outputer
     */
    docOutHandler(reciever: any) {
        this.FilesInfo = reciever.data;
        if (!!this.FilesInfo && this.FilesInfo.filter((f) => f.Status != 1).length > 0) {

            this.fileInputer.isValidation = true;
        }
    }

    sndSupplierOutputHandler(receiver: any) {
        if (receiver.data == undefined) {
            this.SecondarySuppliersInfo = [];
        } else {
            this.SecondarySuppliersInfo = receiver.data;
        }
    }

    showMaskOutputHandler(receiver: boolean) {
        this.isShowSpinner = receiver;
    }

    getCustomPropertyNames(fieldJsonList: any) {
        let fieldNames = [];
        if (fieldJsonList.length > 0) {
            fieldJsonList.forEach(field => {
                if (field.IsCustomerFiled) {
                    fieldNames.push(field.customPropertyName);
                }
            });
        }
        return fieldNames;
    }

    isSubmitDisabled() {
        return this.checkNoChange();
    }

    
    DocumentOwnerValidation(){
        this.isBtnDisable = true;
        var param = {
            "Keycategory":this.recordFormData.KeyCategory.value,
            "Category": this.recordFormData.CategoryVal.value,
            "Country": this.recordFormData.PrimaryCountry.value,
            "GU": this.recordFormData.PrimaryGU.value,
        }
        this.service.GetDocOwnerByCC(param).then(res => {
            var dc = false;
            if(this.recordFormData.KeyCategory.value == "" ||this.recordFormData.CategoryVal.value == ""||this.recordFormData.PrimaryCountry.value == ""){
                dc = true;
            }
            if(this.recordFormData.DocumentOwner.value == res || dc){
                this.saveTemplateClick();
            } else{
                this.recordFormData.DocumentOwner.value = res;
                this.docOwnerChange = true;
            }
                
        });
    }

    recoverfromDC() {
        this.isBtnDisable = false;
        this.docOwnerChange = false;
    }
}
