import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[dragDropPreventDefault]'
})

export class DragDropPreventDefaultDirective {

    @HostListener('document:drop', ['$event'])
    documentDrop(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    @HostListener('document:dragenter', ['$event'])
    documentDragenter(e) {
        this.documentDrop(e);
    }

    @HostListener('document:dragover', ['$event'])
    documentDragover(e) {
        this.documentDrop(e);
    }

    @HostListener('document:dragleave', ['$event'])
    documentDragleave(e) {
        this.documentDrop(e);
    }
}

