import { Component, OnInit, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { Factory } from '../../../../../app.factory';
import { HPEStaticService } from "../../../../../common/services/staticService/HPEStaticService";
import { DatePipe } from '@angular/common';
import { configurationIDs } from 'app/common/enum/configurationIDs';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap} from 'rxjs/operators';
import { NewPaginatorComponent } from '../../../component/new-paginator/new-paginator.component';
import { ParentService } from "../../../../../common/services/NewRecord/parentservice";
import { AgreementStaus } from 'app/common/enum/type.enum';
import { LegalHold} from 'app/common/enum/warning.enum';

@Component({
  selector: 'app-parent-search',
  templateUrl: './parent-search.component.html',
  styleUrls: ['./parent-search.component.css'],
  providers: [
    Factory,
    DatePipe

  ],
  inputs: [
    'isParentSearchShow',
    'dorpdownValues',
    'SupplierName',
    'AgreementID'
  ],
  outputs: [
    'parentSearchShowOuter',
    'parentSearchOuter'
  ]
})
export class ParentSearchComponent implements OnInit {

  constructor(private el: ElementRef,
    private factory: Factory,
    private HPEService: HPEStaticService,
    private datePipe: DatePipe,
    private parentService: ParentService,
  ) {

  }
  //input
  public isParentSearchShow: boolean;
  public dorpdownValues: any;
  public SupplierName: any;
  //output
  public parentSearchShowOuter = new EventEmitter();
  public parentSearchOuter = new EventEmitter();

  public searchFormData: any;
  public isShowLoad: boolean;
  public isLoadingResults = false;
  public isRateLimitReached = false;
  public dataSource = new MatTableDataSource();
  public resultsLength = 0;
  public parentsearchNum: any;
  public isSearchResults = false;
  public AgreementID: any;
  @ViewChild(NewPaginatorComponent) paginator: NewPaginatorComponent;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  public isShowPage: boolean;
  public isSearchBtnDisabled: boolean;
  public isDisable: boolean = true;
  public highClass: any;
  public agreementStatueValue: any = [
    { Key: "Active", Value: "Active" },
    { Key: "Expired", Value: "Expired" },
    { Key: "Pending", Value: "Pending" }
  ];

  public displayedColumns = [
    'RecordNumber',
    'SupplierName',
    'AgreementName',
    'AgreementType',
    'PrimaryCountry',
    'KeyCategory',
    'CategoryVal',
    'DocumentOwner',
    'AgreementStatus',
    'AgreementStartDate'
  ];

  public service: any;
  public items: any;
  public searchload: boolean;
  public isResultShow: boolean;
  private thisItem: any;
  private parentDocument: string = '';
  public isParentDocument: boolean;
  public getCategorys: any;
  public neededFieldsID = configurationIDs.parentSearchForm.SearchFormIDs;
  public enableChange: boolean = true;
  public infoArrow : any="bottom";
  public information:any;
  public firstSearch: boolean = true;
  public LegalHoldInfo = LegalHold.LegalHoldInfoIcon;
  ngOnInit() {
    this.isSearchBtnDisabled = true;
    this.service = this.factory.getService();
    this.resetData();
    // console.log(this.dorpdownValues);
    this.information = "Parent records currently awaiting SAP ID will not appear in the search results.";
    Object.assign(this.sort, {active: 'RecordNumber', direction: 'asc'})
  }

  ngAfterViewInit(): void {
    this.popupResize();
  }

  resetData() {
    this.isDisable = true;
    this.highClass = -1;
    var formDataJsonList = this.HPEService.getFieldbyFiledId(this.neededFieldsID);
    this.searchFormData = this.HPEService.setFormDataToJson(formDataJsonList);
    this.searchFormData.AgreementID.IsDisabled = false;
    this.searchFormData.AgreementStatus.IsDisabled = false;
    if (this.SupplierName == "") {
      this.searchFormData.SupplierName.IsDisabled = false;
    } else {
      this.searchFormData.SupplierName.IsDisabled = false;
      this.searchFormData.SupplierName.value = this.SupplierName;
    }
    this.searchFormData.DocumentOwner.IsDisabled = false;
    var requirelist = this.HPEService.getFormdataFieldNames(this.neededFieldsID);
    requirelist.forEach(element => {
      this.searchFormData[element].IsRequired = false;
    });
    //search button disable function
    requirelist.forEach(element => {
      if (this.searchFormData[element].value != "" && this.searchFormData[element].value != null) {
        this.isSearchBtnDisabled = false;
      }
    });
    //search button disable function end
  }

  repalceForbidChar(inputValue:any) {
    // inputValue.value = inputValue.value.replace(/</g,'');
    this.searchBtnValidate();
  }

  //search button disable function
  searchBtnValidate() {
    this.isSearchBtnDisabled = true;
    // console.log("searchBtnValidate");
    var requirelist = this.HPEService.getFormdataFieldNames(this.neededFieldsID);
    requirelist.forEach(element => {

      if (this.searchFormData[element].FieldType === 'date') {
        if (
          this.searchFormData[element].value != null &&
          this.searchFormData[element].value != "" &&
          this.searchFormData[element].value != "Invalid Date"
        ) {
          this.isSearchBtnDisabled = false;

        }
      } else {
        if (this.searchFormData[element].value != null &&
          (this.searchFormData[element].value as string).trim() != "") {
          this.isSearchBtnDisabled = false;

        }
      }

    });
  }

  //search button disable function end

  setCategory() {
    if (this.searchFormData.KeyCategory.value) {
      var getKey = {
        "isCategory": true,
        "keyword": this.searchFormData.KeyCategory.value,
        "Catword": this.searchFormData.CategoryVal.value,
      }
      this.service.getCategoryMap(getKey).then(res => {
        if (res) {
          this.getCategorys = res;
          this.searchFormData.CategoryVal.IsDisabled = false;
          this.searchFormData.CategoryVal.value = "";
        }
      });
    } else {
      this.getCategorys = [];
      this.searchFormData.CategoryVal.IsDisabled = true;
      this.searchFormData.CategoryVal.value = "";
    }
  }

  clickItem(index: string, item: any) {
    if (item.legalHold) {
      // this.isDisable = true;
    } else {
      this.parentDocument = item;
      this.highClass = index;
      this.isDisable = false;
    }
  }

  selectItem() {
    this.parentSearchOuter.emit(this.parentDocument);
    this.isParentSearchShow = false;
    this.parentSearchShowOuter.emit(this.isParentSearchShow);
  }

  closePage() {
    this.isParentSearchShow = false;
    this.parentSearchShowOuter.emit(this.isParentSearchShow);
  }

  onSearch() {
    this.parentDocument = '';
    this.highClass = -1;
    this.isSearchResults=true;
    this.isDisable = true;
    this.isResultShow = true;
    this.isLoadingResults = true;
    this.isSearchBtnDisabled = true;
    var seachDataList = [];
    var customProperties = [];
    var fieldNames = this.HPEService.getFormdataFieldNames(this.neededFieldsID);
    fieldNames.forEach(res => {
      seachDataList.push(this.searchFormData[res]);
    });
    var ids = configurationIDs.parentSearchForm.ResCusFieldIDs;
    var cusfieldNames = this.HPEService.getFormdataFieldNames(ids);
    cusfieldNames.forEach(res => {
      customProperties.push(this.searchFormData[res].customPropertyName);
    });
    var searchstr = this.configeSearchString(seachDataList);

    var searchData = {
      searchString: searchstr,
      customProperty: customProperties
    };

    // console.log("search string", searchData);


    this.parentService.teamSearchRecordData = searchstr;
    this.paginator.page.emit();
    if(this.firstSearch) {
      this.firstSearch = false;
      this.sort.sortChange.subscribe(() => this.paginator.pageIndexNoEmit = 0);
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(
          startWith({}),
          switchMap(() => {
            this.parentDocument = '';
            this.isDisable = true;
            this.highClass = -1;
            this.isLoadingResults = true;
          var pageindex=0;
            if(!this.isSearchResults){
              pageindex=this.paginator.pageIndex;
            }
            return this.parentService!.getPage(
              this.sort.active, this.sort.direction, pageindex, this.paginator.pageSize);
          }),
          map(data => {
            this.isSearchBtnDisabled = false;
            this.isLoadingResults = false;
            this.isSearchResults=false;
            this.isRateLimitReached = false;
            this.resultsLength = data.Total;
            this.parentsearchNum = data.Total;
            return data.Data;
          }),
          catchError(() => {
            this.isLoadingResults = false;
            this.isSearchResults=false;
            this.isRateLimitReached = true;
            return observableOf([]);
          })
        ).subscribe(data => {
            this.dataSource.data = data
          });
    }

    this.isResultShow = true;
  }

  onPageRefresh(){
    this.paginator.pageIndexNoEmit = 0;
  }

  configeSearchString(formData) {
    var result = "ParentOrSupportingDocument=\"Parent Record\" and AgreementStatus:\"Active\",\"Pending\",\"Expired\"  and type:[Document] and not container:[type:[Document Backup]] and not SupplierID:\"Awaiting SAP ID\" and not number:\""+ this.AgreementID+"\" and not AgreementStatus:\"" + AgreementStaus.Deleted + "\"";
    formData.forEach(res => {
      if (res.value != "" && res.value != null) {
        if (res.FieldType == "singleSelect") {
          result = result + " and " + res.searchClauseName + "=\"" + res.value + "\"";
        } else if (res.FieldType == "bool") {
          if (res.value == "true") {
            result = result + " and " + res.searchClauseName;
          }
        } else if (res.FieldType == "date") {
          var tempdate = this.datePipe.transform(res.value, "yyyy-MM-dd");
          result = result + " and " + res.searchClauseName + "=\"" + tempdate + "\"";
        } else {
          result = result + " and " + res.searchClauseName + ":\"*" + res.value + "*\"";
        }
      }
    });
    return encodeURIComponent(result);
  }
  configeTableData(resData) {
    var tabData = [];
    resData.forEach(val => {
      var tabRow = [];
      var item = JSON.parse(val);
      if (item.AgreementStartDate != "") {
        item.AgreementStartDate = this.datePipe.transform(item.AgreementStartDate, "MM/dd/yyyy");
      }
      //tabRow = [item.RecordNumber, item.SupplierName, item.KeyCategory, item.CategoryVal, item.RecordTitle, item.AgreementType, item.PrimaryCountry, item.DocumentOwner, item.AgreementStatus, item.AgreementStartDate, item.USDExceeded];
      tabRow = [item.RecordNumber, item.SupplierName, item.KeyCategory, item.CategoryVal, item.AgreementName, item.AgreementType, item.PrimaryCountry, item.DocumentOwner, item.AgreementStatus, item.AgreementStartDate];
      tabData.push(tabRow);
    });
    return tabData;
  }

  ReSetParent() {
    this.isResultShow = false;
    this.ReSetTable();
    this.SupplierName = "";
    this.resetData();
    this.searchBtnValidate();
  }

  ReSetTable() {
    this.resultsLength = 0;
    this.dataSource.data = [];
    this.sort.active = "CreatedOn";
    this.sort.direction = "asc";
    this.parentDocument = '';
    this.isDisable = true;
    this.highClass = -1;
  }


  /**
   * for custom popup resize position(top)
   * @return {void}@memberof ParentSearchComponent
   */
  popupResize(): void {
    $('.search_headbox').css({
      'top': ($(window).scrollTop()) / $('.all_box').height() * 100 + '%'
    });
  }
}

