import { Component, ViewChildren, QueryList, OnInit, AfterViewChecked, Input } from '@angular/core';
import { BsDatepickerConfig, BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';
import { DataItem } from '../models';
import moment from 'moment';
import { FilterComponent } from './filter-container.component';
import { DateUtil } from 'app/common/util/date-util';

@Component({
  selector: 'date-scope-container',
  templateUrl: './date-scope.component.html',
  styleUrls: ['./compo.scss']
})
export class DateScopeComponent extends FilterComponent implements OnInit, AfterViewChecked {

  @ViewChildren(BsDatepickerDirective)
  public bsDatepickers: QueryList<BsDatepickerDirective>;

  public bsConfig: BsDatepickerConfig;
  
  public recordFormData: any;
  public recordToData: any;

  public formDataItem: DataItem;
  public toDataItem: DataItem;

  @Input()
  public inputId: string;

  constructor() {
 super(); 
}

  ngOnInit(): void {

    if (!this.inputId) {
      this.inputId = 'date_scope_' + new Date().getTime();
    }

    this.bsConfig = <any>{ dateInputFormat: 'DD-MMM-YYYY', showWeekNumbers: false }
    super.ngOnInit();
    this.dataItem
      .setIsLike(false)
      .setCol(this.property)
      .setAlias(this.property)
      .setOperateChar(':')
      .setValue('');
   const dateScope = this.checkeds[0] || {form: null, to: null};
   if(dateScope.form != null){
    this.recordFormData = moment(DateUtil.newDate(dateScope.form)).toDate() || null;
   }
   if(dateScope.to != null){
    this.recordToData = moment(DateUtil.newDate(dateScope.to)).toDate() || null;
   }
   this.formDataItem = Object.assign(new DataItem(), this.dataItem);
   this.formDataItem.setAlias(this.property+ '_From').setOperateChar('>=').setValue(dateScope.form);
   this.toDataItem = Object.assign(new DataItem(), this.dataItem);
   this.toDataItem.setAlias(this.property+ '_To').setOperateChar('<=').setValue(dateScope.to);
   if(dateScope.form != null){
    this._tabelFilterService.addQuery(this.formDataItem);
   }
   if(dateScope.to != null){
    this._tabelFilterService.addQuery(this.toDataItem);
   }
  }

  ngAfterViewChecked(): void {

    if(this.bsDatepickers == null){
      return;
    }
    this.bsDatepickers.forEach(bsDatepicker => {
      const matches = <any>document.querySelectorAll("span[bsDatepickerDayDecorator]");
      for (let userItem of Array.from(matches)) {
        (userItem as any).onclick =  (event) => {
          event.stopPropagation();
          event.preventDefault();
        }
      }
    });
  }

  recordFormDataChange(date: Date): void {

    if(date != null && !this.isDateType(date)){
      date = null;
      // setTimeout(() => {
      //   this.recordFormData = null;
      // });
      return;
    }

    let dateString = this.transformDate(date);
    const toDateString = this.transformDate(this.recordToData);
    if (date && this.recordToData && new Date(dateString).getTime() > new Date(toDateString).getTime()) {
      date = null;
      this.recordFormData = null;
    }
    dateString = this.transformDate(date);

    if(dateString == null){
      this._tabelFilterService.removeQuery(this.formDataItem);
    }else{
      this._tabelFilterService.addQuery(this.formDataItem);
    }
    this.formDataItem.setValue(dateString);
    this.filterItem.emit(this.formDataItem)
  }

  recordToDataChange(date: Date): void {

    if(date != null && !this.isDateType(date)){
      date = null;
      // setTimeout(() => {
      //   this.recordFormData = null;
      // });
      return;
    }

    let dateString = this.transformDate(date);
    const fromDateString = this.transformDate(this.recordFormData);
    if (date && this.recordFormData && new Date(dateString).getTime() < new Date(fromDateString).getTime()) {
      date = null;
      this.recordToData = null;
    }
    dateString = this.transformDate(date);

    if(dateString == null){
      this._tabelFilterService.removeQuery(this.toDataItem);
    }else{
      this._tabelFilterService.addQuery(this.toDataItem);
    }
    this.toDataItem.setValue(dateString);
    this.filterItem.emit(this.toDataItem)
  }

  cleanFilter(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    if(!this.hasCondition()){
      return;
    }
    this.cleanData();
    this.recordFormDataChange(null);
    this.recordToDataChange(null);
  }

  cleanData(){
    if(this.hasCondition()){
     this.recordFormData = '';
     this.recordToData = '';
     this._tabelFilterService.removeQuery(this.formDataItem);
     this._tabelFilterService.removeQuery(this.toDataItem);
     this._parent.regeditShowAction();
    }
  }

  public hasCondition(){
    return (this.recordFormData != ''
    || this.recordToData != '')
      && ( this.recordFormData != null
        || this.recordToData != null);
  }

  private transformDate(date: Date): string{
    const datePipe = new DatePipe('en-US');
    const setDob = datePipe.transform(date, 'dd-MMM-yyyy');
    return setDob;
  }

  public thisMonth(event: MouseEvent): void {
    this.thisToken(event, 'months');
  }

  public lastMonth(event: MouseEvent): void {
    this.lastToken(event, 'months');
  }

  public nextMonth(event: MouseEvent): void {
    this.nextToken(event, 'months');
  }

  public thisQuarter(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    const dateRange = this.getCurrentQuarterInFiscalYear(!this.showNext);
    this.setDate(dateRange.getFrom(), dateRange.getTo());
  }

  public lastQuarter(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    const dateRange = this.getCurrentQuarterInFiscalYear();
    const from = moment(dateRange.getFrom()).subtract(3, 'months').startOf('months').toDate();
    const to = moment(dateRange.getTo()).subtract(3, 'months').endOf('months').toDate();
    this.setDate(from, to);
  }

  public nextQuarter(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    const dateRange = this.getCurrentQuarterInFiscalYear();
    const from = moment(dateRange.getFrom()).add(3, 'months').startOf('months').toDate();
    const to = moment(dateRange.getTo()).add(3, 'months').endOf('months').toDate();
    this.setDate(from, to);
  }

  public thisYear(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    const dateRange = this.getCurrentFiscalYear(!this.showNext);
    this.setDate(dateRange.getFrom(), dateRange.getTo());
  }

  public lastYear(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    const dateRange = this.getCurrentFiscalYear();
    const from = moment(dateRange.getFrom()).subtract(1, 'years').toDate();
    const to = moment(dateRange.getTo()).subtract(1, 'years').toDate();
    this.setDate(from, to);
  }

  public nextYear(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    const dateRange = this.getCurrentFiscalYear();
    const from = moment(dateRange.getFrom()).add(1, 'years').toDate();
    const to = moment(dateRange.getTo()).add(1, 'years').toDate();
    this.setDate(from, to);
  }

  private thisToken(event: MouseEvent, token: any){
    event.stopPropagation();
    event.preventDefault();
    const moment_ = moment();
    const startDate = moment_.clone().startOf(token).toDate();
    let endDate = moment_.clone().toDate();
    if(this.showNext){
      endDate = moment_.clone().endOf(token).toDate();
    }
    this.setDate(startDate, endDate);
  }

  private lastToken(event: MouseEvent, token: any){
    event.stopPropagation();
    event.preventDefault();
    const moment_ = moment().subtract(1, token);
    const startDate = moment_.clone().startOf(token).toDate();
    const endDate = moment_.clone().endOf(token).toDate();
    this.setDate(startDate, endDate);
  }

  private nextToken(event: MouseEvent, token: any){
    event.stopPropagation();
    event.preventDefault();
    const moment_ = moment().add(1, token);
    const startDate = moment_.clone().startOf(token).toDate();
    const endDate = moment_.clone().endOf(token).toDate();
    this.setDate(startDate, endDate);
  }

  private setDate(startDate: Date, endDate: Date): void {
    this.recordFormData = startDate;
    this.recordToData = endDate;
    this.recordFormDataChange(startDate);
    this.recordToDataChange(endDate);
  }

  private getCurrentFiscalYear(current: boolean = false): DateRange {

    // Aug 31st
    const moment_ = moment();
    const dateRange = DateRange.range(moment_.toDate(), moment_.toDate());
    const boundaryLine = moment_.clone().set('month', 7).endOf('month');
    if(moment_.isSameOrBefore(boundaryLine)){
      const pre = moment_.clone().subtract(1, 'years');
      // Sep 1st
      const preStartDate = pre.set('month', 8).startOf('month').toDate();
      let preEndDate = boundaryLine.clone().toDate();

      if(current){
        preEndDate = moment_.clone().toDate();
      }
      
      dateRange.setFrom(preStartDate);
      dateRange.setTo(preEndDate);
    }else {
      const next = boundaryLine.clone().add(1, 'years');
      let nextStartDate = boundaryLine.clone().add(1, 'days').toDate();
      const nextEndDate = next.toDate();

      if(current){
        nextStartDate = moment_.clone().toDate();
      }

      dateRange.setFrom(nextStartDate);
      dateRange.setTo(nextEndDate);
    }
    return dateRange;
  }

  private getCurrentQuarterInFiscalYear(current: boolean = false): DateRange {
    // Aug 31st
    const moment_ = moment();
    const dateRange = this.getCurrentFiscalYear();

    const fromDate = moment(dateRange.getFrom());
    const toDate = moment(dateRange.getTo());

    // Frist Quarter 9.1 - 11.31
    const fristQuarterEndDate = fromDate.clone().add(2, 'months').endOf('months');
    // Second Quarter 12.1 - 2.28
    const secondQuarterEndDate = fristQuarterEndDate.clone().add(1, 'days').add(2, 'months').endOf('months');
    // Third Quarter 3.1 - 5.31
    const thirdQuarterEndDate = secondQuarterEndDate.clone().add(1, 'days').add(2, 'months').endOf('months');
    // Fourth Quarter 6.1 - 8.31
    const fourthQuarterEndDate = toDate;

    if(moment_.isBetween(fromDate, fristQuarterEndDate)){
      dateRange.setTo(fristQuarterEndDate.toDate());
    }else if(moment_.isBetween(fristQuarterEndDate, secondQuarterEndDate)){
      dateRange.setFrom(fristQuarterEndDate.clone().add(1, 'days').toDate());
      dateRange.setTo(secondQuarterEndDate.toDate());
    }else if(moment_.isBetween(secondQuarterEndDate, thirdQuarterEndDate)){
      dateRange.setFrom(secondQuarterEndDate.clone().add(1, 'days').toDate());
      dateRange.setTo(thirdQuarterEndDate.toDate());
    }else if(moment_.isBetween(thirdQuarterEndDate, fourthQuarterEndDate)){
      dateRange.setFrom(thirdQuarterEndDate.clone().add(1, 'days').toDate());
      dateRange.setTo(fourthQuarterEndDate.toDate());
    }

    if(current){
      dateRange.setTo(moment_.clone().toDate());
    }

    return dateRange;
  }

  private isDateType(date: Date){
    return !isNaN(date.getDate()) && Object.prototype.toString.call(date) === '[object Date]'
  }

}

export class DateRange {

  constructor(public from: Date, public to: Date) {

  }

  public static range(from: Date, to: Date): DateRange {
    return new DateRange(from, to);
  }

  public setFrom(from: Date): DateRange {
    this.from = from;
    return this;
  }

  public setTo(to: Date): DateRange {
    this.to = to;
    return this;
  }

  public getFrom(): Date {
    return this.from;
  }

  public getTo(): Date {
    return this.to;
  }

}
