import { Injectable } from '@angular/core';
import { HPEStaticService } from "../../../common/services/staticService/HPEStaticService";
import { AuthService } from 'app/common/services/UserAuth/AuthService';
import { RecordType } from 'app/common/enum/type.enum';
import { MessageService } from 'app/common/services/message/messageService'
import { DatePipe } from '@angular/common';
import { configurationIDs } from 'app/common/enum/configurationIDs';
import { DateUtil } from 'app/common/util/date-util';

@Injectable()
export class ViewRecordStaticService {
    
    constructor(
        private HPEService: HPEStaticService,
        private auth: AuthService,
        private message: MessageService,
        private datepipe: DatePipe,
    ) { }


    setAdminViewDataToJson(recordFieldsDefault: any, recordQuery: any) {
        let jsonResult;
        let jsonStr = "{";
        /*foreach the needed fields*/
        recordFieldsDefault.forEach(res => {
            if(res.customPropertyName == "OnlyNotify"){
                if(res.value == "No"){
                    res.value = false
                }else if(res.value == "Yes"){
                    res.value = true
                }
            }
            // console.log("edit test",res);
            /*set information readonly*/
            res.isRead = true;
            let fieldName = res.IsCustomerFiled ? res.searchClauseName : res.customPropertyName;
            /*foreach the query result*/
            recordQuery.forEach(element => {
                if (element.Key === "OnlyNotify") {
                    if(element.Value == "No"){
                        element.Value = false
                    }else if(element.Value == "Yes"){
                        element.Value = true
                    }
                }
                let resultName = res.IsCustomerFiled ? element.SearchClauseName : element.Key;

                if (fieldName == "ParentDocument" && resultName == "ParentOrSupportingDocument"
                    && element.Value == RecordType.Supporting) {
                    res.isShow = true;
                }
                if (fieldName && resultName && fieldName.toLowerCase() == resultName.toLowerCase()) {
                    if (element.ischange == true) {
                        res.toolTip = true;
                        if (element.Value == "") {
                            element.Value = "";
                            res.oldValue = "No Previous Value";
                        } else {
                            //res.oldValue = "Previous Value:\n" + element.Value;
                            res.oldValue = "Previous Value:<br/>" + element.Value;
                        }
                        if (element.newValue == "" || element.newValue == null) {
                            res.value = "";
                        } else {
                            res.value = element.newValue;
                        }
                    } else {
                        res.toolTip = false;
                        if (element.Value == "") {
                            res.value = "";
                        } else {
                            res.value = element.Value;
                        }
                    }
                }
            });
            let itemContent;
            /*deal the system fields */
            if (!res.IsCustomerFiled) {
                itemContent = "\"" + this.replaceChar(res.labelName, " ", "") + "\":" + JSON.stringify(res)
            } else {
                itemContent = "\"" + res.searchClauseName + "\":" + JSON.stringify(res)
            }
            if (jsonStr == "{") {
                jsonStr = jsonStr + itemContent;
            } else {
                jsonStr = jsonStr + "," + itemContent;
            }
        });
        jsonStr = jsonStr + "}";
        jsonResult = JSON.parse(jsonStr);
        return jsonResult;
    }


    setDeleteAdminViewDataToJson(recordFieldsDefault: any, recordQuery: any) {
        let jsonResult;
        let jsonStr = "{";
        /*foreach the needed fields*/
        recordFieldsDefault.forEach(res => {
            if(res.customPropertyName == "OnlyNotify"){
                if(res.value == "No"){
                    res.value = false
                }else if(res.value == "Yes"){
                    res.value = true
                }
            }
            // console.log("edit test",res);
            /*set information readonly*/
            res.isRead = true;
            let fieldName = res.IsCustomerFiled ? res.searchClauseName : res.customPropertyName;
            /*foreach the query result*/
            recordQuery.forEach(element => {
                if (element.Key === "OnlyNotify") {
                    if(element.Value == "No"){
                        element.Value = false
                    }else if(element.Value == "Yes"){
                        element.Value = true
                    }
                }
                let resultName = res.IsCustomerFiled ? element.SearchClauseName : element.Key;

                if (fieldName == "ParentDocument" && resultName == "ParentOrSupportingDocument"
                    && element.Value == RecordType.Supporting) {
                    res.isShow = true;
                }
                if (fieldName && resultName && fieldName.toLowerCase() == resultName.toLowerCase()) {
                    res.value = element.Value;
                }
            });
            let itemContent;
            /*deal the system fields */
            if (!res.IsCustomerFiled) {
                itemContent = "\"" + this.replaceChar(res.labelName, " ", "") + "\":" + JSON.stringify(res)
            } else {
                itemContent = "\"" + res.searchClauseName + "\":" + JSON.stringify(res)
            }
            if (jsonStr == "{") {
                jsonStr = jsonStr + itemContent;
            } else {
                jsonStr = jsonStr + "," + itemContent;
            }
        });
        jsonStr = jsonStr + "}";
        jsonResult = JSON.parse(jsonStr);
        return jsonResult;
    }

    /*set the record fields from query result to json*/
    setFormDataToJson(recordFieldsDefault: any, recordQuery: any) {
        let jsonResult;
        let jsonStr = "{";
        /*foreach the needed fields*/
        recordFieldsDefault.forEach(res => {
            if(res.customPropertyName == "OnlyNotify"){
                if(res.value == "No"){
                    res.value = false
                }else if(res.value == "Yes"){
                    res.value = true
                }
            }
            /*set information readonly*/
            res.isRead = true;
            let fieldName = res.IsCustomerFiled ? res.searchClauseName : res.customPropertyName;
            /*foreach the query result*/
            recordQuery.forEach(element => {
                if (element.Key === "OnlyNotify") {
                    if(element.Value == "No"){
                        element.Value = false
                    }else if(element.Value == "Yes"){
                        element.Value = true
                    }
                }
                let resultName = res.IsCustomerFiled ? element.SearchClauseName : element.Key;

                if (fieldName == "ParentDocument" && resultName == "ParentOrSupportingDocument"
                    && element.Value == RecordType.Supporting) {
                    res.isShow = true;
                }
                if (fieldName && resultName && fieldName.toLowerCase() == resultName.toLowerCase()) {
                    element.Value == "" ? res.value = "" : res.value = element.Value;
                }
            });
            let itemContent;
            /*deal the system fields */
            if (!res.IsCustomerFiled) {
                itemContent = "\"" + this.replaceChar(res.labelName, " ", "") + "\":" + JSON.stringify(res)
            } else {
                itemContent = "\"" + res.searchClauseName + "\":" + JSON.stringify(res)
            }
            if (jsonStr == "{") {
                jsonStr = jsonStr + itemContent;
            } else {
                jsonStr = jsonStr + "," + itemContent;
            }
        });
        jsonStr = jsonStr + "}";
        jsonResult = JSON.parse(jsonStr);
        return jsonResult;
    }
    /*set the template record fields from query result to json*/

    setTemplateFormDataToJson(recordFieldsDefault: any, recordQuery: any) {
        let jsonResult;
        let jsonStr = "{";
        /*foreach the needed fields*/
        recordFieldsDefault.forEach(res => {
            if(res.customPropertyName == "OnlyNotify"){
                if(res.value == "No"){
                    res.value = false
                }else if(res.value == "Yes"){
                    res.value = true
                }
            }
            /*set information readonly*/
            res.isRead = false;
            let fieldName = res.IsCustomerFiled ? res.searchClauseName : res.customPropertyName;
            /*foreach the query result*/
            if(recordQuery != null){
                recordQuery.forEach(element => {
                    if (element.Key === "OnlyNotify") {
                        if(element.Value == "No"){
                            element.Value = false
                        }else if(element.Value == "Yes"){
                            element.Value = true
                        }
                    }
                    if (element.Key === "parentRecordUri") {
                        return;
                    }
                    let resultName = res.IsCustomerFiled ? element.SearchClauseName : element.Key;
    
                    if (fieldName == "ParentDocument" && resultName == "ParentOrSupportingDocument"
                        && element.Value == RecordType.Supporting) {
                        res.isShow = true;
                    }
                    if (fieldName && resultName && fieldName.toLowerCase() == resultName.toLowerCase()) {
                        element.Value == "" ? res.value = "" : res.value = element.Value;
                    }
                });
            }
            let itemContent;
            /*deal the system fields */
            if (!res.IsCustomerFiled) {
                itemContent = "\"" + this.replaceChar(res.labelName, " ", "") + "\":" + JSON.stringify(res)
            } else {
                itemContent = "\"" + res.searchClauseName + "\":" + JSON.stringify(res)
            }
            if (jsonStr == "{") {
                jsonStr = jsonStr + itemContent;
            } else {
                jsonStr = jsonStr + "," + itemContent;
            }
        });
        jsonStr = jsonStr + "}";
        jsonResult = JSON.parse(jsonStr);
        return jsonResult;
    }
    /*set the record fields from query result to json*/
    setResubmitDataToJson(recordFieldsDefault: any, recordQuery: any) {
        let jsonResult;
        let jsonStr = "{";
        /*foreach the needed fields*/
        recordFieldsDefault.forEach(res => {
            if(res.customPropertyName == "OnlyNotify"){
                if(res.value == "No"){
                    res.value = false
                }else if(res.value == "Yes"){
                    res.value = true
                }
            }
            /*set information readonly*/
            res.isRead = true;
            let fieldName = res.IsCustomerFiled ? res.searchClauseName : res.customPropertyName;
            /*foreach the query result*/
            recordQuery.forEach(element => {
                if (element.Key === "OnlyNotify") {
                    if(element.Value == "No"){
                        element.Value = false
                    }else if(element.Value == "Yes"){
                        element.Value = true
                    }
                }
                let resultName = res.IsCustomerFiled ? element.SearchClauseName : element.Key;

                if (fieldName == "ParentDocument" && resultName == "ParentOrSupportingDocument"
                    && element.Value == RecordType.Supporting) {
                    res.isShow = true;
                }
                if (fieldName && resultName && fieldName.toLowerCase() == resultName.toLowerCase()) {
                    if (fieldName === "Number") {
                        res.value = element.Value ? element.Value : "";
                    } else {
                        res.value = element.newValue ? element.newValue : "";
                    }
                }
            });
            let itemContent;
            /*deal the system fields */
            if (!res.IsCustomerFiled) {
                itemContent = "\"" + this.replaceChar(res.labelName, " ", "") + "\":" + JSON.stringify(res)
            } else {
                itemContent = "\"" + res.searchClauseName + "\":" + JSON.stringify(res)
            }
            if (jsonStr == "{") {
                jsonStr = jsonStr + itemContent;
            } else {
                jsonStr = jsonStr + "," + itemContent;
            }
        });
        jsonStr = jsonStr + "}";
        jsonResult = JSON.parse(jsonStr);
        return jsonResult;
    }
    getFormdataCustomPropertyNames(IDs: any) {
        let fieldNames = [];
        IDs.forEach(id => {
            let field = this.HPEService.getFieldbyFiledId(id);
            fieldNames.push(field.customPropertyName);
        });
        return fieldNames;
    }

    /*Get useful Fields' properties By ID */
    getFields(fieldIds: any) {
        let recordFields = [];
        fieldIds.forEach(fId => {
            let field = this.HPEService.getFieldbyFiledId(fId);
            recordFields.push(field);
        });
        return recordFields;
    }

    /* Set Record Info For Saving Or Updating */
    setFormRecordData(FormRecordDataList, RecordType, IsSubmit, recordUri, FilesInfo, secondarySuppliersInfo) {
        var user = this.auth.getUserInforFn();
        var recordFormFields = [];
        this.HPEService.SetAllDateTimeToDate(FormRecordDataList);
        FormRecordDataList.forEach(res => {
            var formField = {
                "Key": res.customPropertyName,
                "Value": res.value,
                "IsCustomer": res.IsCustomerFiled
            };
            recordFormFields.push(formField);
        });
        let sndSuppliers = [];
        if (secondarySuppliersInfo && secondarySuppliersInfo.length > 0) {
            secondarySuppliersInfo.forEach(element => {
                if (element) {
                    let ss;
                    ss = {
                        "CompanyCode": element.CompanyCode,
                        "SupplierID": element.SupplierID,
                        "SupplierName": element.SupplierName,
                        "PrimaryCountry": element.PrimaryCountry,
                        "SupplierUri": element.SupplierUri,
                        "SupplierSource": element.SupplierSource,
                        "CompanyDescription": element.CompanyDescription
                    }
                    sndSuppliers.push(ss);
                }
            });
        }
        var result;
        result = {
            "RecordFields": recordFormFields,
            "RecordType": RecordType,
            "RecordUri": recordUri,
            "User": user,
            "FilesInfo": FilesInfo,
            "IsSubmit": IsSubmit,
            "SecondarySuppliers": sndSuppliers
        };
        return result;
    }

    setRecordContainerUri(recordFormData, ParentDocumentUri) {
        var containerUri = "";
        if (recordFormData.ParentDocument.value) {
            containerUri = ParentDocumentUri.uri;
        }
        return containerUri;
    }

    ValidationReset(isNoValidation, isSaveNoValidation, recordFormData) {
        isNoValidation.valid = false;
        isSaveNoValidation.valid = false;
        var ids = configurationIDs.submitForm.SubmitVilidationIDs;
        var fieldNames = this.HPEService.getFormdataFieldNames(ids);
        fieldNames.forEach(res => {
            recordFormData[res].isValidation = true;
        });
    }

    ValidationResetRenewed(isNoValidation, isSaveNoValidation, recordFormData) {
        isNoValidation.valid = false;
        isSaveNoValidation.valid = false;
        var ids = configurationIDs.submitForm.SubmitVilidationRenewedIDs;
        var fieldNames = this.HPEService.getFormdataFieldNames(ids);
        fieldNames.forEach(res => {
            recordFormData[res].isValidation = true;
        });
    }

    //trigger upload component's function to delete hprm documents   
    triggerDelete(isNeededHistory: boolean, fn1: any, fn2: any) {
        this.message.sendDeleteMsg({ deleteParam: [isNeededHistory, fn1, fn2] });
    }

    private replaceChar(strOriginal: string, strOldPart: string, strNewPart: string) {
        return strOriginal.replace(strOldPart, strNewPart);
    }

    configSupplierSearchString(formData) {
        var result = "type:\"Supplier\"";
        formData.forEach(res => {
            if (res.value != "") {
                result = result + " and " + res.searchClauseName + ":\"*" + res.value + "*\"";;
            }
        });
        return result;
    }

    checkInvalidDate(date: any) {
        const isEmpty = !date;
        const isDate = Object.prototype.toString.call(date) === '[object Date]';
        let isValid = false;
        if (!isEmpty && isDate) {
            isValid = !isNaN(date.getDate());
        }
        if (!isEmpty && isDate && isValid) {
            return true;
        } else {
            return false;
        }
    }

    renewalDateValidation(recordFormData: any) {
        if (!!recordFormData.AgreementStartDate.value
            && Object.prototype.toString.call(DateUtil.newDate(recordFormData.AgreementStartDate.value)) === '[object Date]'
            && !isNaN(DateUtil.newDate(recordFormData.AgreementStartDate.value).getDate())
            && !!recordFormData.RenewalDate.value
            && Object.prototype.toString.call(DateUtil.newDate(recordFormData.RenewalDate.value)) === '[object Date]'
            && !isNaN(DateUtil.newDate(recordFormData.RenewalDate.value).getDate())) {
            var startDate = new Date(this.datepipe.transform(recordFormData.AgreementStartDate.value, 'dd MMM yyyy'));
            var renewalDate = new Date(this.datepipe.transform(recordFormData.RenewalDate.value, 'dd MMM yyyy'));
            let renewalNotice = + recordFormData.RenewalNotice.value.replace(/[^0-9]/g, '');
            if (startDate <= renewalDate && renewalNotice <= 3650) {
                recordFormData.RenewalDate.isValidation = true;
                recordFormData.RenewalNotice.isValidation = true;
            } else {
                if (recordFormData.RenewalNotice.value > 3650) {
                    recordFormData.RenewalDate.value = "";
                }
                recordFormData.RenewalNotice.isValidation = false;
                recordFormData.RenewalDate.isValidation = false;
            }
        } else if (!!recordFormData.RenewalNotice.value
            && !!recordFormData.AgreementStartDate.value
            && !!recordFormData.AgreementEndDate.value
            && recordFormData.RenewalNotice.isValidation === false) {
            recordFormData.RenewalNotice.isValidation = false;
            recordFormData.RenewalDate.isValidation = false;
        } else {
            recordFormData.RenewalDate.isValidation = true;
            recordFormData.RenewalNotice.isValidation = true;
        }
        return recordFormData;
    }

    /**
     * compare two objects by the way of json
     * @param obj1 
     * @param obj2 
     */
    public static isEqualsObj(obj1: object, obj2: object): boolean {

        if (!obj1 && !obj2) {
            return true;
        } else if (!obj1 || !obj2) {
            return false;
        }
        const keya = Object.keys(obj1);
        const keyb = Object.keys(obj2);

        if (keya.length !== keyb.length) {
            return false;
        }
        let filenotequal = true;
        keya.every(key => {
            let a = JSON.stringify(obj1[key]);
            let b = JSON.stringify(obj2[key]);
            if (a === b) { 
            } else{
                filenotequal = false;
            }
            return true;
        });

        return filenotequal;
    }

    /**
     * compare all values between of two objects
     * @param obj1 
     * @param obj2 
     */
    public static isEqualsObjValue(obj1: object, obj2: object): any {
        if (!obj1 && !obj2) {
            return true;
        } else if (!obj1 || !obj2) {
            return false;
        }

        const getValues = function (obj): any[] {
            keys.sort((keyf, keya) => {
 return keyf > keya ? 1 : 0 
});
            let values = keys.map(key => {
                if (key === 'TerminationDate' && obj[key].value === null){
                    obj[key].value = "";
                }
                if (obj[key].FieldType === 'date' && obj[key].value) {
                    // date type compare by time
                    return DateUtil.newDate(obj[key].value).getTime();
                } else {
                    return obj[key].value;
                }
            });
            return values;
        }
        let keys = [];
        for (let key in obj1) {
            keys.push(key);
        }
        const values1 = getValues(obj1);
        const values2 = getValues(obj2);
        var allfieldchange = JSON.stringify(values1) === JSON.stringify(values2);
        let keyField =["AgreementName",
        "SupplierName",
        "SupplierID",
        "CompanyCode",
        "StakeholderOwner",
        "LegalDepartmentContact",
        "DocumentOwner",
        "KeyCategory",
        "CategoryVal",
        "SubCategory",
        "PrimaryCountry",
        "PrimaryGU",
        "PrimaryRegion",
        "AgreementStartDate",
        "AgreementEndDate",
        "RenewalDate",
        "MarketIntelligence",
        "InfoSec",
        "GDPR",
        "USDExceeded",
        "SupplierCodeOfConduct",
        "KeySupplier",
        "ParentOrSupportingDocument",
        "ParentDocument",
        "RenewalNotice",
        "AgreementPaper",
        "License",
        "LicenseType",
        "RenewalType",
        "PaymentTerms",
        "ReasonForPaymentType",
        "AgreementSpendType"];
        if(keys.length == 0){
            keys = [];
        } else{
            keys = keyField;
        }

        const values3 = getValues(obj1);
        const values4 = getValues(obj2);
        var keyFieldChange = JSON.stringify(values3) === JSON.stringify(values4);
        var result = {
            allfieldchange:allfieldchange,
            keyFieldChange:keyFieldChange 
        };
        return result;
    }

    /**
     * compare two objects by the way of json
     * @param obj1 
     * @param obj2 
     */
    public static isOnlyRename(obj1: object): boolean {
        const keya = Object.keys(obj1);
        let a = [];
        let b = [];
        keya.every(key => {
            if(obj1[key].Status == 3){
               a.push(key);
            } else if(obj1[key].Status == 1 || obj1[key].Status == 2){
                b.push(key);
             }
             return true;
        });
        if(a.length > 0 && b.length == 0){
            return true;
        } else{
            return false;
        }
    }
}