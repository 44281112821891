import { Component, ChangeDetectorRef } from "@angular/core";
import { DatePipe } from "app/common/pipe/date.pipe";
import { ViewRecordStaticService } from "../../../../../common/services/staticService/ViewRecordStaticService";
import { MessageService } from "app/common/services/message/messageService";
import { RecordType } from "app/common/enum/type.enum";
import { DateUtil } from "app/common/util/date-util";
import { event } from "jquery";
import { Subscription } from "rxjs";
import {  Router } from "@angular/router";
import { NavigationStart } from "@angular/router";
import { debug } from "console";
import { AuthService } from 'app/common/services/UserAuth/AuthService';
import { NDAToolUser } from "app/common/enum/userGroup";
@Component({
  selector: "AgreementInfo",
  templateUrl: "./AgreementInfo.component.html",
  styleUrls: ["./AgreementInfo.component.css"],
  inputs: ["inputsData"],
})
export class AgreementInfoComponent {
  subscription: Subscription;
  public newStartValue: any;
  public StartToolTip: any;
  public newEndValue: any;
  public EndToolTip: any;
  public ToolTip: any;
  public RenewalNoticeToolTip: any;
  public newValue: boolean;
  public newReasonValue: boolean;
  public newValueRenewalDate: boolean;
  public inputsData: any;
  public recordFormData: any;
  public recordDorpdownData: any;
  public isMore: boolean = true;
  public bsConfig: any;
  public ReasonToolTip: any;
  public num: any = 200;
  public isOtherLanguageShow: boolean = false;
  public renewedValue: boolean = true;
  public isRenewedY:boolean;
  private routerSub$: Subscription;
  constructor(
    private router: Router,
    private datepipe: DatePipe,
    private vrService: ViewRecordStaticService,
    public changeDetectorRef: ChangeDetectorRef,
    private auth: AuthService,
    private message: MessageService
  ) {}

  ngOnInit() {
    var arr = window.location.pathname.split("/");
    var pgname = "";
    pgname = arr[1].toString().split("?")[0];
    console.log(pgname,arr[1])
    this.recordFormData = this.inputsData.formFields;
    this.recordFormData.ReasonForPaymentType.IsDisabled = true;
    this.recordFormData.ReasonForPaymentType.IsRequired = false;

    if (
      this.recordFormData.PaymentTerms.value == "None of above please specify"
    ) {
      this.recordFormData.ReasonForPaymentType.IsRequired = true;
    } else {
      this.recordFormData.ReasonForPaymentType.IsRequired = false;
    }
    if (
      this.recordFormData.PaymentTerms.value ==
        "None of above please specify" &&
      this.recordFormData.PaymentTerms.IsDisabled == false
    ) {
      this.recordFormData.ReasonForPaymentType.IsDisabled = false;
    } else {
      this.recordFormData.ReasonForPaymentType.IsDisabled = true;
      this.recordFormData.ReasonForPaymentType.value = "";
    }
    if (
      this.recordFormData.ReasonForPaymentType.toolTip == true &&
      this.recordFormData.ReasonForPaymentType.selectTip == true
    ) {
      this.newReasonValue = true;
      this.ReasonToolTip = this.recordFormData.ReasonForPaymentType.oldValue;
    } else {
      this.newReasonValue = false;
      this.ReasonToolTip = "";
    }
    const txtVal = this.recordFormData.ReasonForPaymentType.value.length;
    this.num = 200 - txtVal;
    if (
      this.recordFormData.AgreementStartDate.toolTip == true &&
      this.recordFormData.AgreementStartDate.selectTip == true
    ) {
      this.newStartValue = true;
      if (
        this.recordFormData.AgreementStartDate.oldValue == "No Previous Value"
      ) {
        this.StartToolTip = "No Previous Value";
      } else {
        var partEnd =
          this.recordFormData.AgreementStartDate.oldValue.split("<br/>");
        this.StartToolTip =
          "Previous Value:<br/>" +
          this.datepipe.transform(partEnd[1], "dd-MMM-yyyy");
      }
    } else {
      this.newStartValue = false;
      this.StartToolTip = "";
    }
    if (
      this.recordFormData.AgreementEndDate.toolTip == true &&
      this.recordFormData.AgreementEndDate.selectTip == true
    ) {
      this.newEndValue = true;
      if (
        this.recordFormData.AgreementEndDate.oldValue == "No Previous Value"
      ) {
        this.EndToolTip = "No Previous Value";
      } else {
        var partEnd =
          this.recordFormData.AgreementEndDate.oldValue.split("<br/>");
        this.EndToolTip =
          "Previous Value:<br/>" +
          this.datepipe.transform(partEnd[1], "dd-MMM-yyyy");
      }
    } else {
      this.newEndValue = false;
      this.EndToolTip = "";
    }
    if (
      this.recordFormData.RenewalNotice.toolTip == true &&
      this.recordFormData.RenewalNotice.selectTip == true
    ) {
      this.newValue = true;
      this.RenewalNoticeToolTip = this.recordFormData.RenewalNotice.oldValue;
    } else {
      this.newValue = false;
      this.RenewalNoticeToolTip = "";
    }
    if (
      this.recordFormData.RenewalDate.toolTip == true &&
      this.recordFormData.RenewalDate.selectTip == true
    ) {
      this.newValueRenewalDate = true;
      if (this.recordFormData.RenewalDate.oldValue == "No Previous Value") {
        this.ToolTip = "No Previous Value";
      } else {
        var partEnd = this.recordFormData.RenewalDate.oldValue.split("<br/>");
        this.ToolTip =
          "Previous Value:<br/>" +
          this.datepipe.transform(partEnd[1], "dd-MMM-yyyy");
      }
    } else {
      this.newValueRenewalDate = false;
      this.ToolTip = "";
    }
    // if(pgname == "newrecord"){
    //   $('#Renewed').css('display','none');           
    //   console.log(this.recordFormData.RenewedReviewed.value);
    // }else{
    //   $('#Renewed').css('display','flex');  
    //   this.renewedValue = true         
    //   console.log(this.recordFormData.RenewedReviewed.value);
    // }
    //xt test
    const tempRenewalDate = DateUtil.newDate(
      this.datepipe.transform(
          this.recordFormData.RenewalDate.value,
        "dd MMM yyyy"
      )
    );
  const renewalDay = Date.parse((tempRenewalDate.toLocaleDateString()));
  const day = Date.parse(new Date().toLocaleDateString()); 
  const agreementVal = this.recordFormData.AgreementStatus.value;
  if(pgname == "newrecord"){
    $('#RenewedReviewedS').css('visibility','hidden');  
  }else if(agreementVal == "Active" || agreementVal == "Expired"){
    if(renewalDay > day ){
      $('#RenewedReviewedS').css('visibility','hidden'); 
      console.log("888")  
    }else{
      $('#RenewedReviewedS').css('visibility','visible'); 
      if(this.recordFormData.RenewedReviewed.value == "Yes"){
        // $('#notRenewedNotify').prop('disabled','disabled');
        this.isRenewedY = true;
      }
      console.log(agreementVal)
    }
  } else{
    $('#RenewedReviewedS').css('visibility','hidden'); 
    // $('#Renewed').css('display','flex'); 
    this.renewedValue = true; 
  }

  // if(pgname == "newrecord"){
  //   $('#RenewedReviewedS').css('visibility','hidden');   
  // }else if(renewalDay !== day || this.recordFormData.RenewedReviewed.value == "Yes"){
  //   // if(renewalDay !== day || this.recordFormData.RenewedReviewed.value == "Yes"){
  //     $('#RenewedReviewedS').css('visibility','hidden');   
  //   // }else{
  //   //   $('#RenewedReviewedS').css('visibility','visible'); 
  //   // }
  // }
  // else{
  //   $('#RenewedReviewedS').css('visibility','hidden'); 
  //   this.renewedValue = true;
  // console.log(this.recordFormData.AgreementStatus.value)
  // }
    // $('#Renewed').css('display','flex'); 
    
    //test end
    this.recordDorpdownData = this.inputsData.dropDownValues;
    // if(this.recordFormData.AgreementType.value == "Other"){
    //     this.recordFormData.OtherAgreementType.isShow=true;
    //     this.recordFormData.OtherAgreementType.IsRequired=true;
    // }
    // else{
    //     this.recordFormData.OtherAgreementType.isShow=false;
    //     this.recordFormData.OtherAgreementType.IsRequired=false;
    // }
    // if(this.recordFormData.Language.value == "Other" ){
    //     this.isOtherLanguageShow=true;
    //     this.message.sendOtherLanguageShow({isOtherLanguageShow: this.isOtherLanguageShow});
    //     this.recordFormData.OtherLanguage.isShow=true;
    //     if(this.recordFormData.ParentOrSupportingDocument.value == RecordType.Parent){
    //         this.recordFormData.OtherLanguage.IsRequired=true;
    //     }
    //     else{
    //         this.recordFormData.OtherLanguage.IsRequired=false;
    //     }
    // }else{
    //     this.recordFormData.OtherLanguage.isShow=false;
    //     this.recordFormData.OtherLanguage.IsRequired=false;
    // }
    this.bsConfig = {
      dateInputFormat: "DD-MMM-YYYY",
      showWeekNumbers: false,
      minDate: DateUtil.newDate("02-Jan-1000"),
    };
    
    if (
      this.recordFormData.RenewalDate.value != null &&
      this.recordFormData.RenewalDate.value != "" 
     
    ) {
      if (this.recordFormData.RenewalDate.isRead) {
          this.recordFormData.RenewalDate.value = this.datepipe.transform(
          this.recordFormData.RenewalDate.value,
          "dd-MMM-yyyy"
        );
      } else {
          var cvalue = DateUtil.newDate(
          this.datepipe.transform(
            this.recordFormData.RenewalDate.value,
            "dd MMM yyyy"
          )
        );
        this.recordFormData.RenewalDate.value = cvalue;
      }
    }
    if (this.recordFormData.AgreementStartDate.value) {
      if (this.recordFormData.AgreementStartDate.isRead) {
        this.recordFormData.AgreementStartDate.value = this.datepipe.transform(
          this.recordFormData.AgreementStartDate.value,
          "dd-MMM-yyyy"
        );
      } else {
        var cvalue = DateUtil.newDate(
          this.datepipe.transform(
            this.recordFormData.AgreementStartDate.value,
            "dd MMM yyyy"
          )
        );
        this.recordFormData.AgreementStartDate.value = cvalue;
      }
    }
    if (this.recordFormData.AgreementEndDate.value) {
      if (this.recordFormData.AgreementEndDate.isRead) {
        this.recordFormData.AgreementEndDate.value = this.datepipe.transform(
          this.recordFormData.AgreementEndDate.value,
          "dd-MMM-yyyy"
        );
      } else {
        var cvalue = DateUtil.newDate(
          this.datepipe.transform(
            this.recordFormData.AgreementEndDate.value,
            "dd MMM yyyy"
          )
        );
        this.recordFormData.AgreementEndDate.value = cvalue;
      }
    }
    this.recordFormData.PaymentTerms.IsRequired = true;
    if (
      this.recordFormData.PaymentTerms.value == "None of above please specify"
    ) {
      this.recordFormData.ReasonForPaymentType.IsRequired = true;
    }
  }

  isAgreement() {
    if (this.isMore == true) {
      this.isMore = false;
    } else {
      this.isMore = true;
    }
    $(".isAgreement").slideToggle();
  }
  SignatureTypeChange($event) {
    if ($event.value != "E-signature - Other") {
      this.recordFormData.ESignatureOtherText.value = "";
    }
  }
  setLanInfo() {
    //     if (this.recordFormData.Language.value == "Other"){
    //             this.recordFormData.OtherLanguage.isShow = true;
    //             this.isOtherLanguageShow=true;
    //             if(this.recordFormData.ParentOrSupportingDocument.value == RecordType.Parent){
    //                 this.recordFormData.OtherLanguage.IsRequired = true;
    //             }
    //             else{
    //                 this.recordFormData.OtherLanguage.IsRequired = false;
    //             }
    //         }
    //         else {
    //             this.isOtherLanguageShow=false;
    //             this.recordFormData.OtherLanguage.isShow = false;
    //             this.recordFormData.OtherLanguage.IsRequired=false;
    //         }
    //         this.recordFormData.OtherLanguage.value="";
    //         this.message.sendOtherLanguageShow({isOtherLanguageShow: this.isOtherLanguageShow});
  }
    setAgreementTypeInfo() {
      //     if(this.recordFormData.AgreementType.value=="Other"){
      //         this.recordFormData.OtherAgreementType.isShow=true;
      //         this.recordFormData.OtherAgreementType.IsRequired=true;
      //     }
      //     else{
      //         this.recordFormData.OtherAgreementType.isShow=false;
      //         this.recordFormData.OtherAgreementType.IsRequired=false;
      //     }
      //     this.recordFormData.OtherAgreementType.value = "";
      //     this.recordFormData.PredecessorAgreementID.isError = false;
      var user = this.auth.getUserInforFn();
      var isNDA = false;
      NDAToolUser.forEach(res => {
          if (res.toUpperCase() == user.EID.toUpperCase()){
              isNDA = true;
          }
      });
      if (isNDA && this.recordFormData.AgreementType.value == "NDA" ){
        this.recordFormData.KeyCategory.IsRequired = false;
        this.recordFormData.CategoryVal.IsRequired = false;
        this.recordFormData.SubCategory.IsRequired = false;
      } else{
        this.recordFormData.KeyCategory.IsRequired = true;
        this.recordFormData.CategoryVal.IsRequired = true;
        this.recordFormData.SubCategory.IsRequired = true;
      }
    }

  onRenewalNoticeChanged($event) {
    this.recordFormData.RenewalNotice.value = $event.replace(/[^0-9]/g, "");
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
    if (
      this.recordFormData.RenewalNotice.IsRequired == true &&
      this.recordFormData.RenewalNotice.value !== ""
    ) {
      this.recordFormData.RenewalNotice.isValidation = true;
    }
    this.dateChangeListen("RenewalNotice");
  }
  onPaymentTermsOtherChanged() {
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
    if (
      this.recordFormData.ReasonForPaymentType.IsRequired == true &&
      this.recordFormData.ReasonForPaymentType.value !== ""
    ) {
      this.recordFormData.ReasonForPaymentType.isValidation = true;
    }
    const txtVal = this.recordFormData.ReasonForPaymentType.value.length;
    this.num = 200 - txtVal;
  }
  // addPaymentOther
  onPaymentTermsChanged() {
    this.recordFormData.ReasonForPaymentType.isValidation = true;
    if (
      this.recordFormData.PaymentTerms.value == "None of above please specify"
    ) {
      this.recordFormData.ReasonForPaymentType.IsRequired = true;
    } else {
      this.recordFormData.ReasonForPaymentType.IsRequired = false;
    }
    if (
      this.recordFormData.PaymentTerms.value ==
        "None of above please specify" &&
      this.recordFormData.PaymentTerms.IsDisabled == false
    ) {
      this.recordFormData.ReasonForPaymentType.IsDisabled = false;
    } else {
      this.recordFormData.ReasonForPaymentType.IsDisabled = true;
      this.recordFormData.ReasonForPaymentType.value = "";
      this.num = 200;
    }
  }
  onAgreementValueChanged($event) {
    this.recordFormData.AgreementValue.value = $event.replace(/[^0-9\-\$\<\>]/g, "");
  }

  validationNumber($event) {
    $event.target.value = $event.target.value.replace(/[^0-9]/g, "");
  }

  dateBlur(event) {
    const target = event.target ? event.target : event.srcElement;
    if (
      !DateUtil.isValid(this.recordFormData.AgreementStartDate.value) ||
      DateUtil.newDate(this.recordFormData.AgreementStartDate.value).getTime() <
        this.bsConfig.minDate.getTime()
    ) {
      this.recordFormData.AgreementStartDate.value = null;
      this.dateChangeListen("AgreementStartDate");
    }
    if (
      !DateUtil.isValid(this.recordFormData.AgreementEndDate.value) ||
      DateUtil.newDate(this.recordFormData.AgreementEndDate.value).getTime() <
        this.bsConfig.minDate.getTime()
    ) {
      this.recordFormData.AgreementEndDate.value = null;
      this.dateChangeListen("AgreementEndDate");
    }
    if (
      (event && !DateUtil.isValid(target.value)) ||
      DateUtil.newDate(target.value).getTime() < this.bsConfig.minDate.getTime()
    ) {
      target.value = "";
    }
  }

  dateChangeListen(eventObject: any) {
    if (this.recordFormData.AgreementStartDate.value == "Invalid Date") {
      this.recordFormData.AgreementStartDate.value = null;
    } else if (this.recordFormData.AgreementEndDate.value == "Invalid Date") {
      this.recordFormData.AgreementEndDate.value = null;
    }
    /*validate agreementStartDate and agreementEndDate*/
    if (eventObject !== "RenewalNotice") {
      console.log(eventObject)
      if (eventObject == "AgreementStartDate") {
        if (
          Object.prototype.toString.call(
            DateUtil.newDate(this.recordFormData.AgreementStartDate.value)
          ) === "[object Date]" &&
          this.recordFormData.AgreementStartDate.isValidation == false &&
          !isNaN(
            DateUtil.newDate(
              this.recordFormData.AgreementStartDate.value
            ).getDate()
          )
        ) {
          this.recordFormData.AgreementStartDate.isValidation = true;
        } else if (
          !this.recordFormData.AgreementStartDate.value &&
          this.recordFormData.AgreementStartDate.isValidation == false
        ) {
          this.recordFormData.AgreementStartDate.isValidation = true;
        }
      } else if (eventObject == "AgreementEndDate") {
        if (
          Object.prototype.toString.call(
            DateUtil.newDate(this.recordFormData.AgreementEndDate.value)
          ) === "[object Date]" &&
          !isNaN(
            DateUtil.newDate(
              this.recordFormData.AgreementEndDate.value
            ).getDate()
          ) &&
          this.recordFormData.AgreementEndDate.isValidation == false
        ) {
          this.recordFormData.AgreementEndDate.isValidation = true;
        } else if (
          !this.recordFormData.AgreementEndDate.value &&
          this.recordFormData.AgreementEndDate.isValidation == false
        ) {
          this.recordFormData.AgreementEndDate.isValidation = true;
        }
      }
      this.validateStartAndEndDate(eventObject);
    }
    /*operate renewal date*/
    if (eventObject !== "AgreementStartDate") {
      /*recalculate renewal date*/
      var arr = window.location.pathname.split("/");
      var pgname = "";
      pgname = arr[1].toString().split("?")[0];
      console.log(pgname,arr[1])
      if (
        !!this.recordFormData.AgreementEndDate.value &&
        Object.prototype.toString.call(
          DateUtil.newDate(this.recordFormData.AgreementEndDate.value)
        ) === "[object Date]" &&
        !isNaN(
          DateUtil.newDate(this.recordFormData.AgreementEndDate.value).getDate()
        ) &&
        !!this.recordFormData.RenewalNotice.value
      ) {
        const endDate = DateUtil.newDate(
          this.datepipe.transform(
            this.recordFormData.AgreementEndDate.value,
            "dd MMM yyyy"
          )
        );
        if (
          this.recordFormData.RenewalNotice.value ==
          this.recordFormData.RenewalNotice.value.replace(/[^0-9]/g, "")
        ) {
          const renewalNotice: number = this.recordFormData.RenewalNotice.value;
          if (renewalNotice <= 3650) {
            const renewalDate = DateUtil.newDate(
              endDate.setDate(endDate.getDate() - renewalNotice)
            );
            this.recordFormData.RenewalDate.value = renewalDate;
           
            const day =Date.parse(new Date().toLocaleDateString());
            const renewalDay = Date.parse(renewalDate.toLocaleDateString());
            const agreementStatusVal = this.recordFormData.AgreementStatus.value;
            if(pgname == "newrecord"){ 
             $('#RenewedReviewedS').css('visibility','hidden');         
              console.log(this.recordFormData.RenewedReviewed.value,"newrecord");
            } else if(agreementStatusVal == "Active" || agreementStatusVal == "Expired") {
              
              if (renewalDay == day || renewalDay< day){
                $('#RenewedReviewedS').css('visibility','visible'); 
               
                console.log(this.isRenewedY,day,renewalDay)
                this.recordFormData.RenewedReviewed.value = $('#notRenewedNotify').prop('checked');
                if(this.recordFormData.RenewedReviewed.value == true){
                  // $('#notRenewedNotify').prop('disabled',true);
                  // $('#notRenewedNotify').prop('disabled','disabled');
                  this.isRenewedY = true;
                 
                  console.log("13579")
                }
                console.log("edit",this.recordFormData.RenewedReviewed.value);
              } else {
                $('#RenewedReviewedS').css('visibility','hidden');  
                this.isRenewedY = false;
                this.recordFormData.RenewedReviewed.value = false;
                console.log($('#notRenewedNotify').prop('checked'),"edit display");             
                console.log(this.isRenewedY,day,renewalDay)
              }
            }else{
              $('#RenewedReviewedS').css('visibility','hidden');  
              this.recordFormData.RenewedReviewed.value = false;
              console.log($('#notRenewedNotify').prop('checked'),"edit display");  
            }
          } else {
            this.recordFormData.RenewalDate.value = "";
            this.recordFormData.RenewalDate.isValidation = false;
            this.recordFormData.RenewalNotice.isValidation = false;
            return;
          }
        }
      } else if (
        /*clear renewal date*/
        !this.recordFormData.AgreementEndDate.value ||
        Object.prototype.toString.call(
          DateUtil.newDate(this.recordFormData.AgreementEndDate.value)
        ) !== "[object Date]" ||
        isNaN(
          DateUtil.newDate(this.recordFormData.AgreementEndDate.value).getDate()
        ) ||
        !this.recordFormData.RenewalNotice.value
      ) {
       
        this.recordFormData.RenewalDate.value = "";
        this.recordFormData.RenewalDate.isValidation = true;
        if (
          (!this.recordFormData.RenewalNotice.IsRequired &&
            this.recordFormData.RenewalNotice.value.trim() == "") ||
          !this.recordFormData.AgreementEndDate.value ||
          Object.prototype.toString.call(
            DateUtil.newDate(this.recordFormData.AgreementEndDate.value)
          ) !== "[object Date]" ||
          isNaN(
            DateUtil.newDate(
              this.recordFormData.AgreementEndDate.value
            ).getDate()
          )
        ) {
          this.recordFormData.RenewalNotice.isValidation = true;
        }
      }
    }
    /*validate agreementStartDate and RenewalPreviewDate*/
    if (
      this.recordFormData.AgreementStartDate.isValidation == true &&
      this.recordFormData.AgreementEndDate.isValidation == true
    ) {
      this.vrService.renewalDateValidation(this.recordFormData);
    } else {
      this.recordFormData.RenewalDate.isValidation = true;
      this.recordFormData.RenewalNotice.isValidation = true;
    }
  }

  validateStartAndEndDate(dateName: any) {
    if (
      !!this.recordFormData.AgreementStartDate.value &&
      Object.prototype.toString.call(
        DateUtil.newDate(this.recordFormData.AgreementStartDate.value)
      ) === "[object Date]" &&
      !isNaN(
        DateUtil.newDate(this.recordFormData.AgreementStartDate.value).getDate()
      ) &&
      !!this.recordFormData.AgreementEndDate.value &&
      Object.prototype.toString.call(
        DateUtil.newDate(this.recordFormData.AgreementEndDate.value)
      ) === "[object Date]" &&
      !isNaN(
        DateUtil.newDate(this.recordFormData.AgreementEndDate.value).getDate()
      )
    ) {
      const startDate = DateUtil.newDate(
        this.datepipe.transform(
          this.recordFormData.AgreementStartDate.value,
          "dd MMM yyyy"
        )
      );
      const endDate = DateUtil.newDate(
        this.datepipe.transform(
          this.recordFormData.AgreementEndDate.value,
          "dd MMM yyyy"
        )
      );
      if (startDate > endDate) {
        if (dateName == "AgreementStartDate") {
          this.recordFormData.AgreementStartDate.isValidation = false;
          this.recordFormData.AgreementEndDate.isValidation = true;
        } else if (dateName == "AgreementEndDate") {
          this.recordFormData.AgreementEndDate.isValidation = false;
          this.recordFormData.AgreementStartDate.isValidation = true;
        }
      } else {
        this.recordFormData.AgreementStartDate.isValidation = true;
        this.recordFormData.AgreementEndDate.isValidation = true;
      }
    }
  }

  getval(event) {
    const aaa = event;
    console.log("event", event);
  }

  checkRenewNotice(event) {
    let s = this.recordFormData.RenewalNotice.value;
    if (s.indexOf("0") > -1 && s.length > 1) {
      s = s.replace(/\b(0+)/gi, "");
      if (s === "") {
        s = "0";
      }
      this.recordFormData.RenewalNotice.value = s;
    }
    event.target.value = this.recordFormData.RenewalNotice.value;
  }
  checkAgreementValue(event) {
    let s = this.recordFormData.AgreementValue.value;
    s = s.replace(/[^0-9\-\$\<\>]/g, "");
    this.recordFormData.AgreementValue.value = s;
    event.target.value = this.recordFormData.AgreementValue.value;
  }
  checkOne(event: any) {
    this.recordFormData.RenewedReviewed.value = !this.recordFormData.RenewedReviewed.value;
    console.log(this.recordFormData.RenewedReviewed.value)
  }
}
