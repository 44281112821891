import { Component, OnInit, Output, EventEmitter} from '@angular/core'
import { Factory } from '../../../../app.factory';
import { AuthService } from 'app/common/services/UserAuth/AuthService';

@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
  outputs: ['userProfileShow']
})
export class UserProfileComponent implements OnInit {

  @Output() userProfileShow = new EventEmitter<any>();  
  private service: any;
  public isShow: boolean=false;
  public isQuery: boolean=false;
  public userName : string;
  public userEID : string;
  public businessUnit : string;
  public country : string;
  public keyCategories : string;
  public GU : string;
  public otherForBU : string;
  public accessLevel : string;
  public userStatus : string;
  constructor(
    private factory: Factory,
    private auth: AuthService,
    ) {}

  ngOnInit() {
    this.service = this.factory.getService();
    var user = this.auth.getUserInforFn();
    var data = { Eid: user.EID };
    this.isQuery = true;
    this.service.getUserDetailsByEid(data).then(res => {
      if("Success" == res.Status) {  
        var userDetails  = res.Data; 
        this.userName = userDetails.FirstName;
        this.userEID = userDetails.EID;
        this.country = userDetails.UserCountry;
        this.businessUnit = userDetails.BusinessUnit;
        this.keyCategories = userDetails.KeyCategory.split(';').join(', ');
        this.GU = userDetails.Gu;
        this.otherForBU = userDetails.OtherForBU;
        this.accessLevel = userDetails.AccessLevel;
        this.userStatus = userDetails. Status;
      }
      this.isQuery = false;
    });
  }

  closePop(){
    this.userProfileShow.emit(false);
  }

}
