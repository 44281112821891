import { NgModule } from '@angular/core';
import { SubStringCol } from './subStringCol.pipe';

@NgModule({
  imports: [],
  declarations: [
    SubStringCol
  ],
  exports: [
    SubStringCol
  ]
})
export class SubStringColModule {

}