import { Injectable } from "@angular/core";
import { Subject, Observable, } from "rxjs";
import { Factory } from '../../../app.factory';
import { DatePipe } from '@angular/common';

@Injectable()
export class MessageService {
    public historyData:any;
    public historyRejectData:any;
    public service: any;
    public searchRes: any;
    public searchValue: any;
    public searchDataQ: any;
    public searchDataProperties: any;
    public resultNum: any;
    public isShowLoad: any;
    public searchResultNum: any;
    public items:any;
    public searchEnter: boolean = true;

    public parentRed: boolean = false;

    private subject = new Subject<any>();
    private secondCountrysubject = new Subject<any>();
    private deleteSubject = new Subject<any>();
    private searchButtonsubject = new Subject<any>();
    private primaryChangeSubject = new Subject<any>();
    private BlockDis = new Subject<any>();

    private KeySupplierClickSubject=new Subject<any>();
    private searchSubject = new Subject<any>();
    private viewSubject = new Subject<any>();
    private MultiFilesErrorsubject = new Subject<any>();
    private CanNotChengeRecordTypesubject=new Subject<any>();
    private docToRadioErrSubject = new Subject<any>();
    private searchFlagSubject = new Subject<any>();
    private parentRedSubject = new Subject<any>();
    private SupplierFlagSubject = new Subject<any>();
    private MatchAgreementSubject = new Subject<any>();
    private OtherLanguageShowsubject=new Subject<any>();
    private ParentDocumentDisabledSubject = new Subject<any>();
    private SecSupSubject = new Subject<any>();
    public RejectpopupNotNullSubject = new Subject<any>();
    public SecondaryCountryforCancelButton = new Subject<any>();
    public parentDocument = new Subject<any>();

    // category location
    public CategoryLocationSetCategory = new Subject<any>();
    public CategoryLocationSetSubcategory = new Subject<any>();

    constructor(
    private factory: Factory,
    private datepipe: DatePipe,
    ) { }
    sendMsg(message: any) {
        this.subject.next(message);
    }

    get(): Observable<any> {
        return this.subject.asObservable();
    }
    sendsecondCountryMsg(message: any) {
        this.secondCountrysubject.next(message);
    }

    getsecondCountry(): Observable<any> {
        return this.secondCountrysubject.asObservable();
    }
    sendsearchButtonMsg(message: any) {
        this.searchButtonsubject.next(message);
    }
    getsearchButtonMsg(): Observable<any> {
        return this.searchButtonsubject.asObservable();
    }
    
    sendParentDocumentAbled(message: any){
        this.ParentDocumentDisabledSubject.next(message);
    }
    getParentDocumentAbled(): Observable<any> {
        return this.ParentDocumentDisabledSubject.asObservable();
    }


    sendDeleteMsg(message: any) {
        this.deleteSubject.next(message);
    }  
    getDeleteMsg(): Observable<any> {
        return this.deleteSubject.asObservable();
    }

    sendSearchMsg(message: any) {
        this.searchSubject.next(message);
    }

    getSearch(): Observable<any> {
        return this.searchSubject.asObservable();
    }

    sendViewMsg(message: any) {
        this.viewSubject.next(message);
    }

    getView(): Observable<any> {
        return this.viewSubject.asObservable();
    }

    transValToArr(gotValue: string){
        let initArr:any;
        if(gotValue == ''){
            initArr = [];
        }else{
            initArr = gotValue.split(';');
        }
        let newInitArr = [];
        for(let i = 0, len = initArr.length; i < len; i++){
        newInitArr.push(initArr[i].trim());
        }
        return newInitArr.length;
        } 

    public getTableSubject = new Subject<any>();
    public getTableData(): any {
    this.searchValue = window.localStorage.getItem("searchValue");
    this.searchDataQ = window.localStorage.getItem("searchDataQ");
    this.searchDataProperties = window.localStorage.getItem("searchDataProperties");
    this.service = this.factory.getService();
    this.service.HPServiceRecord(this.searchDataQ, this.searchDataProperties).then(res => {
      this.searchRes = res.Results;
      this.searchRes.forEach(val => {
        val.Fields.SecondCountry.Value = this.transValToArr(val.Fields.SecondCountry.Value);
        if (val.Fields.AgreementEndDate.DateTime != "" && val.Fields.AgreementEndDate.DateTime != null){
        val.Fields.AgreementEndDate.DateTime = this.datepipe.transform(val.Fields.AgreementEndDate.DateTime, 'dd-MMM-yyyy')
      }
      });
      if(this.searchValue == ""){
        this.resultNum = 0;
        this.searchRes = ""
        this.getTableSubject.next(this.searchRes);
      }else{
        this.resultNum = this.searchRes.length;
        this.getTableSubject.next(this.searchRes);
      }
        }, err => {

        });
    }
    sendMultiFilesErrorMsg(message: any) {
        this.MultiFilesErrorsubject.next(message);
    } 
    getMultiFilesErrorMsg(): Observable<any> {
        return this.MultiFilesErrorsubject.asObservable();
    }
    sendOtherLanguageShow(message: any) {
        this.OtherLanguageShowsubject.next(message);
    } 
    getOtherLanguageShow(): Observable<any> {
        return this.OtherLanguageShowsubject.asObservable();
    }

    sendDocToRadioErr(message: any){
        this.docToRadioErrSubject.next(message);
    }
    getDocToRadioErr(): Observable<any>{
        return this.docToRadioErrSubject.asObservable();
    }

    sendPrimaryChange(message:any) {
        this.primaryChangeSubject.next(message);
    }
    getPrimaryChange(): Observable<any> {
        return this.primaryChangeSubject.asObservable();
    }

    sendBlockDis(message: any) {
        this.BlockDis.next(message);
    }
    getBlockDis(): Observable<any> {
        return this.BlockDis.asObservable();
    }

    sendMenuSearchFlag(message: any) {
        this.searchFlagSubject.next(message);
    }
    getMenuSearchFlag(): Observable<any> {
        return this.searchFlagSubject.asObservable();
    }

    sendParentRedFlag(message: any) {
        this.parentRedSubject.next(message);
    }
    getParentRedFlag(): Subject<any> {
        return this.parentRedSubject;
    }

    sendSupplierFlag(message: any) {
        this.SupplierFlagSubject.next(message);
    }
    getSupplierFlag(): Observable<any> {
        return this.SupplierFlagSubject.asObservable();
    }

    sendAgreetypeFlag(message: any) {
        this.MatchAgreementSubject.next(message);
    }
    getAgreetypeFlag(): Observable<any> {
        return this.MatchAgreementSubject.asObservable();
    }
    sendSecSupFlag(message: any) {
        this.SecSupSubject.next(message);
    }
    getSecSupFlag(): Observable<any> {
        return this.SecSupSubject.asObservable();
    }
    sendRejectpopupNotNullFlag(message: any) {
        this.RejectpopupNotNullSubject.next(message);
    }
    getRejectpopupNotNullFlag(): Observable<any> {
        return this.RejectpopupNotNullSubject.asObservable();
    }
    sendSecondaryCountryforCancel(message: any){
        this.SecondaryCountryforCancelButton.next(message);
    }
    getSecondaryCountryforCancel(): Observable<any> {
        return this.SecondaryCountryforCancelButton.asObservable();
    }
    sendParentDocument(message: any){
        this.parentDocument.next(message);
    }
    getParentDocument():  Observable<any>{
        return this.parentDocument.asObservable();;
    }
    sendKeySupplierClick(message: any){
        this.KeySupplierClickSubject.next(message);
    }
    getKeySupplierClickSubject(): Observable<any> {
        return this.KeySupplierClickSubject.asObservable();
    }

    sendCategoryLocationSetCategory() {
        this.CategoryLocationSetCategory.next;
    }
    getCategoryLocationSetCategory(): Observable<any> {
        return this.CategoryLocationSetCategory.asObservable();
    }
    sendCategoryLocationSetSubcategory() {
        this.CategoryLocationSetSubcategory.next;
    }
    getCategoryLocationSetSubcategory(): Observable<any> {
        return this.CategoryLocationSetSubcategory.asObservable();
    }
}
