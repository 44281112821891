import { Injectable } from "@angular/core";
import { Observable, } from "rxjs";
import { Factory } from 'app/app.factory';
import { RecordStatus } from 'app/common/enum/type.enum';
@Injectable()
export class SupplierNameService {
    constructor(private factory: Factory) { }
    public service: any;
    public MyRecordsData: string;
    public teamSearchRecordData: string;
    public searchDataProperties: string;
    public pageArgs: string;
    getPage(sort: string, order: string, page: number, pageSize: number,filterstr:string): Observable<PageResult> {
  
      this.service = this.factory.getService();
      this.searchDataProperties = "properties=TotalResults%2CSupplierUri%2CSupplierID%2CRecordTitle%2CCompanyCodeOwnedByCd%2CCompanyCode%2CPrimaryCountry%2CCompany";
      this.pageArgs = this.bindPageArgs(sort, order, page, pageSize);

      this.teamSearchRecordData=filterstr;
      
      return this.service.HPServiceRecord("format=json&q=" + this.teamSearchRecordData, this.searchDataProperties + this.pageArgs).then(res => {
        var result = new PageResult();
        var array = [];
        result.Total = res.TotalResults;
        if (result.Total > 0) {
          res.Results.forEach(element => {
            var temp = new RecordResult();
            
            if (this.checkUndefined(element.Uri)) {
              temp.SupplierUri = element.Uri;
            }
            if (this.checkUndefined(element.RecordTitle)) {
              temp.SupplierName = this.bindValue(element.RecordTitle);
            }
            if (this.checkUndefined(element.Fields)) {
              if(this.bindValue(element.Fields.CompanyCodeOwnedByCd) == 'AVA'){
                if(this.bindValue(element.Fields.PrimaryCountry) == 'United States'){
                  temp.PrimaryCountry = 'Avanade USA';
                } else if(this.bindValue(element.Fields.PrimaryCountry) == 'United Kingdom'){
                  temp.PrimaryCountry = 'Avanade UK';
                } else{
                  temp.PrimaryCountry = 'Avanade '+ this.bindValue(element.Fields.PrimaryCountry);
                }
               } else{
                temp.PrimaryCountry = this.bindValue(element.Fields.PrimaryCountry);
               }
               temp.CompanyCodeOwnedByCd=this.bindValue(element.Fields.CompanyCodeOwnedByCd);
              temp.SupplierID = this.bindValue(element.Fields.SupplierID);
              temp.CompanyCode = this.bindValue(element.Fields.CompanyCode);
              temp.CompanyDescription = this.bindValue(element.Fields.Company.LocationFormattedName);
              if (element.Fields.SupplierID.Value !== "Not required" && element.Fields.SupplierID.Value !== "Awaiting SAP ID") {
                array.push(temp);
              }
            }
            
  
          });
          result.Data = array;
        }
        return result;
      });
    }
    bindPageArgs(sort: string, order: string, page: number, pageSize: number) {
      var result = "&pageSize=" + pageSize + "&start=" + ((page*pageSize)+1);
      if (typeof (sort) != "undefined") {
         if (sort == "CompanyDescription" ) {
           sort = "company";
         }
         if (sort == "SupplierName" || sort == "RecordTitle") {
          sort = "typedTitle";
        }
        result += "&sortBy=" + sort;
      }
      if (order == 'desc') {
        result += "-";
      }
      return result;
    }
    bindValue(field: any) {
      if (typeof (field) != 'undefined') {
        return field.Value;
      }
      return "";
    }
    checkUndefined(field: any) {
      if (typeof (field) != 'undefined') {
        return true;
      }
      return false;
    }
  }
  
  export class LocationMember {
    public Uri: string;
    public EID: number;
  }
  
  export class PageResult {
    public Total: number; 
    public Data: RecordResult[];
  }
  
  export class RecordResult {
    CompanyCode: string;
    SupplierID: string;
    SupplierName: string;
    CompanyCodeOwnedByCd: string;
    PrimaryCountry: string;
    SupplierUri: string;
    CompanyDescription:string;
  }
  
