<section>
  <div class="ove" >
    <label [for]="inputId" class="fl"><span *ngIf="inputData.IsRequired" class="red">*</span> {{labelName}}</label>
    <i *ngIf="!inputData.isRead && !isMulShow" class="mdui-icon material-icons close_btn fl" style="margin-left:5px;position: absolute;" (click)="reset()" tooltip="Remove Secondary Countr(ies)">cancel</i>
  </div>
  <div [clickBlank]="closeCountryPop.bind(this)" [ngStyle]="!inputData.isRead ? {} : {display: 'none'}">
    <div class="form-control nowrap" #expand_more id="buttonWidth" (click)="toggleCheckboxes($event)" [tooltip]="displaySelected" [tooltip-class]="'tooltip-class-country'"
    [ngClass]="{'divDisable':inputData.isRead ,'divAvailable':!inputData.isRead ,'disable-color':isMulShow}">
      <div id="dispSelect" class="nowrap">{{displaySelected}}</div>
      <i class="mdui-icon material-icons arr_btn">expand_more</i>
    </div>
    <div class="abso" [ngStyle]="{'width.px': offsetWidth}">

      <div id="multiPop" class="down_box" [ngStyle]="countryPop ? {} : {display: 'none'}">
        <!-- <div class="btn_box">
          <div class="selectAll select_btn" (click)="selectAll()">Select All</div>
          <div class="noSelect select_btn" (click)="selectNone()">Select None</div>
        </div>-->

        <div style="padding: 10px 10px 0 10px;">
          <div class="search_box">
            <input [id]="inputId" class="form-control search_inp" type="text" placeholder="Type and Search" [(ngModel)]="searchCode" (keyup)="partSearch($event.target.value)"/>
          </div>
          <div class="btn_block">
            <input class="multi_checkbox" [disabled]="selectAllBtnDis" type="checkbox" id="selectAll" name="selectAll" [(ngModel)]="selectAllBtn" (ngModelChange)="selectAll()"/>
            <!-- [ngClass]="{'checkbox_disable': selectAllBtnDis, 'checkbox_editable': !selectAllBtnDis}" -->
            <label for="selectAll">Select All</label>
            <div class="reset_btn" (click)="reset()">Reset</div>
          </div>
        </div>

        <div #options_tab class="optionsTab">
          <div class="checkBoxContainer" *ngFor="let searchresult of searchResults,let i = index" style="padding:0;">
            <input class="multi_checkbox" [name]="searchresult.countryName" [id]="searchresult.countryName" type="checkbox" [(checked)]="searchresult.status"
              (change)="selectOne($event,i)" />
            <label [for]="searchresult.countryName" class="resultLabel">{{searchresult.countryName}}</label>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="fixed_modal" [ngStyle]="{'position': isShowModal ? 'fixed' : 'relative' }" (click)="hidePanel($event)"></div> -->
  </div>
  <div *ngIf="isMulShow" class="noshow" [tooltip]="displaySelected" [tooltip-class]="'tooltip-class-country'"></div>
  <div *ngIf="inputData.isRead" [tooltip]="displaySelected" [tooltip-class]="'tooltip-class-country'" class="nowrap" style="width: 100%;display: inline-block;"><span class="span_is_read nowrap">{{displaySelected}}</span></div>
</section>
