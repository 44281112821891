import { NgModule } from "@angular/core";
import { ProgressBarComponent } from "./progress-bar.component";
import { ProgressBarService } from './progress-bar.service';
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
    ],
    declarations: [
        ProgressBarComponent,
    ],
    providers: [
        ProgressBarService,
    ]
})
export class ProgressBarModule {}