import { Component, OnInit, Input, Output, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { Factory } from 'app/app.factory';
@Component({
    selector: 'document-owner-picker',
    templateUrl: './document-owner-picker.component.html',
    styleUrls: ['./document-owner-picker.component.css'],
})
export class DocumentOwnerPickerComponent implements OnInit {

    @Input()
    public inputData: any;

    @Input()
    public noLabel: boolean = false;

    @Output()
    public beforeCheck: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public afterCheck: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    public excludes: string[];

    @Input()
    public isInitCheck: boolean = true;

    @Input()
    public MarkAsSecDocOwner : boolean = false;

    public selectionValue: any;
    public service: any;
    public svalueIsNull: boolean;
    public isShowpeopleMask: boolean;
    public ToolTip: any;
    public newValue: boolean;
    public InfoShow: boolean;
    public information: any;
    public setTimeoutId: any;
    public isTypeEnd: boolean;
    public isShowpeopleList: boolean;

    private isInputFocus: boolean = false;
    private isChecked: boolean = false;

    constructor (
        private factory: Factory,
        public changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        if (this.inputData.information != "" && this.inputData.information != undefined) {
            this.InfoShow = true;
            this.information = this.inputData.information;
        } else {
            this.InfoShow = false;
            this.information = "";
        }
        if (this.inputData.toolTip == true && this.inputData.selectTip == true) {
            this.newValue = true;
            this.ToolTip = this.inputData.oldValue;
        } else {
            this.newValue = false;
            this.ToolTip = '';
        }
        this.service = this.factory.getService();
        if (this.isInitCheck) {
            this.ClearNoValidData();
        }
        this.selectionValue = [];
        this.svalueIsNull = false;
        this.isShowpeopleMask = false;
    }

    valiChange() {
        $("input").css("background-color", "#D6D6FF");
    }

    onChanged(e: any) {
        this.isShowpeopleList = false;
        let valedData = e.replace(/[\u4E00-\u9FA5]/g, '').trim();
        valedData = valedData.replace(/\s+/g, '');
        this.inputData.value = valedData;
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
        this.svalueIsNull = false;
        this.isShowpeopleMask = true;
        this.selectionValue = [];
        if (this.inputData.value.length > 2) {
            var data = { name: this.inputData.value , MarkAsSecDocOwner : this.MarkAsSecDocOwner};
            this.service.getPeoplePickerForMMPR(data).then(res => {
                if (this.excludes) {
                    if (res) {
                        let excludes = this.excludes.map(exclude => exclude.toLowerCase());
                        res = res.filter(item => excludes.indexOf(String(item.LocationFormattedName.Value).toLowerCase()) === -1);
                    }
                }
                if (!this.isTypeEnd) {
                    this.selectionValue = res;
                    if (this.selectionValue.length !== 0) {
                        this.svalueIsNull = false;
                    } else {
                        this.svalueIsNull = true;
                    }
                    this.isShowpeopleList = true;
                }
            });
        }
    }
    SelectedPeople(data: any) {
        this.inputData.value = data;
        this.isShowpeopleMask = false;
        this.inputData.isValidation = true;
        this.isShowpeopleList = false;
        this.afterCheck.emit();
    }
    inputFocus() {
        this.isInputFocus = true;
        this.isChecked = false;
    }
    inputBlur() {
        this.isInputFocus = false;
    }
    ClearNoValidData() {
        if (this.isInputFocus || this.isChecked) {
            return;
        }
        this.isChecked = true;
        this.isTypeEnd = true;
        this.isShowpeopleMask = false;
        this.isShowpeopleList = false;
        if (this.inputData.value !== "") {
            if (this.inputData.value.trim() === '') {
                this.inputData.value = "";
                return;
            }
            var data = { name: this.inputData.value , MarkAsSecDocOwner : this.MarkAsSecDocOwner };

            this.beforeCheck.emit();
            this.service.getPeoplePickerForMMPR(data).then(res => {
                if (this.excludes) {
                    if (res) {
                        let excludes = this.excludes.map(exclude => exclude.toLowerCase());
                        res = res.filter(item => excludes.indexOf(String(item.LocationFormattedName.Value).toLowerCase()) === -1);
                    }
                }
                if (res && res.filter(item => String(item.LocationFormattedName.Value).toLowerCase() === String(this.inputData.value).toLowerCase()).length > 0) {
                    this.inputData.isValidation = true;
                } else {
                    this.inputData.value = "";
                    if (this.inputData.IsRequired) {
                        this.inputData.isValidation = false;
                    } else {
                        this.inputData.isValidation = true;
                    }
                }
                this.afterCheck.emit();
            });
        }
    }

    itemActive(item: string) {
        this.isInputFocus = true;
        this.beforeCheck.emit();
        this.isTypeEnd = false;
        this.isShowpeopleList = false;
        if (this.setTimeoutId != null) {
            clearTimeout(this.setTimeoutId);
        }
        this.setTimeoutId = setTimeout(() => {
            this.onChanged(item);
            clearTimeout(this.setTimeoutId);
            this.setTimeoutId = null;
        }, 1000);

    }
}
