<section>
    <div>
        <span class="radio label" style="position: relative;" >
             {{inputData.labelName}}
        </span>
    </div>
    <div class="radio-group">
        <div class="radio" style="margin-right: 45px;">
            <label class="label">
                <input type="radio" [name]="inputName === undefined ? inputData.searchClauseName : inputName" [disabled]="inputData.IsDisabled"
                    [(ngModel)]="inputData.value" value="Yes" />
                <span></span>Yes
            </label>
        </div>
    </div>
</section>
