import { ViewRecordStaticService } from "app/common/services/staticService/ViewRecordStaticService";
export class CheckFormData {
    private sourceFormData: { [key: string]: object } = {};
    private hasChanged: boolean = false;
    private nonFieldChange: boolean = false;
    constructor() { }

    public checkChanged(): boolean {
        return this.hasChanged;
    }
    public checknonFieldChange(): boolean {
        return this.nonFieldChange;
    }

    public doCheck(formData: {
        data: { formData: object;[key: string]: object };
        isEdit: boolean;
    }): void {
        // for check unsave data
        if (formData.isEdit) {
            this.hasChanged = false;
            var fielddata = false;
            var filedata = false;
            var suppdata = false;
            this.nonFieldChange = false;
            var keyFieldChange = false;
            for (let key in formData.data) {
                let formDataItem = formData.data[key];
                if (formDataItem && this.sourceFormData[key]) {
                    if (key === 'formData') {
                        var allchange = ViewRecordStaticService.isEqualsObjValue(this.sourceFormData[key], formDataItem);             
                        if (!allchange.allfieldchange) {
                            fielddata = true;
                        }
                        if(!allchange.keyFieldChange){
                            keyFieldChange = true;
                        }
                    } else if (!ViewRecordStaticService.isEqualsObj(this.sourceFormData[key], formDataItem)) {
                        if (key === 'fileData') {
                            filedata = true;
                        } else {
                            suppdata = true;
                        }
                    }
                }
            }
            if (filedata || suppdata || fielddata) {
                this.hasChanged = true;
            }
            if (!keyFieldChange && !suppdata) {
               var renamechange = filedata ? ViewRecordStaticService.isOnlyRename(formData.data['fileData']):filedata;
               this.nonFieldChange = (renamechange ||(!keyFieldChange && !filedata && !suppdata)) && this.hasChanged;
            }
            // keep raw data
            if (!this.hasChanged) {
                for (let key in formData.data) {
                    let formDataItem = formData.data[key];
                    this.sourceFormData[key] = formDataItem ? CheckFormData.dataClone(formDataItem) : [];
                }

            }
        } else {
            this.hasChanged = false;
            this.sourceFormData = {};
        }
    }

    private static dataClone(data: any): any {
        if (!data) {
            return data;
        }
        if (data instanceof Array) {
            let dataArray = [];
            for (let i = 0; i < data.length; i++) {
                dataArray.push(CheckFormData.dataClone(data[i]));
            }
            return dataArray;
        } else if (data instanceof Date) {
            return data;
        } else if (typeof (data) === 'object') {
            let dataObj = {};
            for (let key in data) {
                dataObj[key] = CheckFormData.dataClone(data[key]);
            }
            return dataObj;
        } else {
            return data;
        }
    }

}
