<div *ngIf="isShowpredecessorMask" class="mask" (click)="ClearNoValidData()"></div>
<section>
    <div>
        <label [for]="inputData.filedId"><span *ngIf="inputData.IsRequired" class="red">*</span> {{inputData.labelName}}

            <i *ngIf="InfoShow && !inputData.isRead"
              class="mdui-icon material-icons nosapInfo"
              [tooltip]="information" >info</i>
        </label>
    </div>
    <div [ngStyle]="!inputData.isRead ? {} : {display: 'none'}">
        <input [id]="inputData.filedId" #expand_more type="text" [(ngModel)]="inputData.value" (ngModelChange)="itemActive(inputData.value)" class="form-control zUp"
            [disabled]="inputData.IsDisabled" [ngClass]="{'error':!inputData.isValidation,'redText':inputData.isError, 'nowrap':inputData.IsDisabled}" placeholder="Field must be completed"
            AUTOCOMPLETE="off"
            />
        <i *ngIf="inputData.isError" class="mdui-icon material-icons tippos" [tooltip]="errorInfo">info</i>
        <div class="predecessorBoxContainer" *ngIf="inputData.value.length > 2 && switch" [ngStyle]="{'width.px': expand_more.offsetWidth}">
            <div *ngIf="svalueIsNull && supplierExist" class="left_pad">Predecessor Agreement ID does not match current record</div>
            <div *ngIf="svalueIsNull && !supplierExist" class="left_pad">Predecessor Agreement ID does not exist</div>
            <div *ngIf="!svalueIsNull && supplierExist">
            <div  *ngFor="let svalue of selectionValue" class="predecessorline" (click)="Selectedpredecessor(svalue.originID)">
                <span [tooltip] = "svalue.title">{{svalue.value}}</span>
            </div>
            </div>
        </div>
    </div>
    <div *ngIf="inputData.isRead"><span class="span_is_read nowrap">{{inputData.value}}</span></div>
</section>
