import { Component, ViewChild } from '@angular/core';
import { Factory } from '../../../../../app.factory';
import { MessageService } from 'app/common/services/message/messageService';
import { MultiSelectButtonComponent } from './../../multi-select-button/multi-select-button.component';
@Component({
    selector: 'CategoryLocation',
    templateUrl: './CategoryLocation.component.html',
    styleUrls: ['./CategoryLocation.component.css'],
    inputs: ["inputsData"],
    providers: [Factory]
})
export class CategoryLocationComponent {
    public isMore: boolean = true;
    public inputsData: any;
    public recordFormData: any;
    public recordDorpdownData: any;
    public currentRecordDorpdownData: any;
    public ParentDocumentUri: any;
    public SupplierUri: any;
    public isSearchSupplierShow: boolean;
    public service: any;
    public isMulShow: boolean;

    public isSupporingDoc: boolean;
    public isParentSearchShow: boolean;
    public parentDorpdownValues: any;
    public isParentPop: boolean;

    public isBtnDisable: boolean;
    public isCountryPop: boolean;
    public blockDis: boolean = true;
    public currentPrimary: string;
    public buttonTitle: string;

    // secondary key category
    @ViewChild('secondaryKeyCategorySelect')
    public secondaryKeyCategorySelect: MultiSelectButtonComponent;
    public secondaryKeyCategoryOptions: {value: string; label: string;}[];

    // public secondIsDisable: boolean;
    constructor(private factory:Factory,private message:MessageService) { }
    ngOnInit() {
        this.service = this.factory.getService();
        this.recordFormData = this.inputsData.formFields;
        this.recordDorpdownData = this.inputsData.dropDownValues;
        var oldRecordDorpdownData = new Object();
        oldRecordDorpdownData = this.inputsData.dropDownValues.getKeyCategorys;
        this.currentRecordDorpdownData = oldRecordDorpdownData;
        this.currentPrimary = this.recordFormData.PrimaryCountry.value;
        this.recordDorpdownData.getSecondCountry = [];
        if(this.recordFormData.PrimaryCountry.isRead && this.recordFormData.SecondCountry.isRead || this.recordFormData.PrimaryCountry.value == ''){
            this.isBtnDisable = true;
        }else{
            this.isBtnDisable = false;
        }
        if(this.recordFormData.PrimaryCountry.value !== '' && this.recordFormData.SecondCountry.value !== '' ){
            if(this.recordDorpdownData.getCountrys !== ''){
                this.isCountryPop = true;
                this.composeSecList();
            }
        }else{
            if(this.recordFormData.SecondCountry.isRead){
                this.blockDis = true;
                this.isCountryPop = true;
            }else{
                this.blockDis = true;
                this.isCountryPop = false;
            }    
        }
        if(this.recordFormData.PrimaryCountry.value === '') {
            this.recordDorpdownData.getKeyCategorys = 
            this.recordDorpdownData.getKeyCategorys.filter(item => item.Key !== "Creative Agencies");
        } else {
            this.showKeyCategory();
        }
        this.message.getPrimaryChange().subscribe(result => {
            if(result.key === "DisableSecondCountry"){
                this.isBtnDisable = result.isBtnDisable;
                this.isMulShow = result.isBtnDisable;
            }
        })
        this.message.getPrimaryChange().subscribe(result => {
            if(result.hasOwnProperty('hasPrimary')){
                if(result.hasPrimary){
                    this.isBtnDisable = false; 
                }else{
                    this.isBtnDisable = true;
                    this.isCountryPop = false;
                }
                this.recordDorpdownData.getSecondCountry = [];
                this.composeSecList();
                var second = {
                    secondList :this.recordDorpdownData.getSecondCountry,
                }
                this.message.sendsecondCountryMsg(second);
            }
        })

        this.message.getBlockDis().subscribe(result => {
            if(result.hasOwnProperty('isBlockDis') && result.isBlockDis){
                if(this.recordFormData.PrimaryCountry.value == ''){
                    this.isCountryPop = false;
                    this.isBtnDisable = true;
                }else{
                    if(this.recordFormData.SecondCountry.value == ''){
                        this.isCountryPop = false;
                        this.isBtnDisable = false;
                    }
                }
            }
        })

        this.message.getSecondaryCountryforCancel().subscribe(result => {
            if(result.hasOwnProperty('getSecondaryCountryforCancel') ){
                if(this.recordFormData.SecondCountry.value == ''){
                    this.isCountryPop=result.getSecondaryCountryforCancel;
                }
            }
        })

        this.secondaryKeyCategoryOptions = this.recordDorpdownData.getKeyCategorys.map(keyCategory => {
            return { value: keyCategory.Value, label: keyCategory.Value };
        });

        if (this.recordFormData.KeyCategory.value != "") {
            var getKey = {
                "isCategory": true,
                "keyword": this.recordFormData.KeyCategory.value,
                "Catword": this.recordFormData.CategoryVal.value
            }
            this.service.getCategoryMap(getKey).then(res => {
                if (res) {
                    this.recordDorpdownData.CategoryOption = res;
                    this.recordFormData.CategoryVal.IsDisabled=false;
                    this.recordFormData.SubCategory.IsDisabled= true;
                }
            }); 
        } 
        if (this.recordFormData.CategoryVal.value !== "") {
            var getKey = {
                "isCategory": false,
                "keyword": this.recordFormData.KeyCategory.value,
                "Catword": this.recordFormData.CategoryVal.value
            }
            this.service.getCategoryMap(getKey).then(res => {
                if (res) {
                    this.recordDorpdownData.SubcategoryOption = res;
                    this.recordFormData.SubCategory.IsDisabled= false;
                }
            });
    //         this.service.HPServiceRecord('format=json&q=number:' + this.recordFormData.AgreementID.value, 'properties=RecordAllHolds')
    //   .then(res => {
    //     if (res.Results[0].RecordAllHolds) {
    //       var legalHold = res.Results[0].RecordAllHolds.Value.length > 0 ? true : false; // legalHold.
    //     if(!legalHold){
    //         if(this.recordFormData.PrimaryCountry.value!=''){
    //             var param = {
    //                 "Keycategory":this.recordFormData.KeyCategory.value,
    //                 "Category": this.recordFormData.CategoryVal.value,
    //                 "Country": this.recordFormData.PrimaryCountry.value,
    //                 "GU": this.recordFormData.PrimaryGU.value,
    //             }
    //             this.service.GetDocOwnerByCC(param).then(res1 => {
    //                     this.recordFormData.DocumentOwner.value = res1;
    //             });
    //         }else{
    //             this.recordFormData.DocumentOwner.value ="";
    //         }
    //     }
    //     }
    //   });
            
        } 

    }

    mouseenter(){
        if(!this.isBtnDisable && !this.isCountryPop){
            this.buttonTitle = 'You may select multiple secondary countries';
        }else{
            this.buttonTitle = '';
        }
    }

    isCategory() {
        if(this.isMore == true){
            this.isMore = false
        }else{
            this.isMore = true
        }
        $(".isCategory").slideToggle();
    }
    showKeyCategory() {
        var oldRecordDorpdownData = new Object();
        oldRecordDorpdownData = this.currentRecordDorpdownData;
        sessionStorage.setItem("CountrySelect", this.recordFormData.PrimaryCountry.value);
        if(this.recordFormData.PrimaryCountry.value === "Brazil" 
            || this.recordFormData.PrimaryCountry.value ==="Costa Rica"
            || this.recordFormData.PrimaryCountry.value ==="Argentina"
            || this.recordFormData.PrimaryCountry.value ==="Chile"
            || this.recordFormData.PrimaryCountry.value ==="Peru"
            || this.recordFormData.PrimaryCountry.value ==="Colombia"
            || this.recordFormData.PrimaryCountry.value ==="Venezuela"
            || this.recordFormData.PrimaryCountry.value ==="Mexico"
            || this.recordFormData.PrimaryCountry.value === "Avanade Brazil") {
                this.recordDorpdownData.getKeyCategorys = oldRecordDorpdownData;
            } else {
                this.recordDorpdownData.getKeyCategorys = 
                this.recordDorpdownData.getKeyCategorys.filter(item=>item.Key!== "Creative Agencies");
                if(this.recordFormData.KeyCategory.value == "Creative Agencies") {
                    this.recordFormData.KeyCategory.value = "";
                    this.recordFormData.CategoryVal.value = "";
                    this.recordFormData.SubCategory.value = "";
                }
            }
    }

    setCountry() {
        this.recordDorpdownData.getSecondCountry=[];
        if (this.recordFormData.PrimaryCountry.value != "") {
            this.showKeyCategory();
            var getCountry = {
                "keyword": this.recordFormData.PrimaryCountry.value
            }
            this.service.getGUAndRegionByCountry(getCountry).then(res => {
                if (res) {
                    this.recordFormData.PrimaryGU.value = res.GU;
                    this.recordFormData.PrimaryRegion.value = res.Region;
                    this.recordFormData.PrimaryGU.isValidation = true;
                    this.recordFormData.PrimaryRegion.isValidation = true;
                }
            });
            this.isBtnDisable = false;
            this.composeSecList();
            if(this.recordFormData.CategoryVal.value!=''){
                var temp = {
                    "Category": this.recordFormData.CategoryVal.value,
                    "Country": this.recordFormData.PrimaryCountry.value,
                    "Keycategory":this.recordFormData.KeyCategory.value,
                    "GU": this.recordFormData.PrimaryGU.value,
                };
                this.service.GetDocOwnerByCC(temp).then(res => {
                        this.recordFormData.DocumentOwner.value = res;
                });
            }else{
                this.recordFormData.DocumentOwner.value ='';
            }
        }else {
            this.recordFormData.PrimaryGU.value = "";
            this.recordFormData.PrimaryRegion.value = "";
            this.isBtnDisable = true;
            this.isCountryPop = false;
            this.recordFormData.DocumentOwner.value = "";
        }
        var second = {
            secondList :this.recordDorpdownData.getSecondCountry,
        }
        this.message.sendsecondCountryMsg(second);
    }

    setCategory() {
        if (this.recordFormData.KeyCategory.value != "") {
            var getKey = {
                "isCategory": true,
                "keyword": this.recordFormData.KeyCategory.value,
                "Catword": this.recordFormData.CategoryVal.value,
            }
            this.service.getCategoryMap(getKey).then(res => {
                if (res) {
                    this.recordDorpdownData.CategoryOption = res;
                    this.recordFormData.CategoryVal.IsDisabled=false;
                    this.recordFormData.SubCategory.IsDisabled= true;
                }
            }); 
        } else {
            this.recordDorpdownData.CategoryOption = [];
            this.recordDorpdownData.SubcategoryOption = [];
            this.recordFormData.CategoryVal.IsDisabled = true;
            this.recordFormData.SubCategory.IsDisabled = true; 
        }
        this.recordFormData.DocumentOwner.value = "";
        this.recordFormData.CategoryVal.value = "";
        this.recordFormData.SubCategory.value = "";
        this.recordFormData.License.isShow = false;
        this.recordFormData.LicenseType.isShow = false;
        this.recordFormData.License.value = "";
        this.recordFormData.LicenseType.value = "";
        this.recordFormData.CategoryVal.isValidation = true;
        this.recordFormData.SubCategory.isValidation = true;

        this.message.sendCategoryLocationSetCategory();
        this.secondaryKeyCategorySelect.reset();
    }
    setSubCategory() {
        if (this.recordFormData.CategoryVal.value !== "") {
            var getKey = {
                "isCategory": false,
                "keyword": this.recordFormData.KeyCategory.value,
                "Catword": this.recordFormData.CategoryVal.value,
            }
            this.service.getCategoryMap(getKey).then(res => {
                if (res) {
                    this.recordDorpdownData.SubcategoryOption = res;
                    this.recordFormData.SubCategory.IsDisabled= false;
                }
            });

            if(this.recordFormData.PrimaryCountry.value!=''){
                var param = {
                    "Keycategory":this.recordFormData.KeyCategory.value,
                    "Category": this.recordFormData.CategoryVal.value,
                    "Country": this.recordFormData.PrimaryCountry.value,
                    "GU": this.recordFormData.PrimaryGU.value,
                }
                this.service.GetDocOwnerByCC(param).then(res => {
                        this.recordFormData.DocumentOwner.value = res;
                });
            }else{
                this.recordFormData.DocumentOwner.value ="";
            }
        } else {
            this.recordDorpdownData.SubcategoryOption = [];
            this.recordFormData.SubCategory.IsDisabled= true;
            this.recordFormData.DocumentOwner.value = "";
        }
        this.recordFormData.SubCategory.value = "";
        this.recordFormData.License.value = "";
        this.recordFormData.LicenseType.value = "";

        if(this.recordFormData.KeyCategory.value == "Technology" 
            && this.recordFormData.CategoryVal.value == "IT Software" ) {
            this.recordFormData.License.isShow = true;
            this.recordFormData.LicenseType.isShow = true;
        } else {
            this.recordFormData.License.isShow = false;
            this.recordFormData.LicenseType.isShow = false;
        }
        this.recordFormData.SubCategory.isValidation = true;

        this.message.sendCategoryLocationSetSubcategory();
    }
    getSecondCountry(event:any){
        this.recordFormData.SecondCountry.value = "";
        event.forEach(element => {
            if(this.recordFormData.SecondCountry.value !== ""){
                this.recordFormData.SecondCountry.value = this.recordFormData.SecondCountry.value +"; "+ element.countryName;
            } else{
                this.recordFormData.SecondCountry.value = this.recordFormData.SecondCountry.value + element.countryName;
            }
        });
    }

    addCountryBtn(){
        if(!this.isBtnDisable){
            this.isCountryPop = true;
            this.recordDorpdownData.getSecondCountry = [];
            this.composeSecList();
            var second = {
                secondList :this.recordDorpdownData.getSecondCountry,
            }
            this.message.sendsecondCountryMsg(second);
        }
    }

    composeSecList(){
        this.recordDorpdownData.getCountrys.forEach(element => {
            if(element.Value !== this.recordFormData.PrimaryCountry.value){
                this.recordDorpdownData.getSecondCountry.push({Key: element.Value, Value: element.Value});
            }
        });
    }

    public setSecondaryKeyCategory(value: string) {
        this.secondaryKeyCategorySelect.selectionValue = value.split(',');
    }
}
