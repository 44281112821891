import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fileSize' })

export class FileSizePipe implements PipeTransform {
    transform(value: number): string{
        let transSize = '';
        let transKb = Number((value/1024).toFixed(2));
        if(transKb >= 1000) {
            transSize = Number((transKb/1024).toFixed(2)) + 'M';
        }else {
            transSize = transKb.toString() + 'K';
        }
        return transSize;
    }
}