import { Component, OnInit, AfterViewChecked, DoCheck, ViewChild, ChangeDetectorRef, EventEmitter, ElementRef, Renderer2, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-single-select',
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.css'],
  inputs: [
    'inputData',
    'selectionValue',
    'isCustLabel',
    'inputId',
  ],
  outputs: [
    'inputDataChange: change'
  ],
})
export class SingleSelectComponent implements OnInit, AfterViewChecked, DoCheck {
  public ToolTip: any;
  public newValue: boolean;
  public inputData: any;
  public selectionValue: any;
  public inputDataChange: EventEmitter<any> = new EventEmitter<any>();
  public innerClick = false;
  public first = true;
  public show = false;
  public searchCode: string = '';
  @ViewChild("expand_more")
  public expandMore: ElementRef;
  @ViewChild("dropdown_menu_single_select")
  public dropdownMenuSingleSelect: ElementRef;
  public offsetWidth: number = 0;
  public isCustLabel: boolean = false;
  public inputId: string;
  public InfoShow: boolean;
  public information: any;
  public infoArrow: any='top';
  constructor(
    private renderer: Renderer2, 
    private el: ElementRef,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    // for (let propName in changes) {
    //   let chng = changes[propName];
    //   if(propName == 'inputData'){
    //     console.log('inputData        ', chng);
    //   }
    // }
  }
  ngAfterViewChecked() {  
    if(!this.inputData.isRead && !this.inputData.IsDisabled && this.show) {
      this.offsetWidth = (<HTMLElement>this.expandMore.nativeElement).offsetWidth;
      this.changeDetectorRef.detectChanges();
    }
  }

  ngDoCheck () {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit() {

    if(this.inputData.information!="" && this.inputData.information!=undefined){
      this.InfoShow=true;
      this.information=this.inputData.information;
      if(this.inputData.infoArrow!="" && this.inputData.infoArrow!=undefined){
        this.infoArrow=this.inputData.infoArrow;
      }
    }else{
      this.InfoShow=false;
      this.information="";
    }
    if(this.inputData.toolTip == true && this.inputData.selectTip == true){
      this.newValue = true;
      this.ToolTip = this.inputData.oldValue;
    }else{
      this.ToolTip = "";
    }

    if (!this.inputId) {
      this.inputId = this.inputData.filedId + '_' + new Date().getTime();
    }
    if(this.inputData.toolTip == true && this.inputData.selectTip == true){
      this.newValue = true;
      this.ToolTip = this.inputData.oldValue;
    }else{
      this.ToolTip = "";
    }
  }
  getSelectedValue() {
    if(!this.inputData.value) {
      return "";
    }
    for(const i in this.selectionValue) {
      if(this.selectionValue[i].Key == this.inputData.value) {
        return this.selectionValue[i].Value;
      }else{
        if(this.inputData.labelName == "Payment Terms"){
          return this.inputData.value
        }
      }
    }
  }
  onClicked(key, inner?){
      if(this.first) {
        this.first = false;
        return;
      } else if(inner && inner == 'inner') {
        this.innerClick = true;
      } else if(this.innerClick == true){
        this.innerClick = false;
        return;
      }
      this.inputData.value = key
      if(this.inputData.value !== ""){
        this.inputData.isValidation = true;
      }
      if(this.inputData.filedId == 12) {
        this.inputData.oldValue = sessionStorage.getItem("CountrySelect");
        
      }
      this.inputDataChange.emit(this.inputData);  
  }
  onParentClicked(){
    this.show = !this.show;
    this.clearSearchCode();
  }
  closeDiv() {
    this.show = false;
    this.clearSearchCode();
  }
  clearSearchCode() {
    this.searchCode = '';
  }
  getString(value) {
    return String(value);
  }
  onKeyup() {
    $(<HTMLElement>this.dropdownMenuSingleSelect.nativeElement).scrollTop(0);
  }
  getDropdownDivStyles (isRead) {
    return {
      ...this.inputData.dropdownDivStyles,
      display: isRead ? 'none' : undefined,
    };
  }
}
