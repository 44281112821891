<section>
    <div class="rele">
        <div class="up_title another_up" (click)="isDocuments()">
            <i *ngIf="isMore" class="mdui-icon material-icons expend_icon">expand_more</i>
            <i *ngIf="!isMore" class="mdui-icon material-icons expend_icon">chevron_right</i>
            <span [ngClass]="{edit_doc:isEditDocument&&isONValueShow&&fileInputer.isRead}">Documents</span>
        </div>
        <div dragDropPreventDefault class="isSlideDocuments">
            <div class="doc_box mandatory_box" [ngClass]="{hasborder: successFiles.length > 0}"
                *ngIf="!fileInputer.isRead && !fileInputer.supportingisAuto">
                <div *ngIf="isLoad" class="opt_box"></div>
                <span [ngClass]="{noShowStar: templatePage&&templateDocPage||isEditProcess}"
                    class="upload mandatory">*</span>
                <div class="upload submit_btn fl add_btn z_index"
                    [ngClass]="{invalid: !fileInputer.isValidation,grey_bg:templatePage&&templateDocPage,disableTemplateBtn:templatePage&&templateDocPage}">
                    <i *ngIf="!isLoad" class="mdui-icon material-icons sub_btn">add</i>
                    <label for="file" *ngIf="!isLoad" class="btn_txt">Upload Document</label>
                    <i *ngIf="isLoad" class="mdui-icon material-icons sub_btn">autorenew</i>
                    <span *ngIf="isLoad" class="btn_txt load_txt">Loading...</span>
                    <input unselectable="on" class="hidden_input" name="file" type="file" id="file" #file multiple
                        (change)="uploadFile($event)" />
                </div>
                <nz-upload class="nz-upload-init" [hidden]="isShowTable" nzType="drag" [nzMultiple]="true"
                    [nzShowUploadList]="false" [nzDisabled]="templatePage&&templateDocPage"
                    [nzBeforeUpload]="beforeUpload" nzAction="">
                    <div class="nz-upload-init-div" [ngClass]="{disableTemplateBtn: templatePage&&templateDocPage}">
                        <p class="ant-upload-text">Drag & Drop Document Here</p>
                    </div>
                </nz-upload>
                <!-- <p class="doc_p fl">OR Drag & Drop Documents Here</p> -->
            </div>

            <app-warning-tips style="display: flex;" class="isDocuments"></app-warning-tips>
            <app-error-message-popup></app-error-message-popup>
            <label #nz_upload_p_p *ngIf="!fileInputer.isRead && !fileInputer.supportingisAuto"
                (dragleave)="dragleave($event)" (drop)="drop($event)">
                <nz-upload #nz_upload_p [ngStyle]="{'z-index':zIndex}" class="nz-upload" nzType="drag"
                    [nzMultiple]="true" [nzShowUploadList]="false" [nzBeforeUpload]="beforeUpload"
                    (dragleave)="dragleave($event)" (drop)="drop($event)" nzAction="">
                    <div #nz_upload class="nz-upload-div" [ngStyle]="{'height.px':nzUploadDivHeight}"
                        (dragleave)="dragleave($event)" (drop)="drop($event)">
                        Drag & Drop Document Here
                    </div>
                </nz-upload>
            </label>

            <!-- files table -->
            <div id="documentsList" class="isDocuments" *ngIf="isShowTable && isEditProcessReturn()">
                <!-- <div class="disable_modal" *ngIf="fileInputer.isRead" [ngStyle]="{'height': successFiles.length > 0 ? (successFiles.length + 1) * 43  + 'px' : 0 + 'px'}"></div> -->
                <!-- <div id="editViewMask" #editViewMask class="disable_modal" *ngIf="fileInputer.isRead" ></div> -->
                <div #tablediv id="tableContiner">
                    <mat-table (dragover)="dragoverdiv($event)" class="table" [dataSource]="successFiles">
                        <ng-container matColumnDef="selectedSts">
                            <mat-header-cell class="col_checkbox TableHeader" *matHeaderCellDef>
                                <mat-checkbox class="cus-checkbox" disableRipple="true" [(ngModel)]="allSucFilesSts"
                                    (ngModelChange)="changeAll()"></mat-checkbox>
                            </mat-header-cell>
                            <mat-cell class="col_checkbox" *matCellDef="let row">
                                <mat-checkbox [ngClass]="{'no-border': row.selectedSts}" class="cus-checkbox"
                                    disableRipple="true" [(ngModel)]="row.selectedSts" (ngModelChange)="changeBkg()">
                                </mat-checkbox>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="documentName">
                            <mat-header-cell *matHeaderCellDef class="TableHeader col_doc_name" tooltip="Document Name">
                                Document Name
                            </mat-header-cell>
                            <mat-cell class="col_doc_name" *matCellDef="let row"
                                [ngClass]="{deleted: isDeletedFile(row)}"><span [ngClass]="{added: isAddedFile(row)}"
                                    [tooltip]="row.documentName">{{row.documentName}}</span></mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="uploadedBy">
                            <mat-header-cell *matHeaderCellDef class="TableHeader col_upd_by" tooltip="Uploaded By">
                                Uploaded By
                            </mat-header-cell>
                            <mat-cell class="col_upd_by" *matCellDef="let row"
                                [ngClass]="{deleted: isDeletedFile(row)}"> <span [ngClass]="{added: isAddedFile(row)}"
                                    [tooltip]="row.uploadedBy">{{row.uploadedBy}}</span> </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="lastMod">
                            <mat-header-cell *matHeaderCellDef class="TableHeader col_last_mod"
                                tooltip="Last Modified Date">Last Modified Date
                            </mat-header-cell>
                            <mat-cell class="col_last_mod" *matCellDef="let row"
                                [ngClass]="{deleted: isDeletedFile(row)}"> <span [ngClass]="{added: isAddedFile(row)}"
                                    [tooltip]="row.lastMod | date:'dd-MMM-yyyy'">{{row.lastMod | date:'dd-MMM-yyyy'}}</span>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="fileSize">
                            <mat-header-cell *matHeaderCellDef class="TableHeader col_file_size" tooltip="Size">Size
                            </mat-header-cell>
                            <mat-cell class="col_file_size" *matCellDef="let row"
                                [ngClass]="{deleted: isDeletedFile(row)}"> <span [ngClass]="{added: isAddedFile(row)}"
                                    [tooltip]="row.size | fileSize">{{row.size | fileSize}}</span> </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="optsTool">
                            <mat-header-cell *matHeaderCellDef class="TableHeader col_3dots"></mat-header-cell>
                            <mat-cell class="col_3dots" *matCellDef="let successFile;let i = index;"><div id="domTd" tooltip="More Options" class="col_3dots_text"style="width: 100%; height: 100%;"(click)="clickOpts($event, successFile.optsSts, i)"><i class="mdui-icon material-icons">more_horiz</i></div><div id="optsTool" [ngStyle]="{'display': successFile.optsSts ? 'block' : 'none'}"><ul [ngStyle]="successFile.optsSts ? {} : {'width': 0}" class="opt_dialog"><li *ngIf="successFile.Uri" (click)="downloadFile(successFile)"><i class="icon-save_alt"></i><span>Download</span></li><li *ngIf="!fileInputer.isRead && !fileInputer.supportingisAuto && (fileInputer.isEdit === undefined? false : fileInputer.isEdit)"(click)="OpenrenameBox(successFile,i)"><i class="mdui-icon material-icons">format_italic</i><span>Rename</span></li><li *ngIf="!fileInputer.isRead && !fileInputer.supportingisAuto"(click)="deleteFile(successFile.documentName,i)"><i class="mdui-icon material-icons">delete</i><span>Delete</span></li></ul></div></mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="docCols"></mat-header-row>
                        <mat-row *matRowDef="let row; let i = index; columns: docCols;"
                            [ngStyle]="isShowFile(row) ? {} : {display: 'none'}" [ngClass]="{bkgblue: row.selectedSts}"
                            [id]="'tr'+i" class="trHover"></mat-row>
                    </mat-table>
                </div>
            </div>
        </div>
    </div>
    <div class="fixed_modal" [ngStyle]="{'position': isShowModal ? 'fixed' : 'relative' }" (click)="hidePanel($event)">
    </div>
</section>
<div *ngIf="isShowRenamePopup" class="mask"></div>
<!-- Masks -->
<div *ngIf="isShowSpinner" class="masksp">
    <mat-spinner class="loading-spinner"></mat-spinner>
</div>
<!-- Rename Popup -->
<div class="RenamePopupFrame" *ngIf="isShowRenamePopup">
    <div class="titlBox">
        <p class="team_title fl">Document Rename</p>
        <i (click)="closeRenameBox()" class="mdui-icon material-icons close_icon fr">clear</i>
    </div>
    <div class="rr_content">
        <div class="tab_line">
            <div class="titl_box fl">
                <label for="oldFileName" class="fl"><span class="asterisk">*</span>Old Name</label>
            </div>
            <div class="field_box fr">
                <input type="text" [(ngModel)]="oldFileName" class="form-control"
                    disabled="true" />
            </div>
        </div>
        <div class="tab_line font_Message">
            <span>{{typeTipMsg}}</span>
        </div>
        <div class="tab_line pd_b7">
            <div class="titl_box fl">
                <label for="newFileName" class="fl"><span class="asterisk">*</span>New Name</label>
            </div>
            <div class="field_box fr">
                <input type="text" [(ngModel)]="newFileName" (ngModelChange)="checkFileNameValid()"
                 class="form-control" />
            </div>
        </div>
        <div class="tips" *ngIf="isShowNameError">
            <span>{{nameErrMsg}}</span>
        </div>

        <div class="tab_line releb">
            <button type="submit" class="btn_share btn-primary fr" [disabled]="isRenameDisable"
                (click)="TempSaveName()">
                <i class="mdui-icon material-icons rename_sub_btn">check</i>
                <span class="btn_txt">Save</span>
            </button>
            <button type="submit" class="btn_share btn-secondary fr" (click)="closeRenameBox()">
                <i class="mdui-icon material-icons rename_sub_btn">clear</i>
                <span class="btn_txt">Cancel</span>
            </button>
        </div>
    </div>
</div>
<!-- Rename Popup -->