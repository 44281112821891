import { Component, Input, Output, EventEmitter, OnInit, } from "@angular/core";
import { DeletionReasonsComponent } from "./deletion-reasons/deletion-reasons.component";
import { MatDialog } from "@angular/material/dialog";
import { DeletionService } from "./deletion.service";
import { AuthService } from "app/common/services/UserAuth/AuthService";
import { CommenString, RecordType, AgreementStaus } from "app/common/enum/type.enum";
import { Router, ActivatedRoute } from "@angular/router";
import { DeletionConfirmComponent } from "./deletion-confirm/deletion-confirm.component";
import { PageData } from "app/common/services/staticService/page-navigation.service";
import { RecordStatus } from 'app/common/enum/type.enum';
import { Factory } from 'app/app.factory';

@Component({
  selector: 'app-deletion',
  templateUrl: './deletion.component.html',
  styleUrls: ['./deletion.component.css'],
})
export class DeletionComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private dialog: MatDialog,
    private deletionService: DeletionService,
    private router: Router,
    private routeInfo: ActivatedRoute,
    private factory: Factory,
  ) { }

  private USERTYPE = {
    ADMIN: 'Admin',
    STANDARD: 'Standard',
    DOCUMENT_OWNER: 'DocumentOwner',
  };

  /**
   * record uri
   */
  @Input()
  public id: string;

  /**
   * control the request deletion button disbaled or not
   */
  @Input()
  public disabled: boolean = false;

  @Input()
  public pageData: PageData;

  @Input()
  public datelastupdate_init: any;

  @Input()
  public recordData: any = {};

  @Input()
  public validator: (success: () => void, failed: () => void) => void;

  public datelastupdate_end: any;

  public service: any;

  /**
   * is show reasons popup or not
   */
  public showReasons: boolean = true;

  /**
   * reasons content
   */
  public reasons: {
    reason: string;
    text: string;
    withInput?: boolean;
    inputKey?: string;
  }[] = [
      {
        reason: 'Already submitted Incorrect',
        text: 'Record already submitted previously',
      },
      {
        reason: 'Incorrect record',
        text: 'Incorrect record',
      },
      {
        reason: 'Incorrect attachment',
        text: 'Incorrect attachment',
      },
      {
        reason: 'Cannot be opened',
        text: 'Record cannot be opened and may be corrupt',
      },
      {
        reason: 'Outdated and can be archived',
        text: 'Record is outdated and can be archived',
      },
      {
        reason: 'Other For Delete',
        text: 'Other',
        withInput: true,
        inputKey: 'Other Reason For Delete',
      },
    ];

  @Output()
  public onBeforeSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public onSubmitted: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public onSubmitFailed: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public afterParentOrSupportLegalHold: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public setIsRecordEdit: EventEmitter<any> = new EventEmitter<any>();

  public inputsData: { [key: string]: string };

  private selectedReasons: any;

  private userType = this.USERTYPE.STANDARD;

  ngOnInit() {
    this.userType = this.getUserType();
    // if user is admin, not need to select reasons
    if (this.userType === this.USERTYPE.ADMIN) {
      this.showReasons = false;
    }
    this.service = this.factory.getService();
  }

  /**
   * open reasons popup
   */
  public openReasonsPopup(comfirmFunc?: (success, err) => void): void {
    this.selectedReasons = null;
    this.dialog.open(DeletionReasonsComponent, {
      width: '50%',
      disableClose: true,
      data: {
        reasons: this.reasons,
        confirm: (reasons: any, success?: () => void, err?: () => void) => {
          this.selectedReasons = reasons;
          comfirmFunc(success, err);
        }
      }
    });
  }

  /**
   * open confirm popup
   */
  public openConfirmPopup(comfirmFunc?: (success, err) => void): void {
    this.selectedReasons = null;
    this.dialog.open(DeletionConfirmComponent, {
      width: '44%',
      disableClose: true,
      data: {
        confirm: (success?: () => void, err?: () => void) => {
          comfirmFunc(success, err);
        }
      }
    });
  }

  validateGetRecordDateLastUpdated(callBackFunc?: (...args) => void, errorCallBackFunc?: (...args) => void, ...args) {
    this.onBeforeSubmit.emit();
    var data = { uri: this.routeInfo.snapshot.queryParams.uri };
    this.service.GetRecordDateLastUpdated(data).then(res => {
      this.onSubmitFailed.emit();
      var temp: string = res;
      if (res == "No Data or Failed") {
        this.datelastupdate_end = null;
      } else {
        this.datelastupdate_end = temp.split(";")[0];
      }

      if (this.datelastupdate_end != this.datelastupdate_init) {
        if (temp.split(";")[1] == RecordStatus.PendingReview) {
          this.setIsRecordEdit.emit('isRecordEditByOtherUserAndWaitingReview');
        } else {
          this.setIsRecordEdit.emit('isRecordEditByOtherUser');
        }
        if (errorCallBackFunc) {
          errorCallBackFunc();
        }
      } else {

        if (this.recordData.RecordType === RecordType.Supporting) {
          this.service.HPServiceRecord('format=json&q=number:' + this.recordData.RecordUriOrParentUri, 'properties=RecordAllHolds')
            .then(response => {
              if (response.Results.length > 0) {
                if (response.Results[0].RecordAllHolds) {
                  let legalHold: boolean;
                  legalHold = response.Results[0].RecordAllHolds.Value.length > 0 ? true : false; // legalHold.
                  if (legalHold) {
                    this.onSubmitFailed.emit();
                    this.afterParentOrSupportLegalHold.emit('parent');
                    if (errorCallBackFunc) {
                      errorCallBackFunc();
                    }
                  } else {
                    if (callBackFunc && args && args.length > 0) {
                      callBackFunc(...args);
                    } else if (callBackFunc) {
                      callBackFunc();
                    }
                  }
                }
              } else {
                if (callBackFunc && args && args.length > 0) {
                  callBackFunc(...args);
                } else if (callBackFunc) {
                  callBackFunc();
                }
              }
            });
        } else {
          let q = "format=json&q=recContainer:" + this.recordData.RecordUriOrParentUri + " and ParentOrSupportingDocument:\"Supporting Record\" and type:[Document] and not container:[type:[Document Backup]] and not AgreementStatus:\"In Progress\" and not AgreementStatus:\"" + AgreementStaus.Deleted + "\"";
          this.service.HPServiceRecord(q, 'properties=RecordAllHolds')
            .then(response => {
              if (response.Results.length > 0) {
                let arr = response.Results.filter(item => item.RecordAllHolds.Value);
                if (arr.length > 0) {
                  this.onSubmitFailed.emit();
                  this.afterParentOrSupportLegalHold.emit('supporting');
                  if (errorCallBackFunc) {
                    errorCallBackFunc();
                  }
                } else {
                  if (callBackFunc && args && args.length > 0) {
                    callBackFunc(...args);
                  } else if (callBackFunc) {
                    callBackFunc();
                  }
                }
              } else {
                if (callBackFunc && args && args.length > 0) {
                  callBackFunc(...args);
                } else if (callBackFunc) {
                  callBackFunc();
                }
              }
            }, error => {
              if (errorCallBackFunc) {
                errorCallBackFunc();
              }
            });
        }


      }
    }).catch(() => {
      this.onSubmitFailed.emit();
      if (errorCallBackFunc) {
        errorCallBackFunc();
      }
    });




  }

  onRequestClick() {
    this.validateGetRecordDateLastUpdated(this.AfteronRequestClick.bind(this), null, this.disabled);
  }

  public AfteronRequestClick(disabled): void {
    if (this.disabled && disabled) {
      return;
    }
    if (this.showReasons) {
      this.openReasonsPopup((success, err) => {
        this.doSubmit(success, err);
      });
    } else {
      this.openConfirmPopup((success, err) => {
        this.doSubmit(success, err);
      });
    }
  }

  public onSubmit(): void {
    this.doSubmit();
  }

  private doSubmit(success?: () => void, err?: () => void): void {
    this.validateGetRecordDateLastUpdated(this.validateBeforeSubmit.bind(this), success, success, err);
  }

  private validateBeforeSubmit(success?: () => void, err?: () => void): void {
    if (this.validator) {
      this.validator(() => {
        this.AfterDoSubmit(success, err);
      }, () => {
          if (err) {
            err();
          }
      });
    } else {
      this.AfterDoSubmit(success, err);
    }
  }

  private AfterDoSubmit(success?: () => void, err?: () => void): void {
    let reasons: string;
    if (this.showReasons) {
      reasons = this.selectedReasons;
    }

    this.onBeforeSubmit.emit();
    this.deletionService.requestDeletion(this.id, this.userType, reasons).then(() => {
      if (success) {
        success();
      }
      this.onSubmitted.emit(this.id);
      this.successGoPage();
    }).catch(() => {
      if (err) {
        err();
      }
      this.onSubmitFailed.emit();
    });
  }

  private successGoPage(): void {
    switch (this.userType) {
      case this.USERTYPE.STANDARD: {
        let queryParams = { queryParams: { pageData: undefined } };
        this.router.navigate(['/rs/SubmissionPending'], queryParams);
        break;
      }
      case this.USERTYPE.DOCUMENT_OWNER: {
        let queryParams = { queryParams: { pageData: undefined } };
        this.router.navigate(['/rs/SubmissionPending'], queryParams);
        break;
      }
      default: {
        if (this.pageData) {
          const pageData = this.pageData.getPageDataValue(this.routeInfo);
          let queryParams = { queryParams: { pageData: undefined } };
          let routeUrl = PageData.parseUrlBase64(this.routeInfo);
          if ("RecentlyAccessed" == this.routeInfo.snapshot.queryParams.back) {
            queryParams = { queryParams: { ...pageData, showRA: true } };
          } else {
            queryParams = { queryParams: { ...pageData } };
          }
          this.router.navigate([routeUrl], queryParams)
        } else {
          history.go(-1);
        }
      }
    }
  }

  private getUserType() {
    let userType = this.USERTYPE.STANDARD;
    //find user's biggest role
    let userRole = this.auth.determineUserRole();
    if (userRole === CommenString.AdminRole) {
      userType = this.USERTYPE.ADMIN;
    } else if (userRole === CommenString.DocumentOwnerRole
      || userRole === CommenString.DocumentOwnAndGuLead) {
      userType = this.USERTYPE.DOCUMENT_OWNER;
    }

    return userType;
  }
}
