import { OnInit, Component } from '@angular/core';
import { DataItem } from '../models';
import { FilterComponent } from './filter-container.component';

@Component({
  selector: 'simple-dropdown-container',
  templateUrl: './simple-dropdown.component.html',
  styleUrls: ['./compo.scss']
})
export class SimpleDropdownComponent extends FilterComponent implements OnInit {

  constructor() {
 super(); 
}

  ngOnInit(): void {
    if(this.itemOrder === 'ASC' || this.itemOrder === 'DESC'){
      this.items = this.sortItems(this.items);
    }
    this.items = this.convertItems(this.items, this.checkeds);
    super.ngOnInit();
    this.dataItem
      .setIsLike(this.isLike)
      .setCol(this.property)
      .setAlias(this.property)
      .setOperateChar(':')
      .setValue(this.checkeds);
      if(this.checkeds.length <= 0){
        this._tabelFilterService.removeQuery(this.dataItem);
      }else{
        this._tabelFilterService.addQuery(this.dataItem);
      }
  }

  itemActive(event: MouseEvent, item: any) {
    event.stopPropagation();
    event.preventDefault();
    this.beforeItems = this.items.filter((m) => m.checked).map((m) => m.name);
    item.checked = !item.checked; 
    this.afterItems = this.items.filter((m) => m.checked).map((m) => m.name);
    const itemsChecked = this.items.filter((m) => m.checked).map((m) => m.name);
    this.dataItem.setValue(itemsChecked);
    this.dataItem.addeds = this.afterItems.filter(x => !this.beforeItems.includes(x));
    this.dataItem.removeds = this.beforeItems.filter(x => !this.afterItems.includes(x));
    if(itemsChecked.length == 0){
      this._tabelFilterService.removeQuery(this.dataItem);
    }else{
      this._tabelFilterService.addQuery(this.dataItem);
    }
    this.filterItem.emit(this.dataItem);
  }

  cleanFilter(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    if(!this.hasCondition()){
      return;
    }
    this.beforeItems = this.items.filter((m) => m.checked).map((m) => m.name);
    this.cleanData();
    this.afterItems = [];
    this.dataItem.addeds = this.afterItems.filter(x => !this.beforeItems.includes(x));
    this.dataItem.removeds = this.beforeItems.filter(x => !this.afterItems.includes(x));
    this.filterItem.emit(this.dataItem);
  }

 cleanData(){
   if(this.hasCondition()){
    this.dataItem.setValue([]);
    this.items = this.restItems(this.items);
    this._tabelFilterService.removeQuery(this.dataItem);
    this._parent.regeditShowAction();
   }
 }

 addData(_items: any){
    this.items = this.items.concat(this.convertItems(_items, this.checkeds));
    this.items = this.sortItems(this.items);
    if(!this.hasCondition()){
      this._parent.regeditShowAction();
    }
  }

  removeData(_items: any){
    this.items = this.items.filter(x => !_items.includes(x.name));
    this.checkeds = this.checkeds.filter(x => !_items.includes(x));
    this.dataItem.setValue(this.dataItem.value.filter(x => !_items.includes(x)));
    if(!this.hasCondition()){
      this._tabelFilterService.removeQuery(this.dataItem);
    }
    this.filterItem.emit(this.dataItem);
  }

  public hasCondition(){
    return this.items.some((member) => {
      return member.checked;
    });
  }

}
