import {
  Component, Inject, forwardRef,
  ViewChild, HostListener, EventEmitter, ElementRef
} from '@angular/core';
import { Factory } from '../../../../../app.factory';
import { HPEStaticService } from "../../../../../common/services/staticService/HPEStaticService";
import { configurationIDs } from 'app/common/enum/configurationIDs';
import { BasicInfoComponent } from 'app/modules/main/component/recordForm/BasicInfo/BasicInfo.component';
import { CompanyCodeService } from '../SupplierNameSearch/company-code.service';
import { FilterArrayPipe } from 'app/modules/main/component/tabel-filter/pipe/filter-array.pipe';
import { SecondSupplierService } from "../../../../../common/services/NewRecord/secondsupplierSearchService";
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NewPaginatorComponent } from '../../../component/new-paginator/new-paginator.component';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
@Component({
  selector: 'secondarySupplierSearch',
  templateUrl: './secondarySupplierSearch.component.html',
  styleUrls: ['./secondarySupplierSearch.component.css'],
  providers: [Factory],
  inputs: ['keySupplier', 'recordCountry', 'secondarySupplierDataSource', 'supplierList', 'selectoption'],
  outputs: [
    'selectedScndSup',
    'isShowOrHideFn'
  ]
})
export class secondarySupplierSearchComponent {
  //input
  public recordCountry: any;
  public selectSupplier: any = {
    PrimaryCountry: "", SupplierName: "", CompanyCode: "",
    SupplierUri: "", SupplierNotYetInSystem: false, NoSAPIDrequired: false,
  }
  public selectoption: any = { PrimaryCountry: "", SupplierName: "", CompanyCode: "" }
  public keySupplier: any = { selectSupplier: this.selectSupplier, keySupplierList: [], selectoption: this.selectoption };
  public secondarySupplierDataSource: any = [];
  //output
  public selectedScndSup = new EventEmitter();
  public isShowOrHideFn = new EventEmitter();

  service: any;
  public supplierList: any = [];
  public selectedsupplierList: any = [];
  public highClass: any;
  public isSltSupplier: boolean = false;
  public isResultShow: boolean = false;
  public isTableShow: boolean = true;
  public isYetShow: boolean = true;
  public allSupplierSelect: boolean = false;
  public isLoading: boolean = false;
  public isSearchBtnDisabled: boolean = true;
  public searchFormData: any;
  public noSupplierList: any = [];
  public companyCodes: any = [];
  public filterText: string = '';
  public query: any;
  public isSecondarySupplierValid: boolean = true;
  public displayedColumns = [
    'SupplierName',
    'SupplierID',
    'CompanyCode',
    'CompanyDescription', 
  ];
  dataSource = new MatTableDataSource();
  public isLoadingResults = true;
  public resultsLength = 0;
  public isSearchResults = false;
  @ViewChild(NewPaginatorComponent) paginator: NewPaginatorComponent;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  @ViewChild(MatCheckboxModule) checkbox: MatCheckboxModule;
  @ViewChild('caret')
  public caretElementRef: ElementRef;

  @ViewChild('companyCode')
  public companyCodeElementRef: ElementRef;

  constructor(private factory: Factory,
    private HPEService: HPEStaticService,
    private companyCodeService: CompanyCodeService,
    private secondsupplierService: SecondSupplierService,
    @Inject(forwardRef(() => BasicInfoComponent))
    private _parent: BasicInfoComponent) { }

  ngOnInit() {
    this.service = this.factory.getService();
    Object.assign(this.sort, { active: 'SupplierName', direction: 'asc' })
    this.resetData();
  }

  ngAfterViewInit(): void {
    // this.popupResize();
    this.sort.sortChange.subscribe(() => this.paginator.pageIndexNoEmit = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.highClass = -1;
          this.isLoadingResults = true;
          var pageindex = 0;
          if (!this.isSearchResults) {
            pageindex = this.paginator.pageIndex;
          }
          this.secondsupplierService.teamSearchRecordData = encodeURIComponent(this.query);
          this.secondsupplierService.supplierList = this.selectedsupplierList;
          return this.secondsupplierService!.getPage(
            this.sort.active, this.sort.direction, pageindex, this.paginator.pageSize);
        }),
        map(data => {
          this.searchBtnValidate();
          //this.isSearchBtnDisabled = false;
          this.isLoadingResults = false;
          this.isSearchResults = false;
          this.isResultShow = true;
          this.resultsLength = data.Total;

          return data.Data;
        }),
        catchError(() => {

          this.isSearchResults = false;
          this.isLoadingResults = false;
          return observableOf([]);
        })
      ).subscribe(data => {
        this.dataSource.data = data;
        this.supplierList = data;

      });
  }

  resetData() {
    this.isSearchBtnDisabled = true;
    this.highClass = -1;
    var ids = configurationIDs.supplierSearchForm.SearchFormIDs;
    var formDataJsonList = this.HPEService.getFieldbyFiledId(ids);
    this.searchFormData = this.HPEService.setFormDataToJson(formDataJsonList);

    if (this.supplierList.length > 0) {
      var seachDataList = [];
      var pageindex = 0;
      this.paginator.pageSize = 10;
      if (this.keySupplier.selectoption.PrimaryCountry.value == 'Avanade USA') {
        this.searchFormData.CountryKey.value = 'United States';
        this.searchFormData.CompanyCodeOwnedByCd.value = 'AVA';
      } else if (this.keySupplier.selectoption.PrimaryCountry.value == 'Avanade UK') {
        this.searchFormData.CountryKey.value = 'United Kingdom';
        this.searchFormData.CompanyCodeOwnedByCd.value = 'AVA';
      } else if (this.keySupplier.selectoption.PrimaryCountry.value.substring(0, 7) == 'Avanade') {
        this.searchFormData.CountryKey.value = this.searchFormData.PrimaryCountry.value.substring(8, this.searchFormData.PrimaryCountry.value.length);
        this.searchFormData.CompanyCodeOwnedByCd.value = 'AVA';
      } else if (this.keySupplier.selectoption.PrimaryCountry.value == '') {
        this.searchFormData.CountryKey.value = '';
        this.searchFormData.CompanyCodeOwnedByCd.value = '';
      } else {
        this.searchFormData.CountryKey.value = this.keySupplier.selectoption.PrimaryCountry.value;
        this.searchFormData.CompanyCodeOwnedByCd.value = 'ACN';
      }
      this.searchFormData.SupplierName.value = this.keySupplier.selectoption.SupplierName.value;
      this.searchFormData.CompanyCode.value = this.keySupplier.selectoption.CompanyCode.value;
      seachDataList.push(this.searchFormData.CompanyCodeOwnedByCd);
      seachDataList.push(this.searchFormData.CountryKey);
      seachDataList.push(this.searchFormData.SupplierName);
      seachDataList.push(this.searchFormData.CompanyCode);
      var searchstr = this.configeSearchString(seachDataList);
      this.secondsupplierService.teamSearchRecordData = encodeURIComponent(searchstr);
      this.query = searchstr;
    } else {
      this.isResultShow = false;
      this.isTableShow = false;
    }
    this.searchFormData.PrimaryCountry.IsRequired = false;
    this.searchFormData.SupplierName.IsRequired = false;
    this.searchFormData.CompanyCode.IsRequired = false;
    this.searchFormData.CompanyCode.IsDisabled = false;
    this.searchFormData.SupplierID.IsDisabled = false;
    this.searchFormData.PrimaryCountry.IsDisabled = false;
    this.searchFormData.SupplierName.IsDisabled = false;
    this.searchFormData.PrimaryCountry.value = "";
    this.searchFormData.SupplierName.value = "";
    this.searchFormData.SupplierName.labelName = "Supplier Name";

    if (this.isShowSupplierID()) {
      this.searchFormData.PrimaryCountry.value = this._parent.recordFormData.SupplierCountry.value;
      this.searchFormData.SupplierName.value = this._parent.recordFormData.SupplierName.value;
      this.searchFormData.SupplierName.labelName = "Suggested Supplier Name";
      this.searchFormData.SupplierID.IsDisabled = true;
      this.searchFormData.PrimaryCountry.IsDisabled = true;
      this.searchFormData.SupplierName.IsDisabled = true;
      this.searchFormData.SupplierName.IsRequired = true;
      this.getCompanyCodes()
        .then((companyCodes) => {
          this.companyCodes = companyCodes.map((_item) => {
            return {
              name: _item,
              checked: false
            }
          });
        });
    }

    //search button disable function
    var requirelist = this.HPEService.getFormdataFieldNames(ids);
    requirelist.forEach(element => {
      if (element == "PrimaryCountry" || element == "SupplierName" || element == "CompanyCode") {
        if (this.searchFormData[element].value != "" && this.searchFormData[element].value != null) {
          this.isSearchBtnDisabled = false;
        }
      }
    });
    // //search button disable function end
  }

  //search button disable function
  searchBtnValidate() {
    this.isSearchBtnDisabled = true;
    var ids = configurationIDs.supplierSearchForm.SearchFormIDs;
    var requirelist = this.HPEService.getFormdataFieldNames(ids);
    requirelist.forEach(element => {
      if (element == "PrimaryCountry" || element == "SupplierName" || element == "CompanyCode") {
        if (this.searchFormData[element].value != "" && this.searchFormData[element].value != null) {
          this.isSearchBtnDisabled = false;
        }
      }
    });
  }
  //search button disable function end
  searchScndSupplier() {
    this.highClass = -1;
    //empty selected values
    this.selectedsupplierList.splice(0, this.selectedsupplierList.length);
    this.isResultShow = true;
    this.isTableShow = true;
    this.isLoading = true;
    this.isSltSupplier = false;
    var seachDataList = [];
    this.supplierList = [];
    if (this.searchFormData.PrimaryCountry.value == 'Avanade USA') {
      this.searchFormData.CountryKey.value = 'United States';
      this.searchFormData.CompanyCodeOwnedByCd.value = 'AVA';
    } else if (this.searchFormData.PrimaryCountry.value == 'Avanade UK') {
      this.searchFormData.CountryKey.value = 'United Kingdom';
      this.searchFormData.CompanyCodeOwnedByCd.value = 'AVA';
    } else if (this.searchFormData.PrimaryCountry.value.substring(0, 7) == 'Avanade') {
      this.searchFormData.CountryKey.value = this.searchFormData.PrimaryCountry.value.substring(8, this.searchFormData.PrimaryCountry.value.length);
      this.searchFormData.CompanyCodeOwnedByCd.value = 'AVA';
    } else if (this.searchFormData.PrimaryCountry.value == '') {
      this.searchFormData.CountryKey.value = '';
      this.searchFormData.CompanyCodeOwnedByCd.value = '';
    } else {
      this.searchFormData.CountryKey.value = this.searchFormData.PrimaryCountry.value;
      this.searchFormData.CompanyCodeOwnedByCd.value = 'ACN';
    }
    seachDataList.push(this.searchFormData.CompanyCodeOwnedByCd);
    seachDataList.push(this.searchFormData.CountryKey);
    seachDataList.push(this.searchFormData.SupplierName);
    seachDataList.push(this.searchFormData.CompanyCode);
    var searchstr = this.configeSearchString(seachDataList);
    this.secondsupplierService.teamSearchRecordData = encodeURIComponent(searchstr);
    this.query = searchstr;
    Object.assign(this.sort, { active: 'SupplierName', direction: 'asc' });
    this.paginator.page.emit();
  }

  configeSearchString(formData) {
    var result = "type:\"Supplier\"";
    formData.forEach(res => {
      if (res.value != "") {
        if (res.searchClauseName == 'CountryKey') {
          res.searchClauseName = 'PrimaryCountry';
        }
        if (res.searchClauseName == 'PrimaryCountry' || res.searchClauseName == 'CompanyCodeOwnedByCd') {
          result = result + " and " + res.searchClauseName + ":\"" + res.value + "\"";
        } else {
          if (res.searchClauseName == 'typedTitle') {
            var value=JSON.parse(JSON.stringify(res.value));
            value = value.replace(/"/g, '\\"');
            result = result + " and " + res.searchClauseName + ":\"*" + value + "*\"";
        } else
            result = result + " and " + res.searchClauseName + ":\"*" + res.value + "*\"";
        }
      }
    });
    // result += ` and not SupplierSource: "Awaiting SAP ID","Not required" and not SupplierID: "Awaiting SAP ID","Not required",""`;
    result += ` and not SupplierSource: "Awaiting SAP ID","Not required" and not SupplierID: "Awaiting SAP ID","Not required","" and not SupplierID:02* and not SupplierID:03* and not SupplierID:A* `;
    const secondarys = this.secondarySupplierDataSource
      .filter((secondary) => secondary.SupplierUri != "" && secondary.SupplierUri != null);
    if (this.keySupplier.selectSupplier.SupplierUri.value != 0 || secondarys.length > 0) {
      result = result + "and not uri:";
      if (this.keySupplier.selectSupplier.SupplierUri.value != 0) {
        result = result + this.keySupplier.selectSupplier.SupplierUri.value;
        if (secondarys.length > 0) {
          secondarys.forEach(sltScndSup => {
            result = result + "," + sltScndSup.SupplierUri;
          });
        }
      } else {
        if (secondarys.length > 0) {
          secondarys.forEach(sltScndSup => {
            result = result + sltScndSup.SupplierUri + ",";
          });
          result = (result.substring(result.length - 1) == ',') ? result.substring(0, result.length - 1) : result;
        }
      }
    }
    return result;
  }

  onPageRefresh() {
    this.paginator.pageIndexNoEmit = 0;
  }
  selectcolumn(selectedSupplie) {
    return true;
  }
  selectedOne(selectedSupplier, index) {
    event.stopPropagation();
    event.preventDefault();
    this.highClass = index;
    if (!selectedSupplier.IsSelected && this.secondarySupplierDataSourceLengthGreatThanFifty(this.secondarySupplierDataSource.length, this.getTotalSupplierListCheckedNum() + 1)) {
      this.isSecondarySupplierValid = false;
      return;
    } else {
      this.isSecondarySupplierValid = true;
    }

    selectedSupplier.IsSelected = !selectedSupplier.IsSelected;
    if (selectedSupplier.IsSelected) {
      this.selectedsupplierList.push(selectedSupplier);
    } else {
      if (this.selectedsupplierList.length > 0) {
        for (var i = 0; i < this.selectedsupplierList.length; i++) {
          if (this.selectedsupplierList[i].SupplierID == selectedSupplier.SupplierID) {
            this.selectedsupplierList.splice(i, 1);
            i = this.selectedsupplierList.length;
          }
        }
      }
    }
    if (this.selectedsupplierList.length > 0)
      this.isSltSupplier = true;
    else
      this.isSltSupplier = false;
    // search button control
    //this.isSltSupplier = this.supplierList.some((m) => m.IsSelected);
    //this.allSupplierSelect = this.supplierList.every((m) => m.IsSelected);
    // checkbox all control
    // this.changeChk($event, selectedSupplier.IsSelected);
  }

  selectedOneCheckbox(selectedSupplier, event) {
    event.target.checked = selectedSupplier.IsSelected;
  }

  changeChk(chk: any) {
    // if(this.allSupplierSelect)
    // {
    //   if(!chk)
    //   {
    //     this.allSupplierSelect = false;
    //   }
    // }
    // else
    // {
    //   if(chk)
    //   {
    //     let chkStatus = true;
    //     this.supplierList.forEach(sup => {
    //       if(!sup.IsSelected)
    //       {
    //         chkStatus = false;
    //       }
    //     });
    //     this.allSupplierSelect = chkStatus;
    //   }
    // }
  }
  closeScndSupplierSearch() {
    this.isSltSupplier = false;
    this.isResultShow = false;
    this.isYetShow = true;
    this.allSupplierSelect = false;
    this.isLoading = false;
    this.isTableShow = false;
    this.isSearchBtnDisabled = true;
    this.isSecondarySupplierValid = true;
    this.isShowOrHideFn.emit(false);
  }

  // changeAll(){
  //   this.isSltSupplier = this.allSupplierSelect;
  //   this.supplierList.forEach(element => {
  //       element.IsSelected = this.allSupplierSelect;
  //   });
  // }

  addScndSupplier() {
    if (this.selectedsupplierList.length > 0) {
      if (this.secondarySupplierDataSourceLengthGreatThanFifty(this.secondarySupplierDataSource.length, this.selectedsupplierList.length)) {
        this.isSecondarySupplierValid = false;
      } else {
        this.isSecondarySupplierValid = true;
        this.selectedScndSup.emit(this.selectedsupplierList);
        this.isShowOrHideFn.emit(false);
        this.closeScndSupplierSearch();
      }
    }
  }

  public isShowSupplierID(): boolean {
    const selectSupplier = this._parent.recordFormData;
    return selectSupplier.SupplierID.value == "Awaiting SAP ID"
      || selectSupplier.SupplierID.value == "Not required";
  }

  public checkedSupplierID(value: string): boolean {
    const selectSupplier = this._parent.recordFormData;
    return selectSupplier.SupplierID.value == value;
  }

  private getCompanyCodes(): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
      this.companyCodeService.getSourceList(this.searchFormData.PrimaryCountry.value, (list) => {
        list = list.filter((item, index, array) => {
          const source = this.secondarySupplierDataSource.find((source) => source.CompanyCode == item);
          return array.indexOf(item) === index
            && item != this._parent.recordFormData.CompanyCode.value
            && source == undefined;
        }).sort((a, b) => parseInt(a) - parseInt(b));
        resolve(list);
      });
    });
  }

  closeSupplierNameSearch() {
    this.isShowOrHideFn.emit({
      isSearchScndSupllier: false
    });
  }

  showSelectedCodes(): any {
    return this.companyCodes.filter((m) => m.checked).map((m) => m.name).join(',');
  }

  itemActive(event: MouseEvent, item: any, selectAll: any): any {
    event.stopPropagation();
    event.preventDefault();
    if (!item.checked && this.secondarySupplierDataSourceLengthGreatThanFifty(this.secondarySupplierDataSource.length, this.getTotalShowSupplierIDSupplierListCheckedNum() + 1)) {
      this.isSecondarySupplierValid = false;
      return;
    } else {
      this.isSecondarySupplierValid = true;
    }
    item.checked = !item.checked;
    selectAll.checked = this.companyCodes.every((member) => {
      return member.checked;
    });
  }

  selectOk(): any {
    let codes = [...this.companyCodes];
    codes = codes.filter((m) => m.checked)
      .map((m) => {
        let temp = {
          SupplierName: this._parent.recordFormData.SupplierName.value,
          PrimaryCountry: this._parent.recordFormData.SupplierCountry.value,
          SupplierSource: this._parent.recordFormData.SupplierID.value,
          CompanyCode: m.name,
        };
        return temp;
      });
    if (this.secondarySupplierDataSourceLengthGreatThanFifty(this.secondarySupplierDataSource.length, codes.length)) {
      this.isSecondarySupplierValid = false;
    } else {
      this.isSecondarySupplierValid = true;
      this.selectedScndSup.emit(codes);
      this.isShowOrHideFn.emit(false);
      this.closeScndSupplierSearch();
    }
  }

  isDisabled(): any {
    return this.companyCodes.some((member) => {
      return member.checked;
    });
  }

  openDropdown() {
    if (this.companyCodes.length <= 0) {
      return;
    }
    const nativeElement = this.caretElementRef.nativeElement;
    nativeElement.isOpen = !nativeElement.isOpen;
    // return nativeElement.isOpen;
  }

  isOpen() {
    if (this.caretElementRef == undefined) {
      return false;
    }
    const nativeElement = this.caretElementRef.nativeElement;
    return nativeElement.isOpen;
  }

  @HostListener('document:click', ['$event'])
  onMouseClick(event: MouseEvent) {
    if (this.companyCodeElementRef == null) {
      return;
    }
    const nativeElement = this.companyCodeElementRef.nativeElement;
    // click outside
    if (nativeElement == event.target || nativeElement.contains(event.target)) {
      // nativeElement.isOpen = true;
    } else {
      this.caretElementRef.nativeElement.isOpen = false;
    }
  }

  selectAllActive(event: MouseEvent, selectAll: any) {
    event.stopPropagation();
    event.preventDefault();
    if (this.filterText != '') {
      return;
    }
    if (!selectAll.checked && this.secondarySupplierDataSourceLengthGreatThanFifty(this.secondarySupplierDataSource.length, this.companyCodes.length)) {
      this.isSecondarySupplierValid = false;
      return;
    } else {
      this.isSecondarySupplierValid = true;
    }
    selectAll.checked = !selectAll.checked;
    this.companyCodes = this.companyCodes.map((m) => {
      m.checked = selectAll.checked;
      return m;
    })
  }

  restAllActive(event: MouseEvent, selectAll: any) {
    event.stopPropagation();
    event.preventDefault();
    this.filterText = ''
    selectAll.checked = false;
    this.isSecondarySupplierValid = true;
    this.companyCodes = this.companyCodes.map((m) => {
      m.checked = false;
      return m;
    })
  }

  public hasFilterValues(component: any): boolean {
    const filterArrayPipe = new FilterArrayPipe();
    const values = filterArrayPipe.transform(component.companyCodes, { name: component.filterText });
    return values.length > 0;
  }


  /**
   * for custom popup resize position(top)
   * @return {void}@memberof secondarySupplierSearchComponent
   */
  popupResize(): void {
    $('.search_headbox').css({
      'top': ($(window).scrollTop()) / $('.all_box').height() * 100 + '%'
    });
  }

  secondarySupplierDataSourceLengthGreatThanFifty(sourceLength: any, addedLength: any) {
    const totalLength = sourceLength + addedLength;
    return totalLength > 50;
  }

  getTotalSupplierListCheckedNum() {
    return this.selectedsupplierList.length;
  }

  getTotalShowSupplierIDSupplierListCheckedNum() {
    return this.companyCodes.reduce((totalCheckedNum, one) => {
      if (one.checked) {
        totalCheckedNum += 1;
      }
      return totalCheckedNum;
    }, 0);
  }
}
