import { NgModule } from "@angular/core";
import { DeletionComponent } from "./deletion.component";
import { DeletionReasonsComponent } from "./deletion-reasons/deletion-reasons.component";
import { MatDialogModule } from "@angular/material/dialog";
import { CommonModule } from "@angular/common";
import { DeletionService } from "./deletion.service";
import { FormsModule } from "@angular/forms";
import { DeletionConfirmComponent } from "./deletion-confirm/deletion-confirm.component";

@NgModule({
    declarations: [
        DeletionComponent,
        DeletionReasonsComponent,
        DeletionConfirmComponent,
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        FormsModule,
    ],
    exports: [
        DeletionComponent,
    ],
    providers: [
        DeletionService,
    ]
})
export class DeletionModule {}