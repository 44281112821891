
<div class="mask" *ngIf="isShow">
    <div class="search_headbox" *ngIf="isShow">
        <div class="titlBox">
            <p class="team_title fl">Scheduled Maintenance</p>
            <i (click)="closePop()" class="mdui-icon material-icons close_icon fr">clear</i>
        </div>
        <div class="tab_content">
            <div class="tab_line content_title" [innerHTML]="htmlBody">
            </div>
        </div>
    </div>
</div>

