import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from 'app/common/services/message/messageService'

@Component({
  selector: 'app-warning-tips',
  templateUrl: './warning-tips.component.html',
  styleUrls: ['./warning-tips.component.css']
})
export class WarningTipsComponent implements OnInit {

  public errMsg: string;
  public isShow: boolean;

  constructor(private message: MessageService) { }

  ngOnInit() {
    
    this.message.get().subscribe(result => {
      if(result.fileErrMsg){
        this.isShow = true;
        this.errMsg = result.fileErrMsg;  
      } else {
        this.isShow = false;
      }
    })
  }

  closeErrTip(){
    this.isShow = false;
  }

}
