import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-radioY',
  templateUrl: './radioY.component.html',
  styleUrls: ['./radioY.component.css'],
  inputs: [
    'inputData',
    'inputName',
  ],
})
export class RadioYComponent implements OnInit {
  public ToolTip: any;
  public newValue: boolean;
  public inputData: any;
  public radioValue :any;
  public inputName: string;
  public InfoShow: boolean;
  public information: any;
  public infoArrow: any='top';
  constructor() { }

  ngOnInit() {
    if(this.inputData.information!="" && this.inputData.information!=undefined){
      this.InfoShow=true;
      this.information=this.inputData.information;
      if(this.inputData.infoArrow!="" && this.inputData.infoArrow!=undefined){
        this.infoArrow=this.inputData.infoArrow;
      }
    }else{
      this.InfoShow=false;
      this.information="";
    }
  }
}
