import { NgModule } from '@angular/core';
import { FileSizePipe } from './fileSize.pipe';

@NgModule({
  imports: [],
  declarations: [
    FileSizePipe
  ],
  exports: [
    FileSizePipe
  ]
})
export class FileSizeModule {

}