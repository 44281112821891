import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
// import { r } from '@angular/core/src/render3';

@Component({
  selector: 'new-paginator',
  templateUrl: './new-paginator.component.html',
  styleUrls: ['./new-paginator.component.css']
})
export class NewPaginatorComponent implements OnInit {
  _length:number = 0;
  _pageSize:number = 0;
  _pageSizeOptions:Array<any> = [];
  _pageIndex:number = 0;
  _isInit: boolean = true;
  get isInit(): boolean {
    return this._isInit;
  }
  set isInit(value: boolean) {
    this._isInit = value;
  }
  @Output() onPageRefresh: EventEmitter<any> = new EventEmitter();
 
  @Input()
  set pageSizeOptions(pageSizeOptions: Array<any>) {
    this._pageSizeOptions = pageSizeOptions;
  };
  get pageSizeOptions(): Array<any> {
    return this._paginatorNew.pageSizeOptions; 
  };

  @Input()
  set length(length: number) {
    this._length = length;
    if (length > 0) {
      this.showComponent = true;
      this.lengthInit();
      if (this._pageIndex > 0) {
        this._paginatorNew.pageIndex = this._pageIndex;
        var pageIndexVar = this._pageIndex + 1;
        this.myManualPage(pageIndexVar);
      }
    } else {
      this.showComponent = false;
      this.manualPageMin = 1;
      this.manualPageMax = 1;
      this.totalPage = 1;
      this.countPage = [];
      this.countPage.push(1);
      this.showNext = false;
    }
  }
  get length(): number {
 return this._length; 
}

  get pageIndex(): number {
    return this._paginatorNew.pageIndex; 
  };
  set pageIndex(pageIndex: number) {
    this._pageIndex = pageIndex;
    if (this._length > 0) {
      this._paginatorNew.pageIndex = pageIndex;
      var pageIndexVar = pageIndex + 1;
      this.myManualPage(pageIndexVar);
    }
  };
  set pageIndexNoEmit(pageIndex: number) {
    this._pageIndex = pageIndex;
    if (this._length > 0) {
      this._paginatorNew.pageIndex = pageIndex;
      var pageIndexVar = pageIndex + 1;
      this.myManualPage(pageIndexVar, false);
    }
  };

  @Input()
  set pageSize(pageSize: number) {
    this._pageSize = pageSize;
    if (this._pageSizeOptions && this._pageSizeOptions.length > 0) {
    } else {
      this._pageSizeOptions = [pageSize];
    }
    this._paginatorNew.pageSize = pageSize;
  };
  get pageSize(): number {
    return this._paginatorNew.pageSize; 
  };

  get page(): EventEmitter<PageEvent> { 
    this.onPageRefresh.emit();
    return this._paginatorNew.page; 
  }

  constructor() { }
  dataSource = new MatTableDataSource();
  manualPage = 1;
  manualPageMax = 1;
  manualPageMin = 1;
  totalPage = 1;
  _differ: any;
  countPage:any=[];
  showPrevious: boolean = true;
  showNext: boolean = true;
  showComponent:boolean = false;
  @ViewChild(MatPaginator, {static: true}) public _paginatorNew: MatPaginator;

  ngOnInit() {
  }

  lengthInit() {
    if (this._length && this._length > this._pageSize) {
      //calculate total page: adding 1 page for remainder>0, no adding for remainder=0
      var myPageVar = this._length%this._pageSize;
      if (myPageVar > 0) {
        this.totalPage = parseInt((this._length/this._pageSize).toString()) + 1;
      } else {
        this.totalPage = parseInt((this._length/this._pageSize).toString());
      }
      this.countPage = [];
      var i:number; 
      if (this.totalPage > 5) {
        this.manualPageMin = 1;
        this.manualPageMax = 5;
        for(i=1;i<=5;i++) {
          this.countPage.push(i);
        }
      } else {
        this.manualPageMin = 1;
        this.manualPageMax = this.totalPage;
        for(i=1;i<=this.totalPage;i++) {
          this.countPage.push(i);
        }
      }
      this.showNext= true;
    } else {
      this.manualPageMin = 1;
      this.manualPageMax = 1;
      this.totalPage = 1;
      this.countPage = [];
      this.countPage.push(1);
      this.showNext = false;
    }
    if (this._isInit) {
      if(!this.manualPage || this.manualPage <= 1) {
        this.showPrevious = false;
        this.manualPage = 1;
      } else {
        var maxPageNumber = Math.ceil(this._length/10);
        if(this.manualPage > maxPageNumber) {
          this.manualPage = maxPageNumber;
        }
        if(this.manualPage > 5) {
          this.countPage = [];
          var currentMinPage = this.manualPage - this.manualPage % 5 + 1;
          var currentMaxPage = this.manualPage - this.manualPage % 5 + 5;
          if(maxPageNumber < currentMaxPage) {
            currentMaxPage = maxPageNumber;
          }
          for(i=currentMinPage;i<=currentMaxPage;i++) {
            this.countPage.push(i);
          }
          this.manualPageMin = currentMinPage;
          this.manualPageMax = currentMaxPage;
        }
      }
    } else {
      if (this.manualPage) {
        if (this.manualPage >= 1) {
          this._pageIndex = this.manualPage - 1;
        }
        if (this._pageIndex == 0) {
          this.showPrevious = false;
        }
      }
    }
  }

  private myManualPage(myVal: number, isEmit = true) {
    //get range of page index: not more than totalPage, not less than 1
    var myPage = 1;
    if (myVal <= 1) {
      myPage = 1;
    } else {
      if (myVal >= this.totalPage) {
        myPage = this.totalPage;
      } else {
        myPage = myVal;
      }
    }
    //prepare pageMin and pageMax
    this.countPage = [];
    var i:number;
    if (myPage > 5) {
      var myTempVar = myPage%5;
      if (myPage == this.totalPage) {
        if (myTempVar == 0) {
          this.manualPageMin = myPage - 5 + 1;
        } else {
          this.manualPageMin = myPage - myTempVar + 1;
        }
        this.manualPageMax = this.totalPage;
      } else {
        if (this.totalPage > 5) {
          if (myTempVar == 0) {
            this.manualPageMin = myPage - 5 + 1;
          } else {
            this.manualPageMin = myPage - myTempVar + 1;
          }
          var myNewVar = this.totalPage - this.manualPageMin;
          if (myNewVar <= 5) {
            this.manualPageMax = this.totalPage;
          } else {
            this.manualPageMax = this.manualPageMin + 4;
          }
        } else {
          this.manualPageMin = 1;
          this.manualPageMax = this.totalPage;
        }
      }
    } else {
      this.manualPageMin = 1;
      if (this.totalPage > 5) {
        this.manualPageMax = this.manualPageMin + 4;
      } else {
        this.manualPageMax = this.totalPage;
      }
    }
    for(i=this.manualPageMin;i<=this.manualPageMax;i++) {
      this.countPage.push(i);
    }
    if (myVal <= 1) {
      this.showPrevious = false;
      if (this.manualPageMax > 1) {
        this.showNext = true;
      }
    } else {
      if (myVal >= this.totalPage) {
        myPage = this.totalPage;
        if (this.manualPageMin == 1 && this.manualPageMax == 1) {
          this.showPrevious = false;
        } else {
          this.showPrevious = true;
        }
        this.showNext = false;
      } else {
        this.showPrevious = true;
        this.showNext = true;
      }
    }


    this.gotoPage(myPage, isEmit);
  }

  onKey(val: any) {
    if (this.checkNum(val)) {
      var myValue = parseInt(val);
      if(myValue > this.totalPage || myValue < 1) {
        if (myValue > this.totalPage) {
          this.manualPage = this.totalPage;
        }
        if (myValue < 1 || !this.checkNum(myValue)) {
          this.manualPage = 1;
        }
        this.myManualPage(this.manualPage);
      }
    } else {
      this.manualPage = 1;
      this.myManualPage(this.manualPage);
    }
  }

  onEnter(box: any) {
    box.blur();
  }

  checkNum(val: number) {
    if (isNaN(val)) {
      return false;
    }
    return true;
  }

  public updateManualPage(event: any): void {
    var myPage = 1;
    if (event.target.value && event.target.value != "") {
      var myVal = parseInt(event.target.value);
      if (myVal) {
        myPage = myVal;
      }
    }
    this.myManualPage(myPage);
  }

  public gotoPage(myVal: number, isEmit = true): void {
    this.manualPage = parseInt(myVal.toString());
    var index = 0;
    if (myVal < 1) {
      index = 0;
    } else if (myVal > this.totalPage) {
      index = this.totalPage - 1;
    } else {
      index = myVal - 1;
    }
    this._paginatorNew.pageIndex = index;
    if(isEmit) {
      this._paginatorNew.page.next({
        pageIndex: this._paginatorNew.pageIndex,
        pageSize: this._paginatorNew.pageSize,
        length: this._paginatorNew.length
      });
    }
  }
  
  onSelectPage(iPage: number): void {
    if (iPage >= this.manualPageMax) {
      if (this.manualPageMin == this.manualPageMax && this.manualPageMin == 1) {
        this.showPrevious = false;
      } else {
        this.showPrevious = true;
      }
      if (this.manualPageMax < this.totalPage) {
        this.showNext= true;
      } else {
        this.showNext= false;
      }
    } else if (iPage <= this.manualPageMin) {
      if (this.manualPageMin == this.manualPageMax && this.manualPageMin == 1) {
        this.showNext = false;
      } else {
        this.showNext = true;
      }
      if (iPage <= 1) {
        this.showPrevious = false;
      } else {
        this.showPrevious = true;
      }
    } else {
      this.showPrevious = true;
      this.showNext = true;
    }
    this.gotoPage(parseInt(iPage.toString()));
  }

  goPreviousPage() {
    var currentPage = parseInt(this.manualPage.toString());
    var prePage = 1;
    if (currentPage <= 1) {
      prePage = 1;
    } else {
      prePage = parseInt(this.manualPage.toString()) - 1;
    }
    if (prePage < this.manualPageMin) {
      this.countPage = [];
      var i:number;
      if (this.manualPageMin == this.totalPage) {
        if (this.totalPage  > 5) {
          this.manualPageMin = this.totalPage  - 5;
        } else {
          this.manualPageMin = 1;
        }
        this.manualPageMax = this.totalPage - 1;
      } else {
        if (prePage > 5) {
          this.manualPageMin = prePage - 4;
        } else {
          this.manualPageMin = 1;
        }
        this.manualPageMax = prePage;
      }
      for(i=this.manualPageMin;i<=this.manualPageMax;i++) {
        this.countPage.push(i);
      }
    }
    this.showNext = true;
    if (prePage <= 1) {
      this.showPrevious = false;
    } else {
      this.showPrevious = true;
    }
    this.gotoPage(prePage);
  }

  goFirstPage() {
    this.showPrevious = false;
    this.showNext = true;
    var firstPage = 1;
    this.countPage = [];
    var i:number; 
    if (this.totalPage > 5) {
      this.manualPageMin = 1;
      this.manualPageMax = 5;
      for(i=this.manualPageMin;i<=this.manualPageMax;i++) {
        this.countPage.push(i);
      }
    } else {
      this.manualPageMin = 1;
      this.manualPageMax = this.totalPage;
      for(i=this.manualPageMin;i<=this.manualPageMax;i++) {
        this.countPage.push(i);
      }
    }
    this.gotoPage(firstPage);
  }

  goNextPage() {
    var currentPage = parseInt(this.manualPage.toString());
    var nextPage = 1;
    if (currentPage >= parseInt(this.totalPage.toString())) {
      nextPage = parseInt(this.totalPage.toString());
    } else {
      nextPage = parseInt(this.manualPage.toString()) + 1;
    }
    this.showPrevious = true;
    if (nextPage > this.manualPageMax) {
      //end of the total page then disable next page button
      if (nextPage >= this.totalPage) {
        this.showNext= false;
      }
      //prepare data for next page
      this.countPage = [];
      var i:number;
      if (this.totalPage > this.manualPageMax) {
        //not end of total page
        this.manualPageMin = nextPage;
        if (this.totalPage < (nextPage + 4)) {
          this.manualPageMax = this.totalPage;
        } else {
          this.manualPageMax = nextPage + 4;
        }
        for(i=this.manualPageMin;i<=this.manualPageMax;i++) {
          this.countPage.push(i);
        }
      } else {
        //already end of total page
        this.manualPageMin = nextPage;
        this.manualPageMax = this.totalPage;
        for(i=this.manualPageMin;i<=this.manualPageMax;i++) {
          this.countPage.push(i);
        }
      }
    } else {
      if (nextPage >= this.totalPage) {
        this.showNext= false;
      } else {
        this.showNext= true;
      }
    }
    this.gotoPage(nextPage);
  }

  goLastPage() {
    this.showNext = false;
    this.showPrevious = true;
    var lastPage = parseInt(this.totalPage.toString());
    this.countPage = [];
    var i:number; 
    if (this.totalPage > 5) {
      this.manualPageMax = this.totalPage;
      var myTempVar = this.totalPage%5;
      if (myTempVar == 0) {
        this.manualPageMin = this.totalPage - 4;
      } else {
        this.manualPageMin = this.totalPage - myTempVar + 1;
      }
      for(i=this.manualPageMin;i<=this.manualPageMax;i++) {
        this.countPage.push(i);
      }
    } else {
      this.manualPageMin = 1;
      this.manualPageMax = this.totalPage;
      for(i=this.manualPageMin;i<=this.manualPageMax;i++) {
        this.countPage.push(i);
      }
    }
    this.gotoPage(lastPage);
  }

}
