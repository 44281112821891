<section>
    <div class="rele">
        <div class="up_title another_up" (click)="isCategory()">
            <i *ngIf="isMore" class="mdui-icon material-icons expend_icon">expand_more</i>
            <i *ngIf="!isMore" class="mdui-icon material-icons expend_icon">chevron_right</i>
            Category & Location
        </div>
        <div class="tab_content isCategory">
            <div class="tab_line">
                <div class="row">
                    <div class="col-6 col-lg-3">
                        <app-single-select [inputData]="recordFormData.PrimaryCountry" [selectionValue]="recordDorpdownData.getCountrys" (change)="setCountry()"></app-single-select>
                    </div>                     
                    <div class="col-6 col-lg-3">
                        <app-input [inputData]="recordFormData.PrimaryGU"></app-input>
                    </div>
                    <div class="col-6 col-lg-3">
                        <app-input [inputData]="recordFormData.PrimaryRegion"></app-input>
                    </div>
                    <div class="col-6 col-lg-3" *ngIf="blockDis">
                        <app-MutiSelect *ngIf="isCountryPop" [isMulShow]="isMulShow" [inputData]="recordFormData.SecondCountry" [selectionValue]="recordDorpdownData.getSecondCountry" [primaryVal]="recordFormData.PrimaryCountry" (outputData)="getSecondCountry($event)"></app-MutiSelect>
                        <button *ngIf="!isCountryPop" class="submit_btn fl add_btn" [ngClass]="{'btnDisable': isBtnDisable}" (click)="addCountryBtn()" [tooltip]="buttonTitle" (mouseenter)="mouseenter()">
                            <i class="mdui-icon material-icons sub_btn">add</i>
                            <span class="btn_txt">Secondary Country/Location</span>
                        </button>
                    </div>
                </div>
                <div class="row mard_top_10">
                    <div class="col-6 col-lg-3">
                        <app-single-select [inputData]="recordFormData.KeyCategory" [selectionValue]="recordDorpdownData.getKeyCategorys" (change)="setCategory()"></app-single-select>
                    </div>                    
                    <div class="col-6 col-lg-3">
                        <app-single-select [inputData]="recordFormData.CategoryVal" [selectionValue]="recordDorpdownData.CategoryOption" (change)="setSubCategory()"></app-single-select>
                    </div>
                    <div class="col-6 col-lg-3">
                        <app-single-select [inputData]="recordFormData.SubCategory" [selectionValue]="recordDorpdownData.SubcategoryOption"></app-single-select>
                    </div>
                    <div class="col-6 col-lg-3">
                        <multi-select-button #secondaryKeyCategorySelect [inputData]="recordFormData.SecondaryKeyCategory" 
                            [selectOptions]="secondaryKeyCategoryOptions"
                            [excludes]="[recordFormData.KeyCategory.value]"
                            [btnDisabled]="!recordFormData.KeyCategory.value"
                            labelName="Secondary Key Category" 
                            resetTooltip="Remove Secondary Key Categor(ies)" 
                            btnTooltip="You may select multiple secondary key categories"
                            placeholder="Please Select"></multi-select-button>
                    </div>
                    <div class="col-6 col-lg-3">
                        <app-single-select *ngIf="recordFormData.License.isShow || (recordFormData.KeyCategory.value === 'Technology' && recordFormData.CategoryVal.value === 'IT Software')" [inputData]="recordFormData.License" [selectionValue]="recordDorpdownData.getLicenses"></app-single-select>
                    </div>
                    <div class="col-6 col-lg-3">
                        <app-single-select *ngIf="recordFormData.LicenseType.isShow || (recordFormData.KeyCategory.value === 'Technology' && recordFormData.CategoryVal.value === 'IT Software')" [inputData]="recordFormData.LicenseType" [selectionValue]="recordDorpdownData.getLicTypes"></app-single-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>