import { ReturnMessage } from 'app/common/enum/type.enum';
import { Factory } from 'app/app.factory';
import { Injectable } from "@angular/core";
import { AuthService } from 'app/common/services/UserAuth/AuthService';
import { AjaxService } from "app/common/services/Ajax/ajax.services";
import { ExpectionInfo } from 'app/common/enum/warning.enum';

@Injectable()
export class DeletionService {

    private service: any;
    public ExpectionErrMsgPopup: any = [];

    constructor (
        private auth: AuthService,
        private factory: Factory,
        private ajaxservice: AjaxService,
    ) {}

    /**
     * request deletion
     * @param id record uri
     * @param userType user type: Admin | Standard | DocumentOwner
     * @param reasons reason list
     */
    public requestDeletion (id: string, userType: string, reasons?: any): Promise<any> {
        this.service = this.factory.getService();
        const user = this.auth.getUserInforFn();
        const requestParams = {
            RecordUri: id,
            User: user,
            RecordFields: reasons,
            UserType: userType,
        };
        console.log('request deletion params: ', requestParams);
        return new Promise<any>((resolve, reject) => {
            this.service.DeleteRecords(requestParams).then(res => {
                try {
                    if (!res) {
                        throw (new Error('http request: delete record is no response'));
                    }
                    
                    if (res.Status === ReturnMessage.Success) {
                        /*operation successed*/
                        resolve('success');
                    } else {
                        /*operation failed*/
                        console.log("submit failed!" + res.Status);
                        throw new Error(ExpectionInfo.ExpectionMsg_MessageContent_Line1);
                    }
                } catch (err) {
                    console.error(err);
                    this.ExpectionErrMsgPopup[0] = ExpectionInfo.ExpectionTitle;
                    this.ExpectionErrMsgPopup[1] = err.message;
                    this.ajaxservice.sendMsg(this.ExpectionErrMsgPopup);
                    reject();
                }
            }, err => {
                console.log("submit failed!");
                this.ExpectionErrMsgPopup[0] = ExpectionInfo.ExpectionTitle;
                this.ExpectionErrMsgPopup[1] = 'submit failed!';
                this.ajaxservice.sendMsg(this.ExpectionErrMsgPopup);
                reject();
            });
        });
    }
}