import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-radioYN',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.css'],
  inputs: [
    'inputData',
    'inputName',
  ],
})
export class RadioYNComponent implements OnInit {
  public ToolTip: any;
  public newValue: boolean;
  public inputData: any;
  public radioValue :any;
  public inputName: string;
  public InfoShow: boolean;
  public information: any;
  public infoArrow: any='top';
  constructor() { }

  ngOnInit() {
    if(this.inputData.information!="" && this.inputData.information!=undefined){
      this.InfoShow=true;
      this.information=this.inputData.information;
      if(this.inputData.infoArrow!="" && this.inputData.infoArrow!=undefined){
        this.infoArrow=this.inputData.infoArrow;
      }
    }else{
      this.InfoShow=false;
      this.information="";
    }
    if(this.inputData.toolTip == true && this.inputData.selectTip == true){
      this.newValue = true;
      this.ToolTip = this.inputData.oldValue;
    }else{
      this.ToolTip = "";
    }
  }
  onChanged(event:any){
    this.radioValue=event;
      if(this.radioValue!==""){
           this.inputData.isValidation=true;
        }
  }
}
