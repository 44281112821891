import { Component,EventEmitter } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'Information',
    templateUrl: './Information.component.html',
    styleUrls: ['./Information.component.css'],
    inputs: [
        'inputsData',
      ],
      outputs: [
        'outputFn',
      ],
})
export class InformationComponent {
    public inputsData: any;
    public outputFn = new EventEmitter<any>();
    public isMore: boolean = false;
    public isAdditionalShow: boolean = false;
    constructor(public changeDetectorRef:ChangeDetectorRef) { }
    ngOnInit() {
      $(".isAdditional").slideUp();
    }
    

    isAdditional() {
        if(this.isMore == false){
            this.isMore = true;
            this.isAdditionalShow=true;
        }else{
            this.isMore = false;
            this.isAdditionalShow=false;
        }
        $(".isAdditional").slideToggle();
    }


  AdditionalInformation_onChanged(e: any){
    //let valedData = this.inputsData.formFields.AdditionalInformation.value.replace(/[\u4E00-\u9FA5]/g,'');
    //this.inputsData.formFields.AdditionalInformation.value = valedData;
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
    if(this.inputsData.formFields.AdditionalInformation.value !== "") {
        this.inputsData.formFields.AdditionalInformation.isValidation=true;       
    }    
  }

  SecondaryAdditionalInformation_onChanged(e: any){
    //let valedData = this.inputsData.formFields.SecondaryAdditionalInformation.value.replace(/[\u4E00-\u9FA5]/g,'');
    //this.inputsData.formFields.SecondaryAdditionalInformation.value = valedData;
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
    if(this.inputsData.formFields.SecondaryAdditionalInformation.value !== "") {
        this.inputsData.formFields.SecondaryAdditionalInformation.isValidation=true;       
    }    
  }

  TerminationTerms_onChanged(e: any){
    //let valedData = this.inputsData.formFields.TerminationTerms.value.replace(/[\u4E00-\u9FA5]/g,'');
    //this.inputsData.formFields.TerminationTerms.value = valedData;
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
    if(this.inputsData.formFields.TerminationTerms.value !== "") {
        this.inputsData.formFields.TerminationTerms.isValidation=true;       
    }    
  }
}
