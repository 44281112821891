<div class="mask"></div>
<div class="search_headbox">
    <div class="titlBox">
        <p class="team_title fl">Secondary Supplier(s)</p>
        <i (click)="closeScndSupplierSearch()" class="mdui-icon material-icons close_icon fr">clear</i>
    </div>
    <div class="tab_content">
        <div class="tab_line" *ngIf="isShowSupplierID()">
            <div class="wid_30 v_mid">
                <input id="sec_supplier_not_in_sys" disabled class="v_mid dsNone" type="checkbox"
                    [checked]="checkedSupplierID('Awaiting SAP ID')" /><label class="yet disabled"
                    for="sec_supplier_not_in_sys"> Supplier not yet in System </label>
            </div>
            <div class="wid_30 v_mid ml_4">
                <input id="sec_supplier_no_sap_id" disabled class="v_mid dsNone" type="checkbox"
                    [checked]="checkedSupplierID('Not required')" /><label class="yet disabled"
                    for="sec_supplier_no_sap_id"> No SAP ID Required </label>
            </div>
        </div>
        <div class="tab_line inptBox">
            <div class="wid_25">
                <app-single-select [inputData]="searchFormData.PrimaryCountry" (change)="searchBtnValidate()"
                    [selectionValue]="recordCountry"></app-single-select>
            </div>
            <div class="wid_37 mod_4">
                <app-input [inputData]="searchFormData.SupplierName" (change)="searchBtnValidate()"
                    (keyup)="searchBtnValidate()"></app-input>
            </div>
            <div class="wid_25 mod_4">
                <!-- <app-single-select [inputData]="searchFormData.CompanyCode" [selectionValue]="companyCode"></app-single-select> -->
                <app-input *ngIf="!isShowSupplierID()" [inputData]="searchFormData.CompanyCode"
                    (change)="searchBtnValidate()"></app-input>
                <ng-container *ngIf="isShowSupplierID()">
                    <label for="sec_supplier_company_code"><span class="red">* </span>Company Code</label>
                    <div #companyCode style="float: none;">
                        <div class="caret" #caret (click)="openDropdown()">
                            <div class="form-control" [ngClass]="{disabled: companyCodes.length <= 0}">
                                <input id="sec_supplier_company_code" style="display: none;"
                                    [value]="showSelectedCodes()" />
                                <div class="nowrap" [tooltip]="showSelectedCodes()">{{showSelectedCodes()}}</div>
                                <i class="mdui-icon material-icons arr_btn">expand_more</i>
                            </div>
                        </div>
                        <ul class="filter-dropdown-menu" role="menu" [ngClass]="{'hide': !isOpen()}">
                            <li class="dropdown-warp" role="button">
                                <div class="filter-dropdown-item">
                                    <i class="mdui-icon material-icons expend_icon i-left">search</i>
                                    <label for="sec_supplier_company_code_search" style="display: none;">Type and
                                        Search</label>
                                    <input id="sec_supplier_company_code_search" type="text" [(ngModel)]="filterText"
                                        class="form-control input-control-left" placeholder="Type and Search">
                                </div>
                            </li>
                            <li class="dropdown-warp" role="button" #selectAll>
                                <div class="filter-dropdown-item">
                                    <input id="selectAll" class="multi_checkbox" [disabled]="filterText !== ''"
                                        [ngClass]="{'checked': selectAll.checked}" type="checkbox"
                                        [(ngModel)]="selectAll.checked" />
                                    <label for="selectAll" role="button"
                                        (click)="selectAllActive($event, selectAll)">Select All</label>
                                    <label role="button" style="float: right;"
                                        (click)="restAllActive($event, selectAll)">Reset</label>
                                </div>
                            </li>
                            <li class="divider dropdown-divider"></li>
                            <ul class="scroller" [ngClass]="{'overflow-y-scroll': !hasFilterValues(this)}">
                                <ng-container
                                    *ngFor="let companyCode of companyCodes | filterArray: {name: filterText}">
                                    <li class="dropdown-warp" role="button"
                                        (click)="itemActive($event, companyCode, selectAll)">
                                        <div class="filter-dropdown-item">
                                            <input id="{{companyCode.name}}" class="multi_checkbox"
                                                [ngClass]="{'checked': companyCode.checked}" type="checkbox"
                                                [(ngModel)]="companyCode.checked" />
                                            <label for="{{companyCode.name}}" role="button">{{companyCode.name}}</label>
                                        </div>
                                    </li>
                                </ng-container>
                            </ul>
                        </ul>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="tab_line rele ">
        <span *ngIf="!isShowSupplierID()" class="errorMsg" [hidden]="isSecondarySupplierValid">*You are only allowed to
            select 50 secondary suppliers and company codes</span>
        <button *ngIf="!isShowSupplierID()" type="submit" class="submit_btn fr select_btn"
            (click)="searchScndSupplier()" [disabled]="isSearchBtnDisabled||isLoadingResults">
            <i class="mdui-icon material-icons sub_btn">search</i>
            <span class="btn_txt">Search</span>
        </button>
        <div style="float:none;padding:0px;" class="tab_line rele" *ngIf="isShowSupplierID()">
            <span class="errorMsg" [hidden]="isSecondarySupplierValid">*You
                are only allowed to select 50 secondary suppliers and company codes</span>
            <button (click)="selectOk()" type="submit" class="submit_btn fr ok_btn" [disabled]="!isDisabled()">
                <i class="mdui-icon material-icons sub_btn">check</i>
                <span class="btn_txt">OK</span>
            </button>
            <button type="submit" class="submit_btn fr cancle_btn" (click)="closeScndSupplierSearch()">
                <i class="mdui-icon material-icons sub_btn">close</i>
                <span class="btn_txt">Cancel</span>
            </button>
        </div>
    </div>
    <div [hidden]="!(isResultShow && isTableShow)">
        <div class="table-loading-shade" *ngIf="isLoadingResults">
            <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
        </div>
        <div class="search_context">
            <div class="resultTable">
                <mat-table class="tab" #table [dataSource]="dataSource" matSort matSortActive="SupplierName"
                    matSortDisableClear matSortDirection="asc" [matSortActive]="sort.active" matSortDisableClear
                    [matSortDirection]="sort.direction">

                    <ng-container matColumnDef="CompanyDescription">
                        <mat-header-cell class="tab_txt" *matHeaderCellDef mat-sort-header class="TableHeader"
                            tooltip="Company Description"> Company Description </mat-header-cell>
                        <mat-cell [ngClass]="{'selectedLineclass': RecordResult.IsSelected,'mat-cell': RecordResult.IsSelected,'notselectedLineclass': !RecordResult.IsSelected}" *matCellDef="let RecordResult ;let row; let i = index" [tooltip]="RecordResult.CompanyDescription"> {{RecordResult.CompanyDescription}}</mat-cell> </ng-container>
                    <ng-container matColumnDef="CompanyCode">
                        <mat-header-cell class="tab_txt" *matHeaderCellDef mat-sort-header class="TableHeader"
                            tooltip="Company Code"> Company Code </mat-header-cell>
                        <mat-cell [ngClass]="{'selectedLineclass': RecordResult.IsSelected, 'mat-cell': RecordResult.IsSelected,'notselectedLineclass': !RecordResult.IsSelected}" *matCellDef="let RecordResult ;let row; let i = index" [tooltip]="RecordResult.CompanyCode"> {{RecordResult.CompanyCode}} </mat-cell> </ng-container>
                    <ng-container matColumnDef="SupplierID">
                        <mat-header-cell class="tab_txt" *matHeaderCellDef mat-sort-header class="TableHeader"
                            tooltip="Supplier SAP ID"> Supplier SAP ID </mat-header-cell>
                        <mat-cell [ngClass]="{'selectedLineclass': RecordResult.IsSelected,'mat-cell': RecordResult.IsSelected,'notselectedLineclass': !RecordResult.IsSelected}" *matCellDef="let RecordResult ;let row; let i = index" [tooltip]="RecordResult.SupplierID"> {{RecordResult.SupplierID}} </mat-cell></ng-container>
                    <ng-container matColumnDef="SupplierName">
                        <mat-header-cell class="tab_txt" *matHeaderCellDef mat-sort-header class="TableHeader"
                            tooltip="Supplier Name"> Supplier Name </mat-header-cell>
                        <mat-cell [ngClass]="{'selectedLineclass': RecordResult.IsSelected,'mat-cell': RecordResult.IsSelected,'notselectedLineclass display-inline-block': !RecordResult.IsSelected}"*matCellDef="let RecordResult ;let row; let i = index" [tooltip]="RecordResult.SupplierName"> {{RecordResult.SupplierName}} </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let RecordResult; let i = index; columns: displayedColumns; " (click)="selectedOne(RecordResult,i)" [id]="'tr'+i" [ngClass]="{'selectedLineclass': RecordResult.IsSelected,'mat-cell': RecordResult.IsSelected,'notselectedLineclass': !RecordResult.IsSelected}" class="trHover"> 
                    </mat-row>

                </mat-table>
                <div *ngIf="!isLoadingResults && resultsLength === 0" class="load_box">
                    No Record Found!
                </div>
                <new-paginator #paginator [length]="resultsLength" [pageSize]="10" (onPageRefresh)="onPageRefresh()">
                </new-paginator>

            </div>
        </div>
    </div>
    <div class="tab_line rele btnBox" *ngIf="!isShowSupplierID()&&isResultShow && isTableShow">
        <button type="submit" style="margin-right:0;" class="submit_btn fr select_btn" (click)="addScndSupplier()"
            [disabled]="!isSltSupplier">
            <i class="mdui-icon material-icons sub_btn">check</i>
            <span class="btn_txt">Select</span>
        </button>
        <button type="submit" class="submit_btn fr cancle_btn" (click)="closeScndSupplierSearch()">
            <i class="mdui-icon material-icons sub_btn">close</i>
            <span class="btn_txt">Cancel</span>
        </button>
    </div>
</div>