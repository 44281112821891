import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit, HostListener, ChangeDetectorRef } from '@angular/core';
// import { elementAt } from 'rxjs/operator/elementAt';
// import { fromEvent } from 'rxjs'
// import {  } from 'ngx-watcher';
// import { element } from '@angular/core/src/render3';
import { MessageService } from 'app/common/services/message/messageService';
@Component({
  selector: 'app-MutiSelect',
  templateUrl: './country-tool.component.html',
  styleUrls: ['./country-tool.component.css'],
  inputs: ['inputData','selectionValue', 'primaryVal','isMulShow','inputId'],
  outputs: ['outputData'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class CountryToolComponent {
  public inputData: any;
  public isMulShow: boolean;
  public selectionValue: any;
  public outputData = new EventEmitter<any>();
  public limitShow: number;
  public buttonWidth: any;
  public countryPop: boolean;
  public countries: { countryName: string, status: boolean }[] = [];
  public searchResults: { countryName: string, status: boolean }[] = [];
  public selectedResults: { countryName: string, status: boolean }[] = [];
  public displaySelected: string = '';
  public searchCode: string = '';
  public isMore: boolean;
  public labelName: any;
  // public secondIsDisable: boolean;
  public selectedWithComma:any;
  public titleSelected: any;
  public isShowModal: boolean;
  public primaryVal: any;
  public selectAllBtn: boolean = false;
  public selectAllBtnDis: boolean = false;
  @ViewChild("options_tab")
  public optionsTab: ElementRef;
  @ViewChild("expand_more")
  public expandMore: ElementRef;

  public inputId: string;

  public offsetWidth: number = 0;

  constructor(
      private el: ElementRef,
      private message: MessageService,
      private changeDetectorRef: ChangeDetectorRef,
  ) { }

  onResize(e){
    this.adaptiveHandler();
  }

  ngOnInit() {
    if (!this.inputId) {
      this.inputId = this.inputData.filedId + '_' + new Date().getTime();
    }
    // this.adaptiveHandler();
    // this.countries.push({ countryName: "Selected All", status: false });
    this.selectionValue.forEach(element => {
      this.countries.push({countryName: element.Value, status: false});
    })
    this.searchResults = this.countries;

    if(this.inputData.value == ''){
        if(this.inputData.isRead){
          this.displaySelected = '';
        }else{
          this.displaySelected = 'Please Select';
        }
        this.titleSelected = this.transTitleSelect([]);
    }else{
        let tempInitArr = this.transValToArr(this.inputData.value);
        for(let i = 0, len = tempInitArr.length; i < len; i++){
          this.countryArrStatus(tempInitArr[i], true);
          this.searchArrStatus(tempInitArr[i], true);
          if(tempInitArr[i] !== this.primaryVal.value){
            this.selectedResults.push({countryName: tempInitArr[i], status: true});
          }
        }
        if(this.selectedResults.length == this.countries.length){
          // this.countryArrStatus(this.countries[0].countryName, true);
          // this.searchArrStatus(this.searchResults[0].countryName, true);
          this.selectAllBtn = true;
        }
        this.selectedWithComma = this.transSelected(this.selectedResults);
        this.displaySelected = this.transTempArray(this.selectedWithComma);
        this.titleSelected = this.transTitleSelect(this.selectedResults);
    }

    // this.secondIsDisable = this.inputData.isRead;
    this.labelName = "Secondary Country/Location";
    this.message.getsecondCountry().subscribe((result) => {
        if(result.hasOwnProperty('secondList')){
            this.countries=[];
            this.selectionValue=[];
            // this.countries.push({ countryName: "Selected All", status: false });
            this.selectAllBtn = false;
            this.selectionValue = result.secondList;
            this.selectionValue.forEach(element => {
              this.countries.push({ countryName: element.Value, status: false });
            });
            this.searchResults = this.countries;
        }
      // this.secondIsDisable = result.isRead;
        this.selectedResults = [];
        this.addStringNone();
        // this.displaySelected = "";
        this.countryPop = false;
        this.countryOuter();
    }, err => {
        console.log("getsecondCountry","error");
    })

    this.message.getBlockDis().subscribe(result => {
        if(result.hasOwnProperty('isBlockDis')){
            if(this.inputData.value == ''){
              this.displaySelected = 'Please Select';
              this.titleSelected = this.transTitleSelect([]);
            }
        }
    })

    this.message.getSecondaryCountryforCancel().subscribe(result => {
      if(result.hasOwnProperty('getSecondaryCountryforCancel') ){
        if(this.inputData.value == ''){
          this.displaySelected = '';
        }
      }
  })
  }

  ngAfterViewChecked() {
    if (!this.inputData.isRead && !this.inputData.IsDisabled && this.expandMore) {
      this.offsetWidth = (<HTMLElement>this.expandMore.nativeElement).offsetWidth;
      this.changeDetectorRef.detectChanges();
    }
  }
  
  closeCountryPop(){
    this.countryPop = false;
  }

  transValToArr(gotValue: string){
    let initArr = gotValue.split(';');
    let newInitArr = [];
    for(let i = 0, len = initArr.length; i < len; i++){
      newInitArr.push(initArr[i].trim());
    }
    return newInitArr;
  }

  toggleCheckboxes(e: any) {
    if (this.selectionValue.length == 0 || this.inputData.isRead == true) {
      this.countryPop = false;
    }else {
      this.countryPop = !this.countryPop;
      if(this.countryPop){
        this.isShowModal = true;
      }
      this.clear();
    }
  }

  deleteLabel(deleteId: string, e: any) {
    this.selectedResults.forEach(element => {
      if (element.countryName == deleteId) {
        this.selectedResults.splice(this.selectedResults.indexOf(element), 1);
        this.isMore = this.setLabelLimit();
      }
    });
    this.countryArrStatus(deleteId, false);
    this.searchArrStatus(deleteId, false);
    e.stopPropagation();
  }

  selectAll() {
    this.displaySelected = "";
    this.selectedWithComma ="";
    this.selectedResults = [];
    if(this.selectAllBtn){
      this.countries.forEach(element => {
        element.status = true;
        this.selectedResults.push(element);
      })
      this.searchResults = this.countries;

    }else{
      this.countries.forEach((element) => {
        element.status = false;
      })
      this.selectedResults = [];
      this.addStringNone();
    }
    this.selectedWithComma = this.transSelected(this.selectedResults);
    this.displaySelected = this.transTempArray(this.selectedWithComma);
    this.titleSelected = this.transTitleSelect(this.selectedResults);
    this.countryOuter();

    //sort
    // var set = new Set(this.selectedResults);
    // this.selectedResults = Array.from(set);
    // this.compareArray(this.selectedResults, 'countryName', true);
  }

  reset() {
    this.displaySelected = "";
    this.selectedWithComma ="";
    this.searchCode = '';
    // this.countryPop = false;
    // this.isShowModal = false;
    this.selectedResults = [];
    // this.isMore = this.setLabelLimit();
    this.searchResults.forEach((element) => {
      element.status = false;
      // this.countryArrStatus(element.countryName, false);
    })
    this.countries.forEach((element) => {
      element.status = false;
    })
    this.searchResults = this.countries;
    this.selectAllBtnDis = false;
    this.selectAllBtn = false;
    //transform selectedResults
    // this.displaySelected = this.transTempArray(this.transSelected(this.selectedResults));
    // outer
    this.titleSelected = this.transTitleSelect(this.selectedResults);
    this.addStringNone();
    this.countryOuter();
  }

  selectNone() {
    this.displaySelected = "";
    this.selectedWithComma ="";
    this.countries.forEach((element) => {
      element.status = false;
      // this.countryArrStatus(element.countryName, false);
    })
    this.selectAllBtn = false;
    this.selectedResults = [];
    this.addStringNone();
  }

  selectOne(param: any, index: number) {
    if (param.target.checked) {
      this.selectedResults.push(this.searchResults[index]);
    } else {
      this.selectedResults.forEach(element => {
        if (element.countryName == param.target.id) {
          this.selectedResults.splice(this.selectedResults.indexOf(element), 1);
        }
      });
    }
    this.countryArrStatus(param.target.id, param.target.checked);
    // this.searchArrStatus(param.target.id, param.target.checked);
    this.controlAllSts();
    // }
    //transform selectedResults
    this.selectedWithComma = this.transSelected(this.selectedResults);
    this.displaySelected = this.transTempArray(this.selectedWithComma);
    this.titleSelected = this.transTitleSelect(this.selectedResults);

    // outer
    this.countryOuter();
  }

  clear() {
    this.searchCode = '';
    this.partSearch('');
  }

  partSearch(param: any) {
    $(<HTMLElement>this.optionsTab.nativeElement).scrollTop(0);
    if (param == '') {
      this.searchResults = this.countries;
      this.selectAllBtnDis = false;
    } else {
      this.searchResults = [];
      this.countries.forEach(element => {
        if (element.countryName.toUpperCase().indexOf(param.toUpperCase()) != -1) {
          this.searchResults.push(element);
        }
      });
      //selectAll button disable
      this.selectAllBtnDis = true;
    }
  }

  countryArrStatus(changedItem: string, changedStatus: boolean) {
    this.countries.map((element) => {
      if (element.countryName === changedItem) {
        element.status = changedStatus;
      }
      return element;
    });
  }

  /**
   * match each item of the search Array for the changedItem in params
   * if matched,change the status
   * @param changedItem
   * @param changedStatus
   * @returns matched items
   */
  searchArrStatus(changedItem: string, changedStatus: boolean) {
    this.searchResults.map((element) => {
      if (element.countryName === changedItem) {
        element.status = changedStatus;
      }
      return element;
    })
  }

  setLabelLimit() {
    this.buttonWidth = this.el.nativeElement.querySelector('#buttonWidth').offsetWidth;
    this.limitShow = Math.floor(this.buttonWidth / 100);
    if (this.buttonWidth > 103) {
      if (this.selectedResults.length > this.limitShow) {
        if (this.limitShow == 1) return false;
        return true;
      } else {
        return false;
      }
    }
    this.limitShow = 1;
    return false;
  }

  /**
   * transform Array 'selectedResults' to  temp Array 'tempArray'
   * in order to asyne with the displayed view data when mouse hover on the button
   * @param selectedResults: {countryName: string, status:boolean}[]
   */
  transSelected(selectedResults: { countryName: string, status: boolean }[]): Array<string> {
    let tempArray = [];
    for (let i = 0; i < selectedResults.length; i++) {
      if (selectedResults.length > 0) {
        if (i == selectedResults.length - 1) {
          tempArray.push(selectedResults[i].countryName);
        } else {
          tempArray.push(selectedResults[i].countryName + ', ');
        }
      } else {
        tempArray = [];
      }
    }
    return tempArray;
  }

  transTitleSelect(selectedResults: { countryName: string, status: boolean }[]): Array<string> {
    let tempArray = [];
    if(selectedResults.length > 0){
      for (let i = 0; i < selectedResults.length; i++) {
        tempArray.push(' '+selectedResults[i].countryName);
      }
    }else{
      tempArray = [];
    }
    return tempArray;
  }

  transTempArray(tempArray: Array<string>): string {
    let dispTemp = ''
    if(tempArray.length > 0){
      tempArray.forEach((element) => {
        dispTemp = dispTemp + element;
      })
      // if(dispTemp.length > 40){
      //   dispTemp = dispTemp.substring(0, 40) + "...";
      // }
    }else{
      dispTemp = 'Please Select';
    }
    return dispTemp;
  }

  addStringNone(){
    if(this.selectedResults.length ==0){
      this.displaySelected = 'Please Select';
      this.titleSelected = this.transTitleSelect([]);
    }
  }

  countryOuter(): void {
    this.outputData.emit(this.selectedResults);
  }

  controlAllSts(){
    var index = 0;
    this.countries.forEach(element => {
      // if(element.countryName !== 'Selected All'){
      if(!element.status) return false;
      index++;
      // }
    })
    if(index == this.countries.length){
      this.selectAllBtn = true;
    }else{
      this.selectAllBtn = false;
    }
  }

  // hidePanel(event) {
  //   let buttonWidth = document.getElementById("buttonWidth");
  //   let multiPop = document.getElementById("multiPop");
  //   if (buttonWidth && multiPop) {
  //     if (!buttonWidth.contains(event.target) && !multiPop.contains(event.target)) {
  //       this.isShowModal = false;
  //       this.countryPop = false;
  //     }
  //   }
  // }

  compareArray(arr, property, sequence) {
    for (var j = 0; j < arr.length - 1; j += 1) {

      for (var i = 0; i < arr.length - 1 - j; i += 1) {

        var before = this.compareString(arr[i][property], arr[i + 1][property]);
        if (before === 1) {
          var temp = arr[i];
          arr[i] = arr[i + 1];
          arr[i + 1] = temp;
        }
      }
    }
    if (sequence) {
      arr.reverse();
    }
    return arr;
  }

  compareString(str1, str2) {
    var str1Length = str1.length;
    var str2Length = str2.length;
    var StringLength = Math.min(str1Length, str2Length);
    var StringCode;
    var code = 0;

    for (var i = 0; i < StringLength; i += 1) {
      StringCode = this.compareCharacter(str1[i], str2[i]);
      if (StringCode === -1) {
        code = -1;
        break;
      } else if (StringCode === 1) {
        code = 1;
        break;
      } else {
        code = 0;
      }
    }
    if (code === 0 && str1Length !== str2Length) {
      code = str1Length - str2Length <= -1 ? 1 : -1;
    }
    return code;
  }

  compareCharacter(Letter1, Letter2) {
    var Letter1TypeNum = this.DetermineType(Letter1);
    var Letter2TypeNum = this.DetermineType(Letter2);
    var before;
    var after;
    var code;

    if (Letter1TypeNum !== Letter2TypeNum) {
      code = Letter1TypeNum < Letter2TypeNum;
      return code ? 1 : -1
    } else {

      if (Letter1TypeNum === 0) {
        return code = 0;
      }

      if (Letter1TypeNum === 1) {
        before = Letter1;
        after = Letter2;
      }

      if (Letter1TypeNum === 2) {
        before = Letter1.toLocaleLowerCase().charCodeAt(0);
        after = Letter2.toLocaleLowerCase().charCodeAt(0);
      }
    }
    if (before > after) {
      code = -1;
    } else if (before === after) {
      code = 0;
    } else {
      code = 1;
    }

    return code;
  }

  DetermineType(Letter) {

    var isNum = !isNaN(Letter);
    var isLetter = /[_a-zA-Z]/.test(Letter);
    var isChinese = !/[^\u4E00-\u9FA5]/.test(Letter);
    var typeNum;
    if (isNum) {
      typeNum = 1;
    } else if (isLetter) {
      typeNum = 2;
    } else if (isChinese) {
      typeNum = 3;
    } else {
      typeNum = 0;
    }
    return typeNum;
  }

  adaptiveHandler(){
    let currentWidth = document.getElementById('buttonWidth').offsetWidth;
    // document.getElementById('dispSelect').style.width = (Number(currentWidth) * 0.9) + 'px';
  }

}


