import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { HPEStaticService } from "../../../../common/services/staticService/HPEStaticService";

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
  inputs: [
    'inputData',
    'enableChange',
    'displayValue',
    'displayLabel',
    'inputId',
    'isAgreementValue',
  ],
  outputs: [
    'outputFn',
    'change'
  ]

})
export class InputComponent implements OnInit {

  public inputData: any;
  public enableChange: any = false;
  public outputFn = new EventEmitter<any>();
  public change = new EventEmitter<any>();
  public errorMsg: string = "";
  public ToolTip: any;
  public newValue: boolean;
  public InfoShow: boolean;
  public information: any;
  public displayValue: { value: string; oldValue?: string};
  public displayLabel: string;
  public isAgreementValue: boolean;

  public inputId: string;

  @ViewChild('input_ref')
  private inputRef: ElementRef;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    ) { }

  ngOnInit() {
    if (!this.inputId) {
      this.inputId = this.inputData.filedId + '_' + new Date().getTime();
    }

    if(this.inputData.information!="" && this.inputData.information!=undefined){
      this.InfoShow=true;
      this.information=this.inputData.information;
    }else{
      this.InfoShow=false;
      this.information="";
    }
    if(this.inputData.toolTip == true && this.inputData.selectTip == true){
      this.newValue = true;
      this.ToolTip = this.inputData.oldValue;
    }else{
      this.newValue = false;
      this.ToolTip = '';
    }
    // var toolTipArr = [1,8,43,26,13,15,6,39,29,24,23,3,45,63,33,32];
  }

  onChanged(e: any) {
    // let valedData = this.inputData.value.replace(/[\u4E00-\u9FA5]/g,'').trim();
    // this.inputData.value = valedData;
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
    if (this.inputData.value !== "") {
      var regu = "^[ ]+$";
      var re = new RegExp(regu);
      //if user only type 'space' return true
      if(!re.test(this.inputData.value)) {
        this.inputData.isValidation = true;
      } 
    }

    if (true === this.enableChange) {
      this.change.emit();

    }

    //return e.replace(/[\u4E00-\u9FA5]/g, '');
    return e;
  }

  ngDoCheck () {
    if (this.displayValue) {
      if (this.inputRef
          && this.inputRef.nativeElement 
          && this.inputRef.nativeElement.value !== this.displayValue.value) {
        this.inputRef.nativeElement.value = this.displayValue.value;
      }
      if (this.ToolTip !== this.displayValue.oldValue) {
        this.ToolTip = this.displayValue.oldValue;
      }
    }
  }

}
