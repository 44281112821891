<section>
    <div *ngIf="!noLabel">
        <label [for]="inputData.filedId"><span *ngIf="inputData.IsRequired" class="red">*</span> {{inputData.labelName}}
            <i *ngIf="InfoShow && !inputData.isRead" class="mdui-icon material-icons nosapInfo" [tooltip]="information"
                [tooltip-class]="'tooltip-class-peoplepicker'">info</i>
        </label>
    </div>
    <div *ngIf="!inputData.isRead" [clickBlank]="ClearNoValidData.bind(this)">
        <input [id]="inputData.filedId" [ngClass]="{'nowrap':inputData.IsDisabled,'error':!inputData.isValidation}"
            onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')" #expand_more type="text" [(ngModel)]="inputData.value"
            (ngModelChange)="itemActive(inputData.value)" class="form-control zUp"
            [disabled]="inputData.IsDisabled"
            placeholder="Field must be completed" AUTOCOMPLETE="off" (focus)="inputFocus()" (blur)="inputBlur()" />
        <div class="PeopleBoxContainer" *ngIf="isShowpeopleList" [ngStyle]="{'width.px': expand_more.offsetWidth}">
            <div *ngIf="selectionValue.length === 0" class="left_pad">Enterprise ID does not exist.</div>
            <div *ngIf="selectionValue.length > 0">
                <div *ngFor="let svalue of selectionValue" class="Peopleline"
                    (click)="SelectedPeople(svalue.LocationFormattedName.Value)">
                    {{svalue.LocationFormattedName.Value}}
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="inputData.isRead">
        <span class="span_is_read nowrap">
            <input [id]="inputData.filedId" style="display: none;" />
            <span class="nowrap" [ngClass]="{'tip_span':newValue}"
                [tooltip]="inputData.value ? inputData.value : ''">{{inputData.value}}</span>
            <i *ngIf="newValue && inputData.isRead" class="mdui-icon material-icons tooltip_icon"
                [tooltip]="ToolTip ? ToolTip : ''">info</i>
        </span>
    </div>
</section>