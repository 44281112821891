export class DataItem {

    col: string;

    /**
     * unique
     */
    alias: string;

    isLike: boolean;

    value: any;

    operateChar: ':' | '>=' | '>' | '<=' | '<';

    relation: string;

    addeds: any = [];

    removeds: any = [];

    constructor(params?: any) {
        if(params != null){
          Object.assign(this, params);
        }
    }

    convertString(): string {
      let filterString = `and ${this.col} ${this.operateChar}`;
      if(Object.prototype.toString.call(this.value) == '[object Array]'){
        if(this.isLike){
          filterString = 'and (';
          filterString += this.value
            .map((m) => `${this.col} ${this.operateChar} "*${encodeURIComponent(m)}*"`)
            .join(' or ');
            filterString += ' ) ';
        }else{
          filterString += `"${this.value.map((m) => encodeURIComponent(m)).join('","')}"`;
        }
      }else if(Object.prototype.toString.call(this.value) == '[object String]'){
        this.value = this.value.replace(/"/g,'\\"');
        if(this.isLike){
          filterString += `"*${encodeURIComponent(this.value)}*"`;
        }else {
          filterString += `"${encodeURIComponent(this.value)}"`;
        }
      }
        return filterString;
    }

    setValue(val: any): DataItem {
      this.value = val;
      return this;
    }

    setAlias(alias: string): DataItem {
      this.alias = alias;
      return this;
    }

    setCol(col: string): DataItem {
      this.col = col;
      return this;
    }

    setIsLike(isLike: boolean = false): DataItem {
      this.isLike = isLike;
      return this;
    }

    setOperateChar(operateChar: any = ':'): DataItem {
      this.operateChar = operateChar;
      return this;
    }
}


export interface CallBack {
    key: string;
    source: any;
    hasCondition: any;
    callback: any;
    removeItems: any;
}