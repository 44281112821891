<section>
    <div>
        <span class="radio label"><span *ngIf="inputData.IsRequired" class="red">*</span> {{inputData.labelName}}?</span>
    </div>
    <div>
        <fieldset>
            <legend style="display: none;">{{inputData.labelName}}?</legend>
        <div *ngIf="!inputData.isRead" class="wid-50 choice parent-choice">
            <label class="radio">Parent Record
                <input type="radio" [name]="inputData.searchClauseName" [disabled]="inputData.IsDisabled"
                [ngClass]="{'error':!inputData.isValidation, checked: isSelected('Parent Record')}" value="Parent Record" (click)="handleClick($event, inputData)"/>
                <i></i>
            </label>
        </div>
        <div *ngIf="!inputData.isRead" class="wid-50 choice wid-50-ext">
            <label class="radio" style="white-space: nowrap;">Supporting Record
                <input type="radio" [name]="inputData.searchClauseName" [disabled]="inputData.IsDisabled"
                [ngClass]="{'error':!inputData.isValidation, checked: isSelected('Supporting Record')}" value="Supporting Record" (click)="handleClick($event, inputData)"/>
                <i></i>
            </label>
        </div>
        <div [ngClass]="{'tip_span':newValue}" *ngIf="inputData.isRead" class="parent-choice">
            <label class="label labels">
                <input class="read_inp checked" type="radio" [name]="inputData.searchClauseName"
                [value]="inputData.value" />
                <span *ngIf="inputData.value && inputData.value.toString().trim() !== ''"></span>
                <span class="radio_Read" [ngClass]="{'tip_radio_Read':newValue}" [tooltip] = "ParentorSupportingRecord ? ParentorSupportingRecord : ''">{{ParentorSupportingRecord}}</span>
                <i *ngIf="newValue" class="mdui-icon material-icons tooltip_icon" [tooltip] = "ToolTip ? ToolTip : ''">info</i>
            </label>
        </div>
        </fieldset>
    </div>
</section>
