<section>
    <div [ngStyle]="inputData.labelDivStyles">
        <label *ngIf="!isCustLabel" [for]="inputId"><span *ngIf="inputData.IsRequired" class="red">*</span> <span [ngStyle]="inputData.labelName ? {} : {display: 'none'}">{{inputData.labelName ? inputData.labelName : 'empty'}}</span></label>
        <i *ngIf="InfoShow && !inputData.isRead"
                class="mdui-icon material-icons nosapInfo"
                [tooltip]="inputData.information"
                [tooltip-class]="'tooltip-class-radio'">info</i>
    </div>
    <div #expand_more [ngStyle]="getDropdownDivStyles(inputData.isRead)" class="dropdown" [clickBlank]="closeDiv.bind(this)">
        <a *ngIf="inputData.tooltip" [tooltip]="inputData.tooltip" [ngStyle]="inputData.aStyles" (click)="onParentClicked()" [ngClass]="{'a-disabled': inputData.IsDisabled && inputData.isValidation, 'a-input-enabled-show': !inputData.IsDisabled && inputData.isValidation && show, 'a-input-enabled': !inputData.IsDisabled && inputData.isValidation && !show, 'error':!inputData.isValidation}" class="btn a-input dropdown-toggle" role="button" id="dropdownMenuLink" aria-haspopup="true" aria-expanded="false">
            <div class="nowrap adiv dropdown_padding" [ngStyle]="inputData.aDivStyles">{{getSelectedValue() ? getSelectedValue() : (!inputData.hidePleaseSelect ? (!inputData.emptyString ? 'Please Select' : (inputData.emptyString === 'empty' ? '' : inputData.emptyString)) : '')}}</div>
            <i [ngStyle]="inputData.iStyles" class="input-icon mdui-icon material-icons" [ngClass]="{'input-icon-color': inputData.IsDisabled, 'input-error-icon-color': !inputData.IsDisabled && !inputData.isValidation}">expand_more</i>
        </a>
        <a *ngIf="!inputData.tooltip" [ngStyle]="inputData.aStyles" (click)="onParentClicked()" [ngClass]="{'a-disabled': inputData.IsDisabled && inputData.isValidation, 'a-input-enabled-show': !inputData.IsDisabled && inputData.isValidation && show, 'a-input-enabled': !inputData.IsDisabled && inputData.isValidation && !show, 'error':!inputData.isValidation}" class="btn a-input dropdown-toggle" role="button" id="dropdownMenuLink" aria-haspopup="true" aria-expanded="false">
            <div class="nowrap adiv dropdown_padding" [ngStyle]="inputData.aDivStyles">{{getSelectedValue() ? getSelectedValue() : (!inputData.hidePleaseSelect ? (!inputData.emptyString ? 'Please Select' : (inputData.emptyString === 'empty' ? '' : inputData.emptyString)) : '')}}</div>
            <i [ngStyle]="inputData.iStyles" class="input-icon mdui-icon material-icons" [ngClass]="{'input-icon-color': inputData.IsDisabled, 'input-error-icon-color': !inputData.IsDisabled && !inputData.isValidation}">expand_more</i>
        </a>
        <input [id]="inputId" type="text" style="display: none;" [value]="inputData.value" />
        <app-single-select-input [value]="inputData.value" (valueChange)="onClicked($event);"></app-single-select-input>
        <div [ngStyle]="{'min-width': inputData.searchboxpDivStyles ? (inputData.searchboxpDivStyles['min-width'] ? inputData.searchboxpDivStyles['min-width'] : '10.2rem'): '10.2rem', 'width.px': offsetWidth}" class="search_box_parent" [hidden]="inputData.IsDisabled || !show">
            <div class="search-box" *ngIf="!inputData.isSearchBoxShow">
              <div [ngStyle]="inputData.searchboxDivStyles" class="search_box">
                <label [for]="inputId + '_search'" style="display: none;">Type and Search</label>
                <input [id]="inputId + '_search'" [ngStyle]="inputData.inputStyles" class="form-control" type="text" placeholder="Type and Search" [(ngModel)]="searchCode" (change)="$event.stopPropagation();" (keyup)="onKeyup();"/>
                <i [ngStyle]="inputData.iDeleteStyles" [hidden]="!searchCode" class="delete_icon mdui-icon material-icons" (click)="clearSearchCode()">cancel</i>
              </div>
              <div *ngIf="!inputData.showSelected" class="title">{{inputData.value ? '1 Selected' : 'Please Select'}}
                  <div class="reset_btn" (click)="onClicked('', 'inner');">
                      <i class="mdui-icon material-icons clear_i">clear</i>
                      Clear
                  </div>
              </div>
            </div>
            <div #dropdown_menu_single_select [ngStyle]="inputData.dropdownmenuDivStyles" class="dropdown-menu-single-select" aria-labelledby="dropdownMenuLink">
                <ng-container *ngFor="let svalue of selectionValue;">
                    <div *ngIf="!(searchCode && getString(svalue.Value).toUpperCase().indexOf(searchCode.toUpperCase()) === -1)" [ngStyle]="inputData.dropitemDivStyles" class="dropdown-item" (click)="show = false;onClicked(svalue.Key, 'inner');">
                        <i [ngStyle]="inputData.iSelectedStyles" [hidden]="inputData.value !== svalue.Key" class="check-icon mdui-icon material-icons">done</i>
                        {{svalue.Value}}
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div *ngIf="inputData.isRead">
        <span class="span_is_read nowrap">
            <span class="nowrap" [ngClass]="{'tip_span':newValue}" [tooltip] = "inputData.value ? inputData.value : ''">{{inputData.value}}</span>
            <i *ngIf="newValue" class="mdui-icon material-icons tooltip_icon" [tooltip] = "ToolTip ? ToolTip : ''">info</i>
        </span>
    </div>
</section>
