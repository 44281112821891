<div dropdown container="body" [placement]="placement">
    <div dropdownToggle class="caret">
        <i class="mdui-icon material-icons expend_icon">expand_more</i>
    </div>
    <ul *dropdownMenu class="filter-dropdown-menu" role="menu">
        <li class="dropdown-warp" [ngClass]="{'clean': !hasCondition()}" role="button" (click)="cleanFilter($event)">
            <div class="filter-dropdown-item">Clear Filters</div>
        </li>
        <li class="dropdown-warp" role="button" (click)="stopPropagation($event)">
            <div class="filter-dropdown-item">
                <div>From</div>
                <div class="input-group">
                    <label style="display: none;" [for]="inputId + '_from'">From</label>
                    <input [id]="inputId + '_from'" type="text" class="form-control" #dp1="bsDatepicker" bsDatepicker [outsideClick]="false" placeholder="DD-Mon-YYYY" [(ngModel)]="recordFormData" (ngModelChange)="recordFormDataChange($event)" [maxDate]="recordToData" (click)="dp.hide();" [bsConfig]="bsConfig"
                        onkeyup="this.value=this.value.replace(/^\s+|\s+$/g,'')">
                    <div class="input-group-addon" (click)="dp1.toggle();dp.hide();">
                        <i class="mdui-icon material-icons error_date">date_range</i>
                    </div>
                </div>
            </div>
            <div class="filter-dropdown-item">
                <div>To</div>
                <div class="input-group">
                    <label style="display: none;" [for]="inputId + '_to'">To</label>
                    <input [id]="inputId + '_to'" type="text" class="form-control" #dp="bsDatepicker" bsDatepicker placeholder="DD-Mon-YYYY" [(ngModel)]="recordToData" (ngModelChange)="recordToDataChange($event)" [minDate]="recordFormData" (click)="dp1.hide();" [bsConfig]="bsConfig" onkeyup="this.value=this.value.replace(/^\s+|\s+$/g,'')">
                    <div class="input-group-addon" (click)="dp.toggle();dp1.hide();">
                        <i class="mdui-icon material-icons error_date">date_range</i>
                    </div>
                </div>
            </div>
        </li>
        <li class="divider dropdown-divider" (click)="stopPropagation($event)"></li>
        <li class="dropdown-warp" role="menuitem" (click)="stopPropagation($event)">
            <div class="filter-dropdown-item" style="color: #555;">Quick Filter</div>
        </li>
        <ul class="scroller" style="max-height: 135px;">
            <li class="dropdown-warp" role="button" (click)="thisMonth($event)">
                <div class="filter-dropdown-item">This Month</div>
            </li>
            <li class="dropdown-warp" *ngIf="!showNext" role="button" (click)="lastMonth($event)">
                <div class="filter-dropdown-item">Last Month</div>
            </li>
            <li class="dropdown-warp" *ngIf="showNext" role="button" (click)="nextMonth($event)">
                <div class="filter-dropdown-item">Next Month</div>
            </li>
            <li class="dropdown-warp" role="button" (click)="thisQuarter($event)">
                <div class="filter-dropdown-item">This Quarter</div>
            </li>
            <li class="dropdown-warp" *ngIf="!showNext" role="button" (click)="lastQuarter($event)">
                <div class="filter-dropdown-item">Last Quarter</div>
            </li>
            <li class="dropdown-warp" *ngIf="showNext" role="button" (click)="nextQuarter($event)">
                <div class="filter-dropdown-item">Next Quarter</div>
            </li>
            <li class="dropdown-warp" role="button" (click)="thisYear($event)">
                <div class="filter-dropdown-item">This Year</div>
            </li>
            <li class="dropdown-warp" *ngIf="!showNext" role="button" (click)="lastYear($event)">
                <div class="filter-dropdown-item">Last Year</div>
            </li>
            <li class="dropdown-warp" *ngIf="showNext" role="button" (click)="nextYear($event)">
                <div class="filter-dropdown-item">Next Year</div>
            </li>
        </ul>
    </ul>
</div>