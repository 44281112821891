<section>
    <div>
        <label [for]="inputId"><span *ngIf="inputData.IsRequired" class="red">*</span> {{inputData.labelName}}</label>
    </div>
    <div class="rel">
        <!-- Editable Control -->
        <div *ngIf="!inputData.isRead">
            <div class="wid-92">
                <input [id]="inputId" [disabled]="inputData.IsDisabled" type="text" class="form-control" #dp="bsDatepicker" bsDatepicker placeholder="DD-Mon-YYYY" [(ngModel)]="inputData.value" (ngModelChange)="onChanged($event)" [bsConfig]="bsConfig"
                    [ngClass]="{'error':!inputData.isValidation}"
                    (blur)="dateBlur($event)">
            </div>
            <div class="wid-8">
                <i *ngIf="!inputData.IsDisabled" [ngClass]="{'error_icon':!inputData.isValidation}" class="mdui-icon material-icons range" (click)="dp.toggle()" [attr.aria-expanded]="dp.isOpen">date_range</i>
            </div>
        </div>
        <!-- ReadOnly Control -->
        <div *ngIf="inputData.isRead">{{inputData.value}}</div>
    </div>
</section>