import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-single-select-input',
  template: `<input type="hidden"/>`,
  inputs: [
    'value'
  ],
  outputs: [
    'valueChange'
  ],
})
export class SingleSelectInputComponent implements OnChanges {
 
  public value: any;
  public valueChange: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnChanges() {
    this.valueChange.emit(this.value);  
  }
}
