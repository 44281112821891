<section *ngIf="requestInfo">
    <div class="rele">
        <div class="up_title another_up" (click)="isRequest()">
            <i *ngIf="isMore" class="mdui-icon material-icons expend_icon">expand_more</i>
            <i *ngIf="!isMore" class="mdui-icon material-icons expend_icon">chevron_right</i>
            Request Info
        </div>
        <div class="tab_content isRequest align-tab">
            <div class="tab_line">
                <div class="row">
                    <div class="col-6 col-lg-3">
                        <div>
                            <label><span class="red">*</span>Requestor</label>
                        </div>
                        <div>
                            <span class="tipspan">{{Requestor}}</span>
                        </div>
                    </div>
                    <div class="col-6 col-lg-9">
                        <div>
                            <label><span class="red">*</span>Extension Document</label>
                        </div>
                        <div>
                            <ul>
                                <li *ngFor="let ExtensionDoc of ExtensionDocs" class="doc_li">
                                    <i class="mdui-icon material-icons blue_ico">insert_link</i>
                                    <a class="title" [tooltip]="ExtensionDoc.FileName" (click)="downloadFile(ExtensionDoc)">{{ExtensionDoc.FileName}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>