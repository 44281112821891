import { Component, OnInit, Input,SimpleChanges ,EventEmitter,Output} from '@angular/core';
import { MessageService } from 'app/common/services/message/messageService'
import { Factory } from '../../../../app.factory';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
// import { stringify } from '@angular/compiler/src/util';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
  inputs:['NotifiInput'],
  outputs: ['NotifiOutput','NotifiTime','NotifiDate','NotifiButtonShow']
})
export class NotificationComponent implements OnInit {
  
  private service: any;
  public NotifiInput: boolean;
  @Output() NotifiOutput : EventEmitter<any> = new EventEmitter();
  @Output() NotifiTime =new EventEmitter<any>();
  @Output() NotifiDate = new EventEmitter<any>();
  @Output() NotifiButtonShow = new EventEmitter<any>();
  @Output() NotifiAgo =new EventEmitter<any>();
  @Output() NotifiDisplayTime =new EventEmitter<any>();
  @Output() RedIconShow =new EventEmitter<any>();
  public isShow: boolean=false;
  public isNotificationShow: any;
  public NotificationSubject: any;
  public NotificationBody: any;
  public NotificationDisplayStartTime: any;
  public NotificationDisplayEndTime: any;
  public NotificationScheduledDownTime: any;
  public htmlBody:any;
  public hasNotifiClicked:any;
  public ShowAgain: string="false";

  constructor(private message: MessageService,
    private factory: Factory,
    private sanitizer: DomSanitizer,
    private datePipe: DatePipe) {}

  ngOnInit() {
    this.service = this.factory.getService();
    if(this.hasNotifiClicked!=true){
        this.service.ShowNotification().then(res => {
          this.isNotificationShow = res.IsShow;
          this.NotificationSubject = res.NotificationSubject;
          this.NotificationBody = res.NotificationBody;
          this.NotificationDisplayStartTime = res.DisplayStartTime;
          this.NotificationDisplayEndTime=res.DisplayEndTime;
          this.NotificationScheduledDownTime=res.ScheduledDownTime;

          if(this.NotificationDisplayStartTime!=null&&this.NotificationDisplayStartTime!=""&&this.NotificationDisplayStartTime!=undefined
          && this.NotificationDisplayEndTime!=null&&this.NotificationDisplayEndTime!=""&&this.NotificationDisplayEndTime!=undefined){
            var UTCnow=this.datePipe.transform(new Date(),"MM/dd/yyyy hh:mm:ss aa","UTC");
            var UTCnowTime=new Date(UTCnow).getTime();
            var startTime=new Date(this.NotificationDisplayStartTime).getTime();
            var endTime=new Date(this.NotificationDisplayEndTime).getTime();
            var NotificationComponentLoadAgain=window.localStorage.getItem("NotificationComponentLoadAgain");
            var buttonTime=this.datePipe.transform(this.NotificationScheduledDownTime,"hh:mm aa");
            var buttonDate=this.datePipe.transform(this.NotificationScheduledDownTime,"dd-MMM-yyyy");
            var labelAgoArray=((UTCnowTime-startTime)/(24*60*60*1000)).toString().split(".");
            var labelAgo=labelAgoArray[0];

            var labelDisplayTime=this.datePipe.transform(this.NotificationDisplayStartTime,"dd-MMM-yyyy");
            this.NotifiTime.emit(buttonTime);
            this.NotifiDate.emit(buttonDate);
            this.NotifiAgo.emit(labelAgo);
            this.NotifiDisplayTime.emit(labelDisplayTime);
            if(this.isNotificationShow=="1" && UTCnowTime>=startTime && UTCnowTime<=endTime){
              this.NotifiButtonShow.emit(true);
              if(NotificationComponentLoadAgain!=this.ShowAgain){
                this.isShow = true;
                window.localStorage.setItem("NotificationComponentLoadAgain", this.ShowAgain);
                window.localStorage.setItem("RedIconShow","true");
                this.RedIconShow.emit("true");
                this.htmlBody= this.sanitizer.bypassSecurityTrustHtml(this.NotificationBody);
              }
            }
          }
        }, err => {});
      }

        $(".isShowDetail").slideUp();

  }
  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      let chng = changes[propName];
      if(propName == 'NotifiInput'){
        this.hasNotifiClicked=chng.currentValue;
        if(this.hasNotifiClicked==true){
          this.service = this.factory.getService();
          this.service.ShowNotification().then(res => {
            this.isNotificationShow = res.IsShow;
            this.NotificationSubject = res.NotificationSubject;
            this.NotificationBody = res.NotificationBody;
            this.NotificationDisplayStartTime = res.DisplayStartTime;
            this.NotificationDisplayEndTime=res.DisplayEndTime;
  
            if(this.NotificationDisplayStartTime!=null&&this.NotificationDisplayStartTime!=""&&this.NotificationDisplayStartTime!=undefined
            && this.NotificationDisplayEndTime!=null&&this.NotificationDisplayEndTime!=""&&this.NotificationDisplayEndTime!=undefined){
              var UTCnow=this.datePipe.transform(new Date(),"MM/dd/yyyy hh:mm:ss aa","UTC");
              var UTCnowTime=new Date(UTCnow).getTime();
              var startTime=new Date(this.NotificationDisplayStartTime).getTime();
              var endTime=new Date(this.NotificationDisplayEndTime).getTime();
              if(this.isNotificationShow=="1" && UTCnowTime>=startTime && UTCnowTime<=endTime){
                this.isShow = true;
                this.htmlBody= this.sanitizer.bypassSecurityTrustHtml(this.NotificationBody);
              }
            }
          }, err => {});
        }
      }
    }
}

  ShowHideDetail() {
    $(".isShowDetail").slideToggle();
  }

  ngAfterViewInit(): void {
    this.popupResize();
  }

  closePop(){
    this.isShow = false;
    this.NotifiOutput.emit(false);
  }


  /**
   * for custom popup resize position(top)
   * @return {void}@memberof NotificationComponent
   */
  popupResize(): void {
    $('.search_headbox').css({
      'top': ($(window).scrollTop()) / $('.all_box').height() * 100 + '%'
    });
  }

}
