<div class="msgBox">
    <div class="title">
        <div class="title-content">Request Deletion</div>
        <i (click)="onClose()" class="close-icon mdui-icon material-icons">clear</i>
    </div>
    <div class="content">
        <div class="msg">
            <div class="msg-title">You are selecting a permanent deletion of this record.</div>
            <div class="msg-title"><span class="red rea_asterisk">*</span>Please select reason(s):</div>
            <div class="reasons chkBox" *ngFor="let reason of data.reasons">
                <div style="min-width: 70px;">
                    <input [id]="reason.reason" (ngModelChange)="checkOne(reason.reason)" [(ngModel)]="selectedReasons[reason.reason]" class="multi_checkbox fl" type="checkbox" [checked]="selectedReasons[reason.reason]"/>
                    <label [for]="reason.reason" class="fontStyle fl">{{reason.text}}</label>
                </div>
                <div style="position: relative;width: 100%;" *ngIf="reason.withInput && selectedReasons[reason.reason]">
                    <label [for]="reason.reason + '_input'" class="red" style="position: absolute;">*</label>
                    <input [id]="reason.reason + '_input'" (ngModelChange)="reasonChange()" maxlength="255" placeholder="Field must be completed"
                        type="text" [(ngModel)]="selectedReasonsInput[reason.reason]" class="form-control fl"/>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <div>
            <!-- <div class="errorMsgBox" *ngIf="showValidateMsg && validateReason()">
                <i class="mdui-icon material-icons erroricon fl">info</i>
                <label *ngIf="validateReason() === 'no_select'" class="magL11">Please select at least one option</label>
                <label *ngIf="validateReason() === 'no_input'" class="magL11">Please complete all mandatory fields before rejecting</label>
            </div> -->
        </div>
        <div style="display: flex;align-items: center;justify-content: flex-end;">
            <button [disabled]="isLoading" class="submit_btn cancel" (click)="isLoading ? undefined : onClose()" style="width: 82px">
                <i class="mdui-icon material-icons sub_btn">close</i>
                <span class="btn_txt">Cancel</span>
            </button>
            <button [disabled]="isConfirmDisabled()" class="submit_btn confirm" (click)="isConfirmDisabled() ? undefined : onConfirm()">
                <i class="mdui-icon material-icons sub_btn">delete</i>
                <span class="btn_txt">Request Deletion</span>
            </button>
        </div>
    </div>
</div>