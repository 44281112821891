import { Injectable, ComponentFactoryResolver, Injector, ComponentRef, ApplicationRef } from "@angular/core";
import { ProgressBarComponent } from "./progress-bar.component";

@Injectable()
export class ProgressBarService {

    private _componentRefMap: Map<string, ComponentRef<ProgressBarComponent>> = new Map();

    constructor (
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _injector: Injector,
        private _applicationRef: ApplicationRef,
    ) {}

    public showProgressBar (id: string, label?: string): void {
        const _componentFactory = this._componentFactoryResolver
            .resolveComponentFactory<ProgressBarComponent>(ProgressBarComponent);
        const injector = Injector.create({
            providers: [],
            parent: this._injector
        });
        let componentRef = _componentFactory.create(injector);

        id = this.getDomId(id);

        componentRef.instance.domId = id;
        componentRef.instance.label = label;

        this._componentRefMap.set(id, componentRef);

        this._applicationRef.attachView(componentRef.hostView);

        document.body.appendChild(componentRef.location.nativeElement);
    }

    public removeProgressBar (id: string): void {
        id = this.getDomId(id);
        let componentRef = this._componentRefMap.get(id);
        if (componentRef) {
            document.body.removeChild(componentRef.location.nativeElement);
            this._componentRefMap.delete(id);
        }
    }

    public setPercent (id: string, percent: number): void {
        let componentRef = this._componentRefMap.get(this.getDomId(id));
        if (componentRef) {
            componentRef.instance.percent = percent;
        } else {
            throw new Error('progress bar id[' + id + '] is not exist');
        }
    }

    public isExist(id: string): boolean {
        return !!this._componentRefMap.get(this.getDomId(id));
    }

    private getDomId (id: string): string {
        return 'progress_bar_svc' + id;
    }
}