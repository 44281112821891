<section>
    <div class="rele">
        <div class="up_title another_up" (click)="isAdditional()">
            <i *ngIf="isMore" class="mdui-icon material-icons expend_icon">expand_more</i>
            <i *ngIf="!isMore" class="mdui-icon material-icons expend_icon">chevron_right</i>
            Additional Information  
        </div>
        <div class="tab_content isAdditional">
            <div class="tab_line">
                <div class="row">
                    <div class="col-4">
                        <label [for]="inputsData.formFields.AdditionalInformation.filedId" class="box_title">Additional Information</label>
                        <textarea [id]="inputsData.formFields.AdditionalInformation.filedId" *ngIf="!inputsData.formFields.AdditionalInformation.isRead" class="doc_box solid_box textarea_box"
                            [(ngModel)]="inputsData.formFields.AdditionalInformation.value" (ngModelChange)="AdditionalInformation_onChanged($event)"
                            [maxlength]="8000"
                            [disabled]="inputsData.formFields.AdditionalInformation.IsDisabled">
                        </textarea>
                        <textarea [id]="inputsData.formFields.AdditionalInformation.filedId" *ngIf="inputsData.formFields.AdditionalInformation.isRead" unselectable="on" readonly="readonly" class="doc_box solid_box textarea_box disabled">{{inputsData.formFields.AdditionalInformation.value}}</textarea>    
                    </div>
                    <div class="col-4">
                        <label [for]="inputsData.formFields.SecondaryAdditionalInformation.filedId" class="box_title">Service or Product Description</label>
                        <textarea [id]="inputsData.formFields.SecondaryAdditionalInformation.filedId" *ngIf="!inputsData.formFields.SecondaryAdditionalInformation.isRead" class="doc_box solid_box textarea_box"
                            [(ngModel)]="inputsData.formFields.SecondaryAdditionalInformation.value" (ngModelChange)="SecondaryAdditionalInformation_onChanged($event)"
                            [maxlength]="8000"
                            [disabled]="inputsData.formFields.SecondaryAdditionalInformation.IsDisabled">
                        </textarea>
                        <textarea [id]="inputsData.formFields.SecondaryAdditionalInformation.filedId" *ngIf="inputsData.formFields.SecondaryAdditionalInformation.isRead" unselectable="on" readonly="readonly" class="doc_box solid_box textarea_box disabled">{{inputsData.formFields.SecondaryAdditionalInformation.value}}</textarea>               
                    </div>
                    <div class="col-4">
                        <label [for]="inputsData.formFields.TerminationTerms.filedId" class="box_title">Termination Terms</label>
                        <textarea [id]="inputsData.formFields.TerminationTerms.filedId" *ngIf="!inputsData.formFields.TerminationTerms.isRead" class="doc_box solid_box textarea_box"
                            [(ngModel)]="inputsData.formFields.TerminationTerms.value" (ngModelChange)="TerminationTerms_onChanged($event)"
                            [maxlength]="8000"
                            [disabled]="inputsData.formFields.TerminationTerms.IsDisabled">
                        </textarea>
                        <textarea [id]="inputsData.formFields.TerminationTerms.filedId" *ngIf="inputsData.formFields.TerminationTerms.isRead" unselectable="on" readonly="readonly" class="doc_box solid_box textarea_box disabled">{{inputsData.formFields.TerminationTerms.value}}</textarea>               
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="doc_box solid_box isAdditional"></div>    -->
        <div class="v_mid setCheck" style="display: none;">
            <label class="yet">
                <input class="yet_inp v_mid" name="supplierNotSystem" type="checkbox" value=""/> Set as My Favorite </label>
        </div>
    </div>
</section>