import { DatePipe as ComDatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DateUtil } from '../util/date-util';

@Pipe({ name: 'date' })
export class DatePipe extends ComDatePipe implements PipeTransform {
    transform(value: any, format?: string, timezone?: string, locale?: string): null;      
    transform(value: any, format?: string, timezone?: string, locale?: string): string{
        if (value === '' || String(value).trim() === '') {
            return value;
        }
        return super.transform(DateUtil.newDate(value), format, timezone, locale);
    }
}