import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MessageService } from 'app/common/services/message/messageService';
@Component({
  selector: 'app-checkboxYN',
  templateUrl: './checkboxYN.component.html',
  styleUrls: ['./checkboxYN.component.css'],
  inputs: [
    'inputData',
    'inputId',
  ],
  outputs: [
    'outputFn',
  ],
})
export class CheckboxYNComponent implements OnInit {

  public inputData: any;
  public inputId: string;
  @Output()
  public isInputValChange: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public valChange: EventEmitter<any> = new EventEmitter<any>();
  constructor(private message: MessageService) { }

  ngOnInit() {
    if (!this.inputId) {
      this.inputId = this.inputData.filedId + '_' + new Date().getTime();
    }
    this.inputData.value = false;
  }
  checkOne(event: any) {
    // Judging click events then send mes to parent component
    this.isInputValChange.emit(true);

    this.inputData.value = !this.inputData.value;
    this.valChange.emit(this.inputData.value);
    // ready for reject popup
    this.message.sendRejectpopupNotNullFlag({ inputData: this.inputData });
  }
}
