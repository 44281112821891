export class DateUtil {
    /**
     * date parse
     * @param dateStr string
     * return number date time
     */
    public static parse(dateStr: string): number {

        let dateStrTemp: string = dateStr;
        /*
         * default parse
         * IE dd-MMM-yyyy or dd/MMM/yyyy -> NaN
         * Firefox dd-MMM-yyyy -> a number less than 0(incorrect result)
         */
        let dateNumber: number = Date.parse(dateStr);

        if (dateStrTemp) {

            // dd-MMM-yyyy or dd/MMM/yyyy -> dd MMM yyyy
            let dateStrArray: string[];
            const separators = ['-', '/'];
            for (let i = 0; i < separators.length; i++) {
                dateStrArray = dateStrTemp.split(separators[i]);
                if (dateStrArray.length === 3) {
                    break;
                } else {
                    dateStrArray = null;
                }
            }

            if (dateStrArray) {
                let dateTempNumber: number = Date.parse(dateStrArray.join(' '));
                if (!!dateTempNumber && dateNumber !== dateTempNumber) {
                    dateNumber = dateTempNumber;
                }
                if (!dateTempNumber) {
                    dateTempNumber = Date.parse(dateStrArray.join('/'));
                    if (!!dateTempNumber && dateNumber !== dateTempNumber) {
                        dateNumber = dateTempNumber;
                    }
                }
            }
        }

        // timezone issue
        if (dateStr.endsWith('Z')) {
            dateNumber += new Date(dateStr).getTimezoneOffset() * 60000;
        }

        return dateNumber;
    }

    /**
     * new Date
     * @param value string | number | Date
     * reuturn Date
     */
    public static newDate(value?: string | number | Date): Date {
        if (value === undefined) {
            return new Date();
        }
        switch (typeof (value)) {
            case 'string': {
                return new Date(DateUtil.parse(<string>value));
            }
            case 'number': {
                return new Date(<number>(value));
            }
            default: {
                return new Date(<Date>value);
            }
        }
    }

    public static isEmpty (value): boolean {
        return !value;
    }

    public static isValid (value): boolean {
        if (!value) {
            return true;
        }
        if (!DateUtil.newDate(value).getTime()) {
            return false;
        }
        return true;
    }
}