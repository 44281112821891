<div #type_ahead_content>
    <div #input_content style="width: 100%;">
        <ng-content></ng-content>
    </div>
    <div #text_list_content [ngStyle]="{'width.px': input_content.offsetWidth}" class="text-list" *ngIf="isShowTextList">
        <div style="padding-left: 0.75rem;" *ngIf="(!textList || textList.length === 0) && noResultMsg">{{noResultMsg}}</div>
        <div *ngIf="textList && textList.length > 0">
            <div class="text-item" *ngFor="let svalue of textList" (click)="onSelectItem(svalue)">
                {{svalue}}
            </div>
        </div>
    </div>
</div>