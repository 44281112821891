export const environment = {
  providers: 'app',
  isLocal: false,
  Authorize: true,
  production: false,
  ApiBase: 'https://mmrpapi.ciodev.accenture.com/api/',
  HPApiBase : 'https://mmrpapi.ciodev.accenture.com/CMServiceAPI/',
  environment: 'dev',
  clientId: '204fdae6-551d-4712-b72f-79fbfbef3196',
  authorizationUrl: 'https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22/oauth2/v2.0/',
  redirectUri: 'https://mmrp.ciodev.accenture.com',
  resource: 'https://mmrp.ciodev.accenture.com',
  postLogoutRedirectUri: '',
  scope: 'openid',
  people: 'https://collabhub-perf.ciotest.accenture.com/json',
  localAccounts: '',
  sendemail: false,
  logoutUri:"https://federation-sts-stage.accenture.com/adfs/ls/?wa=wsignout1.0 ",
  idolEnable: true,
  LocalAccessToken: "ABC"
};
