import { Component, HostListener, Input } from "@angular/core";

@Component({
    selector: 'progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.css'],
})
export class ProgressBarComponent {

    @Input()
    public label: string;

    public domId: string;

    public percentValue: number = 0;

    public isMouseDown: boolean = false;

    public startX: number = 0;
    public startY: number = 0;

    public positionRight: number = 5;
    public positionBottom: number = 15;

    public set percent (value) {
        this.percentValue = value;
    }

    public get percent (): number {
        return this.percentValue;
    }

    @HostListener('document:mousedown', ['$event'])
    public onMouseDown (event): void {
        this.startX = event.x;
        this.startY = event.y;
        this.isMouseDown = true;
    }

    @HostListener('document:mousemove', ['$event'])
    public onMouseMove(event): void {
        if (this.isMouseDown) {
            let moveX = event.x - this.startX;
            let moveY = event.y - this.startY;

            this.startX = event.x;
            this.startY = event.y;

            this.positionRight -= moveX;
            this.positionBottom -= moveY;
        }
    }

    @HostListener('document:mouseup', ['$event'])
    public onMouseUp(): void {
        this.isMouseDown = false;
    }

    @HostListener('document:moseleave', ['$event'])
    private onMouseLeave () {
        this.isMouseDown = false;
    }

}