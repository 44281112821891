import { ViewChild, Input, Output, OnInit, EventEmitter, HostListener, AfterViewInit, Directive } from '@angular/core';
// import { BsDropdownDirective, Positioning } from 'ngx-bootstrap';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { Positioning } from 'ngx-bootstrap/positioning';
import { CallBack, DataItem } from '../models';
import { TabelFilterService } from '../service/tabel-filter.service';
import { FilterArrayPipe } from '../pipe/filter-array.pipe';

@Directive()
export class FilterComponent implements OnInit, AfterViewInit {

  /**
   * 
   */
  @Input()
  public property: string = 'null';

  /**
   * 
   */
  @Input()
  public placement: string = 'bottom left';

  /**
   * 
   */
  @Output()
  public filterItem: EventEmitter<any> = new EventEmitter<any>();

   /**
   * 
   */
  @Output()
  public filterCondition: EventEmitter<any> = new EventEmitter<any>();

  /**
   * 
   */
  @Input()
  public items: Array<any> = [];

  @Input()
  public checkeds: Array<any> = [];

  /**
   * 
   */
  @Input()
  public relations: Array<any>;

  /**
   * 
   */
  @Input()
  public isLike: boolean;

  /**
   * 
   */
  @Input()
  public itemOrder: 'none' | 'ASC' | 'DESC' = 'ASC';

  public filterText: string = '';

  public dataItem = new DataItem();

  public beforeItems: any = [];

  public afterItems: any = [];

  public showNext: boolean = false;

  public firstFilterCondition: boolean = true;

  /**
   * 
   */
  @ViewChild(BsDropdownDirective)
  public bsDropdown: BsDropdownDirective;


  public _tabelFilterService: TabelFilterService;

  public _parent: any = null;

  constructor() { }

  ngOnInit(): void {
    this._tabelFilterService.rigistCleanDataCallBack(<CallBack>{
      key: this.property,
      source: this,
      hasCondition: this.hasCondition.bind(this),
      callback: this.cleanData.bind(this),
      removeItems: this.removeItems.bind(this)
    });
  }

  ngAfterViewInit(): void {
    (this.bsDropdown as any)._elementRef.nativeElement = this._parent._elementRef.nativeElement;
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.getFilterCondition();
    this._tabelFilterService.singleShowFilter(this.bsDropdown);
  }

  stopPropagation(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
  }

  public sortItems(items: Array<any>){
   // 
   let _items = [...items];
   _items = _items.sort((a: any, b: any) => {
    const isString = typeof a === 'string' && typeof b === 'string';
     if(isString){
      a = (a as string).toLocaleLowerCase();
      b = (b as string).toLocaleLowerCase()
     }else {
      a = (a.name as string).toLocaleLowerCase();
      b = (b.name as string).toLocaleLowerCase()
     }
     if(this.itemOrder === 'ASC'){
      return a.localeCompare(b);  // 
     }else{
      return b.localeCompare(a);
     }
   });
   return _items;
  }
  

  public convertItems(items: Array<any>, checkeds: Array<any> = []){
    // 
    let _items = [...items];
    _items = _items.map((_item) => {
      return {
        name: _item,
        checked: (checkeds as any).includes(_item)
      }
    });

    return _items;
  }

  public restItems(items: Array<any>){
    // 
    let _items = [...items];
    _items = _items.map((_item) => {
      _item.checked = false;
      return _item;
    });

    return _items;
  }

  public cleanData(): void {
    
  }

  public hasCondition(): boolean {
    return false;
  }

  public removeItems(): void {
    
  }

  public haveItems(): boolean {
    return !!this.items && this.items.length > 0;
  }

  public hasFilterValues(component: any): boolean {
    const filterArrayPipe = new FilterArrayPipe();
    const values = filterArrayPipe.transform(component.items, {name: component.filterText});
    return values.length > 0;
  }

  public getFilterCondition() {
    if(this.firstFilterCondition) {
      this.filterCondition.emit();
      this.firstFilterCondition = false;
    } 
  }

}

var positionElements = (Positioning as any).prototype.positionElements;
(Positioning as any).prototype.positionElements = function (hostElement, targetElement, placement, appendToBody) {
  // eslint-disable-next-line prefer-rest-params
  var targetElPosition = positionElements.apply(this, arguments);
  var placementSecondary = placement.split(' ')[1] || 'center';
  if(placementSecondary == 'right'){
     targetElPosition.left -= targetElPosition.width;
  }
  return targetElPosition;
}
