<section *ngIf="isShowSelect || (inputData && inputData.isRead)">
    <div class="ove">
        <label [for]="inputId" class="fl"><span *ngIf="inputData.IsRequired" class="red">*</span> {{labelName}} </label>
        <i *ngIf="!inputData.isRead && !inputData.IsDisabled" class="mdui-icon material-icons close_btn fl"
            style="margin-left:5px;position: absolute;" (click)="reset()"
            [tooltip]="resetTooltip">cancel</i>
    </div>
    <div [clickBlank]="closeOptions.bind(this)" [ngStyle]="!inputData.isRead ? {} : {display: 'none'}">
        <div class="form-control nowrap" #expand_more (click)="inputData.IsDisabled ? undefined : toggleOptions($event)"
            [tooltip]="getSelectedDisplayText() ? getSelectedDisplayText() : placeholder" [tooltip-class]="'tooltip-class'"
            [ngClass]="{'divDisable':inputData.isRead ,'divAvailable':!inputData.isRead ,'disable-color':inputData.IsDisabled}">
            <div style="width: calc(100% - 24px);display: inline-block;" class="nowrap">{{getSelectedDisplayText() ? getSelectedDisplayText() : placeholder}}</div>
            <i class="mdui-icon material-icons arr_btn">expand_more</i>
        </div>
        <div class="abso" [ngStyle]="{'width.px': offsetWidth}">

            <div class="down_box" [ngStyle]="isShowOptions ? {} : {display: 'none'}">
                <div style="padding: 10px 10px 0 10px;">
                    <div class="search_box">
                        <input [id]="inputId" class="form-control search_inp" type="text" placeholder="Type and Search"
                            [(ngModel)]="searchText" (ngModelChange)="searchTextChange()"/>
                    </div>
                    <div class="btn_block">
                        <input [(ngModel)]="isSelectedAll" (ngModelChange)="toggleSelectAll($event)" [disabled]="searchText || options.length === 0" class="multi_checkbox" type="checkbox" [id]="inputId + '_selectAll'"/>
                        <label [for]="inputId + '_selectAll'">Select All</label>
                        <div class="reset_btn" (click)="reset()">Reset</div>
                    </div>
                </div>

                <div #options_tab class="optionsTab">
                    <ng-container *ngFor="let option of options">
                        <div *ngIf="isShowOption(option)" class="checkBoxContainer" style="padding:0;">
                            <input class="multi_checkbox" [id]="'option_' + option.value"
                                type="checkbox" [checked]="isChecked(option.value)" (change)="toggleChecked(option.value)" />
                            <label [for]="'option_' + option.value" class="resultLabel">{{option.label}}</label>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="inputData.isRead" [tooltip]="getSelectedDisplayText()" [tooltip-class]="'tooltip-class'" class="nowrap"
        style="width: 100%;display: inline-block;"><span class="span_is_read nowrap">{{getSelectedDisplayText()}}</span></div>
</section>

<button *ngIf="!isShowSelect && (!inputData || !inputData.isRead)" (click)="isBtnDisable || inputData.IsDisabled ? undefined : isShowSelect = true;" class="submit_btn fl add_btn" [ngClass]="{'btnDisable': isBtnDisable || inputData.IsDisabled}" [tooltip]="isBtnDisable || inputData.IsDisabled ? '' : btnTooltip">
    <i class="mdui-icon material-icons sub_btn">add</i>
    <span class="btn_txt"> {{labelName}} </span>
</button>