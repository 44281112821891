<div dropdown container="body" [placement]="placement">
    <div dropdownToggle class="caret">
        <i class="mdui-icon material-icons expend_icon">expand_more</i>
    </div>
    <ul *dropdownMenu class="filter-dropdown-menu" role="menu" (click)="stopPropagation($event)">
      <li class="dropdown-warp"
      [ngClass]="{'clean': !hasCondition()}"
       role="button" (click)="cleanFilter($event)">
          <div class="filter-dropdown-item">Clear Filters</div>
      </li>
      <li class="divider dropdown-divider" (click)="stopPropagation($event)"></li>
      <ul class="scroller" [ngClass]="{'overflow-y-scroll': !hasFilterValues(this)}">
        <ng-container *ngFor="let item of items">
            <li class="dropdown-warp" role="button" (click)="itemActive($event, item)">
                <div class="input-group filter-dropdown-item">
                    <i [ngClass]="{'v-hide': !item.checked}"
                        class="mdui-icon material-icons expend_icon done">done</i>
                    &nbsp;{{item.name}}
                </div>
            </li>
        </ng-container>
      </ul>
    </ul>
</div>