import { Router, ActivatedRouteSnapshot } from "@angular/router";
import { CheckSaveI } from "./check-saveI";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, Injectable, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { Location } from '@angular/common';

@Injectable()
export class CheckSaveCanDeavtivate  {
    constructor (
        private dialog: MatDialog,
        private router: Router,
        private location: Location,
    ) {}

    canDeactivate(
        checkSaveComponent: CheckSaveI,
        currentRoute: ActivatedRouteSnapshot,
    ): Observable<boolean> {
        return Observable.create((observer) => {
            if (checkSaveComponent.checkSaved() || checkSaveComponent.checkNoChange()) {
                observer.next(true);
                return;
            }

            this.dialog.open(CheckSaveDialogComponent, {
                width: '450px',
                disableClose: true,
                data: { 
                    leave: () => {
                        observer.next(true);
                        return;
                    },
                    stay: () => {
                        // for click browser back button issue
                        const currentUrlTree = this.router.createUrlTree([], currentRoute);
                        const currentUrl = currentUrlTree.toString();
                        this.location.go(currentUrl);
                        
                        observer.next(false);
                        return;
                    }
                }
            });
        });
    }
}

@Component({
    selector: 'check-save-dialog',
    templateUrl: './check-save-dialog.component.html',
    styleUrls: ['./check-save-dialog.component.css'],
})
export class CheckSaveDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<CheckSaveDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}
    
    onLeaveClick(): void {
        this.dialogRef.close();
        this.data.leave();
    }
    onStayClick(): void {
        this.dialogRef.close();
        this.data.stay();
    }
}