import { Injectable } from '@angular/core';
import { CommenString } from 'app/common/enum/type.enum';

@Injectable()
export class AuthService {
    public UserInfor: any = {
        EID: "",
        LogInAs: "",
        Uri: "",
        Email: "",
        KeyCategory: "",
        Gu: "",
        Group: [],
        Salutation: '',
        UserCountry: '',
        GuLead: ''
      };
    constructor() { }

    setUserInforFn(userInfor){
        this.UserInfor = userInfor;
    }

    getUserInforFn(){
        return this.UserInfor;
    }

    determineUserRole(): string {
        let isAdmin = this.UserInfor.Group.find(gr => gr === CommenString.AdminRole);
        let isGuLead = !isAdmin && this.UserInfor.Group.find(gr => gr === CommenString.GuLeadRole);
        let isStandard = !isAdmin && !isGuLead && this.UserInfor.Group.find(gr => gr === CommenString.StandardRole);
        let isReadonly = !isAdmin && !isGuLead && !isStandard && this.UserInfor.Group.find(gr => gr === CommenString.ReadOnlyRole);
        let isDocumentOwner = this.UserInfor.Group.find(gr => gr === CommenString.DocumentOwnerRole);

        if (isAdmin){
          return CommenString.AdminRole
        } else if (isGuLead && !isDocumentOwner) {
          return CommenString.GuLeadRole;
        } else if (!isGuLead && isDocumentOwner) {
          return CommenString.DocumentOwnerRole
        } else if (isGuLead && isDocumentOwner) {
          return CommenString.DocumentOwnAndGuLead;
        } else if (isStandard && !isDocumentOwner) {
          return CommenString.StandardRole;
        } else {
          return CommenString.ReadOnlyRole;
        }
      }
}
