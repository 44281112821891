
 <div class="mask">
</div>
<div class="msgBox" >
        <div class="title">
            <div class="title-content">Warning</div>
            <i (click)="closeDCPopup()" class="close-icon mdui-icon material-icons">clear</i>
        </div>
        <div class="content">
            <div class="msg">
                <i class="mdui-icon material-icons warning m_r">error</i>
                <div>{{DocMessage}}</div>
            </div>
        </div>
        <div class="footer">
            <button class="submit_btn confirm fr" (click)="closeDCPopup()" style="width: 82px">
                <i class="mdui-icon material-icons sub_btn">check</i>
                <span class="btn_txt">OK</span>
            </button>
        </div>
    </div>