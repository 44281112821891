import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    templateUrl: './deletion-reasons.component.html',
    styleUrls: ['./deletion-reasons.component.css'],
})
export class DeletionReasonsComponent {
    constructor (
        public dialogRef: MatDialogRef<DeletionReasonsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    public selectedReasons: {[key: string]: boolean} = {};
    public selectedReasonsInput: {[key: string]: string} = {};

    public showValidateMsg: boolean = false;

    public isLoading: boolean = false;

    public onConfirm (): void {
        this.showValidateMsg = true;
        if (this.validateReason()) {
            return;
        }
        this.isLoading = true;
        this.data.confirm(this.getSelectedReasons(), () => {
            this.dialogRef.close();
            this.isLoading = false;
        }, () => {
            this.dialogRef.close();
            this.isLoading = false;
        });
    }
    public onClose (): void {
        this.dialogRef.close();
    }

    public checkOne (event): void {
        this.showValidateMsg = false;
        //this.selectedReasons[event] = !this.selectedReasons[event];
        this.selectedReasonsInput[event] = '';
    }

    public isConfirmDisabled (): boolean {

        if (this.isLoading || this.validateReason()) {
            return true;
        }

        // let selectedKeys = [];
        // for (let key in this.selectedReasons) {
        //     if (this.selectedReasons[key]) {
        //         selectedKeys.push(key);
        //     }
        // }
        // // input validate (other reason)
        // for (let i = 0; i < selectedKeys.length; i ++) {
        //     let key = selectedKeys[i];
        //     if (this.selectedReasons[key]) {
        //         let isWithInput = false;
        //         this.data.reasons.forEach(reason => {
        //             if (reason.reason === key && reason.withInput) {
        //                 isWithInput = true;
        //             }
        //         });
        //         if (isWithInput && (!this.selectedReasonsInput[key] || !this.selectedReasonsInput[key].trim())) {
        //             return true;
        //         }
        //     }
        // }

        // if (selectedKeys.length === 0) {
        //     return true;
        // }
        return false;
    }

    public reasonChange () {
        this.showValidateMsg = false;
    }
    public validateReason (): 'no_select' | 'no_input' | false {
        let selectedKeys = [];
        for (let key in this.selectedReasons) {
            if (this.selectedReasons[key]) {
                selectedKeys.push(key);
            }
        }
        // input validate (other reason)
        for (let i = 0; i < selectedKeys.length; i++) {
            let key = selectedKeys[i];
            if (this.selectedReasons[key]) {
                let isWithInput = false;
                this.data.reasons.forEach(reason => {
                    if (reason.reason === key && reason.withInput) {
                        isWithInput = true;
                    }
                });
                if (isWithInput && (!this.selectedReasonsInput[key] || !this.selectedReasonsInput[key].trim())) {
                    return 'no_input';
                }
            }
        }

        if (selectedKeys.length === 0) {
            return 'no_select';
        }
        return false;
    }

    private getSelectedReasons () {
        let result = [];
        this.data.reasons.forEach(reason => {
            let key = reason.reason;
            if (this.selectedReasons[key]) {
                result.push({
                    Key: key,
                    Value: this.selectedReasons[key],
                    IsCustomer: true,
                    IsBool: true,
                });
                if (reason.withInput) {
                    result.push({
                        Key: reason.inputKey,
                        Value: this.selectedReasonsInput[key],
                        IsCustomer: true,
                        IsBool: false,
                    });
                }
            }
        });
        return result;
    }
}
