import { Injectable } from '@angular/core';
import { AjaxService } from "./common/services/Ajax/ajax.services";

@Injectable()
export class Factory {
    constructor(private Ajax: AjaxService) { }
    getService() {
        return this.Ajax.getJson([
            {
                name: "getUserGroupOPEmailByEid",
                url: "user/getUserGroupOPEmailByEid",
                method: "post"
            },
            {
                name: "getUserByEid",
                url: "user/getUserByEid",
                method: "post"
            },
            {
                name: "getNewRecord",
                url: "Record/New",
                method: "post"
            },
            {
                name: "getSelectValue",
                url: "Record/LookUp/GetSorP",
                method: "get",
                cache: true
            },
            {
                name: "HPServiceRecord",
                url: "Record",
                method: "HPServiceApiget"
            },
            {
                name: "getStatus",
                url: "Record/LookUp/GetAgreStatus",
                method: "get",
                cache: true
            },
            {
                name: "GetCompanyCode",
                url: "Record/LookUp/GetCompanyCode",
                method: "get",
                cache: true
            },
            {
                name: "getAgreType",
                url: "Record/LookUp/GetAgreType",
                method: "get",
                cache: true
            },
            {
                name: "getAgreComplex",
                url: "Record/LookUp/GetAgreComp",
                method: "get",
                cache: true
            },
            {
                name: "getRenType",
                url: "Record/LookUp/GetRenType",
                method: "get",
                cache: true
            },
            {
                name: "getAgrePaper",
                url: "Record/LookUp/GetAgrePaper",
                method: "get",
                cache: true
            },
            {
                name: "getPayTerm",
                url: "Record/LookUp/GetPayTerm",
                method: "get",
                cache: true
            },
            {
                name: "getPrimaryGU",
                url: "Record/LookUp/GetPrimaryGU",
                method: "get"
            },
            {
                name: "getRegion",
                url: "Record/LookUp/GetPrimaryRegion",
                method: "get"
            },
            {
                name: "getLanguage",
                url: "Record/LookUp/GetLanguage",
                method: "get",
                cache: true
            },{
                name: "getSignatureType",
                url: "Record/LookUp/GetSignatureType",
                method: "get",
                cache: true
            },
            {
                name: "getLicense",
                url: "Record/LookUp/GetLicense",
                method: "get",
                cache: true
            },
            {
                name: "getLicType",
                url: "Record/LookUp/GetLicType",
                method: "get",
                cache: true
            },
            {
                name: "getCountry",
                url: "Record/LookUp/GetCountry",
                method: "get",
                cache: true
            },
            {
                name: "getGU",
                url: "Record/GetGU",
                method: "post"
            },
            {
                name: "getKeyCategory",
                url: "Record/LookUp/GetKeyCategory",
                method: "get",
                cache: true
            },
            {
                name: "getBusinessUnit",
                url: "Record/LookUp/GetBusinessUnit",
                method: "get"
            },
            {
                name: "getCategoryMap",
                url: "Record/LookUp/GetCategoryMap",
                method: "get",
                cache: true
            },
            {
                name: "getCategory",
                url: "Record/LookUp/GetCategory",
                method: "get",
                cache: true
            },
            {
                name: "getGUAndRegionByCountry",
                url: "Record/GUAndRegionByCountry",
                method: "get"
            },
            {
                name: "RecordSearch",
                url: "Record/RecordSearch",
                method: "post"
            },
            {
                name: "GetRecordByUri",
                url: "Record/GetRecordByUri",
                method: "post"
            },
            {
                name: "fileUpload",
                url: "files/upload",
                method: "post"
            },
            {
                name: "fileDelete",
                url: "files/DeleteInNewRecord",
                method: "post"
            },
            {
                name: "hello",
                url: "record/hello",
                method: "get"
            },
            {
                name: "fileDeleteHPRM",
                url: "files/DeleteInEditRecord",
                method: "post"
            },
            {
                name: "userGetGroupMember",
                url: "user/getGroupMember",
                method: "get"
            },
            {
                name: "activityReassign",
                url: "Activity/Reassign",
                method: "post"
            },
            {
                name: "ReRecordStatus",
                url: "Activity/ReRecordStatus",
                method: "post"
            },
            {
                name: "documentSearch",
                url: "Record/DocumentsSearchByRecordUri",
                method: "get"
            },
            {
                name:"editDocumentSearch",
                url:"Record/SearchEditDocumentsByRecordUri",
                method:"get"
            },
            {
                name: "recordtoApprove",
                url: "Activity/Approve",
                method: "post"
            },
            {
                name: "recordtoReject",
                url: "Activity/Reject",
                method: "post"
            },
            {
                name: "getPeoplePicker",
                url: "People/Pickup",
                method: "post"
            }, 
            {
                name: "getPeoplePickerForBCO",
                url: "People/PickupForBCO",
                method: "post"
            },
            {
                name: "getPeoplePickerForMMPR",
                url: "People/PickupforMMPR",
                method: "post"
            },
            {
                name: "checkPeopleValidation",
                url: "People/checkEid",
                method: "post"
            },
            {
                name: "checkPeopleValidationForBCO",
                url: "People/checkEidForBCO",
                method: "post"
            },
            {
                name: "checkClientValidation",
                url: "People/ValidClientName",
                method: "post"
            },
            {
                name: "HPServiceLocation",
                url: "Location",
                method: "HPServiceApiget"
            },
            {
                name: "HPServiceActivity",
                url: "Activity",
                method: "HPServiceApiget"
            },{
                name: "SecondarySuppliersForRecord",
                url:"Record/SecondarySuppliersForRecord",
                method: "get"
            },{
                name: "SecondarySupplierOandNCompare",
                url:"Record/SecondarySupplierOandNCompare",
                method: "get"
            },
            {
                name: "SecondarySuppliersForRecordBackup",
                url:"Record/SecondarySupplierBackup",
                method: "get"
            },
            {
                name: "GetDocOwnerByCC",
                url:"Record/GetDocOwnerByCC",
                method: "get"
            }, {
                name: "RecordWithBackup",
                url: "Record/RecordWithBackup",
                method: "post"
            },{
                name: "RecordResubmit",
                url: "Record/Edit",
                method: "post"
            },{
                name: "ApproveForEdit",
                url: "Activity/ApproveForEdit",
                method: "post"
            },{
                name: "RejectForEdit",
                url: "Activity/RejectForEdit",
                method: "post"
            },{
                name: "DocumentsWithBackup",
                url: "Record/DocumentsWithBackup",
                method: "get"
            },
            {
                name: "getGU",
                url: "Record/GetAllGU",
                method: "get"
            },
            {
                name: "getFiscalYear",
                url: "Record/getFiscalYear",
                method: "get"
            },
            {
                name: "getAllKeyCategory",
                url: "Record/getAllKeyCategory",
                method: "get",
                cache: true
            },
            {
                name:"exportDashboardData",
                url: "Record/exportDashboardData",
                method: "post"
            },
            {
                name:"GetRecordDateLastUpdated",
                url: "Record/GetRecordDateLastUpdated",
                method: "get"
            },{
                name: "recordHistory",
                url: "Record/History",
                method: "get"
            }
            ,{
                name: "DocMoveSearchChildsByRecordUri",
                url: "Record/DocMoveSearchChildsByRecordUri",
                method: "get"
            },
            {
                name:"RejectToSendMail",
                url: "Activity/RejectSendEmail",
                method: "post"
            },
            {
                name:"InsertRecentlyAccessed",
                url: "RecentlyAccessed/Add",
                method: "get"
            },
            {
                name:"GetRecentlyAccessed",
                url: "RecentlyAccessed/Select",
                method: "get"
            },
            {
                name:"GetRecentlyCount",
                url: "RecentlyAccessed/Count",
                method: "get"
            },
            {
                name: "getUserInfoByEid",
                url: "People/getInfo",
                method: "post"
            },
            {
                name:"checkPeopleCountry",
                url:"People/checkCountry",
                method:"post"
            },
            {
                name:"addUserAccess",
                url:"user/addUserAccessByEid",
                method:"post"
            },
            {
                name:"removeUserAccess",
                url:"user/removeUserAccessByEid",
                method:"post"
            },
            {
                name: "DeleteRecords",
                url: "Record/DeleteRecords",
                method: "post"
            },
            {
                name: "DeleteReject",
                url: "Record/DeleteReject",
                method: "post"
            },
            {
                name: "DeleteApprove",
                url: "Record/DeleteApprove",
                method: "post"
            },
            {
                name: "checkUserExisting",
                url: "user/checkExisting",
                method: "post"
            },
            {
                name: "TerminationReject",
                url: "Record/Termination/Reject",
                method: "post"
            },
            {
                name: "TerminationApprove",
                url: "Record/Termination/Approve",
                method: "post"
            },
            {
                name: "TerminationRequest",
                url: "Record/TerminationRequest",
                method: "post"
            },
            {
                name: "HPServiceHistory",
                url: "History",
                method: "HPServiceApiget"
            },
            {
                name: "checkRecStatus",
                url: "record/checkRecStatus",
                method: "get"
            },
            {

                name: "RecordList",
                url: "Search/RecordList",
                method: "doWebGet"

            },
            {

                name: "RecordListCount",
                url: "Search/RecordListCount",
                method: "doWebGet"

            },
            {

                name: "GetRecordList",
                url: "Search/GetRecordList",
                method: "doWebGet"
            },
            {

                name: "GetUserReviewStatus",
                url: "user/userReviewStatus",
                method: "get"
            },
            {

                name: "ConfirmUserReviewStatus",
                url: "user/confirmReviewStatus",
                method: "post"
            },
            {
                name: "ShowNotification",
                url: "Notification/ShowNotification",
                method: "post"
            },
            {
                name: "DeleteRecentlyAccessed",
                url: "RecentlyAccessed/Delete",
                method: "get"
            },
            {
                name: "getUserDetailsByEid",
                url: "user/getUserDetailsByEid",
                method: "post"
            },            
            {
                name: "updateUserDetails",
                url: "user/updateUserDetails",
                method: "post"
            },
            {
                name: "UserAccessAdminReject",
                url: "UserAccessRequest/AdminReject",
                method: "post"
            },
            {
                name: "UserAccessAdminApprove",
                url: "UserAccessRequest/AdminApprove",
                method: "post"
            },
            {
                name: "UserAccessGULeadReject",
                url: "UserAccessRequest/GUReject",
                method: "post"
            },
            {
                name: "UserAccessGULeadApprove",
                url: "UserAccessRequest/GUApprove",
                method: "post"
            },
            {
                name: "getDocumentOwnerList",
                url: "Record/GetDocOwnerList",
                method: "post"
            },
            {
                name: "getSecDocumentOwnerList",
                url: "Record/GetSecDocOwnerList",
                method: "post"
            },
            {
                name: "UserAccessGetKeyCategory",
                url: "UserAccessRequest/GetKeyCategory",
                method: "get"
            },
            {
                name: "UserAccessGetBU",
                url: "UserAccessRequest/GetBU",
                method: "get"
            },
            {
                name: "UserAccessSubmit",
                url: "UserAccessRequest/NewRequest",
                method: "post"
            },
            {
                name: "EditDocOwner",
                url: "Record/EditDocOwner",
                method: "post"
            },
            {
                name: "SaveTemplateRecord",
                url: "TemplateRecord/Save",
                method: "post"
            },
            {
                name: "GetMyTempsList",
                url: "TemplateRecord/GetMyTemps",
                method: "post"
            },
            {
                name: "ToGetTemplateRecord",
                url: "TemplateRecord/GetTempRecordByUri",
                method: "post"
            },
            {
                name: "IdleQueueReassign",
                url: "Activity/IdleQueueReassign",
                method: "post"
            },
            {
                name: "EditActiveTerminatingRecord",
                url: "Record/EditActiveTerminated",
                method: "post"
            },{
                name: "UserHistory",
                url: "User/UserHistory",
                method: 'post'
            },
            {
                name: "DeleteRecforRequiredAction",
                url: "Record/DeleteRecsforRequireAction",
                method: "post"
            },
            {
                name: "UserList",
                url: "Search/UserList",
                method: "post"
            },
            {
                name: "PrimaryClientName",
                url: "People/ClientName",
                method: "post"
            },
        ]);
    }
}
