import { Component, OnInit, Input, Output, ChangeDetectorRef } from '@angular/core';
import { Factory } from 'app/app.factory';
import { Directive, HostListener } from '@angular/core';
@Component({
    selector: 'app-clientpicker',
    templateUrl: './clientpicker.component.html',
    styleUrls: ['./clientpicker.component.css'],
    inputs: [
        'inputData',
        'noLabel',
    ],
    outputs: [
        'outputFn',
    ]
})
export class ClientPickerComponent implements OnInit {

    public inputData: any;
    public noLabel: boolean = false;
    public selectionValue: any;
    public service: any;
    public svalueIsNull: boolean;
    public isShowpeopleMask: boolean;
    public ToolTip: any;
    public newValue: boolean;
    public InfoShow: boolean;
    public information: any;
    public setTimeoutId:any;
    public isTypeEnd :boolean;
    public isShowpeopleList :boolean;
    constructor(private factory: Factory,
        public changeDetectorRef: ChangeDetectorRef) { }

    ngOnInit() {
        if (this.inputData.information != "" && this.inputData.information != undefined) {
            this.InfoShow = true;
            this.information = this.inputData.information;
        } else {
            this.InfoShow = false;
            this.information = "";
        }
        if (this.inputData.toolTip == true && this.inputData.selectTip == true) {
            this.newValue = true;
            this.ToolTip = this.inputData.oldValue;
        } else {
            this.newValue = false;
            this.ToolTip = '';
        }
        this.service = this.factory.getService();
        this.CheckValidation();
        this.selectionValue = [];
        this.svalueIsNull = false;
        this.isShowpeopleMask = false;


    }
    
    valiChange() {
        $("input").css("background-color", "#D6D6FF");
    }
    onChanged(e: any) {
        this.isShowpeopleList = false;
        //let valedData = e.replace(/[\u4E00-\u9FA5]/g, '').trim();
        //valedData = valedData.replace(/\s+/g,'');
        //this.inputData.value = valedData;
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
        this.svalueIsNull = false;
        this.isShowpeopleMask = true;
        this.selectionValue = [];
        if (this.inputData.value.length > 2) { 
            var data = { name: this.inputData.value };
            this.service.PrimaryClientName(data).then(res => {
                if(!this.isTypeEnd){
                this.selectionValue = res;
                if (this.selectionValue.length !== 0) {
                    this.svalueIsNull = false; 
                } else {
                    this.svalueIsNull = true;
                }
                this.isShowpeopleList = true;
            }
            });
        }
    }
    SelectedPeople(data: any) {
        this.inputData.value = data;
        this.isShowpeopleMask = false;
        this.inputData.isValidation = true;
        this.isShowpeopleList = false;
    }
    ClearNoValidData() {
        this.isTypeEnd = true;
        this.isShowpeopleList = false;
        if (this.inputData.value !== "" && this.isShowpeopleMask) {
            // BUG 149599
            if (this.inputData.value.trim() === '') {
                this.inputData.value = "";
                return;
            }
            var data = { name: this.inputData.value };
            this.service.checkClientValidation(data).then(res => {
                //console.log("num", res);
                if (res > 0) {
                    this.inputData.isValidation = true;
                } else {
                    this.inputData.value = "";
                }
                this.isShowpeopleMask = false;
            });
        }
    }

    CheckValidation() {
        this.inputData.isValidation = true;
        if (this.inputData.value !== "") {
            var data = { name: this.inputData.value };
            this.service.checkClientValidation(data).then(res => {
                console.log("num", res);
                if (res > 0) {
                    this.inputData.isValidation = true;
                    this.svalueIsNull = false;
                } else {
                    this.inputData.isValidation = false;
                    this.svalueIsNull = true;
                }
            });
        }

    }
    
    itemActive(item: string) {
        this.isTypeEnd = false;
        this.isShowpeopleList = false;
        if(this.setTimeoutId != null){
        clearTimeout(this.setTimeoutId);
        }
        this.setTimeoutId = setTimeout(() => {
           this.onChanged(item); 
        clearTimeout(this.setTimeoutId);
        this.setTimeoutId = null;
        }, 1000);
       
        }
}
