<div class="msgBox">
    <div class="title">
        <div class="title-content">Deletion Request</div>
        <i (click)="onClose()" class="close-icon mdui-icon material-icons">clear</i>
    </div>
    <p class="contentTitle_Font padTop">Are you sure you want to delete this record?</p>
    <p class="noteTip ove">
        <i class="mdui-icon material-icons nosapInfo tipInfo fl">info</i>
        <span class="fl tipTxt">Note: This record will be permanently deleted.</span>
    </p>
    <div class="footer">
        <button [disabled]="isLoading" class="submit_btn cancel fr" (click)="isLoading ? undefined : onClose()" style="width: 88px">
            <i class="mdui-icon material-icons sub_btn delete_sub_btn">close</i>
            <span class="btn_txt">Cancel</span>
        </button>
        <button [disabled]="isLoading" class="submit_btn confirm fr" (click)="isLoading ? undefined : onConfirm()" style="width: 94px">
            <i class="mdui-icon material-icons sub_btn delete_sub_btn">delete</i>
            <span class="btn_txt">Delete</span>
        </button>
    </div>
</div>