<section>
    <div class="rele">
        <div class="up_title another_up" (click)="isAgreement()">
            <i *ngIf="isMore" class="mdui-icon material-icons expend_icon">expand_more</i>
            <i *ngIf="!isMore" class="mdui-icon material-icons expend_icon">chevron_right</i>
          Agreement Info
        </div>
        <div class="tab_content isAgreement">
            <div class="tab_line">
                <div class="row">
                    <div class="col-6 col-lg-3">
                        <app-input [inputData]="recordFormData.AgreementID"></app-input>
                    </div>
                    <div class="col-6 col-lg-3">
                        <app-single-select [inputData]="recordFormData.AgreementStatus" [selectionValue]="recordDorpdownData.getStatus"></app-single-select>
                    </div>
                    <div class="col-6 col-lg-3">
                        <app-input [inputData]="recordFormData.AgreementName"></app-input>
                    </div>
                    <div class="col-6 col-lg-3">
                        <app-single-select [inputData]="recordFormData.AgreementType" [selectionValue]="recordDorpdownData.getTypes" (change)="setAgreementTypeInfo()"></app-single-select>
                    </div>
                </div>
                <div class="row mard_10">
                    <div class="col-6 col-lg-3">
                        <app-predecessorID [inputData]="recordFormData.PredecessorAgreementID" [supplierData]="recordFormData.SupplierID"></app-predecessorID>
                    </div>
                    <div class="col-6 col-lg-3">
                        <app-single-select [inputData]="recordFormData.AgreementPaper" [selectionValue]="recordDorpdownData.getPapers"></app-single-select>
                    </div>
                    <!-- <div class="mod_2 wid_17">
                        <app-single-select [inputData]="recordFormData.RenewalType" [selectionValue]="recordDorpdownData.getRenewals"></app-single-select>
                    </div> -->

                    <!-- <div class="mod_2 wid_30">
                        <app-input [inputData]="recordFormData.OtherLanguage" *ngIf="recordFormData.OtherLanguage.isShow||recordFormData.Language.value=='Other'"></app-input>
                    </div> -->
                    <div class="col-6 col-lg-3">
                        <app-single-select [inputData]="recordFormData.RenewalType" [selectionValue]="recordDorpdownData.getRenewals"></app-single-select>
                    </div>
                    <div class="col-6 col-lg-3">
                        <app-single-select [inputData]="recordFormData.PaymentTerms" [selectionValue]="recordDorpdownData.getTerms" (change)="onPaymentTermsChanged()"></app-single-select>
                    </div>
                </div>
                <div class="row mard_10 relimit">
                    <div class="col-12">
                        <label [for]="inputsData.formFields.ReasonForPaymentType.filedId" class="box_title"><span *ngIf="inputsData.formFields.ReasonForPaymentType.IsRequired" class="red">*</span>Reason for Payment Term is None of above please specify</label>
                        <textarea [id]="inputsData.formFields.ReasonForPaymentType.filedId" *ngIf="!inputsData.formFields.ReasonForPaymentType.isRead" class="doc_box solid_box textarea_box reasonTextarea"
                            [(ngModel)]="inputsData.formFields.ReasonForPaymentType.value"
                            (ngModelChange)="onPaymentTermsOtherChanged()"
                            [maxlength]="inputsData.formFields.ReasonForPaymentType.maxlength"
                            [ngClass]="{'error':!inputsData.formFields.ReasonForPaymentType.isValidation,'nowrap':inputsData.formFields.ReasonForPaymentType.IsDisabled}"
                            [placeholder]="inputsData.formFields.ReasonForPaymentType.placeholder"
                            [disabled]="inputsData.formFields.ReasonForPaymentType.IsDisabled">
                        </textarea>
                        <span class="numLimit">{{num}}/200</span>
                        <div class="doc_box solid_box textarea_box disabled"
                        *ngIf="inputsData.formFields.ReasonForPaymentType.isRead">
                            <span 
                            [tooltip]="recordFormData.ReasonForPaymentType.value ? recordFormData.ReasonForPaymentType.value : ''" 
                            [id]="inputsData.formFields.ReasonForPaymentType.filedId" 
                            unselectable="on" readonly="readonly" 
                            [ngClass]="{'tip_span':newReasonValue}">{{inputsData.formFields.ReasonForPaymentType.value}}</span>  
                            <i *ngIf="newReasonValue && inputsData.formFields.ReasonForPaymentType.isRead" class="mdui-icon material-icons tooltip_icon reasonIcon" [tooltip] = "ReasonToolTip ? ReasonToolTip : ''" >info</i>
                        </div>
                    </div>
                </div>
                <div class="row mard_10">
                    <!-- <div class="tab_line" *ngIf="recordFormData.OtherAgreementType.isShow||recordFormData.AgreementType.value=='Other' ">
                        <div class="OtherAgreementTypePosition mod_2 wid_17">
                            <app-input [inputData]="recordFormData.OtherAgreementType"></app-input>
                        </div>
                    </div> -->
                    <div class="col-6 col-lg-3">
                        <div class="label_box">
                            <label for="AgreementStartDate">
                                <span *ngIf="recordFormData.AgreementStartDate.IsRequired" class="asterisk">*</span>
                                    {{recordFormData.AgreementStartDate.labelName}}
                            </label>
                        </div>
                        <div class="rel">
                            <div class="input-group" [ngStyle]="!recordFormData.AgreementStartDate.isRead ? {} : {display: 'none'}">
                                
                                <div class="group-input icon_valid">
                                    <i *ngIf="!recordFormData.AgreementStartDate.isValidation&&recordFormData.AgreementStartDate.value!==null&&recordFormData.AgreementStartDate.value !==''" tooltip="Agreement Start Date cannot be after Agreement End Date" class="mdui-icon material-icons error_date">&#xe000;</i>
                                    <input [ngClass]="{'nowrap':recordFormData.AgreementStartDate.IsDisabled,'errorText':!recordFormData.AgreementStartDate.isValidation}" id="AgreementStartDate" [disabled]="recordFormData.AgreementStartDate.IsDisabled || recordFormData.AgreementStartDate.isRead" type="text" class="form-control" #dp_start_date="bsDatepicker" bsDatepicker  [(ngModel)]="recordFormData.AgreementStartDate.value"
                                        (ngModelChange)="dateChangeListen('AgreementStartDate')" [bsConfig]="bsConfig" (blur)="dateBlur($event)" placeholder="DD-Mon-YYYY">
                                </div>
                                <div class="group-icon">
                                    <i *ngIf="!recordFormData.AgreementStartDate.IsDisabled" [ngClass]="{'error_icon':!recordFormData.AgreementStartDate.isValidation}" class="mdui-icon material-icons" (click)="dp_start_date.toggle()" [attr.aria-expanded]="dp_start_date.isOpen">date_range</i>
                                </div>
                            </div>
                            <div *ngIf="recordFormData.AgreementStartDate.isRead" style="float: none;">
                                <span class="span_is_read nowrap">
                                    <span class="nowrap" [ngClass]="{'tip_span':newStartValue}" [tooltip]="recordFormData.AgreementStartDate.value ? recordFormData.AgreementStartDate.value : ''">{{recordFormData.AgreementStartDate.value}}</span>
                                <i *ngIf="newStartValue" class="mdui-icon material-icons tooltip_icon" [tooltip]="StartToolTip ? StartToolTip : ''">info</i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-3">
                        <div class="label_box">
                            <label for="AgreementEndDate">
                                <span *ngIf="recordFormData.AgreementEndDate.IsRequired" class="asterisk">*</span>
                                    {{recordFormData.AgreementEndDate.labelName}}
                            </label>
                        </div>
                        <div class="rel">
                            <div class="input-group" [ngStyle]="!recordFormData.AgreementEndDate.isRead ? {} : {display: 'none'}">
                                <div class="group-input">
                                    <i *ngIf="!recordFormData.AgreementEndDate.isValidation && recordFormData.AgreementEndDate.value !== null && recordFormData.AgreementEndDate.value!== ''" tooltip="Agreement End Date cannot be before Agreement Start Date" class="mdui-icon material-icons error_date">&#xe000;</i>
                                    <input id="AgreementEndDate" [ngClass]="{'nowrap':recordFormData.AgreementEndDate.IsDisabled,'errorText':!recordFormData.AgreementEndDate.isValidation}" [disabled]="recordFormData.AgreementEndDate.IsDisabled || recordFormData.AgreementEndDate.isRead" type="text" class="form-control" #dp_end_date="bsDatepicker" bsDatepicker placeholder="DD-Mon-YYYY" [(ngModel)]="recordFormData.AgreementEndDate.value" (ngModelChange)="dateChangeListen('AgreementEndDate')"
                                        [bsConfig]="bsConfig" (blur)="dateBlur($event)">
                                </div>
                                <div class="group-icon">
                                    <i *ngIf="!recordFormData.AgreementEndDate.IsDisabled" [ngClass]="{'error_icon':!recordFormData.AgreementEndDate.isValidation}" class="mdui-icon material-icons" (click)="dp_end_date.toggle()" [attr.aria-expanded]="dp_end_date.isOpen">date_range</i>
                                </div>
                            </div>
                            <div *ngIf="recordFormData.AgreementEndDate.isRead" style="float: none;">
                                <span class="span_is_read nowrap">
                                    <span class="nowrap" [ngClass]="{'tip_span':newEndValue}" [tooltip]="recordFormData.AgreementEndDate.value ? recordFormData.AgreementEndDate.value : ''">{{recordFormData.AgreementEndDate.value}}</span>
                                <i *ngIf="newEndValue" class="mdui-icon material-icons tooltip_icon" [tooltip]="EndToolTip ? EndToolTip : ''">info</i>
                                </span>

                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-3">
                        <div>
                            <label [for]="recordFormData.RenewalNotice.filedId"><span *ngIf="recordFormData.RenewalNotice.IsRequired" class="asterisk">*</span>{{recordFormData.RenewalNotice.labelName}}</label>
                        </div>
                        <div class = "renewal_notice">
                            <i *ngIf = "!recordFormData.RenewalNotice.isValidation&&recordFormData.RenewalNotice.value!==''" 
                            tooltip = "Renewal/Review Date cannot before the Agreement Start Date.&#10;Please reduce the Renewal/Review Notice days"
                            class="mdui-icon material-icons erroricon">&#xe000;</i>
                            <input [id]="recordFormData.RenewalNotice.filedId" [ngStyle]="!recordFormData.RenewalNotice.isRead ? {} : {display: 'none'}" [maxlength]="recordFormData.RenewalNotice.maxlength" type="text" [(ngModel)]="recordFormData.RenewalNotice.value" 
                            (ngModelChange)="onRenewalNoticeChanged($event)" 
                            (blur)="checkRenewNotice($event)"
                            [placeholder]="recordFormData.RenewalNotice.placeholder" 
                            class="form-control" [ngClass]="{'error':!recordFormData.RenewalNotice.isValidation,'nowrap':recordFormData.RenewalNotice.IsDisabled}" />
                            <div *ngIf="recordFormData.RenewalNotice.isRead" style="float: none;">
                                <span class="span_is_read nowrap">
                                    <span class="nowrap" [ngClass]="{'tip_span':newValue}" [tooltip] = "recordFormData.RenewalNotice.value ? recordFormData.RenewalNotice.value : ''" >{{recordFormData.RenewalNotice.value}}</span>
                                <i *ngIf="newValue" class="mdui-icon material-icons tooltip_icon" [tooltip] = "RenewalNoticeToolTip ? RenewalNoticeToolTip : ''" >info</i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-3">
                                    
                        <div style="position: relative;">
                            <div>
                                <label [for]="recordFormData.RenewalDate.filedId">
                                <span *ngIf="recordFormData.RenewalDate.IsRequired" class="asterisk">*</span>
                                {{recordFormData.RenewalDate.labelName}}
                            </label>
                            </div>                         
                            <!-- Renewal/Review notify start --> 
                            <div id="RenewedReviewedS">          
                                <div id="Renewed" *ngIf="!recordFormData.RenewedReviewed.isRead" class="notifyBox" style="position: absolute; left: 130px;">
                                    <input id="notRenewedNotify" (ngModelChange)="recordFormData.RenewedReviewed.value = checkOne($event)" [(ngModel)]="recordFormData.RenewedReviewed.value"  [ngClass]="{'error':!recordFormData.RenewedReviewed.isValidation}" class="multi_checkbox fl cur renewedSty" type="checkbox" [disabled]="isRenewedY" />
                                   
                                    
                                    <label class="fontStyle fl cur" style="color: #555;padding-left: 10px;">
                                        <span  class="red ng-star-inserted" style="left: 24px;top:3px">*</span>
                                        <span  id="RenewedVal" style="font-size: 10px;position: absolute; left: 30px;">Renewed/Reviewed</span>
                                    </label>
                                    
                                    <i class="mdui-icon material-icons nosapInfo" [tooltip]="recordFormData.RenewedReviewed.information" style="font-size: 14px;position: absolute;left: 120px;">info</i>
                                </div>      
                                <div id="RenewedR" *ngIf="recordFormData.RenewedReviewed.isRead" class="notifyBox" style="position: absolute; left: 130px;">
                                    <input id="notRenewedNotify" (ngModelChange)="recordFormData.RenewedReviewed.value = checkOne($event)" [(ngModel)]="recordFormData.RenewedReviewed.value" class="multi_checkbox fl cur renewedSty" type="checkbox" disabled/>
                                    
                                    <label class="fontStyle fl cur" style="color: #555;padding-left: 10px;">
                                        <span  class="red ng-star-inserted" style="left: 24px;top:3px">*</span>
                                        <span  id="RenewedVal" style="font-size: 10px;position: absolute; left: 30px;">Renewed/Reviewed</span>
                                    </label>
                                </div> 
                            </div>                                                        
                            <!-- Renewal/Review notify end-->               
                        </div>                       
                        <div class="rel">
                            <div class="input-group" [ngStyle]="!recordFormData.RenewalDate.isRead ? {} : {display: 'none'}">
                                <div class="group-input">
                                    <input [id]="recordFormData.RenewalDate.filedId" [disabled]="recordFormData.RenewalDate.IsDisabled" type="text" class="form-control" #dp_renewal_date="bsDatepicker" bsDatepicker [(ngModel)]="recordFormData.RenewalDate.value" [bsConfig]="bsConfig" [ngClass]="{'errorText':!recordFormData.RenewalDate.isValidation,'nowrap':recordFormData.RenewalDate.IsDisabled}">
                                </div>
                                <div class="group-icon">
                                    <i *ngIf="!recordFormData.RenewalDate.IsDisabled" [ngClass]="{'error_icon':!recordFormData.RenewalDate.isValidation}" class="mdui-icon material-icons" (click)="dp_renewal_date.toggle()" [attr.aria-expanded]="dp_renewal_date.isOpen">date_range</i>
                                </div>
                            </div>
                            <div *ngIf="recordFormData.RenewalDate.isRead" style="float: none;">
                                <span class="span_is_read nowrap">
                                    <span class="nowrap" [ngClass]="{'tip_span':newValueRenewalDate}" [tooltip]="recordFormData.RenewalDate.value ? recordFormData.RenewalDate.value : ''">{{recordFormData.RenewalDate.value}}</span>
                                <i *ngIf="newValueRenewalDate" class="mdui-icon material-icons tooltip_icon" [tooltip]="ToolTip ? ToolTip : ''">info</i>
                                </span>
                            </div>
                           
                        </div>
                    </div>
                </div>
                <div class="row mard_10">
                    <div class="col-6 col-lg-3">
                        <app-single-select [inputData]="recordFormData.Language" [selectionValue]="recordDorpdownData.getLanguages" (change)="setLanInfo()"></app-single-select>
                    </div>
                    <div class="col-6 col-lg-3">
                        <div>
                            <label [for]="recordFormData.AgreementValue.filedId"><span *ngIf="recordFormData.AgreementValue.IsRequired" class="asterisk">*</span>{{recordFormData.AgreementValue.labelName}}</label>
                        </div>
                        <div class = "renewal_notice">
                            <input [id]="recordFormData.AgreementValue.filedId" [ngStyle]="!recordFormData.AgreementValue.isRead ? {} : {display: 'none'}" [maxlength]="recordFormData.AgreementValue.maxlength" type="text" [(ngModel)]="recordFormData.AgreementValue.value" 
                            (ngModelChange)="onAgreementValueChanged($event)" 
                            [disabled]="recordFormData.AgreementValue.IsDisabled"
                            (blur)="checkAgreementValue($event)"
                            [placeholder]="recordFormData.AgreementValue.placeholder" 
                            class="form-control agreementValueInp" [ngClass]="{'error':!recordFormData.AgreementValue.isValidation,'nowrap':recordFormData.AgreementValue.IsDisabled}" />
                            <div *ngIf="recordFormData.AgreementValue.isRead" style="float: none;">
                                <span class="span_is_read nowrap">{{recordFormData.AgreementValue.value}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-3">
                    <div>
                            <label [for]="recordFormData.MMDAgreementID.filedId"><span *ngIf="recordFormData.MMDAgreementID.IsRequired" class="asterisk">*</span>{{recordFormData.MMDAgreementID.labelName}}</label>
                    </div>
                        <div class = "renewal_notice">
                            <i *ngIf = "!recordFormData.MMDAgreementID.isValidation&&recordFormData.MMDAgreementID.value!==''"
                                class="mdui-icon material-icons erroricon">&#xe000;</i>
                            <input [id]="recordFormData.MMDAgreementID.filedId" [ngStyle]="!recordFormData.MMDAgreementID.isRead ? {} : {display: 'none'}" [maxlength]="recordFormData.MMDAgreementID.maxlength" type="text" [(ngModel)]="recordFormData.MMDAgreementID.value"
                        
                        
                                [placeholder]="recordFormData.MMDAgreementID.placeholder"
                                class="form-control" [ngClass]="{'error':!recordFormData.MMDAgreementID.isValidation,'nowrap':recordFormData.MMDAgreementID.IsDisabled}" />
                                    <div *ngIf="recordFormData.MMDAgreementID.isRead" style="float: none;">
                                        <span class="span_is_read nowrap">
                                            <span class="nowrap" [ngClass]="{'tip_span':newValue}" [tooltip] = "recordFormData.MMDAgreementID.value ? recordFormData.MMDAgreementID.value : ''" >{{recordFormData.MMDAgreementID.value}}</span>
                                        </span>
                                    </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-3">
                        <app-single-select [inputData]="recordFormData.SignatureType" [selectionValue]="recordDorpdownData.getSignatureTypes" (change)="SignatureTypeChange($event)"></app-single-select>
                    </div>
                    <div class="col-6 col-lg-3" *ngIf="recordFormData.ESignatureOtherText.isShow||recordFormData.SignatureType.value==='E-signature - Other'">
                        <div>
                            <label [for]="recordFormData.ESignatureOtherText.filedId"><span *ngIf="recordFormData.ESignatureOtherText.IsRequired" class="asterisk">*</span>{{recordFormData.ESignatureOtherText.labelName}}</label>
                        </div>
                        <div class = "renewal_notice">
                            <input [id]="recordFormData.ESignatureOtherText.filedId" [ngStyle]="!recordFormData.ESignatureOtherText.isRead ? {} : {display: 'none'}" [maxlength]="recordFormData.ESignatureOtherText.maxlength" type="text" [(ngModel)]="recordFormData.ESignatureOtherText.value" 
                            
                            [disabled]="recordFormData.ESignatureOtherText.IsDisabled"
                            
                            [placeholder]="recordFormData.ESignatureOtherText.placeholder" 
                            class="form-control agreementValueInp" [ngClass]="{'error':!recordFormData.ESignatureOtherText.isValidation,'nowrap':recordFormData.ESignatureOtherText.IsDisabled}" />
                            <div *ngIf="recordFormData.ESignatureOtherText.isRead" style="float: none;">
                                <span class="span_is_read nowrap" [tooltip] = "recordFormData.ESignatureOtherText.value ? recordFormData.ESignatureOtherText.value : ''" >{{recordFormData.ESignatureOtherText.value}}</span>
                            </div>
                        </div>
                    </div>
                    <!--<div class="col-lg-3">
                        <label [for]="recordFormData.MMDAgreementID.filedId">
                            {{recordFormData.MMDAgreementID.labelName}}</label>
                        <app-input [inputData]="recordFormData.MMDAgreementID"></app-input>
                    </div>-->
                </div>
            </div>
            <!-- <div class="tab_line" *ngIf="recordFormData.OtherLanguage.isShow||recordFormData.Language.value=='Other'">
                    <div class="wid_30">
                        <app-input [inputData]="recordFormData.OtherLanguage"></app-input>
                    </div>
                    <div class="mod_2 wid_17">
                    </div>
                    <div class="mod_2 wid_17">
                    </div>
                    <div class="mod_2 wid_17">
                    </div>
            </div> -->
        </div>
    </div>
</section>
