<section>
    <BasicInfo [inputsData]="inputsData" 
                [templatePage]="templatePage"
                [isTemplate]="isTemplate"
                (outputRecordTypeData)="outputRecordTypeDataHandler($event)"
                [secondarySuppliersInputer] = "secondarySuppliersInputer"
                (sndSuppliersOutputer) = "outputSecondarySupplierDataHandler($event)"
        >
        </BasicInfo>
        <hr class="section-line">
        <AgreementInfo [inputsData]="inputsData"></AgreementInfo>
        <hr class="section-line">
        <CategoryLocation [inputsData]="inputsData"></CategoryLocation>
        <hr class="section-line">
        <RiskCompliance [inputsData]="inputsData"></RiskCompliance> 
        <hr class="section-line">
        <Documents [inputsData]="inputsData" 
                [isEditProcess]="isEditProcess"
                [templatePage]="templatePage"
                [templateDocPage]="templateDocPage"
                [fileInputer]="fileInputer" 
                [recordTypeInputer]="recordTypeInputer" 
                (docOutputer)="outputHandler($event)"
                (docSuccessFilesOutputer)="outSuccessFilesHandler($event)"
                (docMaskStatusOutputer) = "docMaskStatusOutputHandler($event)"
        ></Documents>
        <hr class="section-line">
        <Information [inputsData]="inputsData"></Information>
        <hr class="section-line">
</section>