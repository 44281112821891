<section>
    <div>
        <span class="radio label" style="position: relative;" ><span style="position: absolute;left: -10px;" *ngIf="inputData.IsRequired" class="red">*</span> {{inputData.labelName}}
            <i *ngIf="InfoShow && !inputData.isRead"
                class="mdui-icon material-icons nosapInfo"
                [tooltip]="information"
                [tooltip-class]="'tooltip-class-radio'">info</i>
        </span>
    </div>
    <div class="radio-group">
        <div *ngIf="!inputData.isRead" class="radio" style="margin-right: 45px;">
            <label class="label">
                <input type="radio" [name]="inputName === undefined ? inputData.searchClauseName : inputName" [disabled]="inputData.IsDisabled" (ngModelChange)="onChanged($event)"
                    [ngClass]="{'error':!inputData.isValidation}" [(ngModel)]="inputData.value" value="Yes" />
                <span></span>Yes
            </label>
        </div>
        <div *ngIf="!inputData.isRead" class="radio">
            <label class="label">
                <input type="radio" [name]="inputName === undefined ? inputData.searchClauseName : inputName" [disabled]="inputData.IsDisabled" (ngModelChange)="onChanged($event)"
                [ngClass]="{'error':!inputData.isValidation}" [(ngModel)]="inputData.value" value="No" />
                <span></span>No
            </label>
        </div>
        <div class="radio" style="display: flex;align-items: center;" [ngClass]="{'tip_span':newValue}" *ngIf="inputData.isRead">
            <label *ngIf="inputData.value" class="label">
            <input type="radio" [name]="inputName === undefined ? inputData.searchClauseName : inputName" readonly="readonly" checked [value]="inputData.value" />
            <span *ngIf="inputData.value && inputData.value.toString().trim() !== ''"></span>
            <span [ngClass]="{'tip_radio_Read':newValue}" class="radio_Read nowrap" [tooltip] = "inputData.value ? inputData.value : ''" >{{inputData.value}}</span>
            </label>
            <i *ngIf="newValue" class="mdui-icon material-icons tooltip_icon" [tooltip] = "ToolTip ? ToolTip : ''" [tooltip-class]="'tooltip-class-radio'">info</i>
        </div>
    </div>
</section>
