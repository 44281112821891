
<div class="mask" *ngIf="isShow">
    <div class="search_headbox" *ngIf="isShow">
        <div class="titlBox">
            <p class="team_title fl">Action Required</p>
            <i (click)="closeErrPop()" class="mdui-icon material-icons close_icon fr">clear</i>
        </div>
        <div class="tab_content">
            <div class="tab_line content_title" >
                This record currently has multiple files uploaded.
            </div>
            <div class="tab_line content_title">
                Please remove some of them.
            </div>
            <div class="errorMsgBox fl">
                <i class="mdui-icon material-icons close_btn errBtn fl">error</i>
                <p class="error_message">Note: only one file can be uploaded to convert to a parent record.</p>
            </div>
            <div class="tab_line_button">
                <button (click)="closeErrPop()" class="submit_btn fr_ok select_btn ok_btn" type="submit">
                    <i class="mdui-icon material-icons sub_btn">check</i>
                    <span class="btn_txt">OK</span>
                </button> 
            </div>
        </div>
    </div>
</div>

