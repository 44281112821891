import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

declare function escape(s: string): string;
declare function unescape(s: string): string;

@Injectable()
export class PageNavigationService {

    private pageMap = new Map(); //Empty Map

    constructor() { }

    init(pageData: PageData) {
        this.pageMap.set(pageData.urlNoParams, pageData);
    }

    // toDetail(detailUrl: string, form: string) {
    //     this.getPageData(detailUrl).setForm(form);
    // }

    // forward(detailUrl: string) {
    //     this.getPageData(detailUrl).setForm(null);
    // }

    reload() {
        
    }

    // getPageDataByRouter(router: Router): PageData {
    //     const pageData = this.getPageData(PageData.getUrlByRouter(router));
    //     // pageData.url = router.url;
    //     return pageData;
    // }

    getPageDataByRoute(route: ActivatedRoute): PageData {
        const pageData = this.getPageData(PageData.getUrlByActivatedRoute(route));
        // pageData.url = route.snapshot['_routerState'].url;

        // const urlTree = this.router.parseUrl(url);
        // const urlWithoutParams = urlTree.root.children['primary'].segments.map(it => it.path).join('/');
        return pageData;
    }

    getPageData(urlNoParams: string): PageData {
       let pageData = this.pageMap.get(urlNoParams);
       if(pageData == null){
        pageData = PageData.instance(urlNoParams);
        this.pageMap.set(urlNoParams, pageData);
       }
       return pageData;
    }

    refreshRoute(router: Router, route: ActivatedRoute, pageData: PageData) {
        router.navigate(['.'],    {
            relativeTo: route,
            queryParams: pageData.getBase64Params(), 
            queryParamsHandling: "merge", // remove to replace all query params by provided
            replaceUrl: true,
          })
    }



}

export class PageData {

    // url: string;

    urlNoParams: string;

    // detailUrl: string;

    // data: any;

    pageNum: number;

    // from: string;

    sort: string;

    sortDirection: 'asc' | 'desc' | '';

    filters: Map<any, any> = new Map();
    MianSearchCondition:object;


    constructor(urlNoParams?: string) {
        this.urlNoParams = urlNoParams;
     }

    static instance(urlNoParams?: string): PageData {
        return new PageData(urlNoParams);
    }

    static getUrlByRouter(router: Router): string {
        return router.url.split('?')[0];
    }

    static getUrlBase64(route: ActivatedRoute): any {
        return {
            fromUrl: PageData.utf8_to_b64(this.getUrlByActivatedRoute(route))
        };
    }

    static getFromUrl(route: ActivatedRoute): any {
        let fromUrl = route.snapshot.queryParamMap.get('fromUrl');
        if(fromUrl == null){
            fromUrl = "";
        }
        return {
            fromUrl: fromUrl
        };
    }

    static parseUrlBase64(route: ActivatedRoute): string {
        let fromUrl = route.snapshot.queryParamMap.get('fromUrl');
        if(fromUrl == null){
            fromUrl = "";
        }
        return PageData.b64_to_utf8(fromUrl);
    }

    static getUrlByActivatedRoute(route: ActivatedRoute): string {
        return route.snapshot['_routerState'].url.split('?')[0];
    }

    static getPageDataValue(route: ActivatedRoute): any {
        const pageData = route.snapshot.queryParamMap.get('pageData');
        if(pageData == null){
            return {pageData: null};
        }
        return { pageData };
    }

    parsePageData(pageData: string): any {
        let data: any = {};
        if(pageData != null && pageData != "") {
            data = JSON.parse(PageData.b64_to_utf8(pageData));
            data.filters = new Map((Object as any).entries(data.filters));
        }
        return data;
    }

    assignPageData(route: ActivatedRoute): PageData {
        const tempString = this.getPageDataValue(route);
        const temp = this.parsePageData(tempString.pageData);
        Object.assign(this, temp);
        return this;
    }

    getPageDataValue(route: ActivatedRoute): any {
        const pageData = route.snapshot.queryParamMap.get('pageData')
        if(pageData == null){
            return {pageData: null};
        }
        return { pageData };
    }

    hasPageDataValue(route: ActivatedRoute): boolean {
        return this.getPageDataValue(route).pageData != null;
    }

    getParams(): any {
        const params = {
            // from: this.urlNoParams,
            sort: this.sort,
            // detailUrl: this.detailUrl,
            sortDirection: this.sortDirection,
            filters: this.filters,
            pageNum: this.pageNum,
            MianSearchCondition:this.MianSearchCondition
        }
        return params;
    }

    getBase64Params(): any {
       let par = this.getParams();
       par.filters = this.strMapToObject(par.filters);
        const params = {
            pageData: PageData.utf8_to_b64(JSON.stringify(par))
        }
        return params;
    }

    setUrlNoParams(urlNoParams: string): PageData {
        this.urlNoParams = urlNoParams;
        return this;
    }

    // setUrl(url: string): PageData {
    //     this.url = url;
    //     return this;
    // }

    // setDetailUrl(detailUrl: string): PageData {
    //     this.detailUrl = detailUrl;
    //     return this;
    // }

    setPageNum(pageNum: number): PageData {
        this.pageNum = pageNum;
        return this;
    }

    // setForm(from: string): PageData {
    //     this.from = from;
    //     return this;
    // }

    setSort(sort: string): PageData {
        this.sort = sort;
        return this;
    }

    setSortDirection(sortDirection: 'asc' | 'desc' | ''): PageData {
        this.sortDirection = sortDirection;
        return this;
    }

    setFilters(filters: Map<any, any>): PageData {
        this.filters = filters;
        return this;
    }
    setMainSearchCondition(MianSearchCondition: object): PageData {
        this.MianSearchCondition =MianSearchCondition;
        return this;
    }
     getMainSearchCondition(search: any): Array<any> {
        let result = [];
       
            if(search != null) {
                for(var i=0;i< search.length;i++){
                var s=search[i];
            result.push(s);
}
           
        }
                return result;
    }
    getFilterValue(key: string, isDateScope: boolean = false): Array<any> {
        let result = [];
        if(isDateScope){
            const fromItem = this.filters.get(key + '_From');
            const toItem = this.filters.get(key + '_To');
            const dateScope = {form: null, to: null};
            if(fromItem != null){
                dateScope.form = fromItem.value;
            }
            if(toItem != null){
                dateScope.to = toItem.value;
            }
            result = Array.from([dateScope]);
        }else{
            const dataItem = this.filters.get(key);
            if(dataItem != null){
                result = Array.from(dataItem.value);
            }
        }
        return result;
    }

    rest(): PageData {
        // this.from = '';
        // this.detailUrl = '';
        this.sort = '';
        // this.detailUrl = '';
        this.sortDirection = '';
        this.filters = new Map();
        this.pageNum = 0;
        this.MianSearchCondition=null;
        return this;
    }

    private strMapToObject(map: Map<any, any>): any {
        let obj = Object.create(null);
        map.forEach((dataItem, key) => {
            obj[key] = {
                value: dataItem.value
            };
          });
        return obj;
    }

    static utf8_to_b64(str: string) {
        return btoa(unescape(encodeURIComponent(str)));
    }

    static b64_to_utf8(str: string) {
        return decodeURIComponent(escape(atob(str)));
    }

}