import { Component, OnInit, Input, Output } from '@angular/core';
import { Factory } from 'app/app.factory';
import { MessageService } from 'app/common/services/message/messageService';
import { AgreementStaus } from 'app/common/enum/type.enum';
@Component({
    selector: 'app-predecessorID',
    templateUrl: './predecessorID.component.html',
    styleUrls: ['./predecessorID.component.css'],
    inputs: [
        'inputData','supplierData'
    ],
    outputs: [
        'outputFn',
    ]
})
export class PredecessorIDComponent implements OnInit {
    public inputData: any;
    public supplierData: any;
    public selectionValue: any;
    public service: any;
    public svalueIsNull: boolean;
    public isShowpredecessorMask: boolean;
    public switch: boolean;
    public supplierID: any;
    public supplierExist: any;
    public statusSearchString :string ="(AgreementStatus:Active or AgreementStatus:Expired or AgreementStatus:Terminated)";
    public errorInfo :any;
    public InfoShow: boolean;
    public information: any;
    public setTimeoutId:any;
    constructor(private factory: Factory, private message: MessageService) { }

    ngOnInit() {
        if(this.inputData.information!="" && this.inputData.information!=undefined){
            this.InfoShow=true;
            this.information=this.inputData.information;
          }else{
            this.InfoShow=false;
            this.information="";
          }
        this.errorInfo = "Predecessor Agreement ID does not match current record";
        if(this.supplierData.value !== "" &&
        this.supplierData.value !== "Awaiting SAP ID" && this.supplierData.value !== "Not required"
        ){
            this.inputData.IsDisabled = false;
            this.supplierID = this.supplierData.value;
        }
        this.message.getSupplierFlag().subscribe(result => {
            if (result.hasOwnProperty('supplier')) {
                if (result.supplier.SupplierID.value !== ""
                && result.supplier.SupplierID.value !== "Awaiting SAP ID"
                && result.supplier.SupplierID.value !== "Not required") {
                    this.supplierID = result.supplier.SupplierID.value;
                    this.inputData.IsDisabled = false;
                } else {
                    this.inputData.IsDisabled = true; 
                    this.inputData.value = "";
                }
                this.selectionValue = [];
            }
        })
        this.service = this.factory.getService();
        // this.CheckValidation();
        this.selectionValue = [];
        this.svalueIsNull = false;
        this.isShowpredecessorMask = false;
        this.switch = true;
    }

    
        itemActive(item: string) {
            if(this.setTimeoutId != null){
            clearTimeout(this.setTimeoutId);
            }
            this.setTimeoutId = setTimeout(() => {
               this.onChanged(item); 
            clearTimeout(this.setTimeoutId);
            this.setTimeoutId = null;
            }, 1000);
           
            }
    

    onChanged(e:any) {
        this.inputData.isError = false;
        this.svalueIsNull = false;
        this.isShowpredecessorMask = true;
        this.selectionValue = [];
        if (e.length > 2) {
            this.switch = true;
            var searchExistData = "format=json&q=type:[Document] and number:\"" + e + "*\" and not container:[type:[Document Backup]] and not AgreementStatus:\"" + AgreementStaus.Deleted + "\"";
            var returnExistProperties = "properties=TotalResults%2Cnumber&sortBy=number&pageSize=10&start=1";
            this.service.HPServiceRecord(searchExistData, returnExistProperties).then(res => {
                if (res.TotalResults > 0) {
                    // agreementID is exist 
                    var searchData = "format=json&q=" + this.statusSearchString + "and type:[Document] and not container:[type:[Document Backup]] and SupplierID:\"" + this.supplierID + "\" and number:\"" + e + "*\" and not AgreementStatus:\"" + AgreementStaus.Deleted + "\"";
                    var returnProperties = "properties=TotalResults%2Cnumber%2CAgreementName&sortBy=number&pageSize=10&start=1";
                    this.service.HPServiceRecord(searchData, returnProperties).then(result => {
                        var agreementList = [];
                        if (result.TotalResults > 0) {
                            result.Results.forEach(element => {
                                if (this.checkUndefined(element.RecordNumber)) {
                                    var idandname;
                                    var prevalue = element.RecordNumber.Value + " "+ element.Fields.AgreementName.Value
                                    if(prevalue.length > 25){
                                        idandname = prevalue.substring(0,25)+"...";
                                    } else{
                                        idandname = prevalue;
                                    }  
                                    var prede={
                                        value:idandname,
                                        title:prevalue,
                                        originID: element.RecordNumber.Value
                                    };
                                    agreementList.push(prede);
                                }
                                this.selectionValue = agreementList;
                                this.inputData.isValidation = true;
                                this.svalueIsNull = false;
                                this.supplierExist = true;
                            });
                        } else {
                            this.svalueIsNull = true;
                            this.supplierExist = true;
                        }
                    });
                } else {
                    this.svalueIsNull = true;
                    this.supplierExist = false;
                }
            });
        }
    }

    Selectedpredecessor(data: any) {
        this.inputData.value = data;
        this.isShowpredecessorMask = false;
        this.inputData.isValidation = true;
        this.switch = false;
    }

    ClearNoValidData() {
        this.isShowpredecessorMask = false;
        if (this.inputData.value !== "") {
            var searchData = "format=json&q=" + this.statusSearchString + "and type:[Document] and not container:[type:[Document Backup]] and SupplierID:\"" + this.supplierID + "\" and number:\"" + this.inputData.value + "\" and not AgreementStatus:\"" + AgreementStaus.Deleted + "\"";
            var returnProperties = "properties=TotalResults%2Cnumber&sortBy=number&pageSize=10&start=1";
            this.service.HPServiceRecord(searchData, returnProperties).then(result => {
                if (result.TotalResults > 0) {
                    this.inputData.isValidation = true;
                } else {
                    this.inputData.value = "";
                }
            }); 
        } else {
            this.message.sendAgreetypeFlag({AgreementType:""}); 
        }
        this.switch = false;
    }

    checkUndefined(field: any) {
        if (typeof (field) != 'undefined') {
            return true;
        }
        return false;
    }
    
}
