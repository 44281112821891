import { Output, Input, OnInit, Component, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
// import { ElementDef } from '@angular/core/src/view';
import { FilterArrayPipe } from '../tabel-filter/pipe/filter-array.pipe';
import { AuthService } from 'app/common/services/UserAuth/AuthService';
import { trim } from 'lodash';

@Component({
  selector: 'multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.css']
})
export class MultiSelectComponent implements OnInit {
  [x: string]: any;

  /**
   * 
   */
  @Input()
  public items: Array<any> = [];

    /**
   * 
   */
  @Input()
  public checkedItems: Array<any> = [];

  @Input()
  public isDisableforReadonly: Array<any>;

  @Input()
  public keycategorySeachValues: Array<any>;

  @Input()
  public sigalselvalue: boolean = false;

  @Input()
  public  dropdownName:string = '';

  @Input()
  public keyCategoryInfo:string;


  /**
   * 
   */
  @Input()
  public itemSort: 'none' | 'ASC' | 'DESC' = 'ASC';

  @Input()
  public readOnlyKeyCategory: boolean;

  @Input()
  public ngStyle: any;

  public filterText: string = '';
  //public initcheckedItems: Array<any> = [];
  public initcheckedItems: string = '';
  /**
   * 
   */
  @Output() valueChange = new EventEmitter();
  @Output()
  public itemChange: EventEmitter<any> = new EventEmitter<any>();

  public selectAll: any = {};
  public readonlyCategoryValArray = [];
  public checkItChange: boolean = false;
  public isReadOnly: boolean = false;
  public istest: boolean = false;
  public userInfo: any;
  

  constructor(
    private cdRef: ChangeDetectorRef,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    var keyCategoryInfo : any;
    var keyCAll = [];
    var accessStatus : any;
    if(this.auth.getUserInforFn().Group.length == 0){
      accessStatus = "noAccess";
      console.log("no")
    }else{
      accessStatus = this.auth.getUserInforFn().Group[1].split(".")[1];
    }
   
    if(this.readOnlyKeyCategory == true || accessStatus == 'ReadOnly'){
      keyCategoryInfo = this.auth.getUserInforFn().KeyCategory;
      keyCAll = keyCategoryInfo.split(";");
      console.log("111-readonly")
      this.isReadOnly = true;
      if(keyCAll.length == 8){
        this.istest = false;
        console.log("111-readonlyAll")
      } else{
        this.istest = true;
        console.log("111-readonlyn")
      }
    }else{
      this.isReadOnly = false;
      this.istest = false;
      console.log("222")
    }
   
    //this.initcheckedItems=this.checkedItems.slice(0);
    if(this.itemSort === 'ASC' || this.itemSort === 'DESC'){
      this.items = this.sortItems(this.items);
    }
    this.items = this.convertItems(this.items, this.checkedItems, this.isDisableforReadonly);
    console.log(this.items)
    this.itemChange.emit(this.items);    
    this.selectAll.checked = this.items.every((m) => m.checked);
    // if(this.checkedItems.length==this.checkedItems.length){
    //   this.restItems(this.items, true);
    // }
    this.cdRef.detectChanges();
  }

  public itemActive(event: MouseEvent, item: any): void {
    if (this.keycategorySeachValues != undefined) {
      if (this.keycategorySeachValues.length > 0) {
        if (this.keycategorySeachValues.indexOf(item.name) !== -1) {
          if (this.readonlyCategoryValArray.indexOf(item.name) === -1) {
            return
          }
        }
      }
    }
    this.checkItChange = true;
    this.valueChange.emit(this.checkItChange);
    event.stopPropagation();
    event.preventDefault();
    item.checked = !item.checked;
    this.selectAll.checked = this.items.every((m) => m.checked);
    this.itemChange.emit(this.items);
  }

  public restAllItems(event: MouseEvent, selectAll: any) {
    event.stopPropagation();
    event.preventDefault();
    selectAll.checked = false;
    // if(!this.hasCondition()){
    //   return;
    // }
    //debugger;
    this.filterText = '';
    // this.items = this.restItems(this.items);
    // this.itemChange.emit(this.items);
    //this.items = this.convertItems(this.items);
  if(this.dropdownName=='gu'){
    this.initcheckedItems=window.localStorage.getItem("gu");
  }else if(this.dropdownName=='FiscalYear'){
    this.initcheckedItems=window.localStorage.getItem("FiscalYear");
  }else if(this.dropdownName=='KeyCategory'){
    this.initcheckedItems=window.localStorage.getItem("KeyCategory");
  }
    console.log("thisitemssdfasdf",this.items)
    this.items = this.items.map((_item) => {
      if(this.initcheckedItems.indexOf(_item.name)>-1) {
        _item.checked = true;
      }else{
        _item.checked = false;
      }
      return _item;
    });
    var count=(this.initcheckedItems+'').split(",").length;
    if(count==this.items.length){
      selectAll.checked =true;
    }
    this.itemChange.emit(this.items);
  }

  public selectedAllItem(event: MouseEvent, selectAll: any) {
    this.checkItChange = true;
    this.valueChange.emit(this.checkItChange);
    if(this.filterText != ''){
       return;
    }
    event.stopPropagation();
    event.preventDefault();
   
    selectAll.checked = !selectAll.checked;
    this.items = this.restItems(this.items, selectAll.checked);
    this.itemChange.emit(this.items);
  }
  
  stop(event): void {
    event.stopPropagation();
    event.preventDefault();
  }

  public getSelectedItemsString(): string {
    return this.items.filter((m) => m.checked).map((m) => m.name).join(",");    
  }

  public hasCondition(){
    return this.filterText != '' || this.items.some((member) => {
      return member.checked;
    });
  }

  public sortItems(items: Array<any>){
    let _items = [...items];
    _items = _items.sort((a: any, b: any) => {
     const isString = typeof a === 'string' && typeof b === 'string';
     const isNumber = typeof a === 'number' && typeof b === 'number';
     let result = 0;
     if(isNumber){
      if(this.itemSort === 'ASC'){
        result = a - b;  // 
       }else{
        result = b - a;
       }
     }else{
      if(isString){
        a = (a as string).toLocaleLowerCase();
        b = (b as string).toLocaleLowerCase();
      } else {
        a = (a.name as string).toLocaleLowerCase();
        b = (b.name as string).toLocaleLowerCase();
      }
      if(this.itemSort === 'ASC'){
        result = a.localeCompare(b);  // 
      }else{
        result = b.localeCompare(a);
      }
     }
     return result;
    });
    return _items;
   }

  public convertItems(items: Array<any>, checkedItems: Array<any> = [], isDisableforReadOnly: Array<any>) {    
    let _items = [...items];    
    _items = _items.map((_item) => {
     let temp = {
        name: _item,
       checked: false,
       isDisable: false       
      };
      if((checkedItems as any).includes(_item)) {
        temp.checked = true;
      }      
      this.readonlyCategoryValArray = isDisableforReadOnly;   
     
      if (this.sigalselvalue) {
        console.log("isprocessthisjudegment");
        if (this.keycategorySeachValues.indexOf(trim(_item)) !== -1) {          
          this.isReadOnly = true;
          if ((isDisableforReadOnly.indexOf(_item) !== -1)) {
            temp.isDisable = false;
          } else {
            temp.isDisable = true;
          }
        }
      }
      return temp;
    });
    
    return _items;
  }

  public restItems(items: Array<any>, value: boolean = false){
    let _items = [...items];
    _items = _items.map((_item) => {
      _item.checked = value;
      return _item;
    });

    return _items;
  }

  public resetItems(items: Array<any>, value: boolean = false){
    let _items = [...items];
    _items = _items.map((_item) => {
      if(this.checkedItems.indexOf(_item)>-1){
        _item.checked = true;
      }else{
        _item.checked = value;
      }
    
      return _item;
    });

    return _items;
  }

  public hasFilterValues(component: any): boolean {
    const filterArrayPipe = new FilterArrayPipe();
    const values = filterArrayPipe.transform(component.items, {name: component.filterText});
    return values.length > 0;
  }

}
