import { OnInit, Component, Input, EventEmitter, Output, ViewChild, ElementRef, HostListener } from "@angular/core";
import { TypeAheadServiceI } from "./type-ahead.serviceI";

@Component({
    selector: 'type-ahead',
    templateUrl: './type-ahead.component.html',
    styleUrls: ['./type-ahead.component.css'],
})
export class TypeAheadComponent implements OnInit {

    @Input()
    public noResultMsg: string;
    @Input()
    public minMatchSize: number = 0;
    @Input()
    public mustMatched: boolean = true;
    @Input()
    public sourceList: string[];
    @Input()
    public matchType: 'startWith' | 'contentWith' | 'endWidth' = 'startWith';
    @Input()
    public limit: number;

    @ViewChild('type_ahead_content')
    private typeAheadContent: ElementRef;
    @ViewChild('input_content')
    private inputRef: ElementRef;
    @ViewChild('text_list_content')
    private textListContent: ElementRef;

    public value: string;
    public isShowTextList: boolean = false;

    private isTriggerChange: boolean = false;

    set text (value: string) {
        this.value = value;
        if (this.isTriggerChange) {
            this.onTextChange();
        } else {
            this.isTriggerChange = true;
        }
    }
    @Input()
    get text (): string {
        return this.value;
    }
    @Output()
    textChange: EventEmitter<any> = new EventEmitter<any>();

    public textList: string[] = [];

    constructor () {}

    ngOnInit () {}

    ngAfterViewInit () {
        let inputElement = this.getInputNode(this.inputRef.nativeElement);
        inputElement.onfocus = () => {
            this.onTextChange();
        }
    }
    private getInputNode (node) {
        if (!node || !node.children || node.children.length === 0) {
            return null;
        }
        let inputNode;
        for (let i = 0; i < node.children.length; i ++) {
            if (node.children[i] && node.children[i].nodeName === 'INPUT') {
                inputNode = node.children[i];
                break;
            }
        }
        if (inputNode) {
            return inputNode;
        }
        for (let i = 0; i < node.children.length; i ++) {
            inputNode = this.getInputNode(node.children[i]);
            if (inputNode) {
                break;
            }
        }
        return inputNode;
    }

    public showList (): void {
        this.isShowTextList = true;
    }
    public hideList (): void {
        this.isShowTextList = false;
        this.textList = null;
    }

    public onTextChange (): void {
        if (this.minMatchSize === 0 || this.value && this.value.length >= this.minMatchSize) {
            let textList = [];
            if (this.sourceList && this.sourceList.length > 0) {
                textList = this.sourceList.filter((item: string) => {
                    switch (this.matchType) {
                        case 'contentWith': {
                            return item.includes(this.value);
                        }
                        case 'endWidth': {
                            return item.endsWith(this.value);
                        }
                        default: {
                            return item.startsWith(this.value);
                        }
                    }
                });
            }
            if (this.limit) {
                textList = textList.filter((item, index) => index < this.limit);
            }
            this.setList(textList);
        } else {
            this.hideList();
        }
    }

    public setList (textList: string[]): void {
        this.showList();
        this.textList = textList;
    }

    public onSelectItem (text: string): void {
        this.hideList();
        this.isTriggerChange = false;
        this.textChange.emit(text);
    }

    @HostListener('document:click', ['$event'])
    private documentClick (e) {
        if (this.typeAheadContent && this.textListContent 
                && e.target !== this.typeAheadContent.nativeElement 
                && !this.typeAheadContent.nativeElement.contains(e.target)
                && e.target !== this.textListContent.nativeElement 
                && !this.textListContent.nativeElement.contains(e.target)
        ) {
            if (this.mustMatched && (!this.textList || this.textList.length === 0 || this.textList.indexOf(this.value) === -1)) {
                this.isTriggerChange = false;
                this.textChange.emit('');
            }
            this.hideList();
        }
    }
}