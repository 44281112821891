<div class="inline" dropdown container="body" [placement]="placement">
    <div dropdownToggle class="caret inline">
        <i class="mdui-icon material-icons expend_icon">expand_more</i>
    </div>
    <ul *dropdownMenu class="filter-dropdown-menu" role="menu">
      <li class="dropdown-warp"
       [ngClass]="{'clean': !hasCondition()}"
       role="button" (click)="cleanFilter($event)">
          <div class="filter-dropdown-item">Clear Filters</div>
      </li>
      <li class="divider dropdown-divider" (click)="stopPropagation($event)"></li>
      <li class="dropdown-warp" role="button" (click)="stopPropagation($event)">
          <div class="filter-dropdown-item">
              <i class="mdui-icon material-icons expend_icon i-left">search</i>
              <label style="display: none;" [for]="inputId">Type and Search</label>
              <input [id]="inputId" type="text" [(ngModel)]="filterText" maxlength="40"
               (ngModelChange)="itemActive(filterText)"
               class="form-control input-control-left" placeholder="Type and Search">
          </div>
      </li>
    </ul>
</div>