import { Component } from '@angular/core';
import { RecordType} from 'app/common/enum/type.enum';
import { MessageService } from 'app/common/services/message/messageService';

@Component({
    selector: 'RiskCompliance',
    templateUrl: './RiskCompliance.component.html',
    styleUrls: ['./RiskCompliance.component.css'],
    inputs: ["inputsData"]
})
export class RiskComplianceComponent {
    public isMore: boolean = true;
    public inputsData: any;
    public recordFormData: any;
    public hasKeySupplierClicked: boolean = false;
    constructor(
        private message: MessageService,
    ) {
     }
    ngOnInit() {
        console.log("BasicInfo",this.inputsData);
        this.recordFormData = this.inputsData.formFields;
        //Key Supplier to be default as 'No' when type= parent document on the submit form
        if(this.recordFormData.KeySupplier.value == "" && this.recordFormData.ParentOrSupportingDocument.value == RecordType.Parent){
            this.recordFormData.KeySupplier.value = "No";
        }        
        //this.recordFormData.LegacyTool.value = 'Yes';
    }
    changeYN() {
        if(this.recordFormData.CloudServicesInformation.value == "No") {
            this.recordFormData.WhichCountryLocation.value = "";
        }
    }
    


    isRiskCompliance() {
        if(this.isMore == true){
            this.isMore = false
        }else{
            this.isMore = true
        }
        $(".isRiskCompliance").slideToggle();
    }
    KeySupplierClick(){
        this.hasKeySupplierClicked=true;
        this.message.sendKeySupplierClick({hasKeySupplierClicked: this.hasKeySupplierClicked});
    }

}
