import { Component, Input} from '@angular/core';
import { DataItem } from '../models';
import { FilterComponent } from './filter-container.component';

@Component({
  selector: 'simple-search-container',
  templateUrl: './simple-search.component.html',
  styleUrls: ['./compo.scss']
})
export class SimpleSearchComponent extends FilterComponent {

  private setTimeoutId: any = null;

  constructor() {
 super();
}

  @Input()
  public inputId: string;

  ngOnInit(): void {
    super.ngOnInit();

    if (!this.inputId) {
      this.inputId = 'simple_dropdown_' + new Date().getTime();
    }

    this.filterText = this.checkeds.join('') || '';
    this.dataItem
      .setIsLike(this.isLike)
      .setCol(this.property)
      .setAlias(this.property)
      .setOperateChar(':')
      .setValue(this.filterText);
      if(this.filterText == '' || this.filterText == null){
        this._tabelFilterService.removeQuery(this.dataItem);
      }else{
        this._tabelFilterService.addQuery(this.dataItem);
      }
  }

  itemActive(item: string) {
    if(this.setTimeoutId != null){
      clearTimeout(this.setTimeoutId);
    }
    this.setTimeoutId = setTimeout(() => {
        if(item == '' || item == null){
          this._tabelFilterService.removeQuery(this.dataItem);
        }else{
          this._tabelFilterService.addQuery(this.dataItem);
        }
        this.filterText = item;
        this.dataItem.setValue(item);
        this.filterItem.emit(this.dataItem);
        clearTimeout(this.setTimeoutId);
        this.setTimeoutId = null;
    }, 600);
  }

  cleanFilter(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    if(!this.hasCondition()){
      return;
    }
    this.cleanData();
    this.filterItem.emit(this.dataItem);
    
  }

  cleanData(): void {
    if(this.hasCondition()){
      this.filterText = '';
      this.dataItem.setValue('');
      this._tabelFilterService.removeQuery(this.dataItem);
      this._parent.regeditShowAction();
    }
  }

  public hasCondition(): boolean{
    return this.filterText != '';
  }
}
