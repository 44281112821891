import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { MessageService } from 'app/common/services/message/messageService';
import { ASTtype } from 'app/common/enum/type.enum';
import { A11yModule } from '@angular/cdk/a11y';
import { AuthService } from 'app/common/services/UserAuth/AuthService';
import { CommenString } from 'app/common/enum/type.enum';

@Component({
  selector: 'app-radioAST',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.css'],
  inputs: [
    'inputData'
  ],
  outputs:[
    'outputData'
  ]
})
export class RadioASTComponent implements OnInit {

  public inputData: any;
  public radioValue :any;
  public FileListlength: number;
  public outputData =new EventEmitter();
  public MultiDocsInValid: boolean;
  public ToolTip: any;
  public newValue: boolean;
  public ASTvalue: any;
  public InfoShow: boolean;
  public information: any;
  public infoArrow: any='top';
  isAdmin: boolean;
  constructor(
    private message: MessageService,
    private auth: AuthService
) {
 }

  ngOnInit() {
    this.isAdmin = this.auth.getUserInforFn().Group.includes(CommenString.AdminRole);
    if(!this.isAdmin ){
      this.inputData.IsDisabled = true;
    }else{
      this.inputData.IsDisabled = false;
    }
   
    if(this.inputData.information!="" && this.inputData.information!=undefined){
      this.InfoShow=true;
      this.information=this.inputData.information;
      if(this.inputData.infoArrow!="" && this.inputData.infoArrow!=undefined){
        this.infoArrow=this.inputData.infoArrow;
      }
    }else{
      this.InfoShow=false;
      this.information="";
    }
    if(this.inputData.toolTip == true && this.inputData.selectTip == true){
      this.newValue = true;
      this.ToolTip = this.inputData.oldValue;
    } else {
      this.newValue = false;
      this.ToolTip = "";
    }
    if (this.inputData.value == ASTtype.Delegation) {
      this.ASTvalue = "Delegation";
    } else {
      this.ASTvalue = "Below Threshold";
    }
    if (this.inputData.value ==ASTtype.BThreshold) {
      this.ASTvalue = "Below Threshold";
    } else {
      this.ASTvalue = "P+ not involved(excl. Delegation/Threshold)";
    }
    // this.radioValue = "";
    // this.message.getDocToRadioErr().subscribe(result => {
    //   if(result.hasOwnProperty('FileListlength')){
    //     this.FileListlength = result.FileListlength;
    //   }
    // })

  }


  onChanged(event: any) {
    this.radioValue=event;
  //   if(this.radioValue!==""){
  //     this.inputData.isValidation=true;
  //  }
  }


  isSelected(name: string): boolean {
    return (this.inputData.value === name); // if current radio button is selected, return true, else return false  
  }

}
