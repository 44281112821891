<div dropdown container="body" [placement]="placement" #dropdownEl="bs-dropdown">
    <div dropdownToggle class="caret">
        <i class="mdui-icon material-icons expend_icon">expand_more</i>
    </div>
    <ul *dropdownMenu class="filter-dropdown-menu" role="menu">
        <li class="dropdown-warp"
            [ngClass]="{'clean': !hasCondition()}"
            role="button" (click)="cleanFilter($event)">
          <div class="filter-dropdown-item">Clear Filters</div>
        </li>
      <li class="divider dropdown-divider" (click)="stopPropagation($event)"></li>
      <li class="dropdown-warp" role="button" (click)="stopPropagation($event)">
            <div class="filter-dropdown-item" [style.width.px]="getWidth(scroller, dropdownEl.isOpen)">
                <i class="mdui-icon material-icons expend_icon i-left">search</i>
                <label style="display: none;" [for]="inputId">Type and Search</label>
                <input [id]="inputId" type="text" [(ngModel)]="filterText" [disabled]="!haveItems()"
                    class="form-control input-control-left" placeholder="Type and Search">
            </div>
      </li>
      <ul #scroller class="scroller" [ngClass]="{'overflow-y-scroll': !hasFilterValues(this)}">
        <ng-container *ngFor="let item of items | filterArray: {name: filterText}">
            <li class="dropdown-warp" role="button" (click)="itemActive($event, item)">
                <div class="filter-dropdown-item">
                    <input id="{{item.name}}" class="multi_checkbox"
                        [ngClass]="{'checked': item.checked}"
                        type="checkbox" [(ngModel)]="item.checked"/>
                    <label for="{{item.name}}" role="button">
                        <span class="item-label">{{item.name}}</span>
                    </label>
                </div>
            </li>
        </ng-container>
      </ul>
    </ul>
</div>