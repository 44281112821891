import { DateModule } from './../../../common/pipe/date.module';
import { CompanyCodeService } from './../content/NewRecord/SupplierNameSearch/company-code.service';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { CommonModule } from '@angular/common';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';;
import { DeletionModule } from '../component/deletion/deletion.module';
import { DeletionComponent } from '../component/deletion/deletion.component';

import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from "@angular/cdk/table";

import { secondarySupplierSearchComponent } from '../content/NewRecord/SecondarySupplierSearch/secondarySupplierSearch.component';
import { supplierNameSearchComponent } from '../content/NewRecord/SupplierNameSearch/supplierNameSearch.component';
import { ParentSearchComponent } from '../content/NewRecord/parent-search/parent-search.component';
import { TemplateNewRecordComponent } from '../content/NewRecord/TemplateMain/TemplateMain.component';
import { SelectionComponent } from '../component/Selection/selection.component';
import { RadioComponent } from '../component/radio/radio.component';
import { InputComponent } from '../component/input/input.component';
import { DateComponent } from '../component/date/date.component';
import { SingleSelectComponent } from '../component/single-select/single-select.component';
import { SingleSelectInputComponent } from '../component/single-select/single-select-input.component';
import { RadioYNComponent } from '../component/radioYN/radio.component';
import { RadioYComponent } from '../component/radioY/radioY.component';
import { RadioASTComponent } from '../component/radioAST/radio.component';
import { RadioCloudComponent } from '../component/radioCloud/radio.component';
import { CountryToolComponent } from '../component/country-tool/country-tool.component';
import { CountrySelectComponent } from '../component/country-select/country-select.component';
import { tabComponent } from '../component/commontable/commontable.component';
import { BasicInfoComponent } from '../component/recordForm/BasicInfo/BasicInfo.component';
import { RequestInfoComponent } from '../component/recordForm/RequestInfo/RequestInfo.component';
import { AgreementInfoComponent } from '../component/recordForm/AgreementInfo/AgreementInfo.component';
import { CategoryLocationComponent } from '../component/recordForm/CategoryLocation/CategoryLocation.component';
import { RiskComplianceComponent } from '../component/recordForm/RiskCompliance/RiskCompliance.component';
import { DocumentsComponent } from '../component/recordForm/Documents/Documents.component';
import { InformationComponent } from '../component/recordForm/Information/Information.component';
import { RecordViewComponent } from '../component/recordForm/RecordView/RecordView.component';
import { WarningTipsComponent } from '../component/warning-tips/warning-tips.component';
import { ErrorMessagePopupComponent } from '../component/error-message-popup/error-message-popup.component';
import { NotificationComponent } from '../component/notification/notification.component';
import { FileSizeModule } from 'app/common/pipe/fileSize.module';
import { PeoplePickerComponent } from '../component/peoplepicker/peoplepicker.component';
import { PeoplePickerForBCOComponent } from '../component/peoplepickerForBCO/peoplepickerForBCO.component';
import { ClientPickerComponent } from '../component/clientpicker/clientpicker.component';
import { CheckboxYNComponent } from '../component/checkboxYN/checkboxYN.component';
import { PredecessorIDComponent } from '../component/predecessorID/predecessorID.component';
import { DirectiveModule } from 'app/common/directive/directive.module';
import { SubStringColModule } from 'app/common/pipe/subStringCol.module';
import { TabelFilterModule } from '../component/tabel-filter';
import { TypeAheadModule } from '../component/type-ahead/type-ahead.module';
import { MultiSelectComponent } from '../component/multi-select/multi-select.component';
import { NewPaginatorComponent } from '../component/new-paginator/new-paginator.component';
import { DateModule as DateModuleCompatible } from 'app/common/pipe/date.module';
import { TooltipModule, TooltipOptions } from 'app/common/tooltip';
// import { NgZorroAntdModule } from 'ng-zorro-antd';
import { DatePipe } from 'app/common/pipe/date.pipe';
import { ProgressBarModule as CusProgressBarModule } from 'app/common/progress-bar';
import { DocumentOwnerPickerComponent } from '../component/document-owner-picker/document-owner-picker.component';
import { SecdocumentOwnerPickerComponent } from '../component/secdocument-owner-picker/secdocument-owner-picker.component';
import { MultiSelectButtonComponent } from '../component/multi-select-button/multi-select-button.component';
import { UserProfileComponent } from '../component/user-profile/user-profile.component';
import { DocOwnerTipComponent } from '../component/docOwnerTip/docOwnerTip.component';
@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    BsDatepickerModule.forRoot(),
    FileSizeModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    CdkTableModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    DirectiveModule,
    SubStringColModule,
    TabelFilterModule,
    TypeAheadModule,
    DateModuleCompatible,
    TooltipModule.forRoot({/* default tooltip-options */ } as TooltipOptions), // initialize custom, app-wide, default tooltip-options here
    // or import normally i.e. TooltipModule,
    // NgZorroAntdModule.forRoot(),
    DeletionModule,
    CusProgressBarModule,
  ],
  declarations: [tabComponent,
    secondarySupplierSearchComponent,
    supplierNameSearchComponent,
    ParentSearchComponent,
    TemplateNewRecordComponent,
    SelectionComponent,
    RadioComponent,
    InputComponent,
    DateComponent,
    SingleSelectComponent,
    SingleSelectInputComponent,
    RadioYNComponent,
    CountryToolComponent,
    CountrySelectComponent,
    BasicInfoComponent,
    RequestInfoComponent,
    AgreementInfoComponent,
    CategoryLocationComponent,
    RiskComplianceComponent,
    DocumentsComponent,
    InformationComponent,
    RecordViewComponent,
    WarningTipsComponent,
    PeoplePickerComponent,
    PeoplePickerForBCOComponent,
    ClientPickerComponent,
    ErrorMessagePopupComponent,
    CheckboxYNComponent,
    PredecessorIDComponent,
    MultiSelectComponent,
    NewPaginatorComponent,
    NotificationComponent,
    DocumentOwnerPickerComponent,
    SecdocumentOwnerPickerComponent,
    UserProfileComponent,
    MultiSelectButtonComponent,
    DocOwnerTipComponent,
    RadioYComponent,
    RadioASTComponent,
    RadioCloudComponent
  ],
  exports: [tabComponent,
    supplierNameSearchComponent,
    ParentSearchComponent,
    TemplateNewRecordComponent,
    SelectionComponent,
    RadioComponent,
    InputComponent,
    DateComponent,
    SingleSelectComponent,
    RadioYNComponent,
    CountryToolComponent,
    CountrySelectComponent,
    BasicInfoComponent,
    RequestInfoComponent,
    AgreementInfoComponent,
    CategoryLocationComponent,
    RiskComplianceComponent,
    DocumentsComponent,
    InformationComponent,
    RecordViewComponent,
    WarningTipsComponent,
    PeoplePickerComponent,
    PeoplePickerForBCOComponent,
    ErrorMessagePopupComponent,
    CheckboxYNComponent,
    PredecessorIDComponent,
    MultiSelectComponent,
    NewPaginatorComponent,
    DeletionComponent,
    NotificationComponent,
    DatePipe,
    DocumentOwnerPickerComponent,
    SecdocumentOwnerPickerComponent,
    UserProfileComponent,
    MultiSelectButtonComponent,
    DocOwnerTipComponent,
    RadioYComponent,
    RadioASTComponent,
    RadioCloudComponent
  ],
  providers: [
    CompanyCodeService
  ]
})

export class hostModule { }