import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterArray' })
export class FilterArrayPipe implements PipeTransform {
    transform(items: Array<any>, exponent: any): Array<any> {

        if(exponent === '' || exponent === null || exponent === undefined){
            return items;
        }

        let tempItems = [];
        if(typeof exponent === 'string' || typeof exponent === 'number'){
            tempItems = items.filter((member) => {
               return ('' + member).includes(exponent.toString());
            })
        }

        if(typeof exponent === 'object'){
            tempItems = items.filter((member) => {
                for(let key in exponent){
                    return ('' + member[key])
                    .toLocaleLowerCase()
                    .includes(('' + exponent[key]).toLocaleLowerCase());
                }
            })
        }
        return tempItems;
      }
}