<div *ngIf="isShowMask" class="mask"></div>
<div *ngIf="isShowSpinner" class="mask">
    <mat-spinner class="loading-spinner"></mat-spinner>
</div>
<div class="table-loading-shade" *ngIf="isShowLoad">
    <mat-spinner *ngIf="isShowLoad"></mat-spinner>
</div>
<div class="form_box">
    <div class="all_box" *ngIf="!isShowLoad">
        <div [ngClass]="{'errorTip':isShowErrorTip}" class="templateOption">
            <div [ngClass]="{'tip_error_div':isShowErrorTip}" (dblclick)="showteminp()" [tooltip]="temInpValue" class="optionBox">{{temInpValue}}</div>
            <div [clickBlank]="clickTempateOutSide.bind(this)">
                <input [(ngModel)]="temInpValue" class="templateInp" type="text" [tooltip]="temInpValue" maxlength="200" />
                <i (click)="showteminp()" class="mdui-icon material-icons edit_btn">create</i>
            </div>
            <i [ngClass]="{'errorTip':isShowErrorTip}" [clickBlank]="clickTemBtnOutSide.bind(this)" (click)="istemplateshow()"
                class="mdui-icon material-icons arr_btn tem_btn">expand_more</i>
            <ul #tem_select_ul class="tem_select_ul">
                <li (click)="selectedTem(i,item)" *ngFor="let item of temdatas; let i = index" [value]='item'><span [tooltip]="item">{{item}}</span></li>
            </ul>
        </div>
        <label *ngIf="isShowErrorTip" class="templateLab errorTipText">{{templateErrorTip}}</label>
        <div>
            <RecordView [inputsData]="RecordViewInputData" [fileInputer]="fileInputer" [templatePage]="templatePage" [templateDocPage]="templateDocPage"
                [recordTypeInputer]="recordTypeInputer" (docOutputer)="docOutHandler($event)"
                [secondarySuppliersInputer]="secondarySuppliersInputer"
                (sndSuppliersOutputer)="sndSupplierOutputHandler($event)"
                (showMaskOutputer)="showMaskOutputHandler($event)"></RecordView>
        </div>
        <div *ngIf="isShowLoad" class="load">
            <p class="loading">Loading...</p>
        </div>
        <!-- Buttons and Messages -->
        <div class="btm_box">
            <div class="msg_box">
                <span class="errorMsg" *ngIf="isShowLegalHoldWarning">*The parent record you selected is invalid. Please
                    select another parent record.</span>
                <span class="errorMsg" *ngIf="isNoValidation.valid">*Please complete all mandatory fields before
                    submitting</span>
                <span class="errorMsg" *ngIf="isSaveNoValidation.valid">*Please select parent or supporting
                    record</span>
                <span class="errorMsg" *ngIf="!isParentDocumentValid">*The parent record you selected is
                    invalid. Please
                    select another parent record.</span>
            </div>
            <div class="btn_box">
                <button type="submit" class="btn-shared btn-primary" [disabled]="isTemplateDisable"
                    (click)="DocumentOwnerValidation()">
                    <i class="mdui-icon material-icons sub_btn">save</i>
                    <span class="btn_txt">Save template</span>
                </button>
            </div>
        </div>
        <!-- Buttons and Messages End-->
    </div>
</div>
<div class="alert alert-success success_box" role="alert">
    <i class="mdui-icon material-icons success_ico fl">done</i>
    <span class="error_txt2 fl">Success: Template Saved Successfully !</span>
</div>
<div class="alert alert-danger errorstatus_box" role="alert">
    <i class="mdui-icon material-icons danger_ico fl">error</i>
    <span class="error_txt3 fl">Error: Template Saved failed! Please try again later! </span>
</div>
<!-- DocumentOwnerChange -->
<DocOwnerTip *ngIf="docOwnerChange" (isBtnDisable)="recoverfromDC()"></DocOwnerTip>