import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TypeAheadComponent } from "./type-ahead.component";

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        TypeAheadComponent,
    ],
    exports: [
        TypeAheadComponent,
    ],
    providers: [
      // TabelFilterService
    ],
})
export class TypeAheadModule {}