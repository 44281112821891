import { Component, OnInit, Input, Output, ChangeDetectorRef, EventEmitter } from '@angular/core';
// import { Message } from '@angular/compiler/src/i18n/i18n_ast';
@Component({
    selector: 'DocOwnerTip',
    templateUrl: './docOwnerTip.component.html',
    styleUrls: ['./docOwnerTip.component.css'],
})
export class DocOwnerTipComponent implements OnInit {

    @Output()
    public isBtnDisable: EventEmitter<any> = new EventEmitter<any>();
    @Input()
    public DocMessage: string = '';
    constructor () { }
    ngOnInit() {
        if(this.DocMessage == ''){
            this.DocMessage = "Document Owner has been changed, please re-process.";
        }
    }

    closeDCPopup(){
        this.isBtnDisable.emit();
    }
    
}
