export enum FilesUpload{
    Oversize = "File size exceeded!",
    TypeRestrict = "Invalid file type selected!",
    NameRestrict = "Invalid file name!",
    SameFileRestrict = "File name already uploaded",
    RecordTypeRestrict_selectParentandUploadMultipleFiles="Only one file can be uploaded to a parent record.",
    SameFileInSys = 'This file is already uploaded in MMPR',
}
export enum ExpectionInfo{    
    ExpectionTitle = "System Error",
    ExpectionMsg_MessageContent_Line1="Manage My Procurement Records is temporarily unavailable. Please try again later or contact the Operations team.",    
}
export enum OverloadInfo{    
    OverloadInfoTitle = "Notice please!",
    OverloadInfoMsg_MessageContent_Line1="We can download the contract file for maximum 10 records.",    
}
export enum UserList{
    Duplicate = "User already exists in MMPR.",
    Success = "Success"
}

export enum LegalHold{
    LegalHoldInfoIcon  = "This record has been placed on legal hold and cannot be edited until the hold is lifted."
   
}