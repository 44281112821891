<div class="mask"></div>
<div class="table-loading-shade" *ngIf="isQuery">
    <mat-spinner></mat-spinner>
</div>
<div class="profile_box">
    <div class="titlBox">
        <p class="team_title fl">My Profile</p>
        <i (click)="closePop()" class="mdui-icon material-icons close_icon fr">clear</i>
    </div>
    <div class="user_content">
        <div class="user_photo">
            <i class="mdui-icon material-icons photo_size">&#xe853;</i>
        </div>
        <div class="tab_content">
            <div class="content_box">
            <!-- User Name -->
            <div class="tab_line">
                <div class="titl_box fl">
                <span class="nowrap">
                    <label for="userName" class="fl">User Name</label>
                </span>
                </div>
                <div class="field_box fl">
                <span class="span_is_read nowrap" [tooltip] = "userName" >{{userName}}</span>
                </div>
            </div>
            <!-- User Name -->
            <!-- User EID -->
            <div class="tab_line">
                <div class="titl_box fl">
                <span class="nowrap">
                    <label for="userEID" class="fl">Enterprise ID</label>
                </span>
                </div>
                <div class="field_box fl">
                <span class="span_is_read nowrap" [tooltip] = "userEID">{{userEID}}</span>
                </div>
            </div>
            <!-- User EID -->
            <!--Country -->
            <div class="tab_line">
                <div class="titl_box fl">
                <label for="country" class="fl">Country/Location</label>
                </div>
                <div class="field_box fl">
                <span class="span_is_read nowrap" [tooltip] = "country" >{{country}}</span>
                </div>
            </div>
            <!-- Country -->
            <!-- Business Unit -->
            <div class="tab_line">
                <div class="titl_box fl">
                    <label for="businessUnits" class="fl">Business Unit</label>
                </div>
                <div class="field_box fl">
                    <span class="span_is_read nowrap" [tooltip] = "businessUnit" >{{businessUnit}}</span>
                </div>
            </div>
            <!-- Business Unit -->
            <!-- Other -->
            <div class="tab_line" *ngIf="businessUnit==='Other'">
                <div class="titl_box fl">
                <label for="otherForBU" class="fl">Other</label>
                </div>
                <div class="field_box fl">
                <span class="span_is_read nowrap" [tooltip] = "otherForBU" >{{otherForBU}}</span>
                </div>
            </div>
            <!-- Other -->
            <!-- Key Category -->
            <div class="tab_line">
                <div class="titl_box fl">
                <label for="keyCategories" class="fl">Key Category</label>
                </div>
                <div class="field_box fl">
                <span style="width:140%;" class="span_is_read nowrap" [tooltip] = "keyCategories" >{{keyCategories}}</span>
                </div>
            </div>
            <!-- Key Category -->
            <!-- GU -->
            <div class="tab_line">
                <div class="titl_box fl">
                <label for="GU" class="fl">MU</label>
                </div>
                <div class="field_box fl">
                <span class="span_is_read nowrap" [tooltip] = "GU" >{{GU}}</span>
                </div>
            </div>
            <!-- GU -->
            <!-- Access Level -->
            <div class="tab_line" style="padding-bottom: 0;">
                <div class="titl_box fl">
                <label for="accLevlList" class="fl">Access Level</label>
                </div>   
                <div class="field_box fl">
                <span class="span_is_read nowrap" [tooltip] = "accessLevel" >{{accessLevel}}</span>
                </div>     
            </div>
            <!-- Access Level -->
            <!-- Status -->
            <!-- <div class="tab_line">
                <div class="titl_box fl">
                <label for="userStatus" class="fl"><span class="asterisk">*</span>Status</label>
                </div>
                <div class="field_box fl">
                <div *ngIf="userStatus=='Active'" class="slideStatus">
                    <input type="checkbox" id="switchActive" disabled="true"/>
                    <label id="activeLable" for="switchActive" [ngClass]="{'activeStatus':userStatus=='Active'}">Toggle</label>
                </div>
                <div *ngIf="userStatus=='Inactive'" class="slideStatus">
                    <input type="checkbox" id="switchInactive" disabled="true"/>
                    <label id="inactiveLable" for="switchInactive" [ngClass]="{'inactiveStatus':userStatus=='Inactive'}">Toggle</label>
                </div>
                <div class="statusText">
                    <label for="slideStatus" class="fl">{{userStatus}}</label>
                </div>
                </div>
            </div>             -->
            <!-- Status -->
            </div>  
        </div>
    </div>
</div>