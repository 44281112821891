<table class="tab">
    <thead>
        <td>Agreement ID</td>
        <td>Supplier Name</td>
        <td>Agreement Name</td>
        <td>Agreement Type</td>
        <td>Country/Location</td>
        <td>Key Category</td>
        <td>Category</td>
        <td>Status</td>
        <td>Renewal Type</td>
        <td>Expiration Date</td>
    </thead>
    <tbody>
        <tr class="tab_txt">
            <td colspan="10" class="j_center">
                No Results Found
            </td>
            <!-- <td>P15/5587</td>
            <td>Dell</td>
            <td>DELL0617</td>
            <td>MSA</td>
            <td>Brazil</td>
            <td>Technology</td>
            <td>Hardware</td>
            <td>Active</td>
            <td>Evergreen</td>
            <td>23Dec-2018</td> -->
        </tr>
        <!-- <tr class="tab_txt">
            <td>P15/5587</td>
            <td>Dell</td>
            <td>DELL0617</td>
            <td>MSA</td>
            <td>Brazil</td>
            <td>Technology</td>
            <td>Hardware</td>
            <td>Active</td>
            <td>Evergreen</td>
            <td>23Dec-2018</td>
        </tr> -->
    </tbody>
</table>