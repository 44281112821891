import { Injectable, Inject } from "@angular/core";
import { environment } from "environments/environment";
import { RebarAuthService } from '../../../core/rebarauth/rebar.auth.service';


@Injectable()
export class SessionService {
  private Time: Date;
  constructor(private rebarAuthService: RebarAuthService) {
    this.Time = new Date();
  }
  public load() {
    setInterval(() => {
      const date = new Date();
      const noticeId = window.sessionStorage.getItem("upload.document");
      if (date.getTime() - this.Time.getTime() > 3300000 && !noticeId) {
        this.NoAccessFn();
      }
    }, 300000);
  }
  setTimeFn(time) {
    this.Time = time;
  }

  NoAccessFn() {
    sessionStorage.clear();
    // window.location.href = environment.logoutUri;
    this.rebarAuthService.logout();
  }
}
