

  <!--
      // TODO use 
    <muilt-selecet #muiltSelecet
  [items]="['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '10']"
   [itemSort]="'ASC'" (itemChange)="itemChange($event)"></muilt-selecet>

   // output selected items string
   {{muiltSelecet.getSelectedItemsString()}}
 -->

<ul [ngStyle]="ngStyle" class="filter-dropdown-menu" role="menu" (click)="stop($event);">
    <li class="dropdown-warp" role="button" (click)="stop($event);">
        <div class="filter-dropdown-item">
            <i class="mdui-icon material-icons expend_icon i-left">search</i>
            <input type="text" [(ngModel)]="filterText" 
                class="form-control input-control-left" placeholder="Type and Search">
        </div>
    </li>
    <li class="dropdown-warp" role="button">
        <div *ngIf="!istest" class="filter-dropdown-item">
            <input id="selectAll" class="multi_checkbox" [disabled]="filterText !== ''"
                [ngClass]="{'checked': selectAll.checked}"
                type="checkbox" [(ngModel)]="selectAll.checked"/>
            <label for="selectAll" role="button" (click)="selectedAllItem($event, selectAll)">
                <span class="item-label">Select All</span>
            </label>
            <label role="button" style="float: right;" (click)="restAllItems($event, selectAll)">Reset</label>
        </div>
        <div  *ngIf="istest" class="filter-dropdown-item">
            <input style="cursor: default" id="selectAll" class="multi_checkbox" disabled
                [ngClass]="{'checked': selectAll.checked}"
                type="checkbox" [(ngModel)]="selectAll.checked"/>
            <label style="cursor: default" for="selectAll" role="button">
                <span class="item-label">Select All</span>
            </label>
            <label role="button" style="float: right;" (click)="restAllItems($event, selectAll)">Reset</label>
        </div>
    </li>
    <li class="divider dropdown-divider"></li>
    <ul class="scroller" [ngClass]="{'overflow-y-scroll': !hasFilterValues(this)}">
        <ng-container *ngFor="let item of items | filterArray: {name: filterText}">
            <li class="dropdown-warp" role="button" (click)="itemActive($event, item)">
                <div  class="filter-dropdown-item">
                    <input [disabled]="item.isDisable" id="{{item.name}}" class="multi_checkbox"
                        [ngClass]="{'checked': item.checked}"
                        type="checkbox" [(ngModel)]="item.checked"/>
                    <label  for="{{item.name}}" role="button">
                        <span class="item-label">{{item.name}}</span>
                    </label>
                </div>
            </li>
        </ng-container>
    </ul>
</ul>
