import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'com-tab',
  templateUrl: './commontable.component.html',
  styleUrls: ['./commontable.component.css']
})
export class tabComponent implements OnInit {
  constructor() { }
  ngOnInit() {

  }

}